import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../../images/back-icon.svg";
import React, {Fragment, useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ListItemSecondaryAction} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import {makeStyles} from "@mui/styles";
import {useTranslation} from 'react-i18next'
import themeContext from "../../components/ThemeContext.js";
import {
    ExternalPageLayout,
    StyledPageCardColumn,
    StyledPageLayout,
    StyledTitlePage
} from "../../styles/DesktopStyles.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";


const useStyles = makeStyles((theme) => ({
    listFullItem: {
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '16px !important',
        paddingBottom: '16px !important',
        fontSize: '18px !important'
    },
}));

export default function MessagePreferences({layout}) {
    const root = 'messagePreferences';
    const {open} = useContext(SidebarContext);
    const navigate = useNavigate();
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    function turnBack() {
        return navigate(-1);
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCardColumn>
                {
                    <Fragment>
                        <StyledPageLayout className="mx-4">
                            <StyledTitlePage>
                                <Col className="col-2 float-left">
                                    {
                                        layout !== 'desktop' &&
                                        <img src={back} onClick={turnBack}
                                             className="nav-top-logo float-left cursor-pointer"
                                             height="27px"
                                             alt="back"/>
                                    }
                                </Col>
                                <Col className="col-8">
                                    <h6 className="header-title ellipsis-1-v2">{t(`${root}.title`)}</h6>
                                </Col>
                                <Col className="col-2">
                                </Col>
                            </StyledTitlePage>
                            <Row>
                                <Col className="col-list">
                                    <div>
                                        <List style={{'paddingTop': '0px'}}>
                                            <Fragment>
                                                <ListItem className={classes.listFullItem}>
                                                    <ListItemText id="switch-list-label-all-messages"
                                                                  primary={t(`${root}.list-all-messages`)}/>
                                                    <ListItemSecondaryAction className={classes.ListItem}>
                                                        <label className="form-switch" style={{'opacity': '0.5'}}>
                                                            <input
                                                                id="webauthn-switch"
                                                                type="checkbox"
                                                                disabled
                                                                defaultChecked
                                                            />
                                                            <i></i>
                                                        </label>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                {process.env.REACT_APP_ENV !== "PROD" &&
                                                    <Fragment>
                                                        <Divider/>
                                                        <ListItem className={classes.listFullItem}>
                                                            <ListItemText id="switch-list-label-marketing-messages"
                                                                          primary={t(`${root}.list-marketing-messages`)}/>
                                                            <ListItemSecondaryAction className={classes.ListItem}>
                                                                <label className="form-switch"
                                                                       style={{'opacity': '0.5'}}>
                                                                    <input
                                                                        id="webauthn-switch"
                                                                        type="checkbox"
                                                                        disabled
                                                                        checked={false}
                                                                    />
                                                                    <i></i>
                                                                </label>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </Fragment>}
                                            </Fragment>
                                        </List>
                                    </div>
                                </Col>
                            </Row>
                        </StyledPageLayout>
                    </Fragment>
                }
            </StyledPageCardColumn>
        </ExternalPageLayout>
    )
}
