import {API_METHODS, apiFetch} from "./ApiService.js";
import {operationCompleteUrl, operationCreateOrUpdateUrl} from "./EndpointService.js";

export const operationCreateOrUpdate = async (token_arg, type, id) => {
    let url = `${operationCreateOrUpdateUrl}`;
    try {
        return await apiFetch(url, token_arg, API_METHODS.POST, {
            type: type,
            entityId: id
        });
    } catch (error) {
        return null;
    }
}

export const operationComplete = async (token_arg, type, id) => {
    let url = `${operationCompleteUrl}`;
    try {
        return await apiFetch(url, token_arg, API_METHODS.POST, {
            type: type,
            entityId: id
        });
    } catch (error) {
        return null;
    }
}
