import themeContext from "../components/ThemeContext.js";
import {styled} from "@mui/system";
import {setBackgroundStatusColor, setStatusColorDesktop} from "../service/DashboardService.js";
import Badge from "@mui/material/Badge";
import {CARD_TYPE} from "../components/desktop/ListCard.js";
import Divider from "@mui/material/Divider";
import {Button} from "@mui/material";
import MuiCard from "@mui/material/Card";
import {Row} from "react-bootstrap";

export const StyledDrawer = {
    [`& .MuiDrawer-paper`]: {
        borderRadius: '16px',
        border: `0.3px solid ${themeContext.color.neutral200}`,
    }
};

export const StyledChevronIcon = styled('h6')({
    color: themeContext.color.neutral200,
    textAlign: 'left',
    lineHeight: '1',
    fontWeight: '500',
    fontSize: '12px',
    textTransform: 'uppercase',
});

export const Spinner = styled('img')(({theme}) => ({
    margin: '20px auto',
    minHeight: '100px',
    width: '50px',
    transform: 'translate(-50%, -50%)'
}));

export const StatusLabel = styled('div')(({color, type}) => ({
    borderRadius: '8px',
    padding: '2px 6px 3px 6px',
    fontWeight: 500,
    fontSize: '12px',
    color: type === CARD_TYPE.POLICY ? setStatusColorDesktop(color) : color.color,
    background: type === CARD_TYPE.POLICY ? setBackgroundStatusColor(color) : color.background,
}));

export const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        background: themeContext.color.red,
        color: themeContext.color.white
    },
}));

export const StyledBadgeEmpty = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        border: `${themeContext.spacing.borders.border4} ${themeContext.color.white}`,
        background: themeContext.color.red,
        color: themeContext.color.white,
    },
}));

export const StyledDivider = styled(Divider)({
    borderColor: themeContext.color.neutral100,
    width: '100%'
});

export const StyledButtonAction = styled(Button)(({theme}) => ({
    background: themeContext.color.white,
    color: themeContext.color.neutral400,
    fontSize: '12px',
    textTransform: 'none',
    borderRadius: 16,
    '&:hover': {
        background: themeContext.color.defaultBackground,
    },
}));

export const StyledBigDialog = {
    color: themeContext.color.neutral600,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderRadius: 24,
    scrollbarWidth: 'none',
    background: themeContext.color.white,
    minHeight: 300
}

export const StyledFixedBigDialog = {
    color: themeContext.color.neutral600,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderRadius: 24,
    scrollbarWidth: 'none',
    background: themeContext.color.white,
    height: 741
}

export const StyledNoItemsDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: themeContext.color.neutral100,
    padding: 24,
    outline: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
    borderRadius: 16
});

export const SpanNoWrap = styled('span')({
    whiteSpace: 'nowrap',
});

export const ExternalPageLayout = styled('div')(({open}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: '65px',
    paddingTop: '8px',
    marginLeft: open ? '269px' : '65px',
    gap: '8px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    //width: '100%',
}));

export const StyledPageCard = styled(MuiCard)(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '24px',
    outline: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
    width: '100%',
    boxShadow: 'none',
    //cursor: skeleton === 'true' ? '' : 'pointer',
}));

export const StyledPageCardColumn = styled(MuiCard)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '24px',
    outline: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
    width: '100%',
    boxShadow: 'none',
    //cursor: skeleton === 'true' ? '' : 'pointer',
}));

export const StyledPageLayout = styled('div')(({}) => ({
    background: themeContext.color.white
}));


export const StyledTitlePage = styled(Row)(({}) => ({
    height: '70px',
    width: '100%',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
}));

export const StyledTitlePageWithImage = styled(Row)(({height}) => ({
    height: height ? height : '200px',
    width: '100%',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
}));