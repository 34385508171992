import Toolbar from "@mui/material/Toolbar";
import wideLogoLight from "../../../images/wideLogo-icon-new.svg";
import * as React from "react";
import {useContext, useEffect} from "react";
import MuiAppBar from "@mui/material/AppBar";
import {alpha, styled} from "@mui/material/styles";
import themeContext from "../ThemeContext.js";
import {Button, Chip, Menu, MenuItem, Slide, TextField} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {useNavigate} from "react-router-dom";
import AuthContextMiddleware from "../../middleware/AuthContextMiddleware.js";
import {getUserInfo} from "../../service/ProfileService.js";
import spinner from "../../../images/spinner.svg";
import {useTranslation} from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import {Col, Form, Row} from "react-bootstrap";
import exitIcon from "../../../images/exit-icon.svg";
import DialogContent from "@mui/material/DialogContent";
import Container from "react-bootstrap/Container";
import Dialog from "@mui/material/Dialog";
import jwt_decode from "jwt-decode";
import {DetailBarContext} from "../../middleware/DetailBarContext.js";

const drawerWidth = 244;

/*const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
        {
            props: ({open}) => open,
            style: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
    ],
}));*/

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme}) => ({
    //paddingLeft: '32px',
    paddingRight: '8px',
    zIndex: theme.zIndex.drawer + 1,
    background: 'white',
    boxShadow: 'none',
    borderBottom: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center'
}));

const StyledMenu = styled((props) => (
    <Menu
        disableScrollLock={true}
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderColor: themeContext.color.neutral100,
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: 'rgb(55, 65, 81)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
        ...theme.applyStyles('dark', {
            color: theme.palette.grey[300],
        }),
    },
}));

const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme}) => ({
    //margin: '4px',
    //marginRight: '16px',
    background: themeContext.color.neutral200,
    color: themeContext.color.neutral600,
    flexDirection: 'row-reverse',
    borderRadius: '16px',
}));

const AppVersionLabel = styled('span', {})(({theme}) => ({
    color: themeContext.color.neutral400,
    fontSize: '12px',
    fontWeight: 600,
}));

const Header = ({open, action}) => {
    const isUpdatedUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/isUpdated`;
    const removeTokenUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/removeToken`;
    const switchLoginUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/switchLogin`;
    const token = sessionStorage.getItem('token')
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [username, setUsername] = React.useState("");
    const openMenu = Boolean(anchorEl);
    const authCtx = useContext(AuthContextMiddleware);
    const {t} = useTranslation();
    const [openAlternativeLoginDialog, setOpenAlternativeLoginDialog] = React.useState(false);
    const [emailTarget, setEmailTarget] = React.useState('');
    const [emailTargetError, setEmailTargetError] = React.useState(null);
    const [isLoadingAlternativeLogin, setIsLoadingAlternativeLogin] = React.useState(false);
    const {updateData} = useContext(DetailBarContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function updateAndLogout(appVersion) {
        fetch(removeTokenUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: sessionStorage.getItem('firebaseToken')
            }),
        }).then((response) => {
            console.log(response)
            fetch(isUpdatedUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                return response.json();
            }).then(async (res) => {
                if (res.ok) {
                    if (res.message > appVersion) {
                        navigator.serviceWorker.getRegistrations().then((r) => {
                            return Promise.all(r.map(reg => reg.unregister()));
                        }).then(() => {
                            let username = localStorage.getItem('username-wide')
                            let biometrics = localStorage.getItem('biometrics')
                            let webauthn = localStorage.getItem('webauthn')
                            let firstLogin = false;
                            if (localStorage.getItem('firstLogin')) {
                                firstLogin = localStorage.getItem('firstLogin')
                            }
                            authCtx.logout();
                            sessionStorage.clear();
                            localStorage.clear();
                            if (username !== null) {
                                localStorage.setItem('username-wide', username);
                            }
                            if (biometrics !== null) {
                                localStorage.setItem('biometrics', biometrics);
                            }
                            if (webauthn !== null) {
                                localStorage.setItem('webauthn', webauthn);
                            }
                            if (firstLogin === true) {
                                localStorage.setItem('firstLogin', firstLogin);
                            }
                            window.location.reload();
                        });
                    } else {
                        authCtx.logout();
                        window.location.reload();
                    }
                }
            }).catch(() => {
                authCtx.logout();
                window.location.reload();
            })
        })
    }

    const switchLogin = (e) => {
        e.preventDefault();
        setIsLoadingAlternativeLogin(true)
        fetch(switchLoginUrl, {
            method: 'POST',
            body: JSON.stringify({
                email: emailTarget,
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                setEmailTargetError(t('settings.email-target-error'));
                return res.text().then(text => {
                    setIsLoadingAlternativeLogin(false)
                    throw new Error(text)
                })
            }
        }).then((data) => {
            if (data) {
                let decoded = jwt_decode(data.accessToken);
                if (localStorage.getItem('username-wide')) {
                    let prevUsername = localStorage.getItem('username-wide');
                    if (prevUsername !== decoded.email) {
                        sessionStorage.clear();
                        localStorage.removeItem('webauthn')
                    }
                }
                localStorage.setItem('username-wide', decoded.email);
                sessionStorage.removeItem('login_error');
                authCtx.login(data.accessToken, data.refreshToken, data.pinCode);
                setIsLoadingAlternativeLogin(false)
                if (!window.location.href.includes('/dashboard')) {
                    navigate('/dashboard');
                }
                window.location.reload();
            } else {
                setEmailTargetError(t('settings.email-target-error'));
                setIsLoadingAlternativeLogin(false)
            }
        }).catch(() => {
            setEmailTargetError(t('settings.email-target-error'));
            setIsLoadingAlternativeLogin(false)
        });
    }

    useEffect(() => {
        getUserInfo(token).then(res => {
            if (res && res.name) {
                setUsername(res.name)
            } else if (res && res.email) {
                setUsername(res.email)
            } else {
                setUsername(t('desktop.header.not available'))
            }
        })
    }, []);

    return (
        <AppBar position="fixed">
            <Toolbar>
                <img src={wideLogoLight} alt="wide-logo" height={16}/>
            </Toolbar>
            <div className={'d-flex flex-row align-items-center'} style={{gap: 16}}>
                <AppVersionLabel>V {process.env.REACT_APP_VERSION}</AppVersionLabel>
                <StyledChip icon={<ExpandMore style={{marginRight: '8px'}}/>}
                            label={username ? username : <img src={spinner} className="spinner-img" alt="spinner"/>}
                            onClick={handleClick}/>
            </div>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}>
                <MenuItem onClick={() => {
                    navigate('/settings/profile')
                    handleClose();
                    updateData(null)
                }} disableRipple>
                    {t('desktop.header.profile')}
                </MenuItem>
                {
                    authCtx.switchUser &&
                    <MenuItem onClick={() => {
                        setOpenAlternativeLoginDialog(true);
                        handleClose();
                    }} disableRipple>
                        {t('desktop.header.switch-login')}
                    </MenuItem>
                }
                <MenuItem onClick={() => {
                    updateAndLogout()
                    handleClose();
                }} disableRipple>
                    {t('desktop.header.logout')}
                </MenuItem>
            </StyledMenu>

            <Dialog
                onClose={() => {
                    setOpenAlternativeLoginDialog(false)
                    setEmailTargetError(null);
                    setEmailTarget('');
                }}
                fullWidth={true}
                maxWidth={'sm'}
                PaperProps={{
                    style: {
                        bottom: '5%',
                        color: themeContext.color.neutral600,
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        borderRadius: '20px',
                        height: '90%',
                        marginTop: '10%'
                    }
                }}
                open={openAlternativeLoginDialog}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">

                <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                             style={{textAlign: 'center'}}>
                    <Row style={{justifyContent: 'end'}}>
                        <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                            setOpenAlternativeLoginDialog(false)
                        }} style={{float: 'inline-end'}}/>
                    </Row>
                    <Row style={{marginTop: '-12px'}}>
                        <Col className="col">
                            <h6 className="dialog-form-title-1 float-left text-left">{t('settings.list-alternative-login')}</h6>
                        </Col>
                    </Row>
                </DialogTitle>
                <Form onSubmit={switchLogin}>
                    <DialogContent className="no-scrollbar">
                        <div className="dialog-main-container">
                            <Row className="mt-2">
                                <Col>
                                    <h6 className="dialog-form-title-2">
                                        {t('settings.email-target')}
                                    </h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        value={emailTarget}
                                        onChange={(e) => {
                                            setEmailTarget(e.target.value)
                                            if (!!emailTargetError) {
                                                setEmailTargetError(null)
                                            }
                                        }}
                                        id={`email`}
                                        name={'email'}
                                        fullWidth
                                        placeholder=""
                                        variant="outlined"
                                        required={true}
                                        type="email"
                                        color="primary"
                                        error={!!emailTargetError}
                                        helperText={emailTargetError}
                                        InputProps={{
                                            style: {
                                                'borderRadius': '4px',
                                                color: themeContext.color.neutral600
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Container className="position-absolute"
                                       style={
                                           {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 48,
                                               right: 0
                                           }
                                       }>
                                <Row style={{textAlign: 'center'}}>
                                    <Col>
                                        <Button className="close-button"
                                                variant="outlined"
                                                size="large"
                                                style={{
                                                    color: themeContext.color.white,
                                                    width: '288px',
                                                    textTransform: 'none'
                                                }}
                                                type="submit"
                                                disabled={false}>
                                            {isLoadingAlternativeLogin ?
                                                <img src={spinner} className="spinner-img"
                                                     alt="spinner"/> : t('settings.alternative-login-button')}</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </DialogContent>
                </Form>
            </Dialog>
        </AppBar>
    );
}

export default Header;
