import React, {Fragment, useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {fetchToken} from "../middleware/Firebase.js";
import {Button, Dialog, ListItemButton, MenuItem, Select, Slide, TextField} from "@mui/material";
import {isIpad, isMac, isStandalone, putElementFromIndexedDB} from "../service/UtilityService.js";
import BellOff from '../../images/bell-icon.svg'
import BellOn from '../../images/bellOn-icon.svg'
import wideLogoLight from '../../images/wideLogo-icon.svg';
import {ClientJS} from 'clientjs';
import {makeStyles} from '@mui/styles';
import Card from '@mui/material/Card';
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Avatar from "@mui/material/Avatar";
import baseMaleAvatar from "../../images/male-icon.svg";
import baseFemaleAvatar from "../../images/female-icon.svg";
import vectorIcon from "../../images/policy_branches/vector.svg";
import artCollectionIcon from "../../images/policy_branches/art-collections-icon.svg";
import autoCvtIcon from "../../images/policy_branches/auto-cvt-icon.svg";
import aviationIcon from "../../images/policy_branches/aviation-icon.svg";
import buildingsIcon from "../../images/policy_branches/buildings-icon.svg";
import cablewayIcon from "../../images/policy_branches/cableway-icon.svg";
import certifiedTransportationIcon from "../../images/policy_branches/certified-transportation-icon.svg";
import civiliansIcon from "../../images/policy_branches/civilians-icon.svg";
import collectiveLivesIcon from "../../images/policy_branches/collective-lives-icon.svg";
import companiesIcon from "../../images/policy_branches/companies-icon.svg";
import depositsIcon from "../../images/policy_branches/deposits-icon.svg";
import electronicIcon from "../../images/policy_branches/electronic-icon.svg";
import energyIcon from "../../images/policy_branches/energy-icon.svg";
import farmingIcon from "../../images/policy_branches/farming-icon.svg";
import horecaIcon from "../../images/policy_branches/horeca-icon.svg";
import illnessIcon from "../../images/policy_branches/illness-icon.svg";
import individualLivesIcon from "../../images/policy_branches/individual-lives-icon.svg";
import injuresIcon from "../../images/policy_branches/injures-icon.svg";
import jewellersIcon from "../../images/policy_branches/jewellers-icon.svg";
import legalProtectionIcon from "../../images/policy_branches/legal-protection-icon.svg";
import officeLaboratoriesIcon from "../../images/policy_branches/office-laboratories-icon.svg";
import petInsuranceIcon from "../../images/policy_branches/pet-insurance-icon.svg";
import rcAdminIcon from "../../images/policy_branches/rc-admin-icon.svg";
import rcClinicalTrialsIcon from "../../images/policy_branches/rc-clinical-trials-icon.svg";
import rcIndividuals from "../../images/policy_branches/rc-individuals-icon.svg";
import rcMedicalMalpracticeIcon from "../../images/policy_branches/rc-medical-malpractice-icon.svg";
import rcPollutionIcon from "../../images/policy_branches/rc-pollution-icon.svg";
import rcProfessionalIcon from "../../images/policy_branches/rc-professional-icon.svg";
import shipIcon from "../../images/policy_branches/ship-icon.svg";
import technologiesIcon from "../../images/policy_branches/technologies-icon.svg";
import theftIcon from "../../images/policy_branches/theft-icon.svg";
import thirdSectorIcon from "../../images/policy_branches/third-sector-icon.svg";
import tourOperatorIcon from "../../images/policy_branches/tour-operator-icon.svg";
import tradeCreditsIcon from "../../images/policy_branches/trade-credits-icon.svg";
import travelBusinnessLineIcon from "../../images/policy_branches/travel-businnes-line-icon.svg";
import wheaterIcon from "../../images/policy_branches/wheater-icon.svg";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import exitIcon from "../../images/exit-icon.svg";
import {useMediaQuery} from "react-responsive";
import CardContent from "@mui/material/CardContent";
import nothing from "../../images/nothing-icon.svg";
import CurrencyFormat from 'react-currency-format';
import Header from "../components/mobile/Header.js";
import currencyCodes from "currency-codes";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import spinner from "../../images/spinner.svg";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import ErrorPage from "../pages/ErrorPage.js";
import {NavbarContext} from "../middleware/NavbarContext.js";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100% !important',
    },
    card: {
        margin: "0 auto !important",
        marginBottom: "18px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        minHeight: "140px !important"
    },
    cardTextMid: {
        color: `${themeContext.color.neutral600} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '400 !important',
        fontSize: '12px !important',
        paddingTop: '10px !important',
        paddingBottom: '7px !important',
    },
    personAvatar: {
        width: "30px !important",
        height: "30px !important",
    },
    listItem: {
        width: "100% !important",
        fontSize: "14px !important",
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '18px !important',
        paddingBottom: '18px !important',
    },
    subtitle: {
        textAlign: "left !important",
        fontSize: "18px !important",
        fontStyle: "normal !important",
        lineHeight: "normal !important",
        fontWeight: "700 !important",
        color: `${themeContext.color.neutral400} !important`,
    },
    CTAButton: {
        fontWeight: '500 !important',
        fontSize: '14px !important',
        fontStyle: 'normal !important',
        lineHeight: 'normal !important',
        color: `${themeContext.color.mainActive} !important`,
        textTransform: 'uppercase !important',
        float: 'left !important',
        marginBottom: '8px !important'
    },
    revisionButton: {
        fontWeight: '500 !important',
        fontSize: '14px !important',
        fontStyle: 'normal !important',
        lineHeight: 'normal !important',
        color: `${themeContext.color.yellow} !important`,
        textTransform: 'uppercase !important',
        float: 'left !important',
        marginBottom: '8px !important'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Refunds({layout}) {
    const root = 'refunds';
    let defaultValues = {
        accountHolder: "",
        iban: "",
        swift: "",
        currency: "EUR"
    };
    const authCtx = useContext(AuthContextMiddleware);
    const checkReadUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/checkRead`;
    const updateBankRefUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/updateBankRef`;
    const checkIbanUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/checkIban`;
    const getBankRefsByUserUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/getBankRefsByUser`;
    const getBankRefsByNameUrl = `${process.env.REACT_APP_BASE_URL}/media/refundPremium/getBankRefByName`;
    const getAllRefundPremiumUrl = `${process.env.REACT_APP_BASE_URL}/media/refundPremium/getAllRefundPremium`;
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const getBankRefsByPersonIdUrl = `${process.env.REACT_APP_BASE_URL}/media/refundPremium/getBankRefByPersonId`;
    const checkSwiftUrl = `${process.env.REACT_APP_BASE_URL}/media/refundPremium/checkSwift`;
    const confirmIbanUrl = `${process.env.REACT_APP_BASE_URL}/media/refundPremium/confirmIban`;
    const token = sessionStorage.getItem('token');
    const [isLoadingData, setIsLoadingData] = useState(false);
    const navigate = useNavigate();
    const [openNestedNotSigned, setOpenNestedNotSigned] = useState(0);
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [errors, setErrors] = useState({});
    const client = new ClientJS();
    const [bellRinging, setBellRinging] = useState(false);
    const classes = useStyles();
    const [people, setPeople] = useState([]);
    const [getPermission, setGetPermission] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedPolicyNr, setSelectedPolicyNr] = useState(null);
    const [selectedRequestedPremium, setSelectedRequestedPremium] = useState(null);
    const [selectedNotificationId, setSelectedNotificationId] = useState(null);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [openConfirmIbanDialog, setOpenConfirmIbanDialog] = useState(false);
    const [user, setUser] = useState("");
    const [noRefunds, setNoRefunds] = useState(false);
    const [formValues, setFormValues] = useState(defaultValues)
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState('');
    const [holders, setHolders] = useState([])
    const [initialForm, setInitialForm] = useState(defaultValues);
    const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
    const {t, i18n} = useTranslation();
    const {toggleNavbar} = useContext(NavbarContext);


    useEffect(() => {
        toggleNavbar(true)
        sessionStorage.setItem('root', '/signing');
        if (navigator.onLine) {
            if (sessionStorage.getItem("notificationId") != null) {
                const url = sessionStorage.getItem("notificationId").replaceAll('"', '')
                navigateToMessageDetails(url);
            }
            handleRinging();
            getInfo();
            getRefunds();
        } else {
            //
        }
    }, []);


    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || (isMac() && client.getOSVersion() < "13") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
        //console.log('notification not allowed')
    } else if (!getPermission) {
        requestPermission();
        setGetPermission(true);
    }
    fetchToken();

    function requestPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
            }
        })
    }

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || (isMac() && client.getOSVersion() < "13") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
        //console.log('notification not allowed')
    } else {
        const channel = new BroadcastChannel('sw-messages');
        channel.onmessage = (event) => {
            setBellRinging(true)
        };
        channel.onmessageerror = (event) => {
        };
    }

    const navigateToMessageDetails = (url) => {
        return navigate(`/messages/principal/${url}`);
    }

    const getBranchIcon = (policyType) => {
        switch (policyType) {
            case "TUTELA LEGALE":
                return legalProtectionIcon;
            case "AGRICOLTURA":
                return farmingIcon;
            case "AUTO (RCA - CVT)":
            case "AUTO (CVT)":
            case "CVT AUTO D'EPOCA E SPORTIVE":
            case "CVT SOCCORSO":
            case "KASKO CHILOMETRICA":
                return autoCvtIcon;
            case "PET INSURANCE":
                return petInsuranceIcon;
            case "CAUZIONI":
                return depositsIcon;
            case "CREDITI COMMERCIALI":
                return tradeCreditsIcon;
            case "ELETTRONICA":
                return electronicIcon;
            case "ENERGY":
                return energyIcon;
            case "RC AMMINISTRATORI (D&O)":
            case "RC COLPA GRAVE":
                return rcAdminIcon;
            case "FINE ART E COLLEZIONI":
                return artCollectionIcon;
            case "FURTO":
            case "FURTO PRIVATI":
                return theftIcon;
            case "GLOBALE GIOIELLERIE":
                return jewellersIcon;
            case "GLOBALE FABBRICATI":
                return buildingsIcon;
            case "INFORTUNI":
            case "INFORTUNI COLLETTIVA":
            case "INFORTUNI CONDUCENTI":
            case "INFORTUNI E MALATTIA":
            case "INFORTUNI TERZO SETTORE":
            case "PRESTAZIONI DI ASSISTENZA SANITARIA":
                return injuresIcon;
            case "MALATTIA":
                return illnessIcon;
            case "METEO":
                return wheaterIcon;
            case "VIAGGI LINEA BUSINESS":
            case "VIAGGI LINEA PERSONA":
                return travelBusinnessLineIcon;
            case "MULTIRISCHI AZIENDE":
            case "PROPERTY AZIENDE":
            case "RC AZIENDE":
                return companiesIcon;
            case "MULTIRISCHI HO.RE.CA.":
                return horecaIcon;
            case "MULTIRISCHI TERZO SETTORE":
            case "RC TERZO SETTORE":
                return thirdSectorIcon;
            case "MULTIRISCHI CIVILI":
            case "PROPERTY RISCHI CIVILI":
                return civiliansIcon;
            case "MULTIRISCHI UFFICI/LABORATORI":
                return officeLaboratoriesIcon;
            case "RC INQUINAMENTO":
                return rcPollutionIcon;
            case "RC MEDICAL MALPRACTICE":
                return rcMedicalMalpracticeIcon;
            case "RC PRIVATI":
                return rcIndividuals;
            case "RC PROFESSIONALE":
                return rcProfessionalIcon;
            case "RC SPERIMENTAZIONE CLINICA":
                return certifiedTransportationIcon;
            case "TECNOLOGICI":
                return technologiesIcon;
            case "AGENZIE VIAGGI":
            case "TOUR OPERATOR / AGENZIA VIAGGI":
                return tourOperatorIcon;
            case "TRASPORTI CERTIFICATI IN ABBONAMENTO":
            case "TRASPORTO MERCI":
            case "STOCK & TRANSIT":
            case "SPEDIZIONIERI E VETTORI":
                return rcClinicalTrialsIcon;
            case "CORPI":
            case "RC E CORPI NATANTI":
            case "P&I PROTECTION & INDEMNITY":
                return shipIcon;
            case "VITA COLLETTIVE":
            case "RC PATRIMONIALE COLPA LIEVE":
                return collectiveLivesIcon;
            case "VITA INDIVIDUALI":
                return individualLivesIcon;
            case "AVIAZIONE":
                return aviationIcon;
            case "PERDITE PECUNIARIE":
                return depositsIcon;
            case "CYBER RISK":
                //icona mancante
                return theftIcon;
            case "MULTIRISCHI IMPIANTI A FUNE":
                return cablewayIcon;
            default:
                return vectorIcon;
        }
    }

    function getInfo() {
        fetch(userInfoUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            i18n.changeLanguage(data.preferredLanguage).then(r => {
            })
            if (data.status !== -1) {
                setUser(data)
                localStorage.setItem('wideAPP_userId', data.id)
                putElementFromIndexedDB('profileInfo', data)
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const handleRinging = () => {
        fetch(checkReadUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json',},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.toRead) {
                setBellRinging(true);
            } else {
                setBellRinging(false);
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const submitForm = async (event) => {
        event.preventDefault();
        const newErrors = {};
        if (!formValues.iban) {
            newErrors.iban = t('IBAN not valid');
        } else {
            try {
                const response = await checkIban(formValues.iban);
                const data = await response.json();
                if (data.message === "IBAN not valid" || data.message === "IBAN not usable") {
                    newErrors.iban = t('IBAN not valid');
                }
            } catch (e) {
                newErrors.iban = t('IBAN validation error');
            }
        }
        if (!formValues.accountHolder) {
            newErrors.accountHolder = t('Required field');
        }
        if (formValues.currency !== 'EUR' && !formValues.swift) {
            newErrors.swift = t('This field is required for currency not equal to EUR');
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        if (JSON.stringify(formValues) === JSON.stringify(initialForm)) {
            setErrors({})
            setOpenDetailsDialog(false)
            return;
        }
        checkSwift();
    }

    const checkSwift = () => {
        fetch(checkSwiftUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                swiftCode: formValues.swift,
                currency: formValues.currency
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                authCtx.storeBankRefsToUpdate(selectedPerson, formValues.accountHolder, formValues.iban.toUpperCase().split(" ").join(""), formValues.swift, formValues.currency)
                return navigate(`/refunds/${selectedPerson}/upload`);
            } else {
                const newErrors = {};
                if (data.message.includes('Swift')) {
                    newErrors.swift = t(data.message);
                }
                if (Object.keys(newErrors).length > 0) {
                    setErrors(newErrors);
                }
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        switch (name) {
            case 'accountHolder':
                getBankRefByName(value);
                break;
            case 'iban':
                setFormValues({
                    ...formValues,
                    [`${name}`]: value,
                });
                checkIban(value).then(response => {
                    return response.json();
                }).then((data) => {
                    if (data.exist) {
                        getBankRefByName(data.accountHolder);
                    }
                });
                break;
            case 'currency':
                setFormValues({
                    ...formValues,
                    [`${name}`]: value,
                });
                if (value === 'EUR' && errors.swift) {
                    setErrors({
                        ...errors,
                        ['swift']: null
                    })
                }
                break;
            default:
                setFormValues({
                    ...formValues,
                    [`${name}`]: value,
                });
                break;
        }
    }

    function reload() {
        window.location.reload();
    }

    const handleClick = (index) => {
        if (openNestedNotSigned === index) {
            setOpenNestedNotSigned(-1)
        } else {
            setOpenNestedNotSigned(index)
        }
    }

    const checkIban = (iban) => {
        return fetch(checkIbanUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                iban: iban.toUpperCase().split(" ").join(""),
            }),
        });
    }

    const getAllBankRefs = (selectedPersonId, selectedName) => {
        setIsLoadingForm(true)
        fetch(getBankRefsByUserUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setHolders(data)
            getBankRefsByPersonId(selectedPersonId, selectedName)
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const getBankRefByName = (name) => {
        fetch(getBankRefsByNameUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setFormValues({
                ...formValues,
                accountHolder: name,
                iban: data.iban || '',
                currency: data.currency || 'EUR',
                swift: data.swift || '',
            });
            if (data.iban) {
                setErrors({
                    ...errors,
                    ['iban']: null
                })
            }
            if (data.swift) {
                setErrors({
                    ...errors,
                    ['swift']: null
                })
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const getRefunds = () => {
        setIsLoadingData(true)
        fetch(getAllRefundPremiumUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json',},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                setNoRefunds(true)
            } else {
                let finder = false;
                data = data.map((person) => {
                    person.refund = person.refund.map((refund) => {
                        finder = true
                        let masked = false
                        return refund;
                    }).filter(e => e);
                    if (person.refund.length > 0) {
                        return person;
                    }
                }).filter(e => e)
                if (data.length === 0) {
                    setNoRefunds(true)
                }
                setPeople(data)
                setIsLoadingData(false)
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const getBankRefsByPersonId = (personId, personName) => {
        setIsLoadingForm(true)
        fetch(`${getBankRefsByPersonIdUrl}/${personId}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json',},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            console.log('set account holder', data.accountHolder)
            setFormValues({
                ...formValues,
                iban: data.iban || '',
                currency: data.currency ? data.currency : 'EUR',
                swift: data.swift || '',
                accountHolder: data.accountHolder || personName
            });
            setIsLoadingForm(false)
        }).catch(() => {
            setErrorPage(true)
        })
    }

    function confirmIban() {
        setIsLoadingConfirm(true)
        fetch(`${confirmIbanUrl}`, {
            method: 'POST',
            body: JSON.stringify({
                personId: selectedPerson,
                notificationId: selectedNotificationId,
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                setIsLoadingConfirm(false)
                setOpenConfirmIbanDialog(false)
                getRefunds();
            }
        }).catch(() => {
            setIsLoadingConfirm(false)
            setOpenConfirmIbanDialog(false)
            setErrorPage(true)
        });
    }

    return (errorPage ? <ErrorPage/> :
        <Fragment>
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Row className="nav-top container" style={{
                    paddingLeft: '0',
                    paddingRight: '0',
                }}>
                    <Col>
                        {
                        layout !== 'desktop' ?
                        <Header iconSrc={wideLogoLight}
                             rightSrc={bellRinging ? BellOn : BellOff}
                             rightAction={() => navigate('/messages')}/>
                        :
                        <Header iconSrc={wideLogoLight}/>
                    }
                    </Col>
                </Row>
                <Container className="top-of-the-language"></Container>
                <Row className="mt-2" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Col className="col-list">
                        {isLoadingData &&
                            <Row style={{'height': '85vh'}} className="background-loading-spinner"></Row>
                        }
                    </Col>
                    {!isLoadingData && noRefunds &&
                        <Container style={{
                            margin: '10vh auto',
                            backgroundColor: themeContext.color.neutral10
                        }}>
                            <Row>
                                <Col>
                                    <img src={nothing} alt={t('signing.nothing-text-1')}></img>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <span className="no-messages-text">{t('refunds.no-refunds')}</span>
                                </Col>
                            </Row>
                        </Container>
                    }
                    {!isLoadingData && !noRefunds &&
                        <Fragment>
                            <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                className={classes.root}>
                                {people.map((value, index) => (
                                    <Fragment key={index}>
                                        {index === 0 && <Container>
                                            <Row className="float-left mt-2 mb-2">
                                                <Col>
                                                    <h6 className={classes.subtitle}>{t('refunds.title')}</h6>
                                                </Col>
                                            </Row>
                                        </Container>}
                                        <ListItemButton onClick={() => handleClick(index)}
                                                  className={classes.listItem}>
                                            <ListItemIcon>
                                                {value.image === null && value.gender === 'M' &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseMaleAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image === null && value.gender === 'F' &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseFemaleAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image === null && value.gender === 'C' &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseCompanyAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image === null && value.gender === null &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseMaleAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image &&
                                                    <Avatar className={classes.personAvatar} src={value.image}
                                                            alt="avatar-image"/>}
                                            </ListItemIcon>
                                            {((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                <ListItemText className="list-label"
                                                              secondary={value.name.toLowerCase()}
                                                              style={{
                                                                  textTransform: 'capitalize',
                                                                  color: themeContext.color.neutral600
                                                              }}/> :
                                                <ListItemText className="list-label"
                                                              secondary={`${value.firstName.toLowerCase()} ${value.lastName.toLowerCase()}`}
                                                              style={{
                                                                  textTransform: 'capitalize',
                                                                  color: themeContext.color.neutral600
                                                              }}/>}
                                            <span style={{
                                                textAlign: 'right',
                                                fontSize: '12px',
                                                fontWeight: '700'
                                            }}></span>
                                            {openNestedNotSigned === index ? <ExpandMore/> : <ExpandLess/>}
                                        </ListItemButton>
                                        <Collapse in={openNestedNotSigned === index} timeout="auto" unmountOnExit>
                                            <Container>
                                                <Row>
                                                    <List component="div" className="col div-under-list">
                                                        {value.refund.map((refund) => {
                                                            return (
                                                                <Fragment key={refund.id}>
                                                                    <Card className={classes.card} key={refund.id}>
                                                                        <CardContent style={{'paddingBottom': '12px'}}>
                                                                            <Row>
                                                                                <Col className="col-auto mr-0"
                                                                                     style={{'paddingRight': '0px'}}>
                                                                                    <img
                                                                                        src={getBranchIcon(refund.type)}
                                                                                        alt="type-policy-icon"/>
                                                                                </Col>
                                                                                <Col className="col-7">
                                                                                    <h6 className={classes.cardTextMid}>{refund.type}</h6>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                        {t('dashboard.company-label')}
                                                                                        <span
                                                                                            className="font-weight-bold black">
                                                                                        {refund.company}
                                                                                    </span>
                                                                                    </h6>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                        {t('dashboard.policy-number-label')}
                                                                                        <span
                                                                                            className="font-weight-bold black">
                                                                                        {refund.policyNr}
                                                                                    </span>
                                                                                    </h6>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <h6 className={"card-text-content"}
                                                                                        style={{
                                                                                            color: themeContext.color.main,
                                                                                            lineHeight: '1',
                                                                                            fontSize: '19px'
                                                                                        }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: '29px'
                                                                                        }}>
                                                                                            <CurrencyFormat
                                                                                                displayType={'text'}
                                                                                                value={refund.requestedPremium}
                                                                                                thousandSeparator={'.'}
                                                                                                decimalSeparator={','}
                                                                                            />€ <span
                                                                                        style={{fontSize: '19px'}}>{t(`${root}.price-text`)}</span>
                                                                                    </span>
                                                                                    </h6>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col className="div-masked">
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <h6 className={"card-text-content ellipsis-1-v2"}
                                                                                                style={value.accountHolder ? {} : {color: themeContext.color.red}}>
                                                                                                {t('refunds.holder')}:
                                                                                                <span
                                                                                                    className={value.accountHolder ? "font-weight-bold black" : "font-weight-bold"}>
                                                                                                        {value.accountHolder ? ' ' + value.accountHolder : ' - '}
                                                                                            </span>
                                                                                            </h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <h6 className={"card-text-content ellipsis-1-v2"}
                                                                                                style={value.iban ? {} : {color: themeContext.color.red}}>
                                                                                                {t('refunds.iban')}:
                                                                                                <span
                                                                                                    className={value.iban ? "font-weight-bold black" : "font-weight-bold"}>
                                                                                                          {value.iban ? ' ' + value.iban : ' - '}
                                                                                              </span>
                                                                                            </h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <h6 className={"card-text-content ellipsis-1-v2"}
                                                                                                style={(value.swift || value.currency === 'EUR') ? {} : {color: themeContext.color.red}}>
                                                                                                {t('refunds.swift')}:
                                                                                                <span
                                                                                                    className={value.swift ? "font-weight-bold black" : "font-weight-bold"}>
                                                                                                          {value.swift ? ' ' + value.swift : ' - '}
                                                                                             </span>
                                                                                            </h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <h6 className={"card-text-content ellipsis-1-v2"}
                                                                                                style={value.currency ? {} : {color: themeContext.color.red}}>
                                                                                                {t('refunds.currency')}:
                                                                                                <span
                                                                                                    className={value.currency ? "font-weight-bold black" : "font-weight-bold"}>
                                                                                                        {value.currency ? ' ' + value.currency : ' - '}
                                                                                             </span>
                                                                                            </h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                            {refund.isRevision ?
                                                                                <Row className="float-right">
                                                                                    <Col>
                                                                                        <Button
                                                                                            style={{
                                                                                                marginTop: '0px',
                                                                                                cursorPointer: 'none',
                                                                                                pointerEvents: 'none'
                                                                                            }}
                                                                                            className={classes.revisionButton}>
                                                                                            {t(`${root}.revision`)}
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                                : value.iban ?
                                                                                <Row className="float-right">
                                                                                    <Col className={'px-0'}>
                                                                                        <Button
                                                                                            style={{'marginTop': '0px'}}
                                                                                            onClick={() => {
                                                                                                setIsLoadingForm(true)
                                                                                                setSelectedPerson(value.personId)
                                                                                                setSelectedPolicyNr(refund.policyNr)
                                                                                                setSelectedCompany(refund.company)
                                                                                                setSelectedRequestedPremium(refund.requestedPremium)
                                                                                                setInitialForm({
                                                                                                    accountHolder: value.accountHolder,
                                                                                                    iban: value.iban,
                                                                                                    swift: value.swift,
                                                                                                    currency: value.currency
                                                                                                })
                                                                                                getAllBankRefs(value.personId, value.name)
                                                                                                setOpenDetailsDialog(true)
                                                                                            }}
                                                                                            className={classes.CTAButton}>
                                                                                            {t(`${root}.card-edit`)}
                                                                                        </Button>
                                                                                    </Col>
                                                                                    <Col className={'pl-0'}>
                                                                                        <Button
                                                                                            style={{'marginTop': '0px'}}
                                                                                            disabled={!(value.iban && value.currency && value.accountHolder && (value.swift || value.currency === 'EUR'))}
                                                                                            onClick={() => {
                                                                                                setSelectedPerson(value.personId)
                                                                                                setSelectedNotificationId(refund.id)
                                                                                                setOpenConfirmIbanDialog(true)
                                                                                            }}
                                                                                            className={classes.CTAButton}>
                                                                                            {t(`${root}.card-confirm`)}
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row> :
                                                                                <Row className="float-right">
                                                                                    <Col>
                                                                                        <Button
                                                                                            style={{
                                                                                                marginTop: '0px'
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                setIsLoadingForm(true)
                                                                                                setSelectedPerson(value.personId)
                                                                                                setSelectedPolicyNr(refund.policyNr)
                                                                                                setSelectedCompany(refund.company)
                                                                                                setSelectedRequestedPremium(refund.requestedPremium)
                                                                                                setInitialForm({
                                                                                                    accountHolder: value.accountHolder,
                                                                                                    iban: value.iban,
                                                                                                    swift: value.swift,
                                                                                                    currency: value.currency
                                                                                                })
                                                                                                getAllBankRefs(value.personId, value.name)
                                                                                                setOpenDetailsDialog(true)
                                                                                            }}
                                                                                            className={classes.CTAButton}>
                                                                                            {t(`${root}.add-new-iban`)}
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                        </CardContent>
                                                                    </Card>
                                                                </Fragment>
                                                            )
                                                        })
                                                        }
                                                    </List>
                                                </Row>
                                            </Container>
                                        </Collapse>
                                    </Fragment>))
                                }
                            </List>
                        </Fragment>
                    }
                </Row>
                <Container className="bottom-with-navbar"
                           style={{'backgroundColor': themeContext.color.neutral10}}></Container>
            </Container>
            <Dialog
                onClose={() => {
                    setFormValues({})
                    setErrors({})
                    setOpenDetailsDialog(false)
                }}
                fullScreen={!isDesktop}
                fullWidth={isDesktop}
                maxWidth={'sm'}
                PaperProps={{
                    style: {
                        bottom: isDesktop ? '5%' : '0',
                        color: themeContext.color.neutral600,
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        borderRadius: isDesktop ? '20px' : 'none',
                        height: isDesktop ? '90%' : '100%',
                        marginTop: '10%'
                    }
                }}
                open={openDetailsDialog}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title"
                             className="dialog-title dialog-title-div"
                             style={{textAlign: 'center'}}>
                    <Row style={{justifyContent: 'end'}}>
                        <img src={exitIcon} className="exit-button" alt="close"
                             onClick={() => {
                                 setFormValues({})
                                 setErrors({})
                                 setOpenDetailsDialog(false)
                             }} style={{float: 'inline-end'}}/>
                    </Row>
                    <Row style={{marginTop: '-12px'}}>
                        <Col className="col">
                            <h6 className="dialog-form-title-1 float-left">{t(`${root}.dialog-title`)}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="dialog-form-title-2 float-left">{t(`${root}.policy-nr-label`)} {selectedPolicyNr}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="dialog-form-title-3 float-left">{t(`${root}.company-label`)} {selectedCompany}</h6>
                        </Col>
                    </Row>
                </DialogTitle>
                <DialogContent className="no-scrollbar">
                    <Container className="dialog-main-container"
                               style={{paddingBottom: "96px"}}>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-title-2 float-left">
                                    {t(`${root}.dialog-description-1`)} {selectedPolicyNr} {t(`${root}.dialog-description-2`)} {selectedCompany} {t(`${root}.dialog-description-3`)}
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-subtitle" style={{fontSize: 18}}>
                                    <CurrencyFormat
                                        displayType={'text'}
                                        value={selectedRequestedPremium}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                    /> €
                                </h6>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '56px'}}>
                            <Col>
                                <h6 className="dialog-form-subtitle">{t(`${root}.dialog-subtitle`)}</h6>
                            </Col>
                        </Row>
                        {
                            isLoadingForm &&
                            <Row style={{'height': '200px'}}
                                 className="background-loading-spinner">
                            </Row>
                        }
                        {
                            !isLoadingForm &&
                            <Form onSubmit={submitForm} style={{marginBottom: '60px'}}>
                                <Row>
                                    <Col>
                                        <Row className="mt-1">
                                            <Col>
                                                <h6 className="dialog-parameter mt-4 text-capitalize">
                                                    {t(`${root}.holder`)}
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Select
                                            variant={'outlined'}
                                            labelId="accountHolder-label"
                                            id="accountHolder"
                                            name="accountHolder"
                                            value={formValues.accountHolder}
                                            onChange={handleInputChange}
                                            fullWidth
                                            required
                                            style={{
                                                'backgroundColor': themeContext.color.white,
                                                'textAlign': 'left'
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    'textAlign': 'left',
                                                    'borderRadius': '4px',
                                                    'color': themeContext.color.neutral600,
                                                    'width': '100%',
                                                }
                                            }}>
                                            {
                                                holders.map((value, index) => (
                                                    <MenuItem key={index + 1}
                                                              value={value.name}>
                                                        {value.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                        <Row className="mt-1">
                                            <Col>
                                                <h6 className="dialog-parameter mt-4 text-capitalize">
                                                    {t(`${root}.iban`)}
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TextField
                                                    error={!!errors.iban}
                                                    helperText={errors.iban}
                                                    required
                                                    onChange={handleInputChange}
                                                    id={`iban`}
                                                    name={`iban`}
                                                    value={formValues.iban}
                                                    fullWidth
                                                    placeholder=""
                                                    variant="outlined"
                                                    color="primary"
                                                    InputProps={{
                                                        inputProps: {min: 0},
                                                        style: {
                                                            'borderRadius': '4px',
                                                            'color': themeContext.color.neutral600
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-1">
                                            <Col>
                                                <h6 className="dialog-parameter mt-4 text-capitalize">
                                                    {t(`${root}.swift`)}
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TextField
                                                    onChange={handleInputChange}
                                                    id={`swift`}
                                                    autoComplete={'off'}
                                                    name={`swift`}
                                                    fullWidth
                                                    placeholder=""
                                                    variant="outlined"
                                                    value={formValues.swift}
                                                    color="primary"
                                                    error={!!errors.swift}
                                                    helperText={errors.swift}
                                                    InputProps={{
                                                        inputProps: {min: 0},
                                                        style: {
                                                            'borderRadius': '4px',
                                                            'color': themeContext.color.neutral600
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-1">
                                            <Col>
                                                <h6 className="dialog-parameter mt-4 text-capitalize">
                                                    {t(`${root}.currency`)}
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Select
                                                    id={`currency`}
                                                    name={'currency'}
                                                    value={formValues.currency}
                                                    variant={'outlined'}
                                                    onChange={handleInputChange}
                                                    fullWidth
                                                    style={{
                                                        'backgroundColor': themeContext.color.white,
                                                        'textAlign': 'left'
                                                    }}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            'textAlign': 'left',
                                                            'borderRadius': '4px',
                                                            'color': themeContext.color.neutral600,
                                                            'width': '100%',
                                                        }
                                                    }}
                                                >
                                                    {
                                                        currencyCodes.data.map((currency, index) => (
                                                            <MenuItem key={index}
                                                                      value={currency.code}>
                                                                {currency.code} - {t(currency.currency)}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Container className="position-absolute"
                                           style={
                                               isDesktop ? {
                                                   background: 'transparent',
                                                   opacity: '1',
                                                   paddingBottom: "20px",
                                                   left: 0,
                                                   bottom: 0,
                                                   right: 0
                                               } : {
                                                   background: 'transparent',
                                                   opacity: '1',
                                                   paddingBottom: "68px",
                                                   left: 0,
                                                   bottom: 0,
                                                   right: 0
                                               }
                                           }>
                                    <Row style={{textAlign: 'center'}}>
                                        <Col>
                                            <Button
                                                className={"close-button"}
                                                variant="outlined"
                                                size="large"
                                                style={{
                                                    color: themeContext.color.white,
                                                    width: '288px',
                                                    textTransform: 'none'
                                                }}
                                                type="submit"
                                            >{t(`${root}.save`)}</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        }
                    </Container>
                </DialogContent>
            </Dialog>
            <DialogDoubleAction
                open={openConfirmIbanDialog}
                handleClose={() => setOpenConfirmIbanDialog(false)}
                title={t('refunds.dialog-confirm-iban-title')}
                description={t('refunds.dialog-confirm-iban-description')}
                buttonYesText={isLoadingConfirm ?
                    <img src={spinner} className="spinner-img" alt="spinner"/> : t('dialog.button-confirm')}
                handleYes={() => {
                    confirmIban();
                }}
                buttonNoText={t('dialog.button-cancel')}
                handleNo={() => setOpenConfirmIbanDialog(false)}
            ></DialogDoubleAction>
        </Fragment>)
}
