import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';

export default function Maintenance() {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (navigator.onLine) {
            //
        }
    }, []);

    function reload() {
        window.location.reload();
    }

    return (
        <Container className="full-height d-flex flex-column justify-content-center">
            <Row>
                {
                    <img src={process.env.PUBLIC_URL + '/offline-icon.svg'} className="noMessages"
                         alt="offline-image"></img>
                }
            </Row>
            <Row>
                <Col>
                    <h6 className="login-title align-center">{t('maintenance.text-1')}</h6>
                    <h6 className="login-title align-center">{t('maintenance.text-2')}</h6>
                    <h6 className="mt-3 align-center">{t('maintenance.copy-1')}</h6>
                    <h6 className="mt-1 align-center">{t('maintenance.copy-2')}<span className={'font-weight-bold'}>{t('maintenance.copy-3')}</span></h6>
                </Col>
            </Row>
        </Container>
    )
}