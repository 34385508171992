import React, {Fragment} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {Button, Menu, MenuItem, MenuList, Radio} from "@mui/material";
import BellOff from '../../../images/bell-icon.svg'
import BellOn from '../../../images/bellOn-icon.svg'
import baseFemaleAvatar from '../../../images/female-icon.svg'
import wideLogoLight from '../../../images/wideLogo-icon.svg';
import {makeStyles} from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Moment from "react-moment";
import baseMaleAvatar from "../../../images/male-icon.svg";
import {upperCaseFirst} from "upper-case-first";
import searchIcon from "../../../images/search-icon.svg";
import exitIcon from "../../../images/exit-icon.svg";
import nothing from "../../../images/nothing-icon.svg";
import scrollbarUsersPlaceholder from "../../../images/user-placeholder.svg";
import cardPlaceholder_min from "../../../images/card-placeholder_min.svg";
import cardPlaceholder_678 from "../../../images/card-placeholder_678.svg";
import cardPlaceholder_918 from "../../../images/card-placeholder_918.svg";
import cardPlaceholder_full from "../../../images/card-placeholder_full.svg";
import slimpayLogo from "../../../images/slimpay-logo-blue.svg";
import Header from "../../components/mobile/Header.js";
import baseCompanyAvatar from "../../../images/company-avatar.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../../components/ThemeContext.js";
import {StyledBadge, StyledBadgeZero} from "../../styles/Styles.js";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: "0 auto !important",
        marginTop: "10px !important",
        marginBottom: "25px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        minHeight: "140px !important"
    },
    titles: {
        color: `${themeContext.color.neutral400} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '500 !important',
        fontSize: '12px !important',
        textTransform: 'uppercase !important',
    },
    cardTextMid: {
        color: `${themeContext.color.neutral400} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '400 !important',
        fontSize: '12px !important',
        textTransform: 'uppercase !important',
        paddingTop: '10px !important',
        paddingBottom: '7px !important',
    },
    avatar: {
        //  width: theme.spacing(7),
        //  height: theme.spacing(7),
        width: '56px !important',
        height: '56px !important',
        margin: '4px 7px 4px 7px !important',
    },
    bigAvatar: {
        width: '66px !important',
        height: '66px !important',
        margin: '0 0 0 5px !important',
        boxShadow: `0px 2px 5px ${themeContext.color.boxShadow} !important`,
    },
    detailButton: {
        fontWeight: '600 !important',
        fontSize: '12px !important',
        lineHeight: '16px !important',
        letterSpacing: '2.07px !important',
        color: `${themeContext.color.mainActive} !important`,
        textTransform: 'uppercase !important',
    }
}));

export default function Payments(props) {

    const {
        serviceError,
        people,
        filter,
        selectedAvatar,
        timeoutAvatar,
        paymentNotification,
        requestedCents,
        currency,
        overallPremium,
        slimpayPeople,
        selectedRadio,
        disablePaymentButton,
        bellRinging,
        isLoadingNotifications,
        isLoadingPeople,
        searchMode,
        stringFilter,
        linkToPaymentMethod,
        setSearchValue,
        setStatusColor,
        setGrayScale,
        handleClickPerson,
        handleSearchMode,
        handleOpenMenu,
        handleCloseMenu,
        handleFilter,
        noPeople,
        openMenu,
        reload,
        onClickPaymentNotificationSummary,
        onClickPaymentNotification,
        width_min,
        width_767,
        width_991,
        width_1199
    } = props;
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const classes = useStyles();

    return (serviceError ? <Container className="pt-5 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
        <Container style={{'paddingTop': '10%'}}></Container>
        <Row>
            <Col>
                {<img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                      alt="service-error-image"></img>}
            </Col>
        </Row>
        <Row>
            <Col>
                <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
            </Col>
        </Row>
        <Row style={{'marginTop': '5%'}}>
            <Col>
                <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
            </Col>
        </Row>
    </Container> : <Fragment>
        <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
            <Row className="nav-top container" style={{
                'paddingLeft': '0',
                'paddingRight': '0',
            }}>
                <Col>
                        <Header iconSrc={wideLogoLight}
                             rightSrc={bellRinging ? BellOn : BellOff}
                             rightAction={() => navigate('/messages')}/>
                    <Row style={{
                        'paddingTop': '8px'
                    }}>
                        <Col className="col-auto float-left">
                            <h6 className={classes.titles}>{t('payment.select-payments-title')}
                            </h6>
                        </Col>
                    </Row>
                    <Row style={{'backgroundColor': themeContext.color.neutral10}}>
                        <Col style={{'textAlign': 'left'}}>
                            <Button
                                className={(isLoadingNotifications < 2 || disablePaymentButton) ? "btn-custom-disable text-uppercase" : "btn-custom text-uppercase"}
                                variant="outlined"
                                size="large"
                                style={{
                                    'color': themeContext.color.white,
                                    'float': 'left'
                                }} type="submit"
                                disabled={isLoadingNotifications < 2 || disablePaymentButton}
                                onClick={linkToPaymentMethod}
                            >{t('payment.payment-button')} {requestedCents}
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{'backgroundColor': themeContext.color.neutral10}}>
                        <Col>
                            <h6 style={{
                                textAlign: 'left',
                                fontStyle: 'italic',
                                color: themeContext.color.neutral200,
                                fontSize: '14px',
                                fontWeight: 'normal',
                                paddingTop: '8px',
                                marginBottom: '0px',
                            }}>{t('payment.overall-premium')} {currency} {overallPremium}</h6>
                        </Col>
                    </Row>
                    {!noPeople && <Fragment>
                        <Row style={{
                            'paddingTop': '20px',
                            'backgroundColor': themeContext.color.neutral10
                        }}>
                            {!searchMode && <Col className="col-auto float-left">
                                <h6 className={classes.titles}>{t('payment.beneficiaries-title')}
                                </h6>
                            </Col>}
                            {searchMode && <Col className="col-auto float-left">
                            </Col>}
                            <Col className="col float-right" style={{'marginTop': '-7px'}}>
                                {searchMode && <Row className="div-search" style={{'marginRight': '0'}}>
                                    <Col className="col-10">
                                        <input id="search-input" className="input-search"
                                               onChange={e => setSearchValue(e.target.value)}
                                               autoFocus></input>
                                    </Col>
                                    <Col className="col-2"
                                         style={{
                                             'paddingRight': '2px',
                                             'textAlign': 'right'
                                         }}>
                                        <img src={exitIcon} alt="close" onClick={handleSearchMode}
                                             height="25px" width="25px"/>
                                    </Col>
                                </Row>}
                                {!searchMode && <img src={searchIcon}
                                                     hidden
                                                     className="nav-top-logo float-right cursor-pointer"
                                                     height="27px"
                                                     alt="search-icon"
                                                     onClick={handleSearchMode}/>}

                            </Col>
                        </Row>
                        {isLoadingPeople && <Row className="pt-3" style={{'backgroundColor': themeContext.color.neutral10}}>
                            <Col style={{
                                textAlign: 'left',
                                'backgroundColor': themeContext.color.neutral10
                            }}>
                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                     className="mr-1"/>
                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                     className="mr-1"/>
                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                     className="mr-1"/>
                            </Col>
                        </Row>}
                        {!isLoadingPeople && <Row style={{
                            'paddingTop': '10px',
                            'backgroundColor': themeContext.color.neutral10
                        }}>
                            <Col className="col-list">
                                <Container className="people-scrollable-row">
                                    <Container className="scrolling-wrapper-flexbox no-scrollbar"
                                               id="scroll-container"
                                               style={{'backgroundColor': themeContext.color.neutral10}}>
                                        {people.map((person, index) => (
                                            <div className="card card-avatar" key={index}
                                                 style={{'backgroundColor': themeContext.color.neutral10}}>
                                                <CardContent className="card-avatar-content"
                                                             hidden={(person.payableNotificationsNr === 0 && (!person.slimpayIsPending || person.slimpayIsPending) && person.slimPayMandate)}>
                                                    <Row style={{'width': '50px'}}>
                                                        {person.image === null && person.gender === 'M' && person.payableNotificationsNr === 0 &&
                                                            <StyledBadgeZero
                                                                badgeContent={'0'}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseMaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : null}/>
                                                            </StyledBadgeZero>
                                                        }
                                                        {person.image === null && person.gender === 'M' && person.payableNotificationsNr !== 0 &&
                                                            <StyledBadge
                                                                badgeContent={person.payableNotificationsNr}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseMaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : null}/>
                                                            </StyledBadge>
                                                        }
                                                        {person.image === null && person.gender === 'F' && person.payableNotificationsNr === 0 &&
                                                            <StyledBadgeZero
                                                                badgeContent={'0'}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseFemaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : null}/>
                                                            </StyledBadgeZero>
                                                        }
                                                        {person.image === null && person.gender === 'F' && person.payableNotificationsNr !== 0 &&
                                                            <StyledBadge
                                                                badgeContent={person.payableNotificationsNr}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseFemaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : null}/>
                                                            </StyledBadge>
                                                        }
                                                        {person.image === null && person.gender === 'C' && person.payableNotificationsNr === 0 &&
                                                            <StyledBadgeZero
                                                                badgeContent={'0'}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseCompanyAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : null}/>
                                                            </StyledBadgeZero>
                                                        }
                                                        {person.image === null && person.gender === 'C' && person.payableNotificationsNr !== 0 &&
                                                            <StyledBadge
                                                                badgeContent={person.payableNotificationsNr}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseCompanyAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : null}/>
                                                            </StyledBadge>
                                                        }
                                                        {person.image === null && person.gender === null && person.payableNotificationsNr === 0 &&
                                                            <StyledBadgeZero
                                                                badgeContent={'0'}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseMaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : null}/>
                                                            </StyledBadgeZero>
                                                        }
                                                        {person.image === null && person.gender === null && person.payableNotificationsNr !== 0 &&
                                                            <StyledBadge
                                                                badgeContent={person.payableNotificationsNr}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseMaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : null}/>
                                                            </StyledBadge>
                                                        }
                                                        {person.image && person.payableNotificationsNr === 0 &&
                                                            <StyledBadgeZero
                                                                badgeContent={'0'}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={person.image}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : null}/>
                                                            </StyledBadgeZero>
                                                        }
                                                        {person.image && person.payableNotificationsNr !== 0 &&
                                                            <StyledBadge
                                                                badgeContent={person.payableNotificationsNr}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={person.image}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : null}/>
                                                            </StyledBadge>
                                                        }
                                                    </Row>
                                                    <Row
                                                        style={selectedAvatar.current === person.id ? {'width': '73px'} : {'width': '67px'}}>
                                                        <h6 className={selectedAvatar.current === person.id ? "avatar-text-big text-uppercase" : "avatar-text text-uppercase"}
                                                            style={selectedAvatar.current === person.id ? {color: `${setStatusColor(person.status)}`} : {}}>
                                                            {((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ? upperCaseFirst(`${person.name}`) :
                                                                <span><span
                                                                    className="ellipsis-1">{upperCaseFirst(`${person.firstName}`)} </span><span
                                                                    className="ellipsis-1">{upperCaseFirst(`${person.lastName}`)}</span></span>}
                                                        </h6>
                                                    </Row>
                                                </CardContent>
                                            </div>))}
                                    </Container>
                                </Container>
                            </Col>
                        </Row>}
                    </Fragment>}
                    <Row className="pb-2" style={{
                        'paddingTop': '20px',
                        'backgroundColor': themeContext.color.neutral10
                    }}>
                        <Col className="col-6">
                            <h6 className={classes.titles}>{t('payment.payment-alert-title')}</h6>
                        </Col>
                        <Col className="col-6">
                            {filter.current === 'STATUS_DEFAULT' &&
                                <Button hidden
                                        className="filter-btn filter-btn-2 float-right justify-content-end"
                                        style={{'color': themeContext.color.neutral400}}
                                        onClick={handleOpenMenu}>
                                    <span className="material-symbols-outlined"
                                          style={{'fontSize': '22px', 'paddingRight': '2px', 'paddingBottom': '3px'}}>
                                            filter_list
                                    </span>
                                </Button>
                            }
                            {filter.current !== 'STATUS_DEFAULT' &&
                                <Button hidden
                                        className="filter-btn filter-btn-2 float-right justify-content-end"
                                        style={{'color': themeContext.color.main}}
                                        onClick={handleOpenMenu}>
                                    <span className="material-symbols-outlined"
                                          style={{'fontSize': '22px', 'paddingRight': '2px', 'paddingBottom': '3px'}}>
                                        filter_list
                                    </span>
                                    {stringFilter(filter.current)}
                                </Button>
                            }
                            <Menu
                                id="simple-menu"
                                anchorEl={openMenu}
                                keepMounted
                                open={Boolean(openMenu)}
                                onClose={handleCloseMenu}
                                className="menu filter-menu-dashboard"
                                PaperProps={{
                                    style: {
                                        'maxWidth': '100%',
                                        'width': '200px',
                                    },
                                }}>
                                <MenuList className="title-filter-item">{t('dashboard.filter-title')}</MenuList>
                                <MenuItem className="filter-item" onClick={() => handleFilter('STATUS_ACTIVE')}
                                          style={filter.current === 'STATUS_ACTIVE' ? {
                                              'color': themeContext.color.green,
                                              'fontWeight': '800',
                                              'backgroundColor': themeContext.color.neutral10
                                          } : {'color': themeContext.color.green}}>
                                    {filter.current === 'STATUS_ACTIVE' ? <Fragment>
                                        {t('dashboard.filter-active')}<img src={exitIcon}
                                                                           className="ml-auto"
                                                                           alt="exit-icon"/>
                                    </Fragment> : t('dashboard.filter-active')}
                                </MenuItem>
                                <MenuItem className="filter-item"
                                          onClick={() => handleFilter('STATUS_EXPIRING')}
                                          style={filter.current === 'STATUS_EXPIRING' ? {
                                              'color': themeContext.color.yellow,
                                              'fontWeight': '800',
                                              'backgroundColor': themeContext.color.neutral10
                                          } : {'color': themeContext.color.yellow}}>
                                    {filter.current === 'STATUS_EXPIRING' ? <Fragment>
                                        {t('dashboard.filter-expirity')}<img src={exitIcon}
                                                                             className="ml-auto"
                                                                             alt="exit-icon"/>
                                    </Fragment> : t('dashboard.filter-expirity')}
                                </MenuItem>
                                <MenuItem className="filter-item" onClick={() => handleFilter('STATUS_EXPIRED')}
                                          style={filter.current === 'STATUS_EXPIRED' ? {
                                              'color': themeContext.color.red,
                                              'fontWeight': '800',
                                              'backgroundColor': themeContext.color.neutral10
                                          } : {'color': themeContext.color.red}}>
                                    {filter.current === 'STATUS_EXPIRED' ? <Fragment>
                                        {t('dashboard.filter-expired')}<img src={exitIcon}
                                                                            className="ml-auto"
                                                                            alt="exit-icon"/>
                                    </Fragment> : t('dashboard.filter-expired')}
                                </MenuItem>
                                <MenuItem className="filter-item"
                                          onClick={() => handleFilter('STATUS_INACTIVE')}
                                          style={filter.current === 'STATUS_INACTIVE' ? {
                                              'color': themeContext.color.neutral200,
                                              'fontWeight': '800',
                                              'backgroundColor': themeContext.color.neutral10
                                          } : {'color': themeContext.color.neutral200}}>
                                    {filter.current === 'STATUS_INACTIVE' ? <Fragment>
                                        {t('dashboard.filter-killed')}<img src={exitIcon}
                                                                           className="ml-auto"
                                                                           alt="exit-icon"/>
                                    </Fragment> : t('dashboard.filter-killed')}
                                </MenuItem>
                            </Menu>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {!noPeople ? <Container className="top-of-the-payment"></Container> :
                <Container className="top-of-the-payment" style={{'paddingTop': '212px'}}></Container>}
            <Row className="mt-2" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Col>
                    {isLoadingNotifications < 2 && <Fragment>
                        <Row className="mt-2">
                            <Col className="col-list">
                                {width_min && !width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                {width_min && width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && width_1199 &&
                                    <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-list">
                                {width_min && !width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                {width_min && width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && width_1199 &&
                                    <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-list">
                                {width_min && !width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                {width_min && width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && width_1199 &&
                                    <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-list">
                                {width_min && !width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                {width_min && width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && width_1199 &&
                                    <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                            </Col>
                        </Row>
                    </Fragment>}
                    {selectedAvatar.current !== 'null' && isLoadingNotifications === 2 && paymentNotification.length === 0 &&
                        <Container>
                            <Row>
                                <Col>
                                    <img src={nothing}
                                         alt={t('payment.nothing-text-1')}
                                    ></img>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="no-messages-text">{t('payment.nothing-text-1')}</span>
                                </Col>
                            </Row>
                        </Container>
                    }
                    {selectedAvatar.current === 'null' && isLoadingNotifications === 2 && paymentNotification.length === 0 &&
                        <Container>
                            <Row>
                                <Col>
                                    <img src={nothing}
                                         alt={t('payment.nothing-text-1')}
                                    ></img>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="no-messages-text">{t('payment.nothing-text-1')}</span>
                                </Col>
                            </Row>
                        </Container>
                    }
                    {isLoadingNotifications === 2 && paymentNotification.map((value, index) => (
                        <Card className={classes.card}
                              key={index}
                              onClick={(e) => {
                                  if (!slimpayPeople.includes(value.personId)) {
                                      let policiesArr = []
                                      if (value.length > 0) {
                                          value.map((title, indexTitle) => {
                                              if (!policiesArr.includes(title.policy_id)) {
                                                  policiesArr.push(title.policy_id)
                                              }
                                          })
                                      } else {
                                          if (!policiesArr.includes(value.policy_id)) {
                                              policiesArr.push(value.policy_id)
                                          }
                                      }
                                      e.stopPropagation();
                                      if (value.notificationType !== 'SDD') {
                                          onClickPaymentNotification(value.id, value.requestedPremium, value.personId, policiesArr)
                                      }
                                  }
                              }}>
                            {!slimpayPeople.includes(value.personId) ?
                                <CardContent style={{'paddingBottom': '0px'}}>
                                    <Row>
                                        <Col className="col-auto mr-0 pr-0">
                                            {value.notificationType !== 'SDD' && <Radio
                                                checked={parseInt(selectedRadio) === value.id}
                                                name={value.requestedPremium}
                                                value={value.id}
                                                inputProps={{'aria-label': 'radio'}}
                                                className="justify-content-end radio-custom"
                                                color="primary"
                                                size="small"
                                            />}
                                        </Col>
                                        <Col className="col-7 pl-1">
                                            <h6 className={classes.cardTextMid}>
                                                {people.map((person, index) => (person.policyHolderId === value.policyHolderId ? (((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                        <span key={index}>{person.name}</span> : <span
                                                            key={index}>{person.firstName} {person.lastName}</span>) :
                                                    <span key={index}></span>))}
                                            </h6>
                                        </Col>
                                        <Col className="col" style={{textAlign: 'end', paddingRight: '22px'}}>
                                            {value.notificationType === 'SDD' &&
                                                <img style={{marginTop: '-7px'}} height="40px"
                                                     src={slimpayLogo} alt="slimpay-logo"/>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h6 className={"card-text-content"}>
                                                    <span className="ellipsis-1">
                                                    {t('payment.advice-id')}
                                                        <span className="font-weight-bold black">
                                                            {value.nr}
                                                        </span>
                                                    </span>
                                            </h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h6 className={"card-text-content"}>
                                                    <span className="ellipsis-1">
                                                    {t('payment.expiry-date')}
                                                        <Moment locale="it" format="DD/MM/YYYY"
                                                                className="black font-weight-bold">
                                                            {value.datebill}
                                                        </Moment>
                                                    </span>
                                            </h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h6 className={"card-text-content"}
                                                style={{
                                                    'lineHeight': '1.3',
                                                    'textTransform': 'uppercase'
                                                }}>
                                                    <span className="ellipsis-1">
                                                    {t('payment.total')}
                                                        <span className="font-weight-bold blue" style={{
                                                            'fontSize': '18px'
                                                        }}>
                                                            {value.requestedPremium}
                                                    </span>
                                                </span>
                                            </h6>
                                        </Col>
                                        <Col style={{
                                            'textAlign': 'right',
                                            'paddingLeft': '0',
                                            'paddingBottom': '0px',
                                            'marginBottom': '8px',
                                            'marginTop': '8px'
                                        }}>
                                            <Row>
                                                <Col>
                                                    <Button style={{'marginTop': '0px'}}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onClickPaymentNotificationSummary(value.id)
                                                            }}
                                                            className={classes.detailButton}>{t('payment.detail-button')}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardContent> : <CardContent style={{'paddingBottom': '16px'}}>
                                    <Row>
                                        <Col className="col-9">
                                            <h6 className={classes.cardTextMid}>
                                                {people.map((person, index) => (person.policyHolderId === value.policyHolderId ? (((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                        <span key={index}>{person.name}</span> : <span
                                                            key={index}>{person.firstName} {person.lastName}</span>) :
                                                    <span key={index}></span>))}
                                            </h6>
                                        </Col>
                                        <Col className="col-3">
                                            <img style={{marginTop: '-7px'}} height="40px"
                                                 src={slimpayLogo} alt="slimpay-logo"/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span style={{
                                                float: 'left',
                                                textAlign: 'left',
                                                color: themeContext.color.neutral600,
                                                fontSize: '12px'
                                            }}>
                                                {t('paymentsStatus.slimpay-pending')}
                                            </span>
                                        </Col>
                                    </Row>
                                </CardContent>}
                        </Card>))}
                </Col>
            </Row>
            <Container className="bottom-with-navbar" style={{'backgroundColor': themeContext.color.neutral10}}></Container>
        </Container>
    </Fragment>)
}
