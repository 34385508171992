import React, {Fragment, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {
    putElementFromIndexedDB
} from "../service/UtilityService.js";
import baseFemaleAvatar from '../../images/female-icon.svg'
import {makeStyles} from '@mui/styles';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import baseMaleAvatar from "../../images/male-icon.svg";
import {upperCaseFirst} from "upper-case-first";
import nothing from "../../images/nothing-icon.svg";
import scrollbarUsersPlaceholder from "../../images/user-placeholder.svg";
import {Button} from "@mui/material";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import {useTranslation} from "react-i18next";
import back from "../../images/back-icon.svg";
import DialogTitle from "@mui/material/DialogTitle";
import exitIcon from "../../images/exit-icon.svg";
import DialogContent from "@mui/material/DialogContent";
import InfiniteScroll from "react-infinite-scroll-component";
import Card from "@mui/material/Card";
import Moment from "react-moment";
import spinner from "../../images/spinner.svg";
import CardsOnLoading from "../components/CardsOnLoading.js";
import themeContext from "../components/ThemeContext.js";
import DialogOperationComplete from "../components/DialogOperationComplete.js";
import SupportTicketDialog from "../components/SupportTicketDialog.js";
import ErrorPage from "../pages/ErrorPage.js";


const useStyles = makeStyles((theme) => ({
    card: {
        margin: "0 15px !important",
        marginTop: "10px !important",
        marginBottom: "18px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important"
    },
    accountExecCard: {
        background: `${themeContext.color.mainActive} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        height: "190px !important",
    },
    cardAvatar: {
        float: "right !important",
        width: "35px !important",
        height: "35px !important",
    },
    avatar: {
        width: '56px !important',
        height: '56px !important',
        margin: '4px 7px 4px 7px !important',
    },
    bigAvatar: {
        width: '66px !important',
        height: '66px !important',
        margin: '0 0 0 5px !important',
        boxShadow: `0px 2px 5px ${themeContext.color.boxShadow} !important`,
    },
    accountExecCardTextHigh: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '100 !important',
        fontSize: '12px !important'
    },
    accountExecCardTextMid: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: 'bold !important',
        fontSize: '21px !important'
    },
    accountExecCardTextLow: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1.6 !important',
        fontWeight: '600 !important',
        fontSize: '12px !important',
        textDecoration: "underline !important",
        float: 'left !important'
    },
    large: {
        width: '64px !important',
        height: '64px !important',
    },
}));

export default function Support({layout}) {
    useEffect(() => {
        if (navigator.onLine) {
            getPeople();
            getInitialTickets();
        }
    }, []);

    const root = 'support';
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const getTicketsUrl = `${process.env.REACT_APP_BASE_URL}/profile/support/list?size=10`;
    const token = sessionStorage.getItem('token');
    const {t, i18n} = useTranslation();
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const [isLoadingScrollbar, setIsLoadingScrollbar] = useState(false);
    const navigate = useNavigate();
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const classes = useStyles();
    const [tickets, setTickets] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [people, setPeople] = useState([]);
    const [selectedAvatar, setSelectedAvatar] = useState("null");
    const [timeoutAvatar, setTimeoutAvatar] = useState(false);
    const [noPeople, setNoPeople] = useState(false);
    const [page, setPage] = useState(1);
    const [openNewRequestDialog, setOpenNewRequestDialog] = useState(false)
    const [openSaveCompletedDialog, setOpenSaveCompletedDialog] = useState(false);

    function reload() {
        window.location.reload();
    }

    const fetchMoreData = () => {
        let url = `${getTicketsUrl}&page=${page}`;
        if (selectedAvatar !== 'null') {
            url += `&personId=${selectedAvatar}`;
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setTickets((prevItems) => prevItems.concat(data.content));
                setPage(page + 1);
            })
            .catch((error) => {
                console.error("Errore durante il caricamento degli elementi:", error);
            });
    }

    function getPeople() {
        setIsLoadingScrollbar(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeople([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    setPeople(current => [...current, item])
                }
            })
            if (count === 0) {
                sessionStorage.setItem('noPeople', true);
                setNoPeople(true);
            } else {
                sessionStorage.removeItem('noPeople');
                setNoPeople(false);
            }
            putElementFromIndexedDB('people', data)
            setIsLoadingScrollbar(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function getInitialTickets(personId) {
        let url = `${getTicketsUrl}&page=0`;
        if (personId) {
            url += `&personId=${personId}`;
        }
        setTickets([])
        setIsLoadingPeople(true)
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setTickets(data.content)
            //reset pagina
            setPage(1)
            setTotalPages(data.totalPages)
            setTotalElements(data.totalElements)
            setIsLoadingPeople(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
            case 5:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }
    const handleClickPerson = (personId) => {
        setTimeoutAvatar(true);
        setTickets([]);
        if (selectedAvatar === personId) {
            setSelectedAvatar("null");
            getInitialTickets();
        } else {
            setSelectedAvatar(personId);
            getInitialTickets(personId);
        }
        setTimeout(function () {
            setTimeoutAvatar(false);
        }, 1000);
    }

    const onClickTicket = (id) => {
        return navigate(`/settings/support/${id}`);
    }

    const setGrayScale = (value) => {
        switch (value) {
            case 1:
                return 'grayscale(1)';
            case 2:
            case 3:
            case 4:
                return '';
        }
    }

    function turnBack() {
        return navigate(-1);
    }

    const getTicketStatus = (status) => {
        switch (status) {
            case 'A':
                return {title: t(`${root}.status-A`), color: themeContext.color.mainActive};
            case 'C':
                return {title: t(`${root}.status-C`), color: themeContext.color.neutral400};
            case 'P':
                return {title: t(`${root}.status-P`), color: themeContext.color.main};
            case 'R':
                return {title: t(`${root}.status-R`), color: themeContext.color.green};
        }
    }

    const completeAndClose = () => {
        setOpenNewRequestDialog(false);
        setSelectedAvatar("null");
        getInitialTickets();
        setTimeout(() => {
            setOpenSaveCompletedDialog(true)
        }, 200)
        setTimeout(() => {
            setOpenSaveCompletedDialog(false)
            window.scrollTo(0, 0)
        }, 2000)
    }

    return (
        errorPage ?
            <ErrorPage/> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Row className="nav-top container px-0">
                        <Col>
                            {!noPeople &&
                                <Fragment>
                                    <Row className="container" style={{
                                        'paddingTop': '19.5px',
                                        'paddingBottom': '20px',
                                        'paddingLeft': '0',
                                        'paddingRight': '0',
                                        'width': "100vw"
                                    }}>
                                        <Col className="col-2 float-left">
                                            {
                                                layout !== 'desktop' &&
                                                <img src={back} onClick={turnBack}
                                                  className="nav-top-logo float-left cursor-pointer"
                                                  height="27px"
                                                  alt="back"/>}
                                        </Col>
                                        <Col className="col-8">
                                            <h6 className="header-title ellipsis-1-v2">{t(`${root}.title`)}</h6>
                                        </Col>
                                        <Col className="col-2">

                                        </Col>
                                    </Row>
                                    {
                                        <Row style={{'marginTop': '10px'}}>
                                            <Col className="col-list">
                                                <Container className="people-scrollable-row">
                                                    {
                                                        isLoadingScrollbar &&
                                                        <Row className="mt-2 w-100">
                                                            <Col style={{textAlign: 'left'}}>
                                                                <img src={scrollbarUsersPlaceholder}
                                                                     alt="card-scrollbar" className="mr-1"/>
                                                                <img src={scrollbarUsersPlaceholder}
                                                                     alt="card-scrollbar" className="mr-1"/>
                                                                <img src={scrollbarUsersPlaceholder}
                                                                     alt="card-scrollbar" className="mr-1"/>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {!isLoadingScrollbar &&
                                                        <Container className="scrolling-wrapper-flexbox no-scrollbar"
                                                                   id="scroll-container">
                                                            {
                                                                people.map((person, index) => (
                                                                    <div className="card card-avatar" key={index}
                                                                         style={{backgroundColor: themeContext.color.neutral10}}>
                                                                        <CardContent className="card-avatar-content"
                                                                                     style={{backgroundColor: themeContext.color.neutral10}}>
                                                                            <Row style={{'width': '50px'}}>

                                                                                {
                                                                                    person.image === null && person.gender === 'M' &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseMaleAvatar}
                                                                                            className={selectedAvatar === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `3px solid ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                                }
                                                                                {
                                                                                    person.image === null && person.gender === 'F' &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseFemaleAvatar}
                                                                                            className={selectedAvatar === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `3px solid ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                                }
                                                                                {
                                                                                    person.image === null && person.gender === 'C' &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseCompanyAvatar}
                                                                                            className={selectedAvatar === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `3px solid ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                                }
                                                                                {
                                                                                    person.image === null && person.gender === null &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseMaleAvatar}
                                                                                            className={selectedAvatar === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `3px solid ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                                }
                                                                                {
                                                                                    person.image &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={person.image}
                                                                                            className={selectedAvatar === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `3px solid ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                                }
                                                                            </Row>
                                                                            <Row
                                                                                style={selectedAvatar === person.id ? {'width': '73px'} : {'width': '67px'}}>
                                                                                <h6 className={selectedAvatar === person.id ? "avatar-text-big text-uppercase" : "avatar-text text-uppercase"}
                                                                                    style={selectedAvatar === person.id ? {color: `${setStatusColor(person.status)}`} : {}}>
                                                                                    {((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                                                        upperCaseFirst(`${person.name}`)
                                                                                        : <span><span
                                                                                            className="ellipsis-1">{upperCaseFirst(`${person.firstName}`)} </span><span
                                                                                            className="ellipsis-1">{upperCaseFirst(`${person.lastName}`)}</span></span>
                                                                                    }
                                                                                </h6>
                                                                            </Row>
                                                                        </CardContent>
                                                                    </div>
                                                                ))
                                                            }
                                                        </Container>
                                                    }
                                                </Container>
                                            </Col>
                                        </Row>
                                    }
                                </Fragment>
                            }
                        </Col>
                    </Row>
                    {
                        !noPeople ?
                            <Container className="top-of-the-support"></Container>
                            :
                            <Container className="top-of-the-support" style={{'paddingTop': '112px'}}></Container>
                    }
                    <Row className="mt-2" style={{
                        paddingBottom: '0px'
                    }}>
                        <Col className={'px-0'}>
                            {isLoadingPeople && selectedAvatar === 'null' && <CardsOnLoading/>}
                            {
                                !isLoadingPeople && totalElements === 0 && selectedAvatar === 'null' &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('messages.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t(`${root}.nothing-text-1`)}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {
                                !isLoadingPeople && totalElements === 0 && selectedAvatar !== 'null' &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('messages.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t(`${root}.nothing-text-1`)}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {!isLoadingPeople && totalElements > 0 &&
                                <InfiniteScroll
                                    dataLength={tickets.length}
                                    next={fetchMoreData}
                                    hasMore={totalPages >= page}
                                    loader={<img src={spinner} className="spinner-img w-100" alt="spinner"/>}>
                                    {tickets.map((ticket, index) => (
                                        <Card className={classes.card}
                                              key={index}

                                              onClick={() => onClickTicket(ticket.id)}>
                                            <CardContent style={{'paddingBottom': '16px'}}>
                                                <Row>
                                                    <Col className="col-10">
                                                        <h6 className={'card-text-content black ellipsis-2-v2 font-weight-bold'}>{ticket.title}</h6>
                                                    </Col>
                                                    <Col className="col-2">
                                                        {
                                                            ticket.image === null && ticket.gender === 'M' &&
                                                            <Avatar src={baseMaleAvatar}
                                                                    className={classes.cardAvatar}
                                                                    alt="male-avatar"/>
                                                        }
                                                        {
                                                            ticket.image === null && ticket.gender === 'F' &&
                                                            <Avatar src={baseFemaleAvatar}
                                                                    className={classes.cardAvatar}
                                                                    alt="male-avatar"/>
                                                        }
                                                        {
                                                            ticket.image === null && ticket.gender === 'C' &&
                                                            <Avatar src={baseCompanyAvatar}
                                                                    className={classes.cardAvatar}
                                                                    alt="male-avatar"/>
                                                        }
                                                        {
                                                            ticket.image === null && ticket.gender === null &&
                                                            <Avatar src={baseMaleAvatar}
                                                                    className={classes.cardAvatar}
                                                                    alt="male-avatar"/>
                                                        }
                                                        {
                                                            ticket.image &&
                                                            <Avatar src={ticket.image} className={classes.cardAvatar}
                                                                    alt="male-avatar"/>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h6 className={'card-text-content ellipsis-1-v2'}>
                                                            {t(`${root}.policy-holder-label`)}
                                                            <span className="black">
                                                                {ticket.name}
                                                            </span>
                                                        </h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h6 className={'card-text-content ellipsis-1-v2'}>{t(`${root}.open-date-label`)}
                                                            <span className="black">
                                                                    <Moment locale="it"
                                                                            format="DD/MM/YYYY">{ticket.createdAt}</Moment>
                                                                </span>
                                                        </h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h6 className={'card-text-content ellipsis-1-v2'}
                                                            style={{marginBottom: 0}}>
                                                            {t(`${root}.status-label`)}
                                                            <span className="font-weight-bold"
                                                                  style={{color: getTicketStatus(ticket.status).color}}>
                                                            {getTicketStatus(ticket.status).title}
                                                        </span>
                                                        </h6>
                                                    </Col>
                                                </Row>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </InfiniteScroll>}
                        </Col>
                    </Row>
                    <Container className="fixed-bottom linear-gradient-white"
                               style={{
                                   'opacity': '1',
                                   'paddingBottom': "32px"
                               }}>
                        <Row>
                            <Col>
                                <Button className="primary-button"
                                        variant="outlined"
                                        size="large"
                                        style={{width: 288}} type="submit"
                                        onClick={() => {
                                            setOpenNewRequestDialog(true)
                                        }}
                                >{t(`${root}.new-request`)}</Button>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="bottom-with-navbar"
                               style={{'backgroundColor': themeContext.color.neutral10}}></Container>
                </Container>
                <SupportTicketDialog
                    openNewRequestDialog={openNewRequestDialog}
                    handleClose={() => setOpenNewRequestDialog(false)}
                    handleSave={() => completeAndClose()}
                    handleServiceError={() => setErrorPage(true)}
                    hasChips={true}
                    title={t(`${root}.select-typology`)}/>
                <DialogOperationComplete open={openSaveCompletedDialog}
                                         title={t('changeIBAN.confirm-operation')}/>
            </Fragment>
    )
}
