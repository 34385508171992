import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import themeContext from "../components/ThemeContext.js";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {DesktopLayoutContext} from "../middleware/DesktopLayoutContext.js";

export default function ServiceError() {
    const {t, i18n} = useTranslation();
    const isUnderMaintenanceUrl = `${process.env.REACT_APP_BASE_URL}/maintenance/isUnderMaintenance`;
    const [isUnderMaintenanceMode, setIsUnderMaintenanceMode] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const {handleHeader, handleSidebar, handleDetailbar} = useContext(DesktopLayoutContext);

    useEffect(() => {
        handleSidebar(false);
        handleHeader(false);
        handleDetailbar(false);
        if (navigator.onLine) {
            isUnderMaintenance();
        }
        return () => {
            if(isDesktop){
                handleSidebar(true);
                handleHeader(true);
                handleDetailbar(true);
            }
        }
    }, []);

    function reload() {
        return navigate('/dashboard')
    }

    const isUnderMaintenance = () => {
        fetch(isUnderMaintenanceUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                return response.text().then(text => {
                    throw new Error(text)
                })
            }
        }).then((res) => {
            if (res.ok) {
                if (res.message === "true") {
                    setIsUnderMaintenanceMode(true)
                }
            } else if (res.status === 503) {
                return res.text().then(text => {
                    throw new Error(text)
                })
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    return (
        <Container className="pt-5 full-height" style={{'backgroundColor': themeContext.color.white}}>
            <Container style={{'paddingTop': '10%'}}></Container>
            <Row>
                {
                    <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                         alt="offline-image"></img>
                }
            </Row>
            <Row>
                <Col>
                    <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                </Col>
            </Row>
            {navigator.onLine && <Row style={{'marginTop': '5%'}}>
                <Col>
                    <a className="reload-btn cursor-pointer" onClick={() => reload()}>{t('serviceError.reload')}</a>
                </Col>
            </Row>
            }
        </Container>
    )
}