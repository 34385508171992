import React, {createContext, useMemo, useState} from 'react';
import CheckoutDialog from "../components/desktop/CheckoutDialog.js";
import {PAYMENT_METHOD} from "../service/PaymentsService.js";

export const DetailBarContext = createContext();

export const dataType = {
    POLICY: 'policy',
    CLAIM: 'claim',
    PAYMENT: 'payment',
}
let url = null;

export const DetailBarProvider = ({children}) => {
    const [active, setActive] = useState(false);
    const [data, setData] = useState(null);
    const [dataType, setDataType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openCheckoutDialog, setOpenCheckoutDialog] = useState({value: false, dataType: null, data: null});

    const activeDetailBar = (value) => {
        setActive(value);
    };

    const updateData = (newData) => {
        setData(newData);
    };

    const updateType = (newType) => {
        setDataType(newType);
    };

    const updateDataAndType = (newData, newType) => {
        setData(newData);
        setDataType(newType);
    };

    const handleLoading = (state) => {
        setIsLoading(state);
    };

    const handleCloseCheckoutDialog = (isCompleted) => {
        setOpenCheckoutDialog(prevState => ({...prevState, value: false, isCompleted: isCompleted}));
    }

    const generateTitleName = () => {
        switch (openCheckoutDialog.dataType) {
            case PAYMENT_METHOD.CREDIT:
                return (`title-nexi`);
            case PAYMENT_METHOD.DEBIT:
                return (`title-slimPay`);
            case PAYMENT_METHOD.BANK:
                return (`title-bank`);
        }
    }

    const checkoutDialog = useMemo(() => {
        return <CheckoutDialog
            open={openCheckoutDialog.value}
            handleClose={handleCloseCheckoutDialog}
            title={generateTitleName()}
            titleStyle={{'textAlign': 'center'}}
            dataType={openCheckoutDialog.dataType}
            data={openCheckoutDialog.data}
        />
    }, [openCheckoutDialog.value]);

    if(!url) {
        url = window.location.href;
    } else if (url !== window.location.href && data) {
        updateData(null);
        url = window.location.href;
    } else {
        url = window.location.href;
    }

    return (
        <DetailBarContext.Provider value={{active, activeDetailBar, data, updateData, isLoading, handleLoading, dataType, updateType, updateDataAndType, openCheckoutDialog, setOpenCheckoutDialog}}>
            {children} {openCheckoutDialog && checkoutDialog}
        </DetailBarContext.Provider>
    );
};
