import * as React from 'react';
import {styled} from "@mui/system";
import MuiCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import themeContext from "../ThemeContext.js";
import {Fragment, useContext} from "react";
import {DetailBarContext} from "../../middleware/DetailBarContext.js";
import skeletonList1 from "../../../images/desktop/skeletons/card_list_skeleton_1.svg";
import skeletonList2 from "../../../images/desktop/skeletons/card_list_skeleton_2.svg";
import {useMediaQuery} from "react-responsive";
import {
    CTA_CATEGORIES,
    getBranchIcon, getPolicy, setPersonImage,
    setStatusColorDesktop,
    setStatusString
} from "../../service/DashboardService.js";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import companyIcon from "../../../images/desktop/company-icon.svg";
import hourglassIcon from "../../../images/desktop/hourglass-icon.svg";
import autoCvtIcon from "../../../images/policy_branches/auto-cvt-icon.svg";
import genericClaims from "../../../images/policy_branches/generic_claims.svg";
import {useTranslation} from "react-i18next";
import paymentIcon from "../../../images/nav-payment-hover.svg";
import signingIcon from "../../../images/nav-signing-hover.svg";
import regulationIcon from "../../../images/nav-regulations-hover.svg";
import creditCardIcon from "../../../images/desktop/payments/credit-card-icon.svg";
import DisabledCreditCardInfo from "../../../images/desktop/payments/disabled-credit-card-icon.svg";
import bankIcon from "../../../images/desktop/payments/bank-icon.svg";
import {Button} from "@mui/material";
import {StatusLabel, StyledButtonAction, StyledDivider, StyledNoItemsDiv} from "../../styles/DesktopStyles.js";
import {getClaim, setClaimStatus} from "../../service/ClaimsService.js";
import {ListCardContainer} from "../../pages/desktop/Dashboard.js";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {useNavigate} from "react-router-dom";
import {getNotification, PAYMENT_METHOD} from "../../service/PaymentsService.js";
import slimpayLogo from "../../../images/desktop/payments/slimpay-logo-gray.svg";
import Divider from "@mui/material/Divider";
import {withStyles} from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import {Col} from "react-bootstrap";
import infoPaymentIcon from "../../../images/payment-info-icon.svg";


const StyledCard = styled(MuiCard)(({theme, skeleton}) => ({
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '24px',
    outline: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
    width: '100%',
    height: '106px',
    boxShadow: 'none',
    cursor: skeleton === 'true' ? '' : 'pointer',
    '&:hover': {
        boxShadow: skeleton === 'true' ? '' : '0px 8px 16px rgba(0, 0, 0, 0.15)',
    }
}));

const StyledCardContent = styled(CardContent)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '0 !important',
    width: '100%',
}));

const CardContentTop = styled('div')(({theme, skeleton, list_card_width}) => ({
    padding: '16px',
    height: '60%',
    display: 'flex',
    flexDirection: 'row',
    width: list_card_width ? '100%' : '1300px',
    alignItems: 'center',
    fontSize: '14px',
    justifyContent: 'space-between',
}));

const CardContentBottom = styled('div')(({theme, right}) => (
    right ?
        {
            paddingLeft: '24px',
            paddingRight: '24px',
            height: '40%',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'left',
            gap: '24px'
        } : {
            paddingLeft: '24px',
            paddingRight: '24px',
            height: '40%',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center'
        }
));

const BackgroundLeftImage = styled('div')(({theme}) => ({
    background: themeContext.color.neutral100,
    borderRadius: '100%',
    width: '32px',
    height: '32px',
    minWidth: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const CardColumnStart = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '8px',
    width: "28%",
    paddingRight: '24px'
}));

const CardColumnCenter = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '8px',
    width: "28%",
    paddingRight: '24px'
}));

const CardColumnCenterReverse = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    //gap: '8px',
    width: "28%",
    paddingRight: '24px',
    flexDirection: 'column'
}));


const CardColumnEnd = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '8px',
    width: "16%",
    //paddingRight: '24px'
}));

const CardText = styled('span')(({theme}) => ({
    fontSize: '14px',
}));

const CardTextBold = styled('span')(({theme, fontSize}) => ({
    fontSize: fontSize ? fontSize : '14px',
    fontWeight: 'bold'
}));

export const CARD_TYPE = {
    POLICY: 'policy',
    CLAIM: 'claim',
    PAYMENT: 'payment',
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: themeContext.color.neutral600,
        color: themeContext.color.neutral10,
        fontSize: '12px',
        width: '100%',
        lineHeight: '1.5',
        marginTop: '8px'
    },
}))(Tooltip);

export default function ListCard({
                                     type,
                                     object,
                                     isLoading,
                                     handleSignDocumentPolicy,
                                     handleRegulatePolicy,
                                     handleRenewPolicy,
                                     activeCard,
                                     setActiveCard,
                                     noItemsText
                                 }) {
    const token = sessionStorage.getItem('token')
    const {t, i18n} = useTranslation();
    const {
        active,
        activeDetailBar,
        data,
        updateData,
        handleLoading,
        updateDataAndType,
        setOpenCheckoutDialog
    } = useContext(DetailBarContext);
    const list_card_width = useMediaQuery({query: '(max-width: 2000px)'});
    const skeletonNr = Array(4).fill(null);
    const navigate = useNavigate();
    let peopleArray = [];

    if (object && type === CARD_TYPE.PAYMENT) {
        peopleArray = object.people;
        object = object.data;
    }

    const handleCard = (value) => {
        if (data && (data.id === value.id)) {
            setActiveCard(null)
            updateData(null)
        } else {
            setActiveCard(value.id)
            switch (type) {
                case CARD_TYPE.POLICY:
                    getPolicy(token, value.id).then((res) => {
                        updateDataAndType(res, type)
                    });
                    break;
                case CARD_TYPE.CLAIM:
                    getClaim(token, value.id).then((res) => {
                        updateDataAndType(res, type)
                    });
                    break;
                case CARD_TYPE.PAYMENT:
                    getNotification(object, value.id, peopleArray).then((res) => {
                        updateDataAndType(res, type)
                    });
                    break;
            }
        }
    }
    const handleCTA = (event, value) => {
        event.stopPropagation();
        updateData(null)
        switch (value) {
            case CTA_CATEGORIES.PAYMENTS:
                handleRenewPolicy(value.id);
                break;
            case CTA_CATEGORIES.SIGNING:
            case CTA_CATEGORIES.PENDING:
                handleSignDocumentPolicy(value.id);
                break;
            case CTA_CATEGORIES.REGULATIONS:
                handleRegulatePolicy();
                break;
        }
    }

    const cardPlaceholderNew = (
        <ListCardContainer>
            {skeletonNr.map((_, index) => (
                <StyledCard key={index} skeleton={'true'}>
                    <StyledCardContent>
                        <CardContentTop skeleton={'true'} list_card_width={list_card_width}>
                            <CardColumnStart>
                                <img src={skeletonList1} alt={'skeleton'}/>
                            </CardColumnStart>
                            <CardColumnCenter>
                                <img src={skeletonList2} alt={'skeleton'}/>
                            </CardColumnCenter>
                            <CardColumnCenter>
                                <img src={skeletonList2} alt={'skeleton'}/>
                            </CardColumnCenter>
                            <CardColumnEnd>
                                <img src={skeletonList2} alt={'skeleton'}/>
                            </CardColumnEnd>
                        </CardContentTop>
                        <StyledDivider/>
                        <CardContentBottom/>
                    </StyledCardContent>
                </StyledCard>
            ))}
        </ListCardContainer>
    );

    const noItems = (
        <StyledNoItemsDiv>
            <span className={'d-flex align-items-center font-weight-bold font-italic'}
                  style={{color: themeContext.color.neutral400}}>{noItemsText}</span>
        </StyledNoItemsDiv>
    )


    const getStyleFromType = (value, type) => {
        switch (type) {
            case CARD_TYPE.POLICY:
                return {outline: `${themeContext.spacing.borders.border2} ${setStatusColorDesktop(value.status)}`}
            case CARD_TYPE.CLAIM:
                return {outline: `${themeContext.spacing.borders.border2} ${setClaimStatus(value.closeType, value.settledValueCents).color}`}
            case CARD_TYPE.PAYMENT:
                return {outline: `${themeContext.spacing.borders.border2} ${themeContext.color.main}`}
        }
    }

    return (
        isLoading < 2 ? cardPlaceholderNew : (object.length === 0 ? noItems :
            object.map((value, index) => (
                <StyledCard onClick={() => handleCard(value)} key={index}
                            style={(activeCard && (value.id === activeCard)) ? getStyleFromType(value, type) : {}}>
                    {type === CARD_TYPE.POLICY &&
                        <StyledCardContent>
                            <CardContentTop list_card_width={list_card_width}>
                                <CardColumnStart>
                                    <Avatar sx={{width: 32, height: 32}} alt={value.name}
                                            src={setPersonImage(value.gender, value.image)} key={value.id}/>
                                    <CardTextBold
                                        className={'text-left ellipsis-2'}>{(value.firstName && value.lastName) ? `${value.firstName} ${value.lastName}` : value.name}</CardTextBold>
                                </CardColumnStart>
                                <CardColumnCenter>
                                    <BackgroundLeftImage>
                                        <img src={getBranchIcon(value.type)} alt={'type'}/>
                                    </BackgroundLeftImage>
                                    <CardText className={'ellipsis-1-v2'}>
                                        {getBranchIcon(value.type) === autoCvtIcon ? value.plate : value.type}
                                    </CardText>
                                </CardColumnCenter>
                                <CardColumnCenter>
                                    <BackgroundLeftImage>
                                        <img src={companyIcon} alt={'company'}/>
                                    </BackgroundLeftImage>
                                    <CardText className={'ellipsis-1-v2'}>{value.company}</CardText>
                                </CardColumnCenter>
                                <CardColumnEnd>
                                    <BackgroundLeftImage>
                                        <img src={hourglassIcon} alt={'hourglass'}/>
                                    </BackgroundLeftImage>
                                    <CardTextBold className={'ellipsis-1-v2'}>
                                        {moment(value.insuranceEnd).format('DD/MM/YYYY')}
                                    </CardTextBold>
                                </CardColumnEnd>
                            </CardContentTop>
                            <Fragment>
                                <StyledDivider/>
                                <CardContentBottom>
                                    <StatusLabel color={value.status} type={CARD_TYPE.POLICY}>
                                        {t(setStatusString(value.status))}
                                    </StatusLabel>
                                    {
                                        value.cta.map((cta, index) => (
                                            <Fragment key={index}>
                                                {cta === CTA_CATEGORIES.PENDING &&
                                                    <>
                                                        <Divider orientation="vertical" variant="middle" flexItem
                                                                 style={{margin: '10px 13px 10px 14px'}}/>
                                                        <StyledButtonAction className={'text-uppercase font-weight-bold '}
                                                                            endIcon={<ChevronRightIcon/>}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                updateData(null)
                                                                                navigate('/signing')
                                                                            }}>
                                                            {t('dashboard.revision-button')}
                                                        </StyledButtonAction>
                                                    </>
                                                }
                                                {cta && cta !== CTA_CATEGORIES.PENDING &&
                                                    <>
                                                        <Divider orientation="vertical" variant="middle" flexItem
                                                                 style={{margin: '10px 13px 10px 16px'}}/>
                                                        <Button onClick={(event) => handleCTA(event, cta)} variant="text"
                                                                sx={{borderRadius: '12px', border: 'none', fontWeight: 'bold'}}
                                                                startIcon={(cta !== CTA_CATEGORIES.PENDING && cta !== CTA_CATEGORIES.FUTURE) &&
                                                                    <img
                                                                        src={cta === CTA_CATEGORIES.PAYMENTS ? paymentIcon : (cta === CTA_CATEGORIES.SIGNING ? signingIcon : (cta === CTA_CATEGORIES.REGULATIONS ? regulationIcon : {}))}
                                                                        alt={cta}/>}>
                                                            {cta === CTA_CATEGORIES.PAYMENTS && t('dashboard.renew-button')}
                                                            {cta === CTA_CATEGORIES.SIGNING && t('dashboard.sign-button')}
                                                            {cta === CTA_CATEGORIES.PENDING && t('dashboard.revision-button')}
                                                            {cta === CTA_CATEGORIES.REGULATIONS && t('dashboard.regulations-button')}
                                                        </Button>
                                                    </>
                                                }
                                            </Fragment>
                                        ))
                                    }
                                </CardContentBottom>
                            </Fragment>
                        </StyledCardContent>}

                    {type === CARD_TYPE.CLAIM &&
                        <StyledCardContent>
                            <CardContentTop list_card_width={list_card_width}>
                                <CardColumnStart>
                                    <Avatar sx={{width: 32, height: 32}} alt={value.name}
                                            src={setPersonImage(value.gender, value.image)} key={value.id}/>
                                    <CardTextBold
                                        className={'text-left ellipsis-2'}>{(value.firstName && value.lastName) ? `${value.firstName} ${value.lastName}` : value.name}</CardTextBold>
                                </CardColumnStart>
                                <CardColumnCenter>
                                    <BackgroundLeftImage>
                                        <img src={value.plate ? autoCvtIcon : genericClaims} alt={'type'}/>
                                    </BackgroundLeftImage>
                                    <CardText className={'ellipsis-1-v2'}>
                                        {value.plate ? value.plate : value.claimtype}
                                    </CardText>
                                </CardColumnCenter>
                                {<CardColumnCenterReverse>
                                    <CardText className={'ellipsis-1-v2'}>
                                        {value.controparte ? t(`desktop.claims.counterpart`) : ''}
                                    </CardText>
                                    <CardTextBold className={'ellipsis-1 text-left'}>
                                        {value.controparte}
                                    </CardTextBold>
                                </CardColumnCenterReverse>}
                                <CardColumnCenterReverse style={{width: '16%'}}>
                                    <CardText className={'ellipsis-1-v2'}>
                                        {t(`desktop.claims.open date`)}
                                    </CardText>
                                    <CardTextBold className={'ellipsis-1-v2'}>
                                        {moment(value.eventdate).format('DD/MM/YYYY')}
                                    </CardTextBold>
                                </CardColumnCenterReverse>
                            </CardContentTop>
                            <Fragment>
                                <StyledDivider/>
                                <CardContentBottom>
                                    <CardText className={'ellipsis-1-v2'}>
                                        {t(`desktop.claims.practice number`)}
                                        <CardTextBold>{value.claimnr}</CardTextBold>
                                    </CardText>
                                    <Divider orientation="vertical" variant="middle" flexItem
                                             style={{margin: '10px 13px 10px 13px'}}/>
                                    <StatusLabel
                                        color={setClaimStatus(value.closeType, value.settledValueCents)}
                                        type={CARD_TYPE.CLAIM}>
                                        {t(setClaimStatus(value.closeType, value.settledValueCents).label)}
                                    </StatusLabel>
                                </CardContentBottom>
                            </Fragment>
                        </StyledCardContent>}

                    {type === CARD_TYPE.PAYMENT &&
                        <StyledCardContent>
                            <CardContentTop list_card_width={list_card_width}>
                                <CardColumnStart>
                                    <Avatar sx={{width: 32, height: 32}} alt={value.name}
                                            src={setPersonImage(value.gender, value.image)} key={value.id}/>
                                    <CardTextBold fontSize={'16px'}
                                                  className={'text-left ellipsis-2'}>{(value.firstName && value.lastName) ? `${value.firstName} ${value.lastName}` : value.name}</CardTextBold>
                                </CardColumnStart>
                                <CardColumnCenter>
                                    <CardText className={'ellipsis-1-v2'}>
                                        {t(`desktop.payments.id`)}
                                    </CardText>
                                    <CardTextBold fontSize={'16px'} className={'ellipsis-1-v2 text-left'}>
                                        {value.nr}
                                    </CardTextBold>
                                </CardColumnCenter>
                                <CardColumnCenter>
                                    <CardText className={'ellipsis-1-v2'}>
                                        {t(`desktop.payments.date`)}
                                    </CardText>
                                    <CardTextBold fontSize={'16px'} className={'ellipsis-1-v2'}>
                                        {moment(value.datebill).format('DD/MM/YYYY')}
                                    </CardTextBold>
                                </CardColumnCenter>
                                <CardColumnCenter>
                                    <CardText className={'ellipsis-1-v2'}>
                                        {t(`desktop.payments.total`)}
                                    </CardText>
                                    <CardTextBold fontSize={'16px'} className={'ellipsis-1-v2'} main={true}>
                                        {value.requestedPremium}
                                    </CardTextBold>
                                </CardColumnCenter>
                            </CardContentTop>
                            <Fragment>
                                <StyledDivider/>
                                {
                                    !peopleArray.some(person => person.person?.id === value.personId) ?
                                        <CardContentBottom right={true}>
                                            {
                                                value.requestedCents > 799900 ?
                                                    <HtmlTooltip title={
                                                        <div className={'d-flex flex-row'}>
                                                            <Col className="col-auto"
                                                                 style={{paddingRight: 0, paddingLeft: 0}}>
                                                                <img src={infoPaymentIcon} alt="info-payment-icon"/>
                                                            </Col>
                                                            <Col
                                                                style={{
                                                                    textAlign: 'left',
                                                                    padding: '0px 0px 0px 3px'
                                                                }}>
                                                                <span
                                                                    style={{
                                                                        fontSize: '12px !important',
                                                                        fontWeight: 'normal !important',
                                                                        color: `${themeContext.color.neutral400} !important`,
                                                                    }}>{t('desktop.payments.limit-exceeded')}</span>
                                                            </Col>
                                                        </div>
                                                    }
                                                    placement={"top"}
                                                    slotProps={{
                                                         popper: {
                                                             modifiers: [
                                                                 {
                                                                     name: 'offset',
                                                                     options: {
                                                                         offset: [0, -14],
                                                                     },
                                                                 },
                                                             ],
                                                         },
                                                     }}
                                                    >
                                                        <Button
                                                            variant="text"
                                                            sx={{
                                                                borderRadius: '12px',
                                                                border: 'none',
                                                                fontWeight: 'bold',
                                                                color: themeContext.color.disabledColor
                                                            }}
                                                            startIcon={<img
                                                                src={DisabledCreditCardInfo}
                                                                alt="credit-card-info"/>}>
                                                            {t('desktop.payments.credit-card')}
                                                        </Button>
                                                    </HtmlTooltip>
                                                    :
                                                    <Button
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setOpenCheckoutDialog(prevState => ({
                                                                ...prevState,
                                                                value: true,
                                                                dataType: PAYMENT_METHOD.CREDIT,
                                                                data: value
                                                            }));
                                                        }} variant="text"
                                                        sx={{borderRadius: '12px', border: 'none', fontWeight: 'bold'}}
                                                        startIcon={<img
                                                            src={creditCardIcon}
                                                            alt="credit-card-info"/>}>
                                                        {t('desktop.payments.credit-card')}
                                                    </Button>
                                            }
                                            <Button onClick={(event) => {
                                                event.stopPropagation();
                                                setOpenCheckoutDialog(prevState => ({
                                                    ...prevState,
                                                    value: true,
                                                    dataType: PAYMENT_METHOD.DEBIT,
                                                    data: value
                                                }));
                                            }} variant="text"
                                                    sx={{borderRadius: '12px', border: 'none', fontWeight: 'bold'}}
                                                    startIcon={<img
                                                        src={bankIcon}
                                                        alt="bank-icon"/>}>
                                                {t('desktop.payments.debit')}
                                            </Button>
                                            <Button onClick={(event) => {
                                                event.stopPropagation();
                                                setOpenCheckoutDialog(prevState => ({
                                                    ...prevState,
                                                    value: true,
                                                    dataType: PAYMENT_METHOD.BANK,
                                                    data: value
                                                }));
                                            }} variant="text"
                                                    sx={{borderRadius: '12px', border: 'none', fontWeight: 'bold'}}>
                                                {t('desktop.payments.iban')}
                                            </Button>
                                        </CardContentBottom>
                                        :
                                        <CardContentBottom right={true}>
                                            <Button disabled={true}
                                                    variant="text"
                                                    sx={{borderRadius: '12px', border: 'none', fontWeight: 'bold'}}
                                                    startIcon={
                                                        <img height={40} src={slimpayLogo} alt="slimpay-logo"/>
                                                    }>
                                                {t('desktop.payments.slimPay')}
                                            </Button>
                                        </CardContentBottom>
                                }
                            </Fragment>
                        </StyledCardContent>}
                </StyledCard>
            ))));
}
