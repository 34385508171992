import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import AuthContextMiddleware from "../../middleware/AuthContextMiddleware.js";
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {
    base64url_decode,
    base64url_encode,
    deleteElementFromIndexedDB,
    putElementFromIndexedDB
} from '../../service/UtilityService.js';
import * as idb from "idb";
import back from "../../../images/back-icon.svg";
import {makeStyles} from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Button, InputAdornment, ListItemSecondaryAction, Slide, TextField} from "@mui/material";
import Divider from "@mui/material/Divider";
import editIcon from '../../../images/edit-icon.svg'
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useTranslation} from "react-i18next";
import themeContext from "../../components/ThemeContext.js";
import spinner from "../../../images/spinner.svg";
import ErrorPage from "../../pages/ErrorPage.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";
import {ExternalPageLayout, StyledPageCard, StyledPageLayout, StyledTitlePage} from "../../styles/DesktopStyles.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Profile() {
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingRegistration, setIsLoadingRegistration] = useState(false);


    useEffect(() => {
        if (navigator.onLine) {
            getInfo();
            getAccountExec();
        } else {
            //controllare se funziona ancora
            getOfflineInfo();
        }
    }, []);


    const useStyles = makeStyles((theme) => ({
        divList: {
            width: '100% !important',
            backgroundColor: `${theme.palette.background.paper} !important`,
            padding: "0 0 0 0 !important"
        },
        list: {
            paddingTop: '0 !important',
            paddingBottom: '30px !important',
            color: `${themeContext.color.neutral600} !important`,
        },
        listItem: {
            color: `${themeContext.color.neutral600} !important`,
            padding: '16px 0 0 0 !important',
            fontSize: '18px !important'
        },
        listFullItem: {
            color: `${themeContext.color.neutral400} !important`,
            paddingTop: '18px !important',
            paddingBottom: '18px !important',
            fontSize: '18px !important'
        },
        dialogTitle: {
            'textAlign': 'center !important',
            'paddingTop': '10px !important',
            'paddingBottom': '2px !important',
        },
        dialogDescription: {
            'textAlign': 'center !important',
            'paddingTop': '0px !important',
            'paddingBottom': '15px !important',
        }
    }));

    const {open} = useContext(SidebarContext);
    const token = sessionStorage.getItem('token');
    const [user, setUser] = useState([]);
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [isBiometricsChecked, setIsBiometricsChecked] = useState(!!localStorage.getItem('webauthn'));
    const [isPinChecked, setIsPinChecked] = useState(!!localStorage.getItem('pinIsActive'));
    const [themeChecked, setThemeChecked] = useState('light');
    const [openMenu, setOpenMenu] = useState(null);
    const [accountExec, setAccountExec] = useState([]);
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const webAuthNregistrationUrl = `${process.env.REACT_APP_BASE_URL}/management/webauthn/registration`;
    const changeImageUrl = `${process.env.REACT_APP_BASE_URL}/media/user/changeImage`;
    const accountExecUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/accountExec`;
    const [openDialog, setOpenDialog] = React.useState(false);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);


    const defaultValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email
    };

    const formValues = useRef(defaultValues);

    const classes = useStyles();
    const [checked, setChecked] = React.useState(['wifi']);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    function getInfo() {
        setIsLoadingData(true)
        fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setUser(data);
            }
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        })
    }

    function getOfflineInfo() {
        const dbPromise = idb.openDB('appDB', 1, {
            upgrade(upgradeDb) {
                if (!upgradeDb.objectStoreNames.contains('profileInfo')) {
                    upgradeDb.createObjectStore('profileInfo', {keyPath: 'key'});
                }
            }
        });
        dbPromise.then((db) => {
            let tx = db.transaction('profileInfo', 'readwrite').objectStore('profileInfo');
            return tx.getAll();
        }).then((array) => {
            for (let i = 0; i < array.length; i++) {
                if (array[i].key === 'firstName') {
                    user.firstName = array[i].value;
                    //setFirstName(array[i].value)
                }
                if (array[i].key === 'lastName') {
                    user.lastName = array[i].value;
                    //setLastName(array[i].value)
                }
                if (array[i].key === 'userID') {
                    user.id = array[i].value;
                    //setUserID(array[i].value)
                }
            }
        });
    }

    function turnBack() {
        return navigate(-1);
    }

    function registerCredentials() {
        setIsLoadingRegistration(true);
        let username = localStorage.getItem('username-wide');
        //let token = authCtx.token;

        fetch(`${webAuthNregistrationUrl}/start`, {
            method: 'POST',
            body: JSON.stringify({
                username: username,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            if (!response.ok) {
                return;
            }
            // console.log('data:', registrationResponse.json());
            response.json()
                .then(
                    (registrationResponse) => {
                        let keyData = registrationResponse.publicKeyCredentialCreationOptions;
                        let excludeCredentials = keyData.excludeCredentials
                            .map(c => {
                                c.id = base64url_decode(c.id);
                                c.transports = undefined;
                                return c;
                            });
                        let cred = navigator.credentials.create({
                            publicKey: {
                                rp: keyData.rp,
                                user: {
                                    id: base64url_decode(keyData.user.id),
                                    name: keyData.user.name,
                                    displayName: keyData.user.displayName
                                },
                                challenge: base64url_decode(keyData.challenge),
                                pubKeyCredParams: keyData.pubKeyCredParams,
                                excludeCredentials: excludeCredentials,
                                timeout: 60000,
                                authenticatorSelection: {
                                    userVerification: 'required',
                                    authenticatorAttachment: 'platform',
                                },
                            },
                        }).catch((e) => {
                            if (e.name === 'InvalidStateError') {
                                localStorage.setItem('webauthn', true);
                                setIsBiometricsChecked(current => !current);
                            }
                        });
                        cred.then((credData) => {
                            if (!credData) {
                                setIsLoadingRegistration(false);
                                return;
                            }
                            console.log('cred - register:', credData)
                            let finishRequest = {
                                credential: {
                                    clientExtensionResults: {},
                                    id: credData.id,
                                    rawId: base64url_encode(credData.rawId),
                                    response: {
                                        attestationObject: base64url_encode(credData.response.attestationObject),
                                        clientDataJSON: base64url_encode(credData.response.clientDataJSON)
                                    },
                                    type: "public-key"
                                },
                                registrationId: registrationResponse.registrationId,
                                userAgent: navigator.userAgent
                            };
                            fetch(`${webAuthNregistrationUrl}/finish`, {
                                method: 'POST',
                                body: JSON.stringify(finishRequest),
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`
                                }
                            }).then((finishResponse) => {
                                finishResponse.json().then((r) => console.log("finish registration:", r));
                                // console.log('finish:', finishResponse);
                                localStorage.setItem('webauthn', credData.id);
                                setIsLoadingRegistration(false);
                                setIsBiometricsChecked(current => !current);
                            })
                        }).catch(() => {
                            //todo informare l'utente della registrazione fallita
                            setIsLoadingRegistration(false);
                        });
                    }
                );
        }).catch(() => {
            setErrorPage(true)
        });
        // console.log(cred);
    }

    function handleBiometrics(event) {
        if (event.target.checked) {
            registerCredentials();
        } else {
            localStorage.removeItem('webauthn');
            setIsBiometricsChecked(current => !current);
        }
    }

    function handleTheme(event) {
        if (event.target.checked) {
            setThemeChecked('dark')
        } else {
            setThemeChecked('light')
        }
    }

    const handleInputChange = (e) => {
        const {
            name,
            value
        } = e.target;
        formValues.current = {
            ...formValues.current,
            [name]: value,
        }
    };

    const handlePinChange = () => {
        authCtx.changePin();
        navigate('/pin');
    }

    const handleSetPin = (event) => {
        if (event.target.checked) {
            //todo set pin
            sessionStorage.setItem('pin', false);
            sessionStorage.setItem('confirmedPin', false);
            authCtx.setPin();
            setIsPinChecked(current => !current);
            return navigate('/settings/profile/code');
        } else {
            localStorage.removeItem('pinIsActive')
            deleteElementFromIndexedDB('pin')
            setIsPinChecked(current => !current);
        }
    }

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setOpenMenu(null);
    }


    const handleChangeImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    async function changeImage(e) {
        const file = e.target.files[0];
        const fileType = file.type;
        const fileName = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setIsLoadingImg(true);
            let base64_fullString = reader.result.split(",");
            let base64_string = base64_fullString[1];
            fetch(changeImageUrl, {
                method: 'POST',
                body: JSON.stringify({
                    mimeType: fileType,
                    fileName: fileName,
                    base64: base64_string,
                    type: "user"
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': true
                },
            }).then((res) => {
                if (res.ok) {
                    //alert upload completato
                    console.log(res)
                    return res.json();
                }

            }).then((res) => {
                setIsLoadingImg(false);
                setOpenMenu(false);
                document.getElementById("user-image").children[0].src = res.message;
            }).catch(() => {
                setErrorPage(true)
            });
        };
        reader.onerror = error => {
            console.error(error)
        };
    }

    function getAccountExec() {
        fetch(accountExecUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setAccountExec(data);
            putElementFromIndexedDB('accountExec', data)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    function reload() {
        window.location.reload();
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {errorPage ?
                    <ErrorPage/> :
                    <StyledPageLayout className="w-100 mx-4">
                        <StyledTitlePage>
                            <Col>
                                <Row>
                                    <Col className='col-2 float-left'>
                                        {/*<img src={back} onClick={turnBack}
                                             className="nav-top-logo float-left cursor-pointer"
                                             height="27px"
                                             alt="back"/>*/}
                                    </Col>
                                    <Col className="col-8">
                                        <span className="header-title ellipsis-1-v2">{t('profile.title')}</span>
                                    </Col>
                                    <Col className="col-2 float-right">
                                    </Col>
                                </Row>
                            </Col>
                        </StyledTitlePage>
                        {
                            isLoadingData ?
                                <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                :
                                <Fragment>
                                    <div>
                                        <Row>
                                            <Col style={{
                                                'background': themeContext.color.white,
                                                'paddingRight': '0px',
                                                'paddingLeft': '19.5px'
                                            }}>
                                                <div className={classes.divList}>
                                                    <List className={classes.list}>
                                                        <ListItem className={classes.listItem}>
                                                            <TextField
                                                                variant={'standard'}
                                                                label={t('profile.first-name')}
                                                                name="firstName"
                                                                id="firstName"
                                                                fullWidth
                                                                className="custom-textField-baseline-disable"
                                                                //defaultValue={`${user.firstName}`}
                                                                value={user.firstName}
                                                                //onChange={handleInputChange}
                                                                InputProps={{
                                                                    style: {
                                                                        'color': themeContext.color.neutral600
                                                                    },
                                                                }}
                                                            />
                                                        </ListItem>
                                                        <ListItem className={classes.listItem}>
                                                            <TextField
                                                                variant={'standard'}
                                                                label={t('profile.last-name')}
                                                                id="lastName"
                                                                fullWidth
                                                                className="custom-textField-baseline-disable"
                                                                value={user.lastName}
                                                                //defaultValue={`${user.lastName}`}
                                                                InputProps={{
                                                                    style: {
                                                                        'color': themeContext.color.neutral600
                                                                    },
                                                                }}
                                                            />
                                                        </ListItem>
                                                        <ListItem className={classes.listItem}>
                                                            <TextField
                                                                variant={'standard'}
                                                                label={t('profile.email')}
                                                                id="email"
                                                                fullWidth
                                                                className="custom-textField-baseline-disable"
                                                                //defaultValue={`${user.email}`}
                                                                value={user.email}
                                                                InputProps={{
                                                                    style: {
                                                                        'color': themeContext.color.neutral600
                                                                    },
                                                                }}
                                                            />
                                                        </ListItem>
                                                        <ListItem className={classes.listItem}>
                                                            <TextField
                                                                variant={'standard'}
                                                                label={t('profile.phone')}
                                                                id="phone"
                                                                fullWidth
                                                                className="custom-textField-baseline-disable"
                                                                //defaultValue={`${user.phone}`}
                                                                value={user.phone}
                                                                InputProps={{
                                                                    style: {
                                                                        'color': themeContext.color.neutral600
                                                                    },
                                                                }}
                                                            />
                                                        </ListItem>
                                                        <Link to="/settings/profile/password"
                                                              style={{'textDecoration': 'none'}}>
                                                            <ListItem className={classes.listItem}>
                                                                <TextField
                                                                    variant={'standard'}
                                                                    label={t('profile.password')}
                                                                    id="password"
                                                                    fullWidth
                                                                    type="password"
                                                                    value="11111111"
                                                                    className="custom-textField-baseline-disable"
                                                                    InputProps={{
                                                                        style: {
                                                                            'color': themeContext.color.neutral600
                                                                        },
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <img src={editIcon} alt="edit icon"
                                                                                     style={{'paddingRight': '14px'}}/>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </ListItem>
                                                        </Link>
                                                    </List>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-list"
                                                 style={{'backgroundColor': themeContext.color.white}}>
                                                <div>
                                                    <List style={{'paddingTop': '0px'}}>
                                                        {
                                                            localStorage.getItem('biometrics') &&
                                                            <Fragment>
                                                                <Divider/>
                                                                <ListItem className={classes.listFullItem}>
                                                                    <ListItemText id="switch-list-label-wifi"
                                                                                  primary={t('profile.biometrics-access')}/>
                                                                    <ListItemSecondaryAction
                                                                        className={classes.ListItem}>
                                                                        {isLoadingRegistration ?
                                                                            <img src={spinner}
                                                                                 className="spinner-img"
                                                                                 alt="spinner"/>
                                                                            :
                                                                            <label className="form-switch">
                                                                                <input
                                                                                    id="webauthn-switch"
                                                                                    type="checkbox"
                                                                                    onChange={handleBiometrics}
                                                                                    checked={isBiometricsChecked}/>
                                                                                <i></i>
                                                                            </label>
                                                                        }
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            </Fragment>
                                                        }
                                                        <Divider/>
                                                    </List>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Container style={{
                                        'backgroundColor': themeContext.color.white,
                                        'padding': '10px 30px 40px 30px',
                                    }}>
                                        <Row>
                                            <Col>
                                                <h6 className="footer-text">{t('profile.footer-text')}</h6>
                                                <a onClick={handleClickOpen}
                                                   style={{'color': themeContext.color.main}}
                                                   className="footer-link"
                                                   id="contact-now">{t('profile.footer-contact-us')}</a>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Fragment>
                        }
                        <Dialog
                            open={openDialog}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                            PaperProps={{
                                style: {
                                    'position': 'absolute',
                                    'bottom': '0',
                                    'left': 'auto',
                                    'right': 'auto',
                                    'width': '95%',
                                    'borderRadius': '10px',
                                    'color': themeContext.color.neutral600
                                },
                            }}>
                            <DialogTitle id="alert-dialog-slide-title" className={classes.dialogTitle}>
                                    <span style={{'fontSize': '15px',}}>
                                        {t('contacts.modal-title')}
                                    </span>
                            </DialogTitle>
                            <DialogContent className={classes.dialogDescription}>
                                    <span style={{
                                        'fontSize': '20px',
                                        'fontWeight': "bold",
                                    }}>{accountExec.firstName} {accountExec.lastName}
                                    </span>
                            </DialogContent>
                            <Divider/>
                            {
                                accountExec.phone !== null &&
                                <Fragment>
                                    <DialogActions>
                                        <a href={`tel:${accountExec.phone}`}
                                           style={{
                                               'textDecoration': 'none',
                                               'width': '100%',
                                               'margin': '0 auto'
                                           }}>
                                            <Button color="primary" fullWidth
                                                    style={{
                                                        'textTransform': 'none',
                                                        'color': themeContext.color.green
                                                    }}>
                                                {t('claimDetail.modal-tel-button')}
                                            </Button>
                                        </a>
                                    </DialogActions>
                                    <Divider/>
                                </Fragment>
                            }
                            <DialogActions>
                                <a href={`mailto:${accountExec.email}?subject=${t('profile.email-subject')}&body=${t('profile.email-body-1')} ${accountExec.firstName} ${accountExec.lastName},%0D%0A${t('profile.email-body-2')}%0D%0A`}
                                   style={{
                                       'textDecoration': 'none',
                                       'width': '100%',
                                       'margin': '0 auto'
                                   }}>
                                    <Button color="primary" style={{'textTransform': 'none'}}
                                            fullWidth>
                                        {t('claimDetail.modal-email-button')}
                                    </Button>
                                </a>
                            </DialogActions>
                            <Divider/>
                            <DialogActions>
                                <Button onClick={handleClose} style={{
                                    'color': themeContext.color.neutral400,
                                    'textTransform': 'none'
                                }} fullWidth>
                                    {t('claimDetail.modal-refuse-button')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </StyledPageLayout>
                }
            </StyledPageCard>
        </ExternalPageLayout>
    )
}
