import React, {useState} from 'react';
import {Button, Checkbox, Menu, Radio} from '@mui/material';
import {styled} from "@mui/system";
import {alpha} from "@mui/material/styles";
import Checked from "../../../Checked.js";
import Unchecked from "../../../Unchecked.js";
import themeContext from "../ThemeContext.js";
import spinner from "../../../images/spinner.svg";
import {useTranslation} from "react-i18next";
import exitIcon from "../../../images/exit-icon.svg";
import {b64toFile} from "../../service/UtilityService.js";
import FileSaver from "file-saver";
import {POLICY_STATUS} from "../../service/DashboardService.js";
import {StatusLabel} from "../../styles/DesktopStyles.js";
import {CARD_TYPE} from "./ListCard.js";
import Chips from "./../desktop/Chips.js";

const ContainerTitle = styled('div')(({theme}) => ({
    marginTop: '8px',
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    gap: '16px',
    width: '100%'
}));

const RowTitle = styled('div')(({theme}) => ({
    display: "flex",
    alignItems: 'center',
    gap: '16px'
}));

const StyledTitle = styled('span')(({theme}) => ({
    fontSize: '20px',
    fontWeight: 'bold',
    textTransform: "uppercase"
}));

const MenuTitle = styled('span')(({theme}) => ({
    fontSize: '20px',
    fontWeight: 'bold',
    color: themeContext.color.neutral600
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        disableScrollLock={true}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 24,
        padding: 16,
        border: 'none',
        outline: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
        marginTop: theme.spacing(1),
        minWidth: 360,
        color: 'rgb(55, 65, 81)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
        ...theme.applyStyles('dark', {
            color: theme.palette.grey[300],
        }),
    },
}));

const MainTitle = React.memo(function MainTitle({
                                                    title,
                                                    button,
                                                    buttonAction,
                                                    buttonIcon,
                                                    buttonIconSelected,
                                                    buttonMenuName,
                                                    people,
                                                    isLoadingPeople,
                                                    isLoadingOtherPolicies,
                                                    chips,
                                                    selected,
                                                    onChange,
                                                    maintainSelection
                                                }) {
    const {t, i18n} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [exportStep, setExportStep] = useState(0);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [checked, setChecked] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedRadioCsv, setSelectedRadioCsv] = useState('');
    const [selectedRadioXlsx, setSelectedRadioXlsx] = useState('');
    const [selectedRadioPdf, setSelectedRadioPdf] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const token = sessionStorage.getItem('token');
    const exportFileUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/export/policies`;

    const policyStatusArray = [
        {
            value: 'all',
            label: t('dashboard.select-all')
        },
        {
            value: POLICY_STATUS.ACTIVE,
            label: <StatusLabel color={2} type={CARD_TYPE.POLICY}>{t('desktop.dashboard.active')}</StatusLabel>
        },
        {
            value: POLICY_STATUS.EXPIRING,
            label: <StatusLabel color={3} type={CARD_TYPE.POLICY}>{t('desktop.dashboard.expiring')}</StatusLabel>
        },
        {
            value: POLICY_STATUS.EXPIRED,
            label: <StatusLabel color={4} type={CARD_TYPE.POLICY}>{t('desktop.dashboard.expired')}</StatusLabel>
        },
        {
            value: POLICY_STATUS.INACTIVE,
            label: <StatusLabel color={1} type={CARD_TYPE.POLICY}>{t('desktop.dashboard.not active')}</StatusLabel>
        },
    ]

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(false)

        setChecked([])
        setIsCheckAll(false)
        setExportStep(0)
        setSelectedStatus(null)
        setSelectedRadioCsv('')
        setSelectedRadioXlsx('')
        setSelectedRadioPdf('')
    };

    const handleCheck = (event) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, parseInt(event.target.value)];
        } else {
            updatedList.splice(checked.indexOf(parseInt(event.target.value)), 1);
            setIsCheckAll(false)
        }
        setChecked(updatedList);
    };

    const checkAll = () => {
        setIsCheckAll(!isCheckAll);
        setChecked(people.map(li => li.id));
        if (isCheckAll) {
            setChecked([]);
        }
    };

    function handleRadioCsv(event) {
        setSelectedRadioCsv(event.target.value)
        setSelectedRadioXlsx('')
        setSelectedRadioPdf('')
    }

    function handleRadioXlsx(event) {
        setSelectedRadioXlsx(event.target.value)
        setSelectedRadioCsv('')
        setSelectedRadioPdf('')
    }

    function handleRadioPdf(event) {
        setSelectedRadioPdf(event.target.value)
        setSelectedRadioCsv('')
        setSelectedRadioXlsx('')

    }

    function exportFile() {
        setIsLoading(true);
        let format = '';
        if (selectedRadioCsv !== '') {
            format = 'csv';
        }
        if (selectedRadioXlsx !== '') {
            format = 'xlsx';
        }
        if (selectedRadioPdf !== '') {
            format = 'pdf';
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                personIds: checked,
                policyFilter: selectedStatus === 'all' ? null : selectedStatus,
                format: format
            })
        };
        fetch(exportFileUrl, requestOptions)
            .then((response) => {
                return response.json();
            }).then((data) => {
            if (data.filename.includes('.pdf')) {
                FileSaver.saveAs(b64toFile(data.base64, data.filename, "application/pdf"), data.filename);
                setIsLoading(false);
            }
            if (data.filename.includes('.csv')) {
                FileSaver.saveAs(b64toFile(data.base64, data.filename, "text/csv;charset=utf-8"), data.filename);
                setIsLoading(false);
            }
            if (data.filename.includes('.xlsx')) {
                FileSaver.saveAs(b64toFile(data.base64, data.filename, "application/octet-stream"), data.filename);
                setIsLoading(false);
            }
            setOpenMenu(false)
            setChecked([])
            setIsCheckAll(false)
            setExportStep(0)
            setSelectedStatus(null)
            setSelectedRadioCsv('')
            setSelectedRadioXlsx('')
            setSelectedRadioPdf('')
        }).catch(() => {
            //setServiceError(true)
        });
    }


    return (
        <ContainerTitle>
            <RowTitle>
                <StyledTitle>{title}</StyledTitle>
                {chips && <Chips chips={chips}
                                 maintainSelection={maintainSelection}
                                 selected={selected}
                                 onChange={(value) => onChange(value)} showAll={null}/>}
                {/*<OutlinedInput
                        type={'text'}
                        placeholder={`CERCA`}
                        sx={{
                            borderRadius: '12px',
                            color: themeContext.color.neutral600,
                            height: '32px',
                            width: '280px'
                        }} endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                edge="end">
                                <Search/>
                            </IconButton>
                        </InputAdornment>
                    }/>*/}
            </RowTitle>
            {button && <div>
                <Button
                    disabled={isLoadingPeople || isLoadingOtherPolicies < 2}
                    id="demo-customized-button"
                    aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    sx={{borderRadius: '12px'}}
                    aria-expanded={openMenu ? 'true' : undefined}
                    variant="outlined"
                    disableElevation
                    onClick={(e) => {
                        checkAll()
                        handleClick(e)
                    }}
                    endIcon={<img src={isLoadingPeople || isLoadingOtherPolicies < 2 ? buttonIcon : buttonIconSelected} alt={button}
                                  />}
                >{button}</Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    keepMounted
                >
                    <div>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <div className={'d-flex align-items-center'}>
                                {exportStep === 0 &&
                                    <MenuTitle>{t('dashboard.policy-export-title')}</MenuTitle>
                                }
                                {exportStep === 1 &&
                                    <MenuTitle>{t('desktop.dashboard.policies status')}</MenuTitle>
                                }
                                {exportStep === 2 &&
                                    <MenuTitle>{t('desktop.dashboard.format')}</MenuTitle>
                                }
                            </div>
                            <img src={exitIcon} className="exit-button" alt="close" onClick={handleClose}/>
                        </div>
                        <div className={'d-flex flex-column'} style={{gap: 8}}>
                            {exportStep === 0 &&
                                <>
                                <span onClick={checkAll} style={{
                                    textDecoration: 'underline',
                                    color: themeContext.color.main,
                                    cursor: 'pointer',
                                    width: 'auto'
                                }}>{t('dashboard.select-all')}
                                </span>
                                    {people.map((person, index) => (
                                        <div className={'d-flex flex-row justify-content-start align-items-center'}
                                             key={index}>
                                            <Checkbox
                                                key={index}
                                                value={person.id}
                                                name={String(person.id)}
                                                id={String(person.id)}
                                                type="checkbox"
                                                onClick={(e) => {
                                                    handleCheck(e)
                                                }}
                                                checked={checked.includes(person.id)}
                                                checkedIcon={<Checked/>}
                                                icon={<Unchecked/>}
                                            />
                                            {(((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === ""))) ? person.name : person.firstName + ' ' + person.lastName}
                                        </div>
                                    ))}
                                    <div className={'d-flex justify-content-end align-items-center'}>
                                        <Button className={'d-flex text-transform-none w-auto'}
                                                onClick={() => {
                                                    setExportStep(current => current + 1)
                                                }}
                                                disabled={checked.length === 0}>
                                            {t('dashboard.proceed-button')}
                                        </Button>
                                    </div>
                                </>
                            }
                        </div>
                        {exportStep === 1 &&
                            <>
                                {policyStatusArray.map((status, index) => (
                                    <div key={index}
                                         className={'d-flex flex-row justify-content-start align-items-center'}>
                                        <Radio
                                            checked={selectedStatus === status.value}
                                            onChange={(e) => {
                                                if (selectedStatus === status.value) {
                                                    setSelectedStatus(null)
                                                } else {
                                                    setSelectedStatus(status.value)
                                                }
                                            }}
                                            value={status.value}
                                            name={status.value}
                                            id={status.value}
                                            inputProps={{'aria-label': status.value}}
                                        />
                                        {status.label}
                                    </div>
                                ))}
                                <div className={'d-flex justify-content-end align-items-center'}>
                                    <Button className={'d-flex text-transform-none darkgrey w-auto'}
                                            onClick={() => {
                                                setExportStep(current => current - 1)
                                                setSelectedStatus(null)
                                            }}>
                                        {t('dashboard.back-button')}
                                    </Button>
                                    <Button className={'d-flex text-transform-none w-auto'}
                                            onClick={() => {
                                                setExportStep(current => current + 1)
                                            }}
                                            disabled={selectedStatus === null}>
                                        {t('dashboard.proceed-button')}
                                    </Button>
                                </div>
                            </>
                        }
                        {exportStep === 2 &&
                            <>
                                <div className={'d-flex flex-column justify-content-center align-items-start'}>
                                    <div className={'d-flex flex-row justify-content-start align-items-center'}>
                                        <Radio
                                            checked={selectedRadioCsv === 'csv'}
                                            onChange={handleRadioCsv}
                                            value="csv"
                                            name="radio-buttons"
                                            inputProps={{'aria-label': 'csv'}}
                                            className="justify-content-end"
                                            color="primary"
                                        />
                                        <span>{t('dashboard.extensions-1')}</span>
                                    </div>
                                    <div className={'d-flex flex-row justify-content-start align-items-center'}>
                                        <Radio
                                            checked={selectedRadioXlsx === 'xlsx'}
                                            onChange={handleRadioXlsx}
                                            value="xlsx"
                                            name="radio-buttons"
                                            inputProps={{'aria-label': 'xlsx'}}
                                            className="justify-content-end"
                                            color="primary"
                                        />
                                        <span>{t('dashboard.extensions-2')}</span>
                                    </div>
                                    <div className={'d-flex flex-row justify-content-start align-items-center'}>
                                        <Radio
                                            checked={selectedRadioPdf === 'pdf'}
                                            onChange={handleRadioPdf}
                                            value="pdf"
                                            name="radio-buttons"
                                            inputProps={{'aria-label': 'pdf'}}
                                            className="justify-content-end"
                                            color="primary"
                                        />
                                        <span>{t('dashboard.extensions-3')}</span>
                                    </div>
                                </div>
                                <div className={'d-flex justify-content-end align-items-center'}>
                                    <Button className={'d-flex text-transform-none darkgrey w-auto'}
                                            onClick={() => {
                                                setExportStep(current => current - 1)
                                                setSelectedRadioCsv('')
                                                setSelectedRadioXlsx('')
                                                setSelectedRadioPdf('')
                                            }}>
                                        {t('dashboard.back-button')}
                                    </Button>
                                    <Button className={'d-flex justify-content-center text-transform-none w-auto'}
                                            onClick={exportFile}
                                            disabled={selectedRadioCsv === '' && selectedRadioXlsx === '' && selectedRadioPdf === ''}>
                                        {isLoading ?
                                            <img src={spinner} className="spinner-img-inner-button"
                                                 alt="spinner"/> :
                                            <span>{t('dashboard.export-button')}</span>
                                        }
                                    </Button>
                                </div>
                            </>
                        }
                    </div>
                </StyledMenu>
            </div>}

        </ContainerTitle>
    );
});

export default MainTitle;