import React from 'react';
import './css/App.scss';
import App from './App.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import swLocal from './js/pwa/swLocal.js';
import swDev from './js/pwa/swDev.js';
import swStage from './js/pwa/swStage.js';
import idbDev from './js/pwa/idbDev.js';
import {BrowserRouter} from 'react-router-dom';
import {AuthContextProvider} from "./js/middleware/AuthContextMiddleware.js";
import ReactDOM from 'react-dom/client'; // Importa da 'react-dom/client'

import './js/service/i18n.js';
import swProd from "./js/pwa/swProd.js";
import FetchMiddleware from "./js/middleware/fetchMiddleware.js";
import {NavbarProvider} from "./js/middleware/NavbarContext.js";
import {InterceptorProvider} from "./js/middleware/InterceptorContext.js";
import {DesktopLayoutProvider} from "./js/middleware/DesktopLayoutContext.js";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AuthContextProvider>
        <BrowserRouter>
            <DesktopLayoutProvider>
                <InterceptorProvider>
                    <NavbarProvider>
                        <FetchMiddleware/>
                        <App/>
                    </NavbarProvider>
                </InterceptorProvider>
            </DesktopLayoutProvider>
        </BrowserRouter>
    </AuthContextProvider>
)
idbDev();
if (process.env.REACT_APP_ENV === "LOCAL") {
    swLocal();
}
if (process.env.REACT_APP_ENV === "DEV") {
    swDev();
}
if (process.env.REACT_APP_ENV === "STAGE") {
    swStage();
}
if (process.env.REACT_APP_ENV === "PROD") {
    swProd();
}
