import {API_METHODS, apiFetch} from "./ApiService.js";
import {
    generatePaymentUrl,
    nexiUrl,
    paymentReceivedUrl,
    paymentUrl,
    sendUpdatePassUrl,
    slimPayPendingUrl,
    slimPayUrl
} from "./EndpointService.js";

export const getPayments = async (token_arg, personId) => {
    let url = `${paymentUrl}`;
    if (personId) url += `&personId=${personId}`;
    try {
        return await apiFetch(url, token_arg);
    } catch (error) {
        return null;
    }
};

export const getNotification = async (notifications, notificationId, people) => {
    try {
        const notification = notifications.filter(notification => notification.id === notificationId)[0];
        const person = people.find(person => person.person.id === notification.personId);
        if (person) {
            return {...notification, isSlimPay: true, person: person.person};
        }
        return notification;
    } catch (error) {
        return null;
    }
};

export const getSlimPayOrNexi = async (token_arg, type, id) => {
    const url = type === PAYMENT_METHOD.CREDIT ? `${nexiUrl}?notificationId=${id}` : `${slimPayUrl}?orderId=${id}`;
    try {
        return await apiFetch(url, token_arg);
    } catch (error) {
        return null;
    }
}

export const setSlimpayPending = async (token_arg, personId) => {
    try {
        return await apiFetch(slimPayPendingUrl, token_arg, API_METHODS.POST, {
            personId: personId,
            slimpayIsPending: true
        });
    } catch (error) {
        return null;
    }
}

export const generatePayment = async (token_arg, paymentType, notificationId) => {
    const url =`${generatePaymentUrl}?paymentType=${paymentType}&notificationId=${notificationId}`;
    try {
        return await apiFetch(url, token_arg);
    } catch (error) {
        return null;
    }
}

export const paymentReceived = async (token_arg, userId, paymentType, notificationId) => {
    try {
        return await apiFetch(paymentReceivedUrl, token_arg, API_METHODS.POST, {
            userId: userId,
            type: paymentType,
            notificationId: notificationId
        });
    } catch (error) {
        return null;
    }
}

export const sendUpdatePass = async (token_arg, userId, policyId) => {
    try {
        return await apiFetch(sendUpdatePassUrl, token_arg, API_METHODS.POST, {
            userId: userId,
            policyId: policyId,
        });
    } catch (error) {
        return null;
    }
}

export const PAYMENT_METHOD = {
    CREDIT: 'credit',
    DEBIT: 'debit',
    BANK: 'bank'
}
