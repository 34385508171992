import React, {useContext} from 'react';
import {Box, CssBaseline} from '@mui/material';
import Sidebar from './Sidebar';
import DetailBar from "./DetailBar.js";
import Header from "./Header.js";
import {DesktopLayoutContext} from "../../middleware/DesktopLayoutContext.js";

const Layout = (/*{children}*/) => {
    const {isSidebarVisible, isHeaderVisible, isDetailbarVisible} = useContext(DesktopLayoutContext);

    return (
        <>
            <CssBaseline/>
            {isHeaderVisible && <Header/>}
            <Box
                component="main"
                sx={{
                    [`& .MuiDrawer-paper`]: {
                        m: '8px',
                        mt: '73px',
                        height: `calc(100% - 81px)`
                    }
                }}>
                {isSidebarVisible && <Sidebar/>}
                {/*{children}*/}
                {isDetailbarVisible && <DetailBar/>}
            </Box>
        </>
    );
};

export default Layout;
