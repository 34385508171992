import React, {Fragment, useContext, useRef, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import AuthContextMiddleware from "../../middleware/AuthContextMiddleware.js";
import back from '../../../images/back-icon.svg';
import {Button, InputAdornment, TextField} from "@mui/material";
import DialogSmall from "../../components/DialogSmall.js";
import {useTranslation} from "react-i18next";
import themeContext from "../../components/ThemeContext.js";
import {regexPassword} from "../../service/UtilityService.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";
import {ExternalPageLayout, StyledPageCard, StyledPageLayout, StyledTitlePage} from "../../styles/DesktopStyles.js";

export default function Password() {
    const {open} = useContext(SidebarContext);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const token = sessionStorage.getItem('token');
    const [passwordDialog, setPasswordDialog] = useState(false);
    const changePasswordUrl = `${process.env.REACT_APP_BASE_URL}/management/profile/changePassword`;
    const [oldPasswordVisibility, setOldPasswordVisibility] = useState(false);
    const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
    const [confirmNewPasswordVisibility, setConfirmNewPasswordVisibility] = useState(false);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [openConfirmChangePasswordDialog, setOpenConfirmChangePasswordDialog] = useState(false);

    const defaultValues = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    };
    const formValues = useRef(defaultValues);

    const submitHandler = () => {
        if (formValues.current.newPassword === formValues.current.confirmNewPassword && (formValues.current.newPassword !== "" && formValues.current.confirmNewPassword !== "")) {
            if (formValues.current.newPassword.match(regexPassword)) {
                fetch(changePasswordUrl, {
                    method: 'POST',
                    body: JSON.stringify({
                        oldPassword: formValues.current.oldPassword,
                        newPassword: formValues.current.newPassword,
                    }),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }).then((res) => {
                    return res.json();
                }).then((res) => {
                    if (res.ok) {
                        setOpenConfirmChangePasswordDialog(true)
                    } else {
                        setPasswordDialog(true)
                        /*
                        return res.then((data) => {
                            let errorMessage = 'Authentication failed!';
                            if (data && data.error && data.error.message) {
                                errorMessage = data.error.message;
                            }
                            throw new Error(errorMessage);
                        });
                        */
                    }
                }).catch(() => {
                    setErrorPage(true)
                });
            } else {
                setPasswordDialog(true);
            }
        } else {
            setPasswordDialog(true);
        }
    }

    const handleInputChange = (e) => {
        const {
            name,
            value
        } = e.target;
        formValues.current = {
            ...formValues.current,
            [name]: value,
        }
    };

    const handlePasswordDialog = () => {
        setPasswordDialog(current => !current);
    }

    const handleOldPasswordVisibility = e => {
        const password = document.querySelector("#oldPassword")
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        setOldPasswordVisibility(current => !current)
    }

    const handleNewPasswordVisibility = e => {
        const password = document.querySelector("#newPassword")
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        setNewPasswordVisibility(current => !current)
    }

    const handleConfirmNewPasswordVisibility = e => {
        const password = document.querySelector("#confirmNewPassword")
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        setConfirmNewPasswordVisibility(current => !current)
    }

    function turnBack() {
        return navigate(-1);
    }

    function reload() {
        window.location.reload();
    }

    function handleCloseConfirmChangePasswordDialog() {
        setOpenConfirmChangePasswordDialog(false)
        navigate('/settings/profile');
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {
                    errorPage ?
                        <ErrorPage/> :
                        <StyledPageLayout className="w-100 mx-4">
                            <StyledTitlePage>
                                <Col className="col-2 float-left">
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px" alt="back"/>
                                </Col>
                                <Col className="col-8">
                                    <span className="header-title ellipsis-1-v2">{t('change-password.title')}</span>
                                </Col>
                                <Col className="col float-right"></Col>
                            </StyledTitlePage>
                            <div>
                                <Row style={{'marginTop': '28px'}}>
                                    <Col>
                                        <TextField
                                            id="oldPassword"
                                            className="custom-textfield mb-4"
                                            fullWidth
                                            label={t('change-password.old-password')}
                                            variant="outlined"
                                            name="oldPassword"
                                            type="password"
                                            value={formValues.oldPassword}
                                            defaultValue={defaultValues.oldPassword}
                                            onChange={handleInputChange}
                                            color="primary"
                                            InputProps={{
                                                style: {
                                                    borderRadius: '16px',
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {oldPasswordVisibility &&
                                                            <span
                                                                className="material-icons toggle-password float-right unselectable darkgrey"
                                                                onClick={handleOldPasswordVisibility}>visibility_off</span>}
                                                        {!oldPasswordVisibility &&
                                                            <span
                                                                className="material-icons toggle-password float-right unselectable darkgrey"
                                                                onClick={handleOldPasswordVisibility}>visibility</span>}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextField
                                            id="newPassword"
                                            className="custom-textfield mb-4"
                                            fullWidth
                                            label={t('change-password.new-password')}
                                            variant="outlined"
                                            name="newPassword"
                                            type="password"
                                            value={formValues.newPassword}
                                            onChange={handleInputChange}
                                            color="primary"
                                            InputProps={{
                                                style: {
                                                    borderRadius: '16px',
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {newPasswordVisibility &&
                                                            <span
                                                                className="material-icons toggle-password float-right unselectable darkgrey"
                                                                onClick={handleNewPasswordVisibility}>visibility_off</span>}
                                                        {!newPasswordVisibility &&
                                                            <span
                                                                className="material-icons toggle-password float-right unselectable darkgrey"
                                                                onClick={handleNewPasswordVisibility}>visibility</span>}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextField
                                            id="confirmNewPassword"
                                            className="custom-textfield"
                                            fullWidth
                                            label={t('change-password.confirm-new-password')}
                                            variant="outlined"
                                            name="confirmNewPassword"
                                            type="password"
                                            value={formValues.confirmNewPassword}
                                            onChange={handleInputChange}
                                            color="primary"
                                            InputProps={{
                                                style: {
                                                    borderRadius: '16px',
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {confirmNewPasswordVisibility &&
                                                            <span
                                                                className="material-icons toggle-password float-right unselectable darkgrey"
                                                                onClick={handleConfirmNewPasswordVisibility}>visibility_off</span>}
                                                        {!confirmNewPasswordVisibility &&
                                                            <span
                                                                className="material-icons toggle-password float-right unselectable darkgrey"
                                                                onClick={handleConfirmNewPasswordVisibility}>visibility</span>}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row style={{'marginTop': '20px'}}>
                                    <Col>
                                        <h6 className="paragraph-text">
                                            {t('change-password.info')}
                                        </h6>
                                    </Col>
                                </Row>

                                <Row style={{'marginTop': '48px', marginBottom: 24}}>
                                    <Col>
                                        <Button onClick={submitHandler} className="primary-button"
                                                variant="outlined"
                                                size="large"
                                                type="submit">
                                            {t('change-password.button')}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <DialogSmall title={t('newPassword.modal-criterias-title')}
                                         description={t('newPassword.tooltip-title') + '<ul>' +
                                             '<li>' + t('newPassword.tooltip-1') + '</li>' +
                                             '<li>' + t('newPassword.tooltip-2') + '</li>' +
                                             '<li>' + t('newPassword.tooltip-3') + '</li>' +
                                             '<li>' + t('newPassword.tooltip-4') + '</li>' +
                                             '<li>' + t('newPassword.tooltip-5') + '</li></ul>'
                                         }
                                         buttonText={t('change-password.modal-button')}
                                         open={passwordDialog}
                                         handleClose={handlePasswordDialog}
                            ></DialogSmall>
                            <DialogSmall title={t('change-password.completed-reset-password-title')}
                                         description={t('change-password.completed-reset-password-description')}
                                         buttonText={t('dialog.button-ok')}
                                         open={openConfirmChangePasswordDialog}
                                         handleClose={handleCloseConfirmChangePasswordDialog}
                            ></DialogSmall>
                        </StyledPageLayout>
                }
            </StyledPageCard>
        </ExternalPageLayout>
    )
}