import React, {Fragment} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {Button, Checkbox, Menu, MenuItem, MenuList, Radio} from "@mui/material";
import BellOff from '././../../../images/bell-icon.svg'
import BellOn from './../../../images/bellOn-icon.svg'
import UserImg from './../../../images/profile-icon.svg'
import baseFemaleAvatar from './../../../images/female-icon.svg'
import wideLogoLight from './../../../images/wideLogo-icon.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Moment from "react-moment";
import baseMaleAvatar from "./../../../images/male-icon.svg";
import {upperCaseFirst} from "upper-case-first";
import searchIcon from "./../../../images/search-icon.svg";
import exitIcon from "./../../../images/exit-icon.svg";
import exportIcon from "./../../../images/export.svg";
import nothing from "./../../../images/nothing-icon.svg";
import car from "./../../../images/car-blue.svg";
import activeFromIcon from "./../../../images/active-from-icon.svg";
import cardPlaceholder_min from "./../../../images/card-placeholder_min.svg";
import cardPlaceholder_678 from "./../../../images/card-placeholder_678.svg";
import cardPlaceholder_918 from "./../../../images/card-placeholder_918.svg";
import cardPlaceholder_full from "./../../../images/card-placeholder_full.svg";
import scrollbarUsersPlaceholder from "./../../../images/user-placeholder.svg";
import Checked from "../../../Checked.js";
import Unchecked from "../../../Unchecked.js";
import spinner from "./../../../images/spinner.svg";
import Header from "../../components/mobile/Header.js";
import baseCompanyAvatar from "./../../../images/company-avatar.svg";
import themeContext from "../../components/ThemeContext.js";
import {Title, CardTextMid, CardTextCenter} from "../../styles/Styles.js";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {CTA_CATEGORIES, setStatusColor, setGrayScale, getBranchIcon} from "./../../service/DashboardService.js";
import ErrorPage from "./../ErrorPage.js";

const useStyles = {
    card: {
        margin: "0 auto !important",
        marginTop: "10px !important",
        marginBottom: "18px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        minHeight: "140px !important"
    },
    cardTextCenter: {
        color: `${themeContext.color.neutral400} !important`,
        textAlign: 'center !important',
        lineHeight: '0.6 !important',
        fontSize: '14px !important',
        paddingBottom: '0 !important',
        marginBottom: '0 !important'
    },
    cardAvatar: {
        float: "right !important",
        width: "35px !important",
        height: "35px !important",
    },
    avatar: {
        width: '56px !important',
        height: '56px !important',
        margin: '4px 7px 4px 7px !important',
    },
    bigAvatar: {
        width: '66px !important',
        height: '66px !important',
        margin: '0 0 0 5px !important',
        boxShadow: `0px 2px 5px ${themeContext.color.boxShadow} !important`,
    },
    renewButton: {
        fontWeight: '600 !important',
        fontSize: '12px !important',
        lineHeight: '16px !important',
        letterSpacing: '2.07px !important',
        color: `${themeContext.color.mainActive} !important`,
        textTransform: 'uppercase !important',
        float: 'left !important'
    },
    revisionButton: {
        fontWeight: '600 !important',
        fontSize: '12px !important',
        lineHeight: '16px !important',
        letterSpacing: '2.07px !important',
        color: `${themeContext.color.yellow} !important`,
        textTransform: 'uppercase !important',
        float: 'left !important'
    }
}

export default function Dashboard(props) {
    const {
        peoplePolicies,
        serviceError,
        bellRinging,
        noPeople,
        searchMode,
        isLoadingPeople,
        people,
        selectedAvatar,
        timeoutAvatar,
        filter,
        openMenuExport,
        exportType,
        isCheckAll,
        selectedRadioCsv,
        selectedRadioXlsx,
        selectedRadioPdf,
        isLoading,
        isLoadingPeopleData,
        width_min,
        width_767,
        width_991,
        width_1199,
        isNothingPeople,
        user,
        openMenu,
        checked,
        handleSearchMode,
        handleClickPerson,
        handleOpenMenuExport,
        handleOpenMenu,
        handleCloseMenu,
        handleFilter,
        handleCloseMenuExport,
        handleCheck,
        checkAll,
        handleRadioCsv,
        handleRadioXlsx,
        handleRadioPdf,
        handleBackCheck,
        onClickPolicy,
        handleRenewPolicy,
        handleSignDocumentPolicy,
        handleRegulatePolicy,
        exportFile,
        stringFilter,
        reload,
        setSearchValue,
        setExportType,
        errorPage
    } = props;

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    return (
        errorPage ?
            <ErrorPage/> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Row className="nav-top container" style={{
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Header leftSrc={UserImg} leftAction={() => navigate('/settings')} iconSrc={wideLogoLight}
                                    rightSrc={bellRinging ? BellOn : BellOff}
                                    rightAction={() => navigate('/messages')} email={user.email}/>
                            {!noPeople &&
                                <Fragment>
                                    <Row style={{'marginTop': '11px'}}>
                                        <Col className="col-auto float-left">
                                            <Title>{t('dashboard.beneficiaries-title')}</Title>
                                        </Col>
                                        <Col className="col float-right" style={{'marginTop': '-7px'}}>
                                            {
                                                searchMode &&
                                                <Row className="div-search" style={{'marginRight': '0'}}>
                                                    <Col className="col-10">
                                                        <input id="search-input" className="input-search"
                                                               onChange={e => {
                                                                   setSearchValue(e.target.value)
                                                               }}
                                                               autoFocus></input>
                                                    </Col>
                                                    <Col className="col-2"
                                                         style={{
                                                             'paddingRight': '2px',
                                                             'textAlign': 'right'
                                                         }}>
                                                        <img src={exitIcon} alt="close" onClick={() => {
                                                            handleSearchMode()
                                                        }}
                                                             height="25px" width="25px"/>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                !searchMode &&
                                                <img src={searchIcon}
                                                     className="nav-top-logo float-right cursor-pointer"
                                                     height="27px"
                                                     alt="search-icon"
                                                     onClick={handleSearchMode}/>
                                            }

                                        </Col>
                                    </Row>
                                    {isLoadingPeople &&
                                        <Row className="mt-3 w-100">
                                            <Col style={{textAlign: 'left'}}>
                                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                                     className="mr-1"/>
                                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                                     className="mr-1"/>
                                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                                     className="mr-1"/>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        !isLoadingPeople &&
                                        <Row style={{'marginTop': '10px'}}>
                                            <Col className="col-list">
                                                <Container className="people-scrollable-row">
                                                    <Container className="scrolling-wrapper-flexbox no-scrollbar"
                                                               id="scroll-container">
                                                        {
                                                            !isLoadingPeople && people.map((person, index) => (
                                                                <div className="card card-avatar" key={index}
                                                                     style={{'backgroundColor': themeContext.color.neutral10}}>
                                                                    <CardContent className="card-avatar-content"
                                                                                 style={{'backgroundColor': themeContext.color.neutral10}}>
                                                                        <Row style={{'width': '50px'}}>

                                                                            {
                                                                                person.image === null && person.gender === 'M' &&
                                                                                <Avatar alt="my-avatar"
                                                                                        src={baseMaleAvatar}
                                                                                        sx={selectedAvatar.current === person.id ? useStyles.bigAvatar : useStyles.avatar}
                                                                                        style={{
                                                                                            filter: `${setGrayScale(person.status)}`,
                                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                        }}
                                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                            }
                                                                            {
                                                                                person.image === null && person.gender === 'F' &&
                                                                                <Avatar alt="my-avatar"
                                                                                        src={baseFemaleAvatar}
                                                                                        sx={selectedAvatar.current === person.id ? useStyles.bigAvatar : useStyles.avatar}
                                                                                        style={{
                                                                                            filter: `${setGrayScale(person.status)}`,
                                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                        }}
                                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                            }
                                                                            {
                                                                                person.image === null && person.gender === 'C' &&
                                                                                <Avatar alt="my-avatar"
                                                                                        src={baseCompanyAvatar}
                                                                                        sx={selectedAvatar.current === person.id ? useStyles.bigAvatar : useStyles.avatar}
                                                                                        style={{
                                                                                            filter: `${setGrayScale(person.status)}`,
                                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                        }}
                                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                            }
                                                                            {
                                                                                person.image === null && person.gender === null &&
                                                                                <Avatar alt="my-avatar"
                                                                                        src={baseMaleAvatar}
                                                                                        sx={selectedAvatar.current === person.id ? useStyles.bigAvatar : useStyles.avatar}
                                                                                        style={{
                                                                                            filter: `${setGrayScale(person.status)}`,
                                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                        }}
                                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                            }
                                                                            {
                                                                                person.image &&
                                                                                <Avatar alt="my-avatar"
                                                                                        src={person.image}
                                                                                        sx={selectedAvatar.current === person.id ? useStyles.bigAvatar : useStyles.avatar}
                                                                                        style={{
                                                                                            filter: `${setGrayScale(person.status)}`,
                                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                        }}
                                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                            }
                                                                        </Row>
                                                                        <Row
                                                                            style={selectedAvatar.current === person.id ? {'width': '73px'} : {'width': '67px'}}>
                                                                            <h6 className={selectedAvatar.current === person.id ? "avatar-text-big text-uppercase" : "avatar-text text-uppercase"}
                                                                                style={selectedAvatar.current === person.id ? {color: `${setStatusColor(person.status)}`} : {}}>
                                                                                {((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ? upperCaseFirst(`${person.name}`)
                                                                                    : <span><span
                                                                                        className="ellipsis-1">{upperCaseFirst(`${person.firstName}`)} </span><span
                                                                                        className="ellipsis-1">{upperCaseFirst(`${person.lastName}`)}</span></span>
                                                                                }
                                                                            </h6>
                                                                        </Row>
                                                                    </CardContent>
                                                                </div>
                                                            ))
                                                        }
                                                    </Container>
                                                </Container>
                                            </Col>
                                        </Row>
                                    }
                                </Fragment>
                            }
                            <Row className="mb-2" style={{'marginTop': '20px'}}>
                                <Col className="col-2">
                                    <Title>{t('dashboard.policies-title')}</Title>
                                </Col>
                                <Col className="col-10">
                                    {
                                        filter.current === 'STATUS_DEFAULT' &&
                                        <Fragment>
                                            <Row className="float-right">
                                                <Button
                                                    className="filter-btn filter-btn-2 float-right justify-content-end"
                                                    onClick={handleOpenMenuExport}
                                                    style={{
                                                        'color': themeContext.color.neutral400,
                                                        'backgroundColor': 'unset',
                                                        'width': '20px',
                                                        'marginBottom': '6px',
                                                        '& .MuiTouchRipple .MuiTouchRippleVisible': {
                                                            'left': '3px',
                                                        },
                                                    }}>
                                                    <img onClick={handleOpenMenuExport} src={exportIcon}
                                                         style={{'width': '17px'}} alt="export-icon"/>
                                                </Button>
                                                <hr style={{
                                                    'transform': 'rotate(90deg)',
                                                    'width': '40px',
                                                    'marginTop': '2px',
                                                    'marginRight': '0px'
                                                }}/>
                                                <Button
                                                    className="filter-btn filter-btn-2 float-right justify-content-end"
                                                    style={{
                                                        'color': themeContext.color.neutral400,
                                                        'backgroundColor': 'unset',
                                                        'width': '20px',
                                                        'marginRight': 18,
                                                        '& .MuiTouchRipple .MuiTouchRippleVisible': {
                                                            'paddingRight': '35px',
                                                        },
                                                    }}
                                                    onClick={handleOpenMenu}>
                                                        <span className="material-symbols-outlined" style={{
                                                            'fontSize': '22px',
                                                            'width': 'auto',
                                                        }}>
                                                        filter_list
                                                        </span>
                                                </Button>
                                            </Row>
                                        </Fragment>
                                    }
                                    {
                                        filter.current !== 'STATUS_DEFAULT' &&
                                        <Row className="float-right">
                                            <Button
                                                className="filter-btn filter-btn-2  justify-content-end"
                                                style={{
                                                    'color': themeContext.color.neutral400,
                                                    'backgroundColor': 'unset'
                                                }}
                                                onClick={handleOpenMenuExport}>
                                                <img onClick={handleOpenMenuExport} src={exportIcon}
                                                     style={{'width': '17px'}} alt="export-icon"/>
                                            </Button>
                                            <hr style={{
                                                'transform': 'rotate(90deg)',
                                                'width': '40px',
                                                'marginTop': '2px',
                                            }}/>
                                            <Button className="filter-btn filter-btn-2  justify-content-end"
                                                    style={{
                                                        'color': themeContext.color.main,
                                                        'backgroundColor': 'unset',
                                                        'marginRight': 18,
                                                        '& .MuiTouchRipple .MuiTouchRippleVisible': {
                                                            'left': '-5px',
                                                            'right': '15px',
                                                            'width': 'auto'
                                                        },
                                                    }}
                                                    onClick={handleOpenMenu}>
                                                <span className="material-symbols-outlined" style={{
                                                    'fontSize': '22px',
                                                }}>
                                                    filter_list
                                                </span>
                                                <span style={{
                                                    'minWidth': '30px',
                                                    'padding': '0px'
                                                }}>
                                                    {stringFilter(filter.current)}
                                                </span>
                                            </Button>
                                        </Row>
                                    }
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={openMenu}
                                        keepMounted
                                        open={Boolean(openMenu)}
                                        onClose={handleCloseMenu}
                                        className="menu filter-menu-dashboard"
                                        PaperProps={{style: {'maxWidth': '100%', 'width': '200px'}}}>
                                        <MenuList className="title-filter-item">{t('dashboard.filter-title')}</MenuList>
                                        <MenuItem className="filter-item" onClick={() => handleFilter('STATUS_ACTIVE')}
                                                  style={filter.current === 'STATUS_ACTIVE' ? {
                                                      'color': themeContext.color.green,
                                                      'fontWeight': '800',
                                                      'backgroundColor': themeContext.color.neutral10
                                                  } : {'color': themeContext.color.green}}>
                                            {
                                                filter.current === 'STATUS_ACTIVE' ?
                                                    <Fragment>
                                                        {t('dashboard.filter-active')}
                                                        <img src={exitIcon} className="ml-auto" alt="exit-icon"/>
                                                    </Fragment> : t('dashboard.filter-active')
                                            }
                                        </MenuItem>
                                        <MenuItem className="filter-item"
                                                  onClick={() => handleFilter('STATUS_EXPIRING')}
                                                  style={filter.current === 'STATUS_EXPIRING' ? {
                                                      'color': themeContext.color.yellow,
                                                      'fontWeight': '800',
                                                      'backgroundColor': themeContext.color.neutral10
                                                  } : {'color': themeContext.color.yellow}}>
                                            {
                                                filter.current === 'STATUS_EXPIRING' ?
                                                    <Fragment>
                                                        {t('dashboard.filter-expirity')}
                                                        <img src={exitIcon} className="ml-auto" alt="exit-icon"/>
                                                    </Fragment> : t('dashboard.filter-expirity')
                                            }
                                        </MenuItem>
                                        <MenuItem className="filter-item" onClick={() => handleFilter('STATUS_EXPIRED')}
                                                  style={filter.current === 'STATUS_EXPIRED' ? {
                                                      'color': themeContext.color.red,
                                                      'fontWeight': '800',
                                                      'backgroundColor': themeContext.color.neutral10
                                                  } : {'color': themeContext.color.red}}>
                                            {
                                                filter.current === 'STATUS_EXPIRED' ?
                                                    <Fragment>
                                                        {t('dashboard.filter-expired')}
                                                        <img src={exitIcon} className="ml-auto" alt="exit-icon"/>
                                                    </Fragment> : t('dashboard.filter-expired')
                                            }
                                        </MenuItem>
                                        <MenuItem className="filter-item"
                                                  onClick={() => handleFilter('STATUS_INACTIVE')}
                                                  style={filter.current === 'STATUS_INACTIVE' ? {
                                                      'color': themeContext.color.neutral200,
                                                      'fontWeight': '800',
                                                      'backgroundColor': themeContext.color.neutral10
                                                  } : {'color': themeContext.color.neutral200}}>
                                            {
                                                filter.current === 'STATUS_INACTIVE' ?
                                                    <Fragment>
                                                        {t('dashboard.filter-killed')}
                                                        <img src={exitIcon} className="ml-auto" alt="exit-icon"/>
                                                    </Fragment> : t('dashboard.filter-killed')
                                            }
                                        </MenuItem>
                                    </Menu>
                                    <Menu
                                        id="simple-menu-export"
                                        anchorEl={openMenuExport}
                                        keepMounted
                                        open={Boolean(openMenuExport)}
                                        onClose={handleCloseMenuExport}
                                        className="menu filter-menu-dashboard"
                                        PaperProps={{style: {'maxWidth': '250px', 'width': '250px', 'left': '70px'}}}>
                                        {!exportType &&
                                            <MenuList className="title-filter-item">
                                                {t('dashboard.policy-export-title')}
                                            </MenuList>
                                        }
                                        {!exportType &&
                                            people.map((person, index) => (
                                                <Row key={index}>

                                                    <MenuItem className="filter-item"
                                                              style={{'width': '100%', 'marginTop': '-10px'}}>
                                                        <div style={{'display': 'grid', 'overflow': 'hidden'}}>
                                                            <Row>
                                                                <Col className={"col-1"}>
                                                                    <span style={{
                                                                        'paddingTop': '0px',
                                                                        'width': '220px',
                                                                        'whiteSpace': 'nowrap',
                                                                        'overflow': 'hidden',
                                                                        'textOverflow': 'ellipsis',
                                                                        'display': 'block',
                                                                        'marginLeft': '15px'
                                                                    }}>
                                                                        <Checkbox
                                                                            key={index}
                                                                            value={person.id}
                                                                            name={String(person.id)}
                                                                            id={String(person.id)}
                                                                            type="checkbox"
                                                                            onClick={handleCheck}
                                                                            checked={checked.includes(person.id)}
                                                                            checkedIcon={<Checked/>}
                                                                            icon={<Unchecked/>}
                                                                        />
                                                                        {(((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === ""))) ? person.name : person.firstName + ' ' + person.lastName}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </MenuItem>
                                                </Row>
                                            ))
                                        }
                                        {!exportType &&
                                            <Row style={{
                                                'justifyContent': 'right',
                                                'marginRight': '10px',
                                                'marginTop': '10px'
                                            }}>
                                                <Col className="col-8 left"
                                                     style={{'marginTop': '-5px', 'paddingLeft': ' 31px'}}>
                                                    <Checkbox
                                                        type="checkbox"
                                                        name="selectAll"
                                                        id="selectAll"
                                                        onClick={checkAll}
                                                        checked={isCheckAll}
                                                        checkedIcon={<Checked/>}
                                                        icon={<Unchecked/>}
                                                    />
                                                    <span style={{
                                                        'textDecoration': 'underline',
                                                        'color': themeContext.color.main
                                                    }}>
                                                        {t('dashboard.select-all')}
                                                    </span>
                                                </Col>
                                                <Col className="col-4 right">
                                                    <Button style={{'marginTop': '0px'}}
                                                            sx={useStyles.renewButton}
                                                            onClick={() => {
                                                                setExportType(true)
                                                            }}
                                                            disabled={checked.length === 0}>
                                                        {t('dashboard.proceed-button')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                        {exportType &&
                                            <MenuList className="title-filter-item" style={{'marginBottom': '10px'}}>
                                                {t('dashboard.extensions-title')}
                                            </MenuList>
                                        }
                                        {exportType &&
                                            <Row style={{'marginLeft': '15px'}}>
                                                <Radio
                                                    checked={selectedRadioCsv === 'csv'}
                                                    onChange={handleRadioCsv}
                                                    value="csv"
                                                    name="radio-buttons"
                                                    inputProps={{'aria-label': 'csv'}}
                                                    className="justify-content-end"
                                                    style={{'marginTop': '-8px'}}
                                                    color="primary"
                                                    size="small"
                                                />
                                                <span>{t('dashboard.extensions-1')}</span>
                                            </Row>
                                        }
                                        {exportType &&
                                            <Row style={{'marginLeft': '15px'}}>
                                                <Radio
                                                    checked={selectedRadioXlsx === 'xlsx'}
                                                    onChange={handleRadioXlsx}
                                                    value="xlsx"
                                                    name="radio-buttons"
                                                    inputProps={{'aria-label': 'xlsx'}}
                                                    className="justify-content-end"
                                                    style={{'marginTop': '-8px'}}
                                                    color="primary"
                                                    size="small"
                                                />
                                                <span>{t('dashboard.extensions-2')}</span>
                                            </Row>

                                        }
                                        {exportType &&
                                            <Row style={{'marginLeft': '15px'}}>
                                                <Radio
                                                    checked={selectedRadioPdf === 'pdf'}
                                                    onChange={handleRadioPdf}
                                                    value="pdf"
                                                    name="radio-buttons"
                                                    inputProps={{'aria-label': 'pdf'}}
                                                    className="justify-content-end"
                                                    style={{'marginTop': '-8px'}}
                                                    color="primary"
                                                    size="small"
                                                />
                                                <span>{t('dashboard.extensions-3')}</span>
                                            </Row>

                                        }
                                        {exportType &&
                                            <Row style={{
                                                'justifyContent': 'right',
                                                'marginRight': '10px',
                                                'marginTop': '10px'
                                            }}>
                                                <Col className="col-6 left">
                                                    <Button style={{'marginTop': '0px', 'marginLeft': '10px'}}
                                                            sx={useStyles.renewButton}
                                                            onClick={handleBackCheck}>
                                                        {t('dashboard.back-button')}
                                                    </Button>
                                                </Col>
                                                <Col className="col-6 float-right">
                                                    <Button style={{'marginTop': '0px', 'marginLeft': '30px'}}
                                                            sx={useStyles.renewButton}
                                                            disabled={selectedRadioCsv === '' && selectedRadioXlsx === '' && selectedRadioPdf === ''}
                                                            onClick={exportFile}>
                                                        {isLoading ?
                                                            <img src={spinner} className="spinner-img-inner-button"
                                                                 alt="spinner"/>
                                                            :
                                                            <span>
                                                                {t('dashboard.export-button')}
                                                            </span>
                                                        }
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                    </Menu>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {!noPeople ?
                        <Container className="top-of-the-dashboard"></Container>
                        :
                        <Container className="top-of-the-dashboard" style={{'paddingTop': '112px'}}></Container>
                    }
                    {
                        isLoadingPeopleData &&
                        <Fragment>
                            <Row className="mt-2">
                                <Col className="col-list">
                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && width_1199 &&
                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-list">
                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && width_1199 &&
                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-list">
                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && width_1199 &&
                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-list">
                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && width_1199 &&
                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                </Col>
                            </Row>
                        </Fragment>
                    }
                    <Row className="mt-2">
                        <Col>
                            {
                                !isLoadingPeopleData && isNothingPeople && selectedAvatar.current === 'null' &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('dashboard.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('dashboard.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {
                                !isLoadingPeopleData && isNothingPeople && selectedAvatar.current !== 'null' &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages" alt={t('dashboard.nothing-text-1')}>
                                        </img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('dashboard.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {!isLoadingPeopleData && !isNothingPeople &&
                                peoplePolicies.map((value, index) => (
                                    <Card key={index} onClick={() => onClickPolicy(value.id)} sx={useStyles.card}
                                          style={{'borderRight': `12px solid ${setStatusColor(value.status)}`}}>
                                        <CardContent style={{'paddingBottom': '12px'}}>
                                            <Row>
                                                <Col className="col-auto mr-0" style={{'paddingRight': '0px'}}>
                                                    <img src={getBranchIcon(value.type)} alt="home-policy-icon"
                                                         className="branch-icon"/>
                                                </Col>
                                                <Col className="col-7">
                                                    <CardTextMid>{value.type}</CardTextMid>
                                                </Col>
                                                <Col>
                                                    {
                                                        value.image === null && value.gender === 'M' &&
                                                        <Avatar src={baseMaleAvatar} sx={useStyles.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === 'F' &&
                                                        <Avatar src={baseFemaleAvatar} sx={useStyles.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === 'C' &&
                                                        <Avatar src={baseCompanyAvatar} sx={useStyles.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === null &&
                                                        <Avatar src={baseMaleAvatar} sx={useStyles.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image &&
                                                        <Avatar src={value.image} sx={useStyles.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content">{t('dashboard.expiration-label')}
                                                        <span className="font-weight-bold">
                                                        <Moment locale="it"
                                                                format="DD/MM/YYYY" className="black">
                                                            {value.insuranceEnd}
                                                        </Moment>
                                                    </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('dashboard.object-label')}
                                                        <span className="font-weight-bold black">
                                                            {value.object}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('dashboard.company-label')}
                                                        <span className="font-weight-bold black">
                                                                {value.company}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col" style={{'paddingRight': '0'}}>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">
                                                                {t('dashboard.policy-number-label')}
                                                                <span className="font-weight-bold black">
                                                                        {value.policyNr}
                                                                </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        value.plate !== null &&
                                                        <Row>
                                                            <div className="col-plate">
                                                                <img src={car} alt="car icon"/>
                                                                <span className="card-text-content"
                                                                      style={{
                                                                          'marginLeft': '7px',
                                                                          'color': themeContext.color.neutral600
                                                                      }}>
                                                                    {value.plate}
                                                                </span>
                                                            </div>
                                                        </Row>
                                                    }
                                                </Col>
                                                {(value.cta && value.cta !== CTA_CATEGORIES.FUTURE) &&
                                                    <Col className={'text-right pl-0 pb-0 mb-0 mt-2'}>
                                                        <Row className="float-right">
                                                            <Col>
                                                                <Button
                                                                    style={value.plate === null ? {marginTop: 0} : {marginTop: 24}}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        switch (value.cta) {
                                                                            case CTA_CATEGORIES.PAYMENTS:
                                                                                handleRenewPolicy(value.id);
                                                                                break;
                                                                            case CTA_CATEGORIES.SIGNING:
                                                                            case CTA_CATEGORIES.PENDING:
                                                                                handleSignDocumentPolicy(value.id);
                                                                                break;
                                                                            case CTA_CATEGORIES.REGULATIONS:
                                                                                handleRegulatePolicy();
                                                                                break;
                                                                        }
                                                                    }}
                                                                    sx={value.cta === CTA_CATEGORIES.PENDING ? useStyles.revisionButton : useStyles.renewButton}>
                                                                    {value.cta === CTA_CATEGORIES.PAYMENTS && t('dashboard.renew-button')}
                                                                    {value.cta === CTA_CATEGORIES.SIGNING && t('dashboard.sign-button')}
                                                                    {value.cta === CTA_CATEGORIES.PENDING && t('dashboard.revision-button')}
                                                                    {value.cta === CTA_CATEGORIES.REGULATIONS && t('dashboard.regulations-button')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                }
                                            </Row>
                                            {(value.cta && value.cta === CTA_CATEGORIES.FUTURE) &&
                                                <Row style={{'justifyContent': 'center'}}>
                                                    <Col>
                                                        <CardTextCenter>
                                                            <img src={activeFromIcon} alt="active-from-icon"
                                                                 style={{
                                                                     'paddingTop': '5px',
                                                                     'paddingBottom': '9px',
                                                                     'paddingRight': '4px'
                                                                 }}/>
                                                            {t('dashboard.active-from-label')}
                                                            <CardTextCenter>
                                                                <Moment locale="it"
                                                                        format="DD/MM/YYYY"
                                                                        sx={useStyles.cardTextCenter}
                                                                        style={{'fontWeight': '700'}}>{value.insuranceStart}
                                                                </Moment>
                                                            </CardTextCenter>
                                                        </CardTextCenter>
                                                    </Col>
                                                </Row>
                                            }
                                        </CardContent>
                                    </Card>
                                ))
                            }
                        </Col>
                    </Row>
                    <Container className="bottom-with-navbar"
                               style={{'backgroundColor': themeContext.color.neutral10}}></Container>
                </Container>
            </Fragment>
    )
}