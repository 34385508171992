import themeContext from "../components/ThemeContext.js";
import legalProtectionIcon from "../../images/policy_branches/legal-protection-icon.svg";
import farmingIcon from "../../images/policy_branches/farming-icon.svg";
import autoCvtIcon from "../../images/policy_branches/auto-cvt-icon.svg";
import petInsuranceIcon from "../../images/policy_branches/pet-insurance-icon.svg";
import depositsIcon from "../../images/policy_branches/deposits-icon.svg";
import tradeCreditsIcon from "../../images/policy_branches/trade-credits-icon.svg";
import electronicIcon from "../../images/policy_branches/electronic-icon.svg";
import energyIcon from "../../images/policy_branches/energy-icon.svg";
import rcAdminIcon from "../../images/policy_branches/rc-admin-icon.svg";
import artCollectionIcon from "../../images/policy_branches/art-collections-icon.svg";
import theftIcon from "../../images/policy_branches/theft-icon.svg";
import jewellersIcon from "../../images/policy_branches/jewellers-icon.svg";
import buildingsIcon from "../../images/policy_branches/buildings-icon.svg";
import injuresIcon from "../../images/policy_branches/injures-icon.svg";
import illnessIcon from "../../images/policy_branches/illness-icon.svg";
import wheaterIcon from "../../images/policy_branches/wheater-icon.svg";
import travelBusinnessLineIcon from "../../images/policy_branches/travel-businnes-line-icon.svg";
import companiesIcon from "../../images/policy_branches/companies-icon.svg";
import horecaIcon from "../../images/policy_branches/horeca-icon.svg";
import thirdSectorIcon from "../../images/policy_branches/third-sector-icon.svg";
import civiliansIcon from "../../images/policy_branches/civilians-icon.svg";
import officeLaboratoriesIcon from "../../images/policy_branches/office-laboratories-icon.svg";
import rcPollutionIcon from "../../images/policy_branches/rc-pollution-icon.svg";
import rcMedicalMalpracticeIcon from "../../images/policy_branches/rc-medical-malpractice-icon.svg";
import rcIndividuals from "../../images/policy_branches/rc-individuals-icon.svg";
import rcProfessionalIcon from "../../images/policy_branches/rc-professional-icon.svg";
import rcClinicalTrialsIcon from "../../images/policy_branches/rc-clinical-trials-icon.svg";
import technologiesIcon from "../../images/policy_branches/technologies-icon.svg";
import tourOperatorIcon from "../../images/policy_branches/tour-operator-icon.svg";
import certifiedTransportationIcon from "../../images/policy_branches/certified-transportation-icon.svg";
import shipIcon from "../../images/policy_branches/ship-icon.svg";
import collectiveLivesIcon from "../../images/policy_branches/collective-lives-icon.svg";
import individualLivesIcon from "../../images/policy_branches/individual-lives-icon.svg";
import aviationIcon from "../../images/policy_branches/aviation-icon.svg";
import cablewayIcon from "../../images/policy_branches/cableway-icon.svg";
import vectorIcon from "../../images/policy_branches/vector.svg";

import legalProtectionIconSelected from "../../images/policy_branches_selected/legal-protection-icon.svg";
import farmingIconSelected from "../../images/policy_branches_selected/farming-icon.svg";
import autoCvtIconSelected from "../../images/policy_branches_selected/auto-cvt-icon.svg";
import petInsuranceIconSelected from "../../images/policy_branches_selected/pet-insurance-icon.svg";
import depositsIconSelected from "../../images/policy_branches_selected/deposits-icon.svg";
import tradeCreditsIconSelected from "../../images/policy_branches_selected/trade-credits-icon.svg";
import electronicIconSelected from "../../images/policy_branches_selected/electronic-icon.svg";
import energyIconSelected from "../../images/policy_branches_selected/energy-icon.svg";
import rcAdminIconSelected from "../../images/policy_branches_selected/rc-admin-icon.svg";
import artCollectionIconSelected from "../../images/policy_branches_selected/art-collections-icon.svg";
import theftIconSelected from "../../images/policy_branches_selected/theft-icon.svg";
import jewellersIconSelected from "../../images/policy_branches_selected/jewellers-icon.svg";
import buildingsIconSelected from "../../images/policy_branches_selected/buildings-icon.svg";
import injuresIconSelected from "../../images/policy_branches_selected/injures-icon.svg";
import illnessIconSelected from "../../images/policy_branches_selected/illness-icon.svg";
import wheaterIconSelected from "../../images/policy_branches_selected/wheater-icon.svg";
import travelBusinnessLineIconSelected from "../../images/policy_branches_selected/travel-businnes-line-icon.svg";
import companiesIconSelected from "../../images/policy_branches_selected/companies-icon.svg";
import horecaIconSelected from "../../images/policy_branches_selected/horeca-icon.svg";
import thirdSectorIconSelected from "../../images/policy_branches_selected/third-sector-icon.svg";
import civiliansIconSelected from "../../images/policy_branches_selected/civilians-icon.svg";
import officeLaboratoriesIconSelected from "../../images/policy_branches_selected/office-laboratories-icon.svg";
import rcPollutionIconSelected from "../../images/policy_branches_selected/rc-pollution-icon.svg";
import rcMedicalMalpracticeIconSelected from "../../images/policy_branches_selected/rc-medical-malpractice-icon.svg";
import rcIndividualsSelected from "../../images/policy_branches_selected/rc-individuals-icon.svg";
import rcProfessionalIconSelected from "../../images/policy_branches_selected/rc-professional-icon.svg";
import rcClinicalTrialsIconSelected from "../../images/policy_branches_selected/rc-clinical-trials-icon.svg";
import technologiesIconSelected from "../../images/policy_branches_selected/technologies-icon.svg";
import tourOperatorIconSelected from "../../images/policy_branches_selected/tour-operator-icon.svg";
import certifiedTransportationIconSelected from "../../images/policy_branches_selected/certified-transportation-icon.svg";
import shipIconSelected from "../../images/policy_branches_selected/ship-icon.svg";
import collectiveLivesIconSelected from "../../images/policy_branches_selected/collective-lives-icon.svg";
import individualLivesIconSelected from "../../images/policy_branches_selected/individual-lives-icon.svg";
import aviationIconSelected from "../../images/policy_branches_selected/aviation-icon.svg";
import cablewayIconSelected from "../../images/policy_branches_selected/cableway-icon.svg";
import vectorIconSelected from "../../images/policy_branches_selected/vector.svg";

import {API_METHODS, apiFetch} from "./ApiService.js";
import {
    peoplePoliciesV2Url,
    personInfoUrl,
    policyDocumentUrl,
    policyTitlesHistoryUrl,
    policyTypesUrl,
    policyUrl,
    titleDocumentUrl
} from "./EndpointService.js";
import defaultMaleAvatar from "../../images/male-icon.svg";
import defaultFemaleAvatar from "../../images/female-icon.svg";
import defaultCompanyAvatar from "../../images/company-avatar.svg";

export const POLICY_TYPE = {
    FEATURED: 'featured',
    OTHER: 'other',
    INACTIVE: 'inactive',
}

export const POLICY_STATUS = {
    DEFAULT: 'STATUS_DEFAULT',
    ACTIVE: 'STATUS_ACTIVE',
    EXPIRING: 'STATUS_EXPIRING',
    EXPIRED: 'STATUS_EXPIRED',
    INACTIVE: 'STATUS_INACTIVE',
}

export const setStatusColor = (value) => {
    switch (value) {
        case 1:
            return themeContext.color.neutral200;
        case 2:
        case 5:
            return themeContext.color.green;
        case 3:
            return themeContext.color.yellow;
        case 4:
            return themeContext.color.red;
    }
}

export const setStatusString = (value) => {
    switch (value) {
        case 1:
            return 'desktop.dashboard.not active';
        case 2:
        case 5:
            return 'desktop.dashboard.active';
        case 3:
            return 'desktop.dashboard.expiring';
        case 4:
            return 'desktop.dashboard.expired';
    }
}

export const setStatusColorDesktop = (value) => {
    switch (value) {
        case 1:
            return themeContext.color.neutral400;
        case 2:
        case 5:
            return themeContext.color.green;
        case 3:
            return themeContext.color.yellow;
        case 4:
            return themeContext.color.red;
    }
}

export const setBackgroundStatusColor = (value) => {
    switch (value) {
        case 1:
            return themeContext.color.neutral100;
        case 2:
        case 5:
            return themeContext.color.green10;
        case 3:
            return themeContext.color.yellow10;
        case 4:
            return themeContext.color.red10;
    }
}

export const setGrayScale = (value) => {
    switch (value) {
        case 1:
            return 'grayscale(1)';
        case 2:
        case 3:
        case 4:
            return '';
    }
}

export const CTA_CATEGORIES = {
    REGULATIONS: 'regulations',
    SIGNING: 'signing',
    PAYMENTS: 'payments',
    FUTURE: 'future',
    PENDING: 'pending',
}

export const getBranchIcon = (policyType) => {
    switch (policyType) {
        case "TUTELA LEGALE":
            return legalProtectionIcon;
        case "AGRICOLTURA":
            return farmingIcon;
        case "AUTO (RCA - CVT)":
        case "AUTO (CVT)":
        case "CVT AUTO D'EPOCA E SPORTIVE":
        case "CVT SOCCORSO":
        case "KASKO CHILOMETRICA":
            return autoCvtIcon;
        case "PET INSURANCE":
            return petInsuranceIcon;
        case "CAUZIONI":
            return depositsIcon;
        case "CREDITI COMMERCIALI":
            return tradeCreditsIcon;
        case "ELETTRONICA":
            return electronicIcon;
        case "ENERGY":
            return energyIcon;
        case "RC AMMINISTRATORI (D&O)":
        case "RC COLPA GRAVE":
            return rcAdminIcon;
        case "FINE ART E COLLEZIONI":
            return artCollectionIcon;
        case "FURTO":
        case "FURTO PRIVATI":
            return theftIcon;
        case "GLOBALE GIOIELLERIE":
            return jewellersIcon;
        case "GLOBALE FABBRICATI":
            return buildingsIcon;
        case "INFORTUNI":
        case "INFORTUNI COLLETTIVA":
        case "INFORTUNI CONDUCENTI":
        case "INFORTUNI E MALATTIA":
        case "INFORTUNI TERZO SETTORE":
        case "PRESTAZIONI DI ASSISTENZA SANITARIA":
            return injuresIcon;
        case "MALATTIA":
            return illnessIcon;
        case "METEO":
            return wheaterIcon;
        case "VIAGGI LINEA BUSINESS":
        case "VIAGGI LINEA PERSONA":
            return travelBusinnessLineIcon;
        case "MULTIRISCHI AZIENDE":
        case "PROPERTY AZIENDE":
        case "RC AZIENDE":
            return companiesIcon;
        case "MULTIRISCHI HO.RE.CA.":
            return horecaIcon;
        case "MULTIRISCHI TERZO SETTORE":
        case "RC TERZO SETTORE":
            return thirdSectorIcon;
        case "MULTIRISCHI CIVILI":
        case "PROPERTY RISCHI CIVILI":
            return civiliansIcon;
        case "MULTIRISCHI UFFICI/LABORATORI":
            return officeLaboratoriesIcon;
        case "RC INQUINAMENTO":
            return rcPollutionIcon;
        case "RC MEDICAL MALPRACTICE":
            return rcMedicalMalpracticeIcon;
        case "RC PRIVATI":
            return rcIndividuals;
        case "RC PROFESSIONALE":
            return rcProfessionalIcon;
        case "RC SPERIMENTAZIONE CLINICA":
            return rcClinicalTrialsIcon;
        case "TECNOLOGICI":
            return technologiesIcon;
        case "AGENZIE VIAGGI":
        case "TOUR OPERATOR / AGENZIA VIAGGI":
            return tourOperatorIcon;
        case "TRASPORTI CERTIFICATI IN ABBONAMENTO":
        case "TRASPORTO MERCI":
        case "STOCK & TRANSIT":
        case "SPEDIZIONIERI E VETTORI":
            return certifiedTransportationIcon;
        case "CORPI":
        case "RC E CORPI NATANTI":
        case "P&I PROTECTION & INDEMNITY":
            return shipIcon;
        case "VITA COLLETTIVE":
        case "RC PATRIMONIALE COLPA LIEVE":
            return collectiveLivesIcon;
        case "VITA INDIVIDUALI":
            return individualLivesIcon;
        case "AVIAZIONE":
            return aviationIcon;
        case "PERDITE PECUNIARIE":
            return depositsIcon;
        case "CYBER RISK":
            //icona mancante
            return theftIcon;
        case "MULTIRISCHI IMPIANTI A FUNE":
            return cablewayIcon;
        default:
            return vectorIcon;
    }
}

export const getBranchIconSelected = (policyType) => {
    switch (policyType) {
        case "TUTELA LEGALE":
            return legalProtectionIconSelected;
        case "AGRICOLTURA":
            return farmingIconSelected;
        case "AUTO (RCA - CVT)":
        case "AUTO (CVT)":
        case "CVT AUTO D'EPOCA E SPORTIVE":
        case "CVT SOCCORSO":
        case "KASKO CHILOMETRICA":
            return autoCvtIconSelected;
        case "PET INSURANCE":
            return petInsuranceIconSelected;
        case "CAUZIONI":
            return depositsIconSelected;
        case "CREDITI COMMERCIALI":
            return tradeCreditsIconSelected;
        case "ELETTRONICA":
            return electronicIconSelected;
        case "ENERGY":
            return energyIconSelected;
        case "RC AMMINISTRATORI (D&O)":
        case "RC COLPA GRAVE":
            return rcAdminIconSelected;
        case "FINE ART E COLLEZIONI":
            return artCollectionIconSelected;
        case "FURTO":
        case "FURTO PRIVATI":
            return theftIconSelected;
        case "GLOBALE GIOIELLERIE":
            return jewellersIconSelected;
        case "GLOBALE FABBRICATI":
            return buildingsIconSelected;
        case "INFORTUNI":
        case "INFORTUNI COLLETTIVA":
        case "INFORTUNI CONDUCENTI":
        case "INFORTUNI E MALATTIA":
        case "INFORTUNI TERZO SETTORE":
        case "PRESTAZIONI DI ASSISTENZA SANITARIA":
            return injuresIconSelected;
        case "MALATTIA":
            return illnessIconSelected;
        case "METEO":
            return wheaterIconSelected;
        case "VIAGGI LINEA BUSINESS":
        case "VIAGGI LINEA PERSONA":
            return travelBusinnessLineIconSelected;
        case "MULTIRISCHI AZIENDE":
        case "PROPERTY AZIENDE":
        case "RC AZIENDE":
            return companiesIconSelected;
        case "MULTIRISCHI HO.RE.CA.":
            return horecaIconSelected;
        case "MULTIRISCHI TERZO SETTORE":
        case "RC TERZO SETTORE":
            return thirdSectorIconSelected;
        case "MULTIRISCHI CIVILI":
        case "PROPERTY RISCHI CIVILI":
            return civiliansIconSelected;
        case "MULTIRISCHI UFFICI/LABORATORI":
            return officeLaboratoriesIconSelected;
        case "RC INQUINAMENTO":
            return rcPollutionIconSelected;
        case "RC MEDICAL MALPRACTICE":
            return rcMedicalMalpracticeIconSelected;
        case "RC PRIVATI":
            return rcIndividualsSelected;
        case "RC PROFESSIONALE":
            return rcProfessionalIconSelected;
        case "RC SPERIMENTAZIONE CLINICA":
            return rcClinicalTrialsIconSelected;
        case "TECNOLOGICI":
            return technologiesIconSelected;
        case "AGENZIE VIAGGI":
        case "TOUR OPERATOR / AGENZIA VIAGGI":
            return tourOperatorIconSelected;
        case "TRASPORTI CERTIFICATI IN ABBONAMENTO":
        case "TRASPORTO MERCI":
        case "STOCK & TRANSIT":
        case "SPEDIZIONIERI E VETTORI":
            return certifiedTransportationIconSelected;
        case "CORPI":
        case "RC E CORPI NATANTI":
        case "P&I PROTECTION & INDEMNITY":
            return shipIconSelected;
        case "VITA COLLETTIVE":
        case "RC PATRIMONIALE COLPA LIEVE":
            return collectiveLivesIconSelected;
        case "VITA INDIVIDUALI":
            return individualLivesIconSelected;
        case "AVIAZIONE":
            return aviationIconSelected;
        case "PERDITE PECUNIARIE":
            return depositsIconSelected;
        case "CYBER RISK":
            //icona mancante
            return theftIconSelected;
        case "MULTIRISCHI IMPIANTI A FUNE":
            return cablewayIconSelected;
        default:
            return vectorIconSelected;
    }
}

export const getPolicy = async (token_arg, policyId) => {
    try {
        return await apiFetch(`${policyUrl}/${policyId}`, token_arg);
    } catch (error) {
        return null;
    }
};

export const getPeoplePoliciesV2 = async (token_arg, page, size, filter, personId, branchId) => {
    let url = `${peoplePoliciesV2Url}`;
    if (page === 0 || page) url += `?page=${page}`;
    if (size) url += `${(page === 0 || page) ? '&' : '?'}size=${size}`;
    if (filter) url += `${(page === 0 || page) || size ? '&' : '?'}filter=${filter}`;
    if (personId) url += `${(page === 0 || page) || size || filter ? '&' : '?'}personId=${personId}`;
    if (branchId) url += `${(page === 0 || page) || size || filter || personId ? '&' : '?'}branchId=${branchId}`;

    try {
        return await apiFetch(url, token_arg);
    } catch (error) {
        return null;
    }
};

export const setPersonImage = (gender, image) => {
    switch (image) {
        case null:
            switch (gender) {
                case `M`:
                case null:
                    return defaultMaleAvatar
                case `F`:
                    return defaultFemaleAvatar
                case `C`:
                    return defaultCompanyAvatar
            }
            break;
        default:
            return image;
    }
}

export const formattedPolicy = (policy, isDesktop) => {
    if(isDesktop) {
        let ctaList = [];
        switch (policy.status) {
            case 2:
            case 3:
            case 4:
                if(isDesktop) {
                    if (policy.hasRegulations) {
                        //regola
                        ctaList.push(CTA_CATEGORIES.REGULATIONS)
                    }
                    if (policy.status !== 2 && !policy.isCompleted) {
                        //paga ora
                        ctaList.push(CTA_CATEGORIES.PAYMENTS)
                    }
                    if (policy.status !== 4 && policy.hasDocumentToBeSigned) {
                        if (policy.hasPending === true) {
                            ctaList.push(CTA_CATEGORIES.PENDING)
                        } else {
                            ctaList.push(CTA_CATEGORIES.SIGNING)
                        }
                    }
                }

                break;
            case 1:
                if (policy.type.includes('VIAGGI')) {
                    if (policy.hasRegulations) {
                        //regola
                        ctaList.push(CTA_CATEGORIES.REGULATIONS)
                    }
                    if (!policy.isCompleted) {
                        //paga ora
                        ctaList.push(CTA_CATEGORIES.PAYMENTS)
                    }
                    if (policy.hasDocumentToBeSigned) {
                        if (policy.hasPending === true) {
                            ctaList.push(CTA_CATEGORIES.PENDING)
                        } else {
                            ctaList.push(policy.cta = CTA_CATEGORIES.SIGNING)
                        }
                    }
                }
                break;
            case 5:
                if (policy.hasRegulations) {
                    //regola
                    ctaList.push(CTA_CATEGORIES.REGULATIONS)
                }
                if (!policy.isCompleted) {
                    //paga ora
                    ctaList.push(CTA_CATEGORIES.PAYMENTS)
                }
                if (policy.hasDocumentToBeSigned) {
                    if (policy.hasPending === true) {
                        ctaList.push(CTA_CATEGORIES.PENDING)
                    } else {
                        ctaList.push(CTA_CATEGORIES.SIGNING)
                    }
                }
                break;
        }
        policy.cta = ctaList;
    } else {
        switch (policy.status) {
            case 2:
            case 3:
            case 4:
                if (policy.hasRegulations) {
                    //regola
                    policy.cta = CTA_CATEGORIES.REGULATIONS
                } else if (policy.status !== 2 && !policy.isCompleted) {
                    //paga ora
                    policy.cta = CTA_CATEGORIES.PAYMENTS
                } else {
                    if (policy.status !== 4 && policy.hasDocumentToBeSigned) {
                        if (policy.hasPending === true) {
                            policy.cta = CTA_CATEGORIES.PENDING
                        } else {
                            policy.cta = CTA_CATEGORIES.SIGNING
                        }
                    }
                }
                break;
            case 1:
                if (policy.type.includes('VIAGGI')) {
                    if (policy.hasRegulations) {
                        //regola
                        policy.cta = CTA_CATEGORIES.REGULATIONS
                    } else if (!policy.isCompleted) {
                        //paga ora
                        policy.cta = CTA_CATEGORIES.PAYMENTS
                    } else {
                        if (policy.hasDocumentToBeSigned) {
                            if (policy.hasPending === true) {
                                policy.cta = CTA_CATEGORIES.PENDING
                            } else {
                                policy.cta = CTA_CATEGORIES.SIGNING
                            }
                        }
                    }
                }
                break;
            case 5:
                if(isDesktop) {
                    if (policy.hasRegulations) {
                        //regola
                        policy.cta = CTA_CATEGORIES.REGULATIONS
                    } else if (!policy.isCompleted) {
                        //paga ora
                        policy.cta = CTA_CATEGORIES.PAYMENTS
                    } else {
                        if (policy.hasDocumentToBeSigned) {
                            if (policy.hasPending === true) {
                                policy.cta = CTA_CATEGORIES.PENDING
                            } else {
                                policy.cta = CTA_CATEGORIES.SIGNING
                            }
                        }
                    }
                } else {
                    policy.cta = CTA_CATEGORIES.FUTURE
                }
                break;
        }
    }
    return policy;
}

export const getSignedCopy = async (token_arg, policyId) => {
    try {
        return await apiFetch(`${titleDocumentUrl}`, token_arg, API_METHODS.POST, {titleId: policyId});
    } catch (error) {
        return null;
    }
}

export const getPolicyDocument = async (token_arg, policyId) => {
    try {
        return await apiFetch(`${policyDocumentUrl}`, token_arg, API_METHODS.POST, {policyId: policyId});
    } catch (error) {
        return null;
    }
}

export const getPolicyTitlesHistory = async (token_arg, policyId) => {
    try {
        return await apiFetch(`${policyTitlesHistoryUrl}/${policyId}`, token_arg);
    } catch (error) {
        return null;
    }
}

export const getPersonInfo = async (token_arg, personId) => {
    try {
        return await apiFetch(`${personInfoUrl}/${personId}`, token_arg);
    } catch (error) {
        return null;
    }
}

export const getPolicyTypes = async (token_arg, personId) => {
    let url = `${policyTypesUrl}`;
    if (personId) url += `?personId=${personId}`;
    try {
        return await apiFetch(url, token_arg);
    } catch (error) {
        return null;
    }
}
