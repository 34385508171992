import React from 'react';
import themeContext from "../ThemeContext.js";
import {styled} from "@mui/system";
import {useTranslation} from "react-i18next";
import spinner from "../../../images/spinner.svg";
import Moment from "react-moment";
import MessagesIcon from './../../../images/desktop/messages.svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {StyledButtonAction} from "../../styles/DesktopStyles.js";
import toRead from "../../../images/toRead-icon.png";

const StyledContainer = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    width: '100%',
    alignItems: 'start',
    gap: 8
}));

const StyledTitleContainer = styled('div')(({theme}) => ({
    padding: '8px 0 8px 14px',
    color: themeContext.color.neutral400,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 8
}));

const StyledTitle = styled('span')(({theme}) => ({
    fontSize: '12px',
    color: themeContext.color.neutral400,
}));

const StyledTitleMessage = styled('span')(({theme}) => ({
    fontSize: '12px',
    color: themeContext.color.neutral600,
    fontWeight: '500',
    width: '100%',
    textAlign: 'left'
}));

const StyledShortDescriptionMessage = styled('span')(({theme}) => ({
    fontSize: '10px',
    color: themeContext.color.neutral400,
    fontWeight: '400',
    width: '100%',
    textAlign: 'left'
}));

const StyledDateMessage = styled('span')(({theme}) => ({
    display: 'flex',
    fontSize: '10px',
    color: themeContext.color.neutral200,
    fontWeight: '400',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
}));

const StyledCard = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '8px',
    borderRadius: '16px',
    width: '100%',
    background: themeContext.color.defaultBackground,
    cursor: 'pointer',
    outline: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
    '&:hover': {
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
    }
}));


const MessagesPreview = ({isLoading, data, openDialog}) => {
    const {t, i18n} = useTranslation();

    return (
        <StyledContainer>
            <StyledTitleContainer>
                <img src={MessagesIcon} alt="Messages"/>
                <StyledTitle className={'text-uppercase'}>{t('desktop.messages.title')}</StyledTitle>
            </StyledTitleContainer>
            <div className={'d-flex flex-column w-100'} style={{gap: 8}}>
                {isLoading ? <img src={spinner} className="spinner-img" alt="spinner"/> :
                    (data ? <>
                        {data.content.map((message, index) => (
                            <StyledCard key={index} onClick={() => openDialog(message.id)}>
                                <div className={'d-flex align-items-start flex-column'}>
                                    <div className={'d-flex flex-row'} style={{
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}>
                                        <StyledTitleMessage className={'ellipsis-1-v2'}>
                                            {message.title}
                                        </StyledTitleMessage>
                                        {!message.isRead && <img alt="to-read" src={toRead} width="12px"
                                                                 className="mb-2 ml-2"/>}
                                    </div>
                                    <StyledShortDescriptionMessage className={'ellipsis-1-v2'}>
                                        {message.shortDescription}
                                    </StyledShortDescriptionMessage>
                                </div>
                                <div className={'d-flex align-items-end justify-content-end'}>
                                    <StyledDateMessage>
                                        <Moment locale="it"
                                                format="DD/MM/YYYY">{message.originalDate}</Moment>
                                    </StyledDateMessage>
                                </div>
                            </StyledCard>
                        ))}
                        <div className={'d-flex justify-content-end'}>
                            <StyledButtonAction endIcon={<ChevronRightIcon />} onClick={() => openDialog(null)}>
                                {t('desktop.messages.see-all')}
                            </StyledButtonAction>
                        </div>
                    </> : <></>)
                }
            </div>
        </StyledContainer>

    );
};

export default MessagesPreview;
