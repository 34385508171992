import React, {useContext, useEffect, useRef, useState} from 'react';
import {SidebarContext, VIEW} from "../../middleware/SidebarContext.js";
import {styled} from "@mui/material/styles";
import AvatarGroup from "../../components/desktop/AvatarGroup.js";
import ListCard, {CARD_TYPE} from "../../components/desktop/ListCard.js";
import {drawerWidth} from "../../components/desktop/DetailBar.js";
import themeContext from "../../components/ThemeContext.js";
import exportIcon from "../../../images/desktop/export.svg";
import exportIconSelected from "../../../images/desktop/export-selected.svg";
import {useTranslation} from "react-i18next";
import GridCard from "../../components/desktop/GridCard.js";
import Chips from "../../components/desktop/Chips.js";
import MainTitle from "../../components/desktop/MainTitle.js";
import CardTitle from "../../components/desktop/CardTitle.js";
import {DetailBarContext} from "../../middleware/DetailBarContext.js";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";

export const ListCardContainer = styled('div')(({theme}) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '16px',
}));

export const GridCardContainer = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    gap: '16px',
    flexWrap: 'wrap',
    justifyContent: 'start'
}));

const Dashboard = React.memo(function Dashboard(props) {
    const {
        people,
        isLoadingPeople,
        handleRenewPolicy,
        handleSignDocumentPolicy,
        handleRegulatePolicy,
        featuredPeoplePolicies,
        otherPeoplePolicies,
        inactivePeoplePolicies,
        isLoadingOtherPolicies,
        isLoadingFeaturedPolicies,
        isLoadingInactivePolicies,
        otherCardName,
        policyTypes,
        isLoadingPolicyTypes,
        selectedAvatarState,
        setSelectedAvatarState,
        selectedChipState,
        setSelectedChipState,
        exportType,
        setExportType,
        selectedRadioCsv,
        handleRadioCsv,
        selectedRadioXlsx,
        handleRadioXlsx,
        selectedRadioPdf,
        handleRadioPdf,
        exportFile,
        handleCheck,
        checked,
        isCheckAll,
        checkAll,
        handleBackCheck,
        isLoading,
        selectedPolicyStatus,
        setSelectedPolicyStatus
    } = props;

    const titleState = {
        people,
        exportType,
        setExportType,
        selectedRadioCsv,
        handleRadioCsv,
        selectedRadioXlsx,
        handleRadioXlsx,
        selectedRadioPdf,
        handleRadioPdf,
        exportFile,
        handleCheck,
        checked,
        isCheckAll,
        checkAll,
        handleBackCheck,
        isLoading,
        isLoadingPeople,
        isLoadingOtherPolicies
    }

    const {open, toggleSidebar, view,} = useContext(SidebarContext);
    const {t, i18n} = useTranslation();
    const [openFeaturedCards, setOpenFeaturedCards] = React.useState(true);
    const [openOtherCards, setOpenOtherCards] = React.useState(true);
    const [openInactiveCards, setOpenInactiveCards] = React.useState(true);
    const root = 'desktop.dashboard';
    const [showAllChips, setShowAllChips] = useState(false);
    const stickyDivRef = useRef(null);
    const [stickyTop, setStickyTop] = useState('0px');
    const initialStickyHeight = 63;
    const [activePolicy, setActivePolicy] = useState(null);
    const [policyStatus, setPolicyStatus] = useState([
        {value: 0, label: 'Attive'},
        {value: 1, label: 'Non attive'}
    ]);
    const {updateData} = useContext(DetailBarContext);


    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [isLoadingFeaturedPolicies]);

    const updateStickyTop = () => {
        if (stickyDivRef.current) {
            const height = stickyDivRef.current.offsetHeight + initialStickyHeight - 2; // 2px is the empty cards in the grid view
            setStickyTop(`${height}px`);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", updateStickyTop);
        return () => window.removeEventListener("resize", updateStickyTop);
    }, [updateStickyTop]);


    useEffect(() => {
        updateStickyTop()
    }, [showAllChips, isLoadingPeople, selectedAvatarState, selectedChipState]);

    useEffect(() => {
        setShowAllChips(false)
    }, [selectedAvatarState]);

    const ExternalLayout = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginTop: '65px',
        paddingTop: '8px',
        marginLeft: open ? '269px' : '65px',
        marginRight: activePolicy ? `calc(${drawerWidth})` : 0,
        gap: '8px',
        paddingBottom: '8px',
    }));

    const StickyDiv = styled('div')(({top}) => ({
        display: 'flex',
        position: 'sticky',
        top: top,
        zIndex: '99',
        background: themeContext.color.defaultBackground,
        width: '100%',
        flexDirection: 'column',
        gap: '8px',
        paddingLeft: '20px',
        paddingRight: '20px',
    }));

    const Layout = styled('div')(({theme}) => ({
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
    }));

    const handleFeaturedCards = () => {
        setOpenFeaturedCards(current => !current);
    }
    const handleOtherCards = () => {
        setOpenOtherCards(current => !current);
    }

    const handleInactiveCards = () => {
        setOpenInactiveCards(current => !current);
    }

    return (
        <ExternalLayout>
            <StickyDiv top={`${initialStickyHeight}px`} ref={stickyDivRef}>
                <div className={'d-flex flex-column'}>
                    <MainTitle title={t(`${root}.policies`)} button={t(`${root}.export`)}
                               buttonIcon={exportIcon} buttonIconSelected={exportIconSelected} {...titleState} chips={policyStatus}
                               selected={selectedPolicyStatus}
                               onChange={(value) => {
                                   setSelectedPolicyStatus(value)
                                   setActivePolicy(null)
                                   updateData(null)
                               }} maintainSelection={true}/>
                </div>
                <AvatarGroup people={people} isLoading={isLoadingPeople} selected={selectedAvatarState}
                             onChange={(value) => {
                                 setActivePolicy(null)
                                 updateData(null)
                                 setSelectedAvatarState(value)
                             }}/>
                <Chips
                    isLoading={isLoadingPolicyTypes}
                    chips={policyTypes}
                    selected={selectedChipState}
                    onChange={(value) => {
                        setActivePolicy(null)
                        updateData(null)
                        setSelectedChipState(value)
                    }}
                    showAll={showAllChips}
                    onToggleShowAll={() => {
                        setShowAllChips(prev => !prev)
                    }}
                />
                {selectedPolicyStatus === 0 &&
                    <div onClick={() => {
                        handleFeaturedCards()
                    }} style={{
                        display: "flex",
                        justifyContent: 'space-between',
                        alignItems: "center",
                        gap: '16px',
                        width: '100%',
                        height: '37px',
                        padding: '0',
                        borderRadius: '12px',
                        cursor: 'pointer',
                    }}>
                        {<CardTitle title={t(`${root}.featured`)}/>}
                        {openFeaturedCards ? <ExpandMore/> : <ExpandLess/>}
                    </div>}
                {selectedPolicyStatus === 1 &&
                    <div onClick={() => {
                        handleInactiveCards()
                    }} style={{
                        display: "flex",
                        justifyContent: 'space-between',
                        alignItems: "center",
                        gap: '16px',
                        width: '100%',
                        height: '37px',
                        padding: '0',
                        borderRadius: '12px',
                        cursor: 'pointer',
                    }}>
                        {<CardTitle title={t(`${root}.not actives`)}/>}
                        {openInactiveCards ? <ExpandMore/> : <ExpandLess/>}
                    </div>}
            </StickyDiv>
            {selectedPolicyStatus === 0 && <>
                <Layout>
                    {openFeaturedCards && (view === VIEW.LIST ?
                        <ListCardContainer>
                            <ListCard type={CARD_TYPE.POLICY} object={featuredPeoplePolicies}
                                      isLoading={isLoadingFeaturedPolicies}
                                      handleRegulatePolicy={handleRegulatePolicy} handleRenewPolicy={handleRenewPolicy}
                                      activeCard={activePolicy} setActiveCard={setActivePolicy}
                                      handleSignDocumentPolicy={handleSignDocumentPolicy}
                                      noItemsText={t(`dashboard.nothing-text-1`)}/>
                        </ListCardContainer> :
                        <GridCardContainer>
                            <GridCard type={CARD_TYPE.POLICY} object={featuredPeoplePolicies}
                                      isLoading={isLoadingFeaturedPolicies}
                                      handleRegulatePolicy={handleRegulatePolicy} handleRenewPolicy={handleRenewPolicy}
                                      activeCard={activePolicy} setActiveCard={setActivePolicy}
                                      handleSignDocumentPolicy={handleSignDocumentPolicy}
                                      noItemsText={t(`dashboard.nothing-text-1`)}/>
                        </GridCardContainer>)
                    }
                </Layout>
                <StickyDiv top={stickyTop}>
                    <div onClick={() => {
                        handleOtherCards()
                    }} style={{
                        display: "flex",
                        justifyContent: 'space-between',
                        alignItems: "center",
                        width: '100%',
                        borderRadius: '12px',
                        cursor: 'pointer',
                    }}>
                        <CardTitle title={t(`${root}.other policies`)}/>
                        {openOtherCards ? <ExpandMore/> : <ExpandLess/>}
                    </div>
                </StickyDiv>
                <Layout>
                    {openOtherCards && (view === VIEW.LIST ?
                        <ListCardContainer>
                            <ListCard type={CARD_TYPE.POLICY} object={otherPeoplePolicies}
                                      isLoading={isLoadingOtherPolicies}
                                      handleRegulatePolicy={handleRegulatePolicy} handleRenewPolicy={handleRenewPolicy}
                                      activeCard={activePolicy} setActiveCard={setActivePolicy}
                                      handleSignDocumentPolicy={handleSignDocumentPolicy} itemName={otherCardName}
                                      noItemsText={t(`dashboard.nothing-text-1`)}
                            />
                        </ListCardContainer> :
                        <GridCardContainer>
                            <GridCard type={CARD_TYPE.POLICY} object={otherPeoplePolicies}
                                      isLoading={isLoadingOtherPolicies}
                                      handleRegulatePolicy={handleRegulatePolicy} handleRenewPolicy={handleRenewPolicy}
                                      activeCard={activePolicy} setActiveCard={setActivePolicy}
                                      handleSignDocumentPolicy={handleSignDocumentPolicy} itemName={otherCardName}
                                      noItemsText={t(`dashboard.nothing-text-1`)}/>
                        </GridCardContainer>)
                    }
                </Layout>
            </>}
            {selectedPolicyStatus === 1 &&
                <>
                    <Layout>
                        {openInactiveCards && (view === VIEW.LIST ?
                            <ListCardContainer>
                                <ListCard type={CARD_TYPE.POLICY} object={inactivePeoplePolicies}
                                          isLoading={isLoadingInactivePolicies}
                                          handleRegulatePolicy={handleRegulatePolicy}
                                          handleRenewPolicy={handleRenewPolicy}
                                          activeCard={activePolicy} setActiveCard={setActivePolicy}
                                          handleSignDocumentPolicy={handleSignDocumentPolicy}
                                          noItemsText={t(`dashboard.nothing-text-1`)}/>
                            </ListCardContainer> :
                            <GridCardContainer>
                                <GridCard type={CARD_TYPE.POLICY} object={inactivePeoplePolicies}
                                          isLoading={isLoadingInactivePolicies}
                                          handleRegulatePolicy={handleRegulatePolicy}
                                          handleRenewPolicy={handleRenewPolicy}
                                          activeCard={activePolicy} setActiveCard={setActivePolicy}
                                          handleSignDocumentPolicy={handleSignDocumentPolicy}
                                          noItemsText={t(`dashboard.nothing-text-1`)}/>
                            </GridCardContainer>)
                        }
                    </Layout>
                </>}

        </ExternalLayout>
    )
});

export default Dashboard;
