import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import themeContext from "../ThemeContext.js";
import {DetailBarContext} from "../../middleware/DetailBarContext.js";
import {Fragment, useContext, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import PolicyDetail from "./PolicyDetail.js";
import ClaimDetail from "./ClaimDetail.js";
import PaymentDetail from "./PaymentDetail.js";
import {CARD_TYPE} from "./ListCard.js";

export const drawerWidth = '360px';

export default function DetailBar({}) {
    const {data, dataType} = useContext(DetailBarContext);
    const tPrefix = 'desktop.detailBar';
    const {t, i18n} = useTranslation();

    return (data &&
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'start',
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    scrollbarWidth: 'none',
                    boxSizing: 'border-box',
                    borderRadius: '16px',
                    padding: '24px',
                    border: 'none',
                    outline: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
                    alignItems: 'start',
                    gap: '24px',
                    paddingBottom: dataType === CARD_TYPE.PAYMENT ? '24px' : '80px',
                },
            }}
            variant="permanent"
            anchor="right">
            {/*{isLoading && <div className="background-loading-spinner spinner-desktop"></div>}*/}
            {dataType === CARD_TYPE.POLICY && <PolicyDetail policy={data}/>}
            {dataType === CARD_TYPE.CLAIM && <ClaimDetail claim={data}/>}
            {dataType === CARD_TYPE.PAYMENT && <PaymentDetail/>}
        </Drawer>
    );
}
