import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import AuthContextMiddleware from "../../middleware/AuthContextMiddleware.js";
import Moment from "react-moment";
import back from "../../../images/back-icon.svg";
import {makeStyles} from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button, Slide} from "@mui/material";
import DOMPurify from "dompurify";
import useAnalyticsEventTracker from "../../middleware/useAnalyticsEventTracker.js";
import {ItemColumn, ItemContent, ItemInfoLine, ItemNameLine, ItemRow} from "../../middleware/StyleMiddleware.js";
import {SwipeableList, SwipeableListItem, Type as ListType} from "react-swipeable-list";
import {useTranslation} from "react-i18next";
import themeContext from "../../components/ThemeContext.js";
import {StyledBackdrop} from "../../styles/Styles.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {
    ExternalPageLayout,
    StyledDivider,
    StyledPageCard,
    StyledPageLayout,
    StyledTitlePage, StyledTitlePageWithImage
} from "../../styles/DesktopStyles.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    titles: {
        color: `${themeContext.color.neutral200} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '400 !important',
        fontSize: '12px !important',
        textTransform: 'uppercase !important',
    },
    dialogTitle: {
        'textAlign': 'center !important',
        'paddingTop': '10px !important',
        'paddingBottom': '2px !important',

    },
    dialogDescription: {
        'textAlign': 'center !important',
        'paddingTop': '0px !important',
        'paddingBottom': '15px !important',
    }
}));

export default function ClaimDetail() {
    const {open} = useContext(SidebarContext);
    const [isLoadingData, setIsLoadingData] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getClaim(claimId, type);
            getAccountExec();
            if (sessionStorage.getItem("notificationId") != null) {
                sessionStorage.removeItem("notificationId")
            }
        } else {
            //handleReadOffline(id, type);
        }
    }, []);

    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const params = useParams();
    const claimId = params.claimId;
    const personId = params.personId;
    const vehicleId = params.vehicleId;
    const type = params.type;
    const getDetailsUrl = `${process.env.REACT_APP_BASE_URL}/claims`;
    const authCtx = useContext(AuthContextMiddleware);
    const [claim, setClaim] = useState([]);
    const [policy, setPolicy] = useState([]);
    const [claimActions, setClaimActions] = useState([]);
    const classes = useStyles();
    const accountExecUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/accountExec`;
    const [accountExec, setAccountExec] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const gaEventTrackerPolicyDetail = useAnalyticsEventTracker('PolicyDetail');


    const getClaim = (id) => {
        setIsLoadingData(true);
        fetch(`${getDetailsUrl}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setClaim(data)
            setPolicy(data.policy)
            let filteredClaimActions = data.claimActions.filter(claimAction => !(claimAction.actionType === 'personalizzata_privata' || claimAction.actionType === 'nota_riserva'))
            setClaimActions(filteredClaimActions)
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const setStatusColor = (claim) => {
        switch (claim) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    const turnBack = () => {
        return navigate(-1);
    }

    const onClickPolicynr = (policyId) => {
        gaEventTrackerPolicyDetail('Click', 'Clicked Policy Detail Button')
        if (window.location.href.includes('/claims')) {
            return `/claims/claim/${claimId}/${policyId}`;
        }
    }

    function getAccountExec() {
        fetch(accountExecUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setAccountExec(data);
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    function reload() {
        window.location.reload();
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {
                    errorPage ?
                        <ErrorPage/> :
                        <StyledPageLayout className="w-100 mx-2">
                            <StyledTitlePageWithImage height={280}>
                                <Col>
                                    <Row className="first-nav-top-row-shadow">
                                        <Col className="col-2 float-left">
                                            <img src={back} onClick={turnBack}
                                                 className="nav-top-logo float-left cursor-pointer"
                                                 height="27px"
                                                 alt="back"/>
                                        </Col>
                                        <Col className="col-8">
                                            <h6 className="header-title ellipsis-1-v2">{t('claimDetail.title')}</h6>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: '11px'}}>
                                        <Col>
                                            {
                                                isLoadingData &&
                                                <div style={{'height': '90vh'}}
                                                     className="background-loading-spinner"></div>
                                            }
                                            {
                                                !isLoadingData &&
                                                <Fragment>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="text-normal-detail ellipsis-1-v2">{t('claimDetail.policy-holder')}<span
                                                                className="text-bold-detail black">{claim.name}</span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="text-normal-detail ellipsis-1">{t('claimDetail.number')}<span
                                                                className="text-bold-detail black">{claim.claimnr}</span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="text-normal-detail">{t('claimDetail.open-date')}<Moment
                                                                locale="it"
                                                                format="DD/MM/YYYY"
                                                                className="text-bold-detail black">{claim.eventdate}</Moment>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="text-normal-detail ellipsis-1-v2">{t('claimDetail.type')}
                                                                <span
                                                                    className="text-bold-detail black">{claim.claimtype}</span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    {claim.controparte && <Row>
                                                        <Col>
                                                            <h6 className="text-normal-detail ellipsis-1-v2">{t('claimDetail.counterpart')}
                                                                <span
                                                                    className="text-bold-detail black">{claim.controparte}</span>
                                                            </h6>
                                                        </Col>
                                                    </Row>}
                                                    <Row>
                                                        <Col>
                                                            <h6 className="text-normal-detail ellipsis-1-v2">{t('claimDetail.status')}
                                                                {
                                                                    <span className={'text-bold-detail'}
                                                                          style={(claim.settledValueCents > 0 && claim.closeType === 'SINISTRO CHIUSO') ? {color: themeContext.color.green}
                                                                              : claim.closeType === 'SINISTRO APERTO' ? {color: themeContext.color.mainActive}
                                                                                  : (claim.closeType === 'SINISTRO CHIUSO' ? {color: themeContext.color.red}
                                                                                      : (claim.closeType === 'SENZA SEGUITO' ? {color: themeContext.color.neutral200} : {}))}>
                                                            {claim.closeType}
                                                        </span>
                                                                }
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    {claim.settledValueCents > 0 && <Row>
                                                        <Col>
                                                            <h6 className="text-normal-detail ellipsis-1">{t('claims.settled-value')}
                                                                <span
                                                                    className="text-bold-detail black">{claim.settledValue}</span>
                                                            </h6>
                                                        </Col>
                                                    </Row>}
                                                    <Row style={{'marginBottom': '20px'}}>
                                                        <Col>
                                                            <h6 className="text-normal-detail ellipsis-1-v2">{t('claimDetail.policy-number-label')}
                                                                <Link className="text-bold-detail"
                                                                      to={onClickPolicynr(policy.id)}
                                                                      style={{
                                                                          'color': themeContext.color.main,
                                                                          'textDecoration': 'underline'
                                                                      }}>
                                                                    {policy.policynr}
                                                                </Link>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        {
                                                            (claimActions.length > 0 || claim.note !== null) ?
                                                                <Col className="col-6 float-left">
                                                                    <h6 className={classes.titles}>{t('claimDetail.details')}
                                                                    </h6>
                                                                </Col>
                                                                :
                                                                <Col className="col-6 float-left"></Col>
                                                        }
                                                        <Col className="col" style={{'marginTop': '-14px'}}>
                                                <span className="float-right">
                                                    <a className="footer-link"
                                                       style={{'color': themeContext.color.main}}
                                                       onClick={handleClickOpen}>{t('claimDetail.need-help')}</a>
                                                </span>
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </StyledTitlePageWithImage>
                            <div className={'mx-2'}>
                                {
                                    claimActions.length > 0 &&
                                    <Row>
                                        <SwipeableList
                                            type={ListType.IOS} style={{height: '100%',}}>
                                            {
                                                claimActions.map((claimAction, index) => (
                                                    <SwipeableListItem
                                                        key={claimAction.id}
                                                        className="list-item-multiline w-100 h-100">
                                                        <ItemContent className="h-100">
                                                            <ItemRow className={'p-0 h-100'}>
                                                                <ItemColumn>
                                                                    <ItemNameLine style={{marginTop: 14}}
                                                                                  className="ellipsis-2-v2 black w-70">{claimAction.actionType}</ItemNameLine>
                                                                    <ItemInfoLine className="black font-italic"
                                                                                  style={{paddingRight: 4}}>
                                                                        {claimAction.note}
                                                                    </ItemInfoLine>
                                                                </ItemColumn>
                                                                <ItemColumn
                                                                    className="col-min justify-content-start align-items-end">
                                                                    <ItemNameLine className="black">
                                                                        <Moment locale="it"
                                                                                format="DD/MM/YYYY">{claimAction.actionDate}</Moment>
                                                                    </ItemNameLine>
                                                                </ItemColumn>
                                                            </ItemRow>
                                                        </ItemContent>
                                                    </SwipeableListItem>
                                                ))
                                            }
                                        </SwipeableList>
                                    </Row>
                                }
                                {
                                    claimActions.length === 0 && claim.note !== null &&
                                    <Row>
                                        <SwipeableList
                                            type={ListType.IOS} style={{height: '100%',}}>
                                            <SwipeableListItem
                                                key={0}
                                                className="list-item-multiline w-100 h-100">
                                                <ItemContent className="h-100">
                                                    <ItemRow className={'p-0 h-100'}>
                                                        <ItemColumn>
                                                            <ItemNameLine style={{marginTop: 14}}
                                                                          className="ellipsis-2-v2 black w-70"></ItemNameLine>
                                                            <ItemInfoLine className="black font-italic"
                                                                          dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(claim.note)}}></ItemInfoLine>
                                                        </ItemColumn>
                                                        <ItemColumn
                                                            className="col-min justify-content-start align-items-end">
                                                            <ItemNameLine className="black">
                                                                <Moment locale="it"
                                                                        format="DD/MM/YYYY">{claim.eventdate}</Moment>
                                                            </ItemNameLine>
                                                        </ItemColumn>
                                                    </ItemRow>
                                                </ItemContent>
                                            </SwipeableListItem>
                                        </SwipeableList>
                                    </Row>
                                }
                            </div>
                            <Dialog
                                components={{Backdrop: StyledBackdrop}}
                                open={openDialog}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                                PaperProps={{
                                    style: {
                                        'position': 'absolute',
                                        'bottom': '0',
                                        'left': 'auto',
                                        'right': 'auto',
                                        'width': '95%',
                                        'borderRadius': '10px',
                                        'color': themeContext.color.neutral600
                                    },
                                }}>
                                <DialogTitle id="alert-dialog-slide-title" className={classes.dialogTitle}>
                                    <span style={{'fontSize': '15px',}}>
                                        {t('claimDetail.modal-title')}
                                    </span>
                                </DialogTitle>
                                <DialogContent className={classes.dialogDescription}>
                                    <span style={{
                                        'fontSize': '20px',
                                        'fontWeight': "bold",
                                    }}>
                                        {accountExec.firstName} {accountExec.lastName}
                                    </span>
                                </DialogContent>
                                <StyledDivider/>
                                {
                                    accountExec.phone !== null &&
                                    <Fragment>
                                        <DialogActions>
                                            <a href={`tel:${accountExec.phone}`}
                                               style={{
                                                   'textDecoration': 'none',
                                                   'width': '100%',
                                                   'margin': '0 auto'
                                               }}>
                                                <Button color="primary" fullWidth
                                                        style={{
                                                            'textTransform': 'none',
                                                            'color': themeContext.color.green
                                                        }}>
                                                    {t('claimDetail.modal-tel-button')}
                                                </Button>
                                            </a>
                                        </DialogActions>
                                        <StyledDivider/>
                                    </Fragment>
                                }
                                <DialogActions>
                                    <a href={`mailto:${accountExec.email}'`}
                                       style={{
                                           'textDecoration': 'none',
                                           'width': '100%',
                                           'margin': '0 auto'
                                       }}>
                                        <Button color="primary" style={{'textTransform': 'none'}}
                                                fullWidth>
                                            {t('claimDetail.modal-email-button')}
                                        </Button>
                                    </a>
                                </DialogActions>
                                <StyledDivider/>
                                <DialogActions>
                                    <Button onClick={handleClose} style={{
                                        'color': themeContext.color.neutral400,
                                        'textTransform': 'none'
                                    }} fullWidth>
                                        {t('claimDetail.modal-refuse-button')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </StyledPageLayout>
                }
            </StyledPageCard>
        </ExternalPageLayout>
    )
}