import color from "../../css/App.scss";
import spacing from "../../css/App.scss";

const themeContext = {
    color,
    typography: {},
    spacing: {
        borders:{
            border1: spacing.border1,
            border2: spacing.border2,
            border3: spacing.border3,
            border4: spacing.border4,
        },
    },
};

export default themeContext;