const baseUrl = process.env.REACT_APP_BASE_URL;
export const getPeoplePaymentNotificationUrl = `${baseUrl}/payments/notifications/people?payableOnly=true`;
export const getDocumentToSignedUrl = `${baseUrl}/media/expectedDocuments/getAllDocuments`;
export const getRegulationsUrl = `${baseUrl}/payments/regulations/getAllRegulations`;
export const getAllRefundPremiumUrl = `${baseUrl}/media/refundPremium/getAllRefundPremium`;
export const isUpdatedUrl = `${baseUrl}/management/auth/isUpdated`;
export const removeTokenUrl = `${baseUrl}/notifications/notify/removeToken`;
export const userInfoUrl = `${baseUrl}/profile/user/info`;
export const personInfoUrl = `${baseUrl}/profile/user/person`;
export const policyUrl = `${baseUrl}/dashboard/policies`;
export const policyDocumentUrl = `${baseUrl}/media/policy`;
export const titleDocumentUrl = `${baseUrl}/media/policy/title`;
export const policyTitlesHistoryUrl = `${baseUrl}/dashboard/policies/titlesHistory`;
export const peoplePoliciesV2Url = `${baseUrl}/dashboard/policies/v2/people`;
export const messagesV2Url = `${baseUrl}/notifications/notify/v2/messages`;
export const resumeUrl = `${baseUrl}/notifications/notify/resume`;
export const archivedUrl = `${baseUrl}/notifications/notify/archive`;
export const updateUrl = `${baseUrl}/notifications/notify/principal`;
export const policyTypesUrl = `${baseUrl}/dashboard/policies/types`;
export const claimsV2Url = `${baseUrl}/claims/v2/claims`;
export const claimsUrl = `${baseUrl}/claims`;
export const accountExecUrl = `${baseUrl}/profile/user/accountExec`;
export const countActiveClaimsUrl = `${baseUrl}/claims/v2/countActiveClaims`;
export const paymentUrl = `${baseUrl}/payments/notifications/people?payableOnly=true`;
export const nexiUrl = `${baseUrl}/payments/notifications/isNexiOrderPaid`;
export const slimPayUrl = `${baseUrl}/payments/notifications/getSlimpayOrderStatus`;
export const generatePaymentUrl = `${baseUrl}/payments/notifications/getPaymentUrl`;
export const slimPayPendingUrl = `${baseUrl}/profile/user/setSlimpayPendingStatus`;
export const paymentReceivedUrl = `${baseUrl}/payments/sendPaymentReceived`;
export const sendUpdatePassUrl = `${baseUrl}/dashboard/policies/sendUpdatePass`;
export const operationCreateOrUpdateUrl = `${baseUrl}/management/operation/createOrUpdate`;
export const operationCompleteUrl= `${baseUrl}/management/operation/complete`;
