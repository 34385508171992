import * as React from 'react';
import {Fragment, useContext, useEffect, useState} from 'react';
import {alpha, styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import themeContext from "../ThemeContext.js";
import {t} from "i18next";
import HomeSidebarIcon from "./../../../images/desktop/home-sidebar.svg";
import supportIcon from "./../../../images/desktop/support.svg";
import settingsIcon from "./../../../images/desktop/settings.svg";
import PaymentsIcon from "../../../images/nav-payment-icon.svg";
import RefundsIcon from "./../../../images/nav-refunds-icon.svg";
import ClaimsSidebarIcon from "./../../../images/desktop/claims-sidebar.svg";
import HoldersIcon from './../../../images/desktop/holders.svg';
import VehiclesIcon from './../../../images/desktop/vehicles-icon-neutral.svg';
import PersonalDocumentIcon from './../../../images/desktop/personal-documents-neutral.svg';
import MessagesIcon from './../../../images/desktop/messages.svg';
import UpdateAppIcon from './../../../images/desktop/update-app.svg';
import AuthorizationIcon from './../../../images/desktop/authorization.svg';
import {getCountActiveClaims, getRefunds,} from "../../service/BarService.js";
import {useNavigate} from "react-router-dom";
import {SidebarContext, VIEW} from "../../middleware/SidebarContext.js";
import {Menu, MenuItem} from "@mui/material";
import GridIcon from './../../../images/desktop/grid.svg';
import GridSelectedIcon from './../../../images/desktop/grid-selected.svg';
import ListIcon from './../../../images/desktop/list.svg';
import ListSelectedIcon from './../../../images/desktop/list-selected.svg';
import languageIcon from './../../../images/desktop/language-icon.svg';
import MessagePreferencesIcon from './../../../images/desktop/message-preferences.svg';
import {StyledBadge, StyledBadgeEmpty} from "../../styles/DesktopStyles.js";
import {DetailBarContext} from "../../middleware/DetailBarContext.js";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {getPeoplePoliciesV2} from "../../service/DashboardService.js";
import BrokerCard from "./BrokerCard.js";
import {getAccountExec} from "../../service/ProfileService.js";
import {getMessagesV2Url, MESSAGE_TYPE} from "../../service/MessagesService.js";
import MessagesPreview from "./MessagesPreview.js";
import {subscribe} from "../../service/EventService.js";

import HomeSidebarIconSelected from "./../../../images/desktop/home-sidebar-selected.svg";
import supportIconSelected from "./../../../images/desktop/support-selected.svg";
import settingsIconSelected from "./../../../images/desktop/settings-selected.svg";
import PaymentsIconSelected from "../../../images/nav-payment-hover.svg";
import RefundsIconSelected from "./../../../images/nav-refunds-hover.svg";
import ClaimsSidebarIconSelected from "./../../../images/desktop/claims-sidebar-selected.svg";
import HoldersIconSelected from './../../../images/desktop/holders-selected.svg';
import VehiclesIconSelected from './../../../images/desktop/vehicles-icon-neutral-selected.svg';
import PersonalDocumentIconSelected from './../../../images/desktop/personal-documents-neutral-selected.svg';

import UpdateAppIconSelected from './../../../images/desktop/update-app-selected.svg';
import AuthorizationIconSelected from './../../../images/desktop/authorization-selected.svg';
import languageIconSelected from './../../../images/desktop/language-icon-selected.svg';
import MessagePreferencesIconSelected from './../../../images/message-preferences-selected.svg';

const drawerWidth = 265;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    //justifyContent: 'flex-end',
    height: '40px',
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
}));

const ListItemIconContainer = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '32px',
    minWidth: '32px',
    height: '32px',
    width: '32px',
}));

const MenuItemIconContainer = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    width: '24px',
}));

const StyledMenu = styled((props, position) => (
    <Menu
        disableScrollLock={true}
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
        {...position}
    />
))(({theme, position}) => ({
    '& .MuiPaper-root': {
        borderRadius: 16,
        border: 'none',
        outline: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
        padding: '8px',
        marginTop: `calc(${theme.spacing(1)} - ${position}px)`,
        minWidth: 180,
        color: 'rgb(55, 65, 81)',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
        ...theme.applyStyles('dark', {
            color: theme.palette.grey[300],
        }),
    },
}));

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    borderRadius: 8,
    display: 'flex',
    gap: '8px',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center'
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        [`& .MuiDrawer-paper`]: {
            scrollbarWidth: 'none',
            borderRadius: '16px',
            border: 'none',
            outline: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
            ...(open ? {padding: '16px'} : {padding: '8px'})
        },
        //padding: '16px',
        width: drawerWidth,
        scrollbarWidth: 'none',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variants: [
            {
                props: ({open}) => open,
                style: {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                },
            },
            {
                props: ({open}) => !open,
                style: {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                },
            },
        ],
    }),
);

const swListener = new BroadcastChannel('swListener');

const Sidebar = () => {
    const navigate = useNavigate();
    const {
        open,
        toggleSidebar,
        view,
        toggleView,
        setOpenAppDialog,
        setOpenPolicyDialog,
        setOpenMessagesDialog,
        setMessageId,
        setOpenOpenClaimDialog
    } = useContext(SidebarContext);
    const {updateData} = useContext(DetailBarContext);
    const [openListArray, setOpenListArray] = useState([]);
    const [refunds, setRefunds] = useState(null);
    const [countActiveClaims, setCountActiveClaims] = useState(null);
    const [anchorSettingsEl, setAnchorSettingsEl] = useState(null);
    const openSettingsMenu = Boolean(anchorSettingsEl);
    const token = sessionStorage.getItem('token');
    const [selectedElement, setSelectedElement] = useState(null);
    const [anchorPeopleEl, setAnchorPeopleEl] = useState(null);
    const openPeopleMenu = Boolean(anchorPeopleEl);
    const [countFeaturedPolicies, setCountFeaturedPolicies] = useState(false);
    const [accountExec, setAccountExec] = useState(null);
    const [messagesPreview, setMessagesPreview] = useState(null);

    const handleClickParentList = (index, value) => {
        setSelectedElement(value)
        handleClickChildList(value)
    }

    const routes = {
        'policies': '/dashboard',
        'payments': '/payments',
        'signing': '/signing',
        'regulations': '/regulations',
        'refunds': '/refunds',
        'people': '/settings/people',
        'vehicles': '/settings/vehicles',
        'people-payments': '/settings/payments',
        'personal-documents': '/settings/personal-documents',
        /*'messages': '/messages',*/
        'claims': '/claims',
        /*'openClaim': '/claims/open',*/
        'support': '/settings/support',
        'messagePreferences': '/settings/message-preferences',
        'language': '/settings/language',
    };

    const modalRoutes = {
        'messages': () => {
            setMessageId(null)
            setOpenMessagesDialog(true)
        },
        'openClaim': () => {
            setOpenOpenClaimDialog(true)
        },
    }

    const handleClickChildList = (value) => {
        if (routes[value]) {
            setSelectedElement(value)
            updateData(null);
            return navigate(routes[value]);
        } else if (modalRoutes[value]) {
            return modalRoutes[value]();
        }
    };

    const handleClickSettings = (event) => {
        setAnchorSettingsEl(event.currentTarget);
    };
    const handleCloseSettings = () => {
        setAnchorSettingsEl(null);
    };

    const handleClickPeople = (event) => {
        setAnchorPeopleEl(event.currentTarget);
    };
    const handleClosePeople = () => {
        setAnchorPeopleEl(null);
    };

    const linkToOpenClaims = () => {
        const openClaim = 'openClaim';
        setSelectedElement(openClaim);
        return navigate(routes[openClaim]);
    }

    const openClaimButton = (
        <IconButton onClick={(e) => {
            e.stopPropagation();
            handleClickChildList('openClaim')
        }}>
            <AddCircleOutlineIcon></AddCircleOutlineIcon>
        </IconButton>
    )

    const settingList = [
        {
            name: 'messagePreferences',
            text: t('desktop.settings.message-preferences'),
            badge: null,
            image: MessagePreferencesIcon,
            imageSelected: MessagePreferencesIconSelected,
        },
        {
            name: 'language',
            text: t('desktop.settings.language'),
            badge: null,
            image: languageIcon,
            imageSelected: languageIconSelected,
        },
        {
            name: 'authorizations',
            text: t('desktop.settings.authorizations'),
            badge: null,
            image: AuthorizationIcon,
            imageSelected: AuthorizationIconSelected
        },
        {
            name: 'update',
            text: t('desktop.settings.update'),
            badge: null,
            image: UpdateAppIcon,
            imageSelected: UpdateAppIconSelected
        },
    ]

    const peopleList = [
        {
            name: 'people',
            text: t('desktop.sidebar.see all policy holders'),
            badge: null,
            image: HoldersIcon,
            imageSelected: HoldersIconSelected,
        },
        {
            name: 'vehicles',
            text: t('settings.list-vehicles'),
            badge: null,
            image: VehiclesIcon,
            imageSelected: VehiclesIconSelected,
        },
        {
            name: 'people-payments',
            text: t('settings.list-payments'),
            badge: null,
            image: PaymentsIcon,
            imageSelected: PaymentsIconSelected,
        },
        {
            name: 'personal-documents',
            text: t('settings.list-personal-documents'),
            badge: null,
            image: PersonalDocumentIcon,
            imageSelected: PersonalDocumentIconSelected,
        },
        {
            name: 'refunds',
            text: t('app.nav-refunds'),
            badge: refunds,
            image: RefundsIcon,
            imageSelected: RefundsIconSelected,
        },
    ]


    const sidebarList = [
        {
            name: 'policies',
            text: t('dashboard.policies-title'),
            badge: countFeaturedPolicies,
            image: HomeSidebarIcon,
            imageSelected: HomeSidebarIconSelected,
            children: [],
            visibility: true
        },
        {
            name: 'claims',
            text: t('app.nav-claims'),
            badge: countActiveClaims,
            image: ClaimsSidebarIcon,
            imageSelected: ClaimsSidebarIconSelected,
            children: [],
            action: true,
            button: openClaimButton,
            visibility: true
        },
        {
            name: 'messages',
            text: t('messages.title'),
            badge: null,
            image: MessagesIcon,
            children: [],
            action: true,
            visibility: !open
        },
    ];

    const sidebarBottomList = [
        {
            name: 'people',
            text: t('settings.list-users'),
            badge: null,
            image: HoldersIcon,
            imageSelected: HoldersIconSelected,
            children: [],
            action: true,
        },
        {
            name: 'support',
            text: t('settings.list-support'),
            badge: null,
            image: supportIcon,
            imageSelected: supportIconSelected,
            children: [],
            action: true,
        },
        {
            name: 'settings',
            text: t('desktop.sidebar.settings'),
            badge: null,
            image: settingsIcon,
            imageSelected: settingsIconSelected,
            children: [],
            action: true,
        },
    ]

    useEffect(() => {
        getRefunds(token).then(r => setRefunds(r));
        getCountActiveClaims(token).then(r => setCountActiveClaims(r));
        getPeoplePoliciesV2(token, 0, 1, 'HIGHLIGHTED').then((r) => {
            setCountFeaturedPolicies(r.totalElements > 0)
        })
        getAccountExec(token).then(r => setAccountExec(r))
        getMessagesV2Url(token, 0, 3, MESSAGE_TYPE.NORMAL_NOT_IMPORTANT).then(r => {
            setMessagesPreview(r);
        })
        swListener.onmessage = function () {
            let timerId = setInterval(function timerMethod() {
                const delayDebounceFn = setTimeout(() => {
                    getMessagesV2Url(token, 0, 3, MESSAGE_TYPE.NORMAL_NOT_IMPORTANT).then(r => {
                        setMessagesPreview(r);
                    })
                    clearInterval(timerId);
                }, 1000)
                return () => clearTimeout(delayDebounceFn)
            }, 5000)
        };
        subscribe('reloadMessages', (l) => {
            getMessagesV2Url(token, 0, 3, MESSAGE_TYPE.NORMAL_NOT_IMPORTANT).then(r => {
                setMessagesPreview(r);
            })
        });
    }, [token]);


    const currentPath = window.location.href.replace(window.location.origin, '');

    const isActiveParent = (name) => currentPath === (routes[name])

    const isActiveChild = (name) => sidebarList.some(item => item.name === name && item.children.some(child => currentPath === (routes[child.name])));

    const isActive = (name) => currentPath === (routes[name]) ||
        sidebarList.some(item => item.name === name && item.children.some(child => currentPath === (routes[child.name])));

    const isActiveAll = (name) => currentPath === (routes[name]) &&
        sidebarList.some(item => item.name === name && item.children.some(child => currentPath === (routes[child.name])));

    // Active list item
    const activeChildParentItem = (name, type, isOpen) => {
        if (name === 'people') {
            if (window.location.href.includes('settings/people') || window.location.href.includes('settings/personal-documents') || window.location.href.includes('settings/vehicles') || window.location.href.includes('settings/payments') || window.location.href.includes('refunds')) {
                return {background: themeContext.color.neutral10, borderRadius: 16};
            }
        } else if (name === 'settings') {
            if (window.location.href.includes('settings/language') || window.location.href.includes('settings/message-preferences')) {
                return {background: themeContext.color.neutral10, borderRadius: 16};
            }
        } else {
            if (isActiveAll(name)) {
                return {backgroundColor: themeContext.color.main, borderRadius: 16};
            } else if (isActive(name)) {
                if (isActiveParent(name) && !isActiveChild(name) && type === 'parent') {
                    if (sidebarList.some(item => item.name === name && item.children.length > 0)) {
                        if (isOpen) {
                            return {
                                backgroundColor: themeContext.color.main05,
                                borderRadius: 16,
                                color: themeContext.color.main,
                                fontWeight: "600"
                            };
                        }
                        return {
                            backgroundColor: themeContext.color.main05, borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0, borderTopLeftRadius: 16,
                            borderTopRightRadius: 16, color: themeContext.color.main, fontWeight: "600"
                        };
                    } else {
                        return {
                            backgroundColor: themeContext.color.main05,
                            borderRadius: 16,
                            color: themeContext.color.main,
                            fontWeight: "600"
                        };
                    }
                } else if (!isActiveParent(name) && isActiveChild(name) && type === 'child') {
                    return {backgroundColor: themeContext.color.main, borderRadius: 16};
                } else {
                    if (type === 'group') {
                        return {backgroundColor: themeContext.color.main05, borderRadius: 16};
                    } else if (type === 'parent') {
                        if (!isActiveParent(name) && isActiveChild(name)) {
                            return {
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 16,
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                            };
                        } else {
                            return {
                                backgroundColor: themeContext.color.main05,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 16,
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                            };
                        }
                    } else if (type === 'child') {
                        return {
                            backgroundColor: themeContext.color.main05,
                            borderRadius: 16,
                            fontWeight: '600',
                            color: themeContext.color.main
                        };
                    }
                }
            } else {
                return {borderRadius: 16};
            }
        }
    }

    const activeIcons = (name, type) => {
        if (name !== 'people' && name !== 'settings') {
            if (isActive(name)) {
                if (isActiveParent(name) && !isActiveChild(name) && type === 'parent') {
                    return true
                } else if (type === 'child') {
                    return true
                }
            }
        }
    }

    const activeMenuItems = (name) => {
        if (window.location.href.includes(routes[name])) {
            return {
                fontWeight: 'bold',
                color: themeContext.color.main,
                background: themeContext.color.main05
            }
        }
    }

    const activeMenuItemIcons = (name) => {
        if (window.location.href.includes(routes[name])) {
            return true
        }
    }

    const handleClickSidebarBottomList = (e, index, value) => {
        switch (value) {
            case 'support':
                updateData(null)
                return navigate(routes[value]);
            case 'settings':
                handleClickSettings(e);
                break;
            case 'people':
                handleClickPeople(e);
                break;
        }
    }

    const handleClickSettingsMenuItem = (value) => {
        updateData(null)
        handleCloseSettings()
        switch (value) {
            case 'messagePreferences':
                navigate('/settings/message-preferences')
                break;
            case 'language':
                navigate('/settings/language')
                break;
            case 'authorizations':
                setOpenPolicyDialog(true);
                break;
            case 'update':
                setOpenAppDialog(true);
                break;
        }
    }

    return (
        <Fragment>
            <CssBaseline/>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader sx={open ? {justifyContent: 'flex-end'} : {justifyContent: 'center'}}>
                    {open &&
                        <span style={{fontWeight: 'bold', color: themeContext.color.neutral400}}>MENU</span>}
                    <IconButton onClick={toggleSidebar}>
                        {open ? <ChevronLeftIcon className={'ChevronIcon-custom'}/>
                            : <ChevronRightIcon className={'ChevronIcon-custom'}/>}
                    </IconButton>
                </DrawerHeader>
                <div className={'d-flex flex-column justify-content-between h-100'}>
                    <div className={'d-flex flex-column'} style={{gap: 8}}>
                        <List style={{paddingBottom: 0}}>
                            {sidebarList.map((value, index) => (
                                value.visibility && <ListItem key={index} disablePadding
                                                              style={activeChildParentItem(value.name, 'group', openListArray.includes(index))}
                                                              sx={open ? {
                                                                  display: 'block',
                                                                  py: '0px'
                                                              } : {display: 'block', py: '0px'}}>
                                    <ListItemButton
                                        style={activeChildParentItem(value.name, 'parent', openListArray.includes(index))}
                                        onClick={() => {
                                            handleClickParentList(index, value.name)
                                        }}
                                        sx={[{
                                            display: 'flex',
                                            minHeight: 48,
                                            width: '100%',
                                            px: '6px',
                                            borderRadius: '16px',
                                        },
                                            open ? {justifyContent: 'space-between',} : {justifyContent: 'center',},
                                        ]}
                                    >
                                        <div
                                            className={open ? 'd-flex flex-row align-items-center w-100' : 'd-flex flex-column align-items-center'}>
                                            <ListItemIconContainer>
                                                {!open &&
                                                value.badge > 0 ?
                                                    (typeof value.badge === 'boolean' ?
                                                            <StyledBadgeEmpty badgeContent={value.badge}>
                                                                <ListItemIcon
                                                                    //className={activeIcons(value.name, 'parent', null)}
                                                                    sx={[{
                                                                        minWidth: 0,
                                                                        display: 'block'
                                                                    },
                                                                        open ? {} : {my: 'auto'},
                                                                    ]}
                                                                >
                                                                    <img
                                                                        src={activeIcons(value.name, 'parent', null) ? value.imageSelected : value.image}
                                                                        alt={'sidebar-icon'}/>
                                                                </ListItemIcon>
                                                            </StyledBadgeEmpty>
                                                            : <StyledBadge badgeContent={value.badge}
                                                                           color="primary">
                                                                <ListItemIcon
                                                                    //className={activeIcons(value.name, 'parent', null)}
                                                                    sx={[{
                                                                        minWidth: 0,
                                                                        display: 'block'
                                                                    },
                                                                        open ? {} : {my: 'auto'},
                                                                    ]}
                                                                >
                                                                    <img
                                                                        src={activeIcons(value.name, 'parent', null) ? value.imageSelected : value.image}
                                                                        alt={'sidebar-icon'}/>
                                                                </ListItemIcon>
                                                            </StyledBadge>
                                                    ) :
                                                    <ListItemIcon
                                                        sx={[{
                                                            minWidth: 0,
                                                            display: 'block'
                                                        },
                                                            open ? {} : {my: 'auto'},
                                                        ]}>
                                                        <img
                                                            src={activeIcons(value.name, 'parent', null) ? value.imageSelected : value.image}
                                                            //className={activeIcons(value.name, 'parent', null)}
                                                            alt={'sidebar-icon'}/>
                                                    </ListItemIcon>
                                                }
                                            </ListItemIconContainer>
                                            {open && <div
                                                className={'d-flex flex-row align-items-center justify-content-between w-100'}
                                                style={{gap: '8px'}}>
                                                <div className={'d-flex flex-row align-items-center'} style={{gap: 8}}>
                                                <span style={open ? {opacity: 1} : {opacity: 0}}>
                                                    {value.text}
                                                </span>
                                                    {value.badge > 0 &&
                                                        <span className={value.badge === true ? 'sidebar-badge' : 'sidebar-badge-with-number'}>{value.badge}</span>
                                                    }
                                                </div>
                                                <div>{value.button}</div>
                                            </div>
                                            }
                                        </div>
                                        {/*{
                                        value.children.length > 0 && open &&
                                        <ListItemIcon sx={{display: 'flex', justifyContent: 'flex-end', minWidth: 0}}
                                                      className={activeIcons(value.name, 'parent', null)}
                                                      onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleClickActionParentList(index, value.name)
                                                      }}>
                                            {openListArray.includes(index) ? <ExpandLess/> : <ExpandMore/>}
                                        </ListItemIcon>
                                    }*/}
                                    </ListItemButton>
                                    {/*{
                                    value.children.length > 0 &&
                                    <Collapse in={!openListArray.includes(index)} timeout="auto"
                                              unmountOnExit>
                                        <List sx={open ? {pl: '24px', py: 0} : {}}>
                                            {
                                                value.children.map((child, i) => (
                                                    <ListItem key={i} disablePadding
                                                              style={activeChildParentItem(child.name, 'child', openListArray.includes(index))}
                                                              onClick={() => handleClickChildList(child.name)}>
                                                        <ListItemButton
                                                            sx={[{
                                                                display: 'flex',
                                                                minHeight: 48,
                                                                px: '6px',
                                                                borderRadius: '16px',
                                                            },
                                                                open ? {justifyContent: 'initial',} : {justifyContent: 'center',},
                                                            ]}
                                                        >
                                                            <div
                                                                className={open ? 'd-flex flex-row' : 'd-flex flex-column align-items-center'}>
                                                                <ListItemIconContainer>
                                                                    {!open && child.badge > 0 ?
                                                                        <StyledBadge badgeContent={child.badge}
                                                                                     color="primary">
                                                                            <ListItemIcon
                                                                                className={activeIcons(child.name, 'child', null)}
                                                                                sx={[{
                                                                                    minWidth: 0,
                                                                                    display: 'block'
                                                                                },
                                                                                    open ? {} : {my: 'auto'},
                                                                                ]}
                                                                            >
                                                                                <img src={child.image}
                                                                                     alt={'sidebar-icon'}/>
                                                                            </ListItemIcon>
                                                                        </StyledBadge> :
                                                                        <ListItemIcon
                                                                            className={activeIcons(child.name, 'child', null)}
                                                                            sx={[{
                                                                                minWidth: 0,
                                                                                display: 'block'
                                                                            },
                                                                                open ? {} : {my: 'auto'},
                                                                            ]}
                                                                        >
                                                                            <img src={child.image}
                                                                                 alt={'sidebar-icon'}/>
                                                                        </ListItemIcon>
                                                                    }
                                                                </ListItemIconContainer>
                                                                {!open &&
                                                                    <span
                                                                        className={'d-flex justify-content-center text-center'}
                                                                        style={{
                                                                            fontSize: '10px',
                                                                            whiteSpace: 'pre-wrap'
                                                                        }}>{child.text.split(' ').join('\n')}</span>}
                                                            </div>
                                                            {open &&
                                                                <div className={'d-flex flex-row align-items-center'}
                                                                     style={{gap: '8px'}}>
                                                                    <span style={open ? {opacity: 1} : {opacity: 0}}>
                                                                        {child.text}
                                                                    </span>
                                                                    {child.badge > 0 &&
                                                                        <div>
                                                                    <span
                                                                        className={'sidebar-badge'}>{child.badge}</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    </Collapse>
                                }*/}
                                </ListItem>
                            ))}
                        </List>
                        {open && <MessagesPreview isLoading={messagesPreview === null} data={messagesPreview}
                                                  openDialog={(id) => {
                                                      setMessageId(id)
                                                      setOpenMessagesDialog(true)
                                                  }}/>}
                    </div>
                    <div className={'d-flex flex-column'} style={{gap: 8}}>
                        <BrokerCard isLoading={accountExec === null} data={accountExec} isOpen={open}/>
                        <List>
                            {sidebarBottomList.map((value, index) => (
                                <ListItem key={index} disablePadding
                                          style={activeChildParentItem(value.name, 'group', openListArray.includes(index))}
                                          sx={open ? {display: 'block', py: '0px'} : {display: 'block', py: '0px'}}>
                                    <ListItemButton
                                        id={value.name}
                                        style={((value.name === 'settings' && anchorSettingsEl) || (value.name === 'people' && anchorPeopleEl)) ? {background: themeContext.color.neutral10} : activeChildParentItem(value.name, 'parent', openListArray.includes(index))}
                                        onClick={(e) => {
                                            handleClickSidebarBottomList(e, index, value.name)
                                        }}
                                        sx={[{
                                            display: 'flex',
                                            minHeight: 48,
                                            width: '100%',
                                            px: '6px',
                                            borderRadius: '16px',
                                        },
                                            open ? {justifyContent: 'space-between',} : {justifyContent: 'center',},
                                        ]}
                                    >
                                        <div
                                            className={open ? 'd-flex flex-row' : 'd-flex flex-column align-items-center'}>
                                            <ListItemIconContainer>
                                                {!open ?
                                                    <StyledBadgeEmpty
                                                        badgeContent={value.name === 'people' && refunds > 0 ? true : null}>
                                                        <ListItemIcon
                                                            //className={activeIcons(value.name, 'parent', null)}
                                                            sx={[{
                                                                minWidth: 0,
                                                                display: 'block'
                                                            },
                                                                open ? {} : {my: 'auto'},
                                                            ]}
                                                        >
                                                            <img
                                                                src={activeIcons(value.name, 'parent', null) ? value.imageSelected : value.image}
                                                                alt={'sidebar-icon'}/>
                                                        </ListItemIcon>
                                                    </StyledBadgeEmpty> :
                                                    <ListItemIcon
                                                        sx={[{
                                                            minWidth: 0,
                                                            display: 'block'
                                                        },
                                                            open ? {} : {my: 'auto'},
                                                        ]}>
                                                        <img
                                                            src={activeIcons(value.name, 'parent', null) ? value.imageSelected : value.image}
                                                            //className={activeIcons(value.name, 'parent', null)}
                                                            alt={'sidebar-icon'}/>
                                                    </ListItemIcon>
                                                }
                                            </ListItemIconContainer>
                                            {open && <div className={'d-flex flex-row align-items-center'}
                                                          style={{gap: '8px'}}>
                                                <span style={open ? {opacity: 1} : {opacity: 0}}>
                                                    {value.text}
                                                </span>
                                                {value.badge > 0 &&
                                                    <div><span className={'sidebar-badge'}>{value.badge}</span></div>
                                                }
                                                {value.button}
                                            </div>
                                            }
                                            {value.name === 'people' && (refunds > 0) && open &&
                                                <div className={'ml-2 d-flex align-items-center'}><span
                                                    className={'sidebar-badge'}>{refunds > 0}</span></div>
                                            }
                                        </div>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <StyledMenu position={'80'}
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'settings',
                                }}
                                anchorEl={anchorSettingsEl}
                                open={openSettingsMenu}
                                onClose={handleCloseSettings}>
                        {settingList.map((value, index) => (
                            <StyledMenuItem key={index} style={activeMenuItems(value.name)}
                                            onClick={(e) => {
                                                handleClickSettingsMenuItem(value.name);
                                            }} disableRipple>
                                <MenuItemIconContainer>
                                    <img src={activeMenuItemIcons(value.name) ? value.imageSelected : value.image}
                                         alt={value.name}
                                        //className={activeMenuItemIcons(value.name)}
                                    />
                                </MenuItemIconContainer>
                                {value.text}
                                {value.badge > 0 &&
                                    <div><span className={'sidebar-badge'}>{value.badge}</span></div>
                                }
                            </StyledMenuItem>
                        ))}
                        <div className={'d-flex justify-content-end align-items-center flex-row'}
                             style={{gap: '23px', padding: '8px'}}>
                            <span>{t('desktop.settings.view')}</span>
                            <div className={'d-flex'}>
                                <IconButton onClick={() => {
                                    toggleView(VIEW.GRID)
                                    handleCloseSettings()
                                }}>
                                    <img alt={'grid'} src={view === VIEW.GRID ? GridSelectedIcon : GridIcon}/>
                                </IconButton>
                                <IconButton onClick={() => {
                                    toggleView(VIEW.LIST)
                                    handleCloseSettings()
                                }}>
                                    <img alt={'list'} src={view === VIEW.LIST ? ListSelectedIcon : ListIcon}/>
                                </IconButton>
                            </div>
                        </div>
                    </StyledMenu>
                    <StyledMenu position={'170'}
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'people',
                                }}
                                anchorEl={anchorPeopleEl}
                                open={openPeopleMenu}
                                onClose={handleClosePeople}>
                        {peopleList.map((value, index) => (
                            <StyledMenuItem key={index} style={activeMenuItems(value.name)}
                                            onClick={() => {
                                                handleClickChildList(value.name);
                                                handleClosePeople()
                                            }} disableRipple>
                                <MenuItemIconContainer>
                                    <img src={activeMenuItemIcons(value.name) ? value.imageSelected : value.image}
                                         alt={value.name}
                                        //className={activeMenuItemIcons(value.name)}
                                    />
                                </MenuItemIconContainer>
                                {value.text}
                                {value.badge > 0 &&
                                    <div><span className={'sidebar-badge'}>{value.badge}</span></div>
                                }
                            </StyledMenuItem>
                        ))}
                    </StyledMenu>
                </div>
            </Drawer>
        </Fragment>
    );
};

export default Sidebar;
