import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import AuthContextMiddleware from '../../middleware/AuthContextMiddleware.js';
import {Button, FormHelperText, Link, TextField} from "@mui/material";
import 'react-phone-number-input/style.css'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import {useForm} from "react-hook-form"
import MaterialPhoneNumber from "../../middleware/PhoneNumber.js";
import exit from "../../../images/exit-icon.svg";
import identityConfirmedPh from "../../../images/identityConfirmed-ph.svg";
import {publish} from "../../service/EventService.js";
import spinner from "../../../images/spinner.svg";
import DialogDoubleAction from "../../components/DialogDoubleAction.js";
import DialogSmall from "../../components/DialogSmall.js";
import {useTranslation} from "react-i18next";
import themeContext from "../../components/ThemeContext.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";
import {ExternalPageLayout, StyledPageCard, StyledTitlePage} from "../../styles/DesktopStyles.js";

export default function ConfirmOtpVerifyID() {
    const {control, handleSubmit} = useForm()

    window.addEventListener('offline', () => {
        window.location.reload();
        return 0;
    });

    const {open} = useContext(SidebarContext);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const policyId = query.get('policyId');
    const titleId = query.get('titleId');
    const isCie = query.get('isCie');
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const verifyOtpUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/login/verify/number`;
    const signDocumentV2Url = `${process.env.REACT_APP_BASE_URL}/media/policy/v2/signDocument`;
    const sendNotificationAfterSignUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/documentSigned`;
    const sendOtpUrl = `${process.env.REACT_APP_BASE_URL}/management/otp/send`;
    const saveVidiIdentifyUrl = `${process.env.REACT_APP_BASE_URL}/media/vidi/saveIdentify`;
    const [isLoadingData, setIsLoadingData] = useState(false);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [openOTPDialog, setOpenOTPDialog] = useState(false);
    const [phoneValue, setPhoneValue] = useState(sessionStorage.getItem('phone'));
    const [phoneLang, setPhoneLang] = useState(sessionStorage.getItem('phone-lang'));
    const otpTimer = process.env.REACT_APP_ENV === 'STAGE' || process.env.REACT_APP_ENV === 'DEV' ? 10 : 60;
    const [seconds, setSeconds] = useState(otpTimer);
    const [disabled, setDisabled] = useState(true)
    const params = useParams();
    const documentGroupId = params.documentGroupId;
    const personId = params.personId;
    const documentId = query.get('documentId');
    const [openExitDialog, setOpenExitDialog] = useState(false);
    const [documentIsSigned, setDocumentIsSigned] = useState(false);
    const [otpError, setOtpError] = useState(null);
    const [isLoadingSigning, setIsLoadingSigning] = useState(false);
    const token = sessionStorage.getItem('token');
    const [openFallbackDialog, setOpenFallbackDialog] = useState(false);


    useEffect(() => {
        if (navigator.onLine) {
            //
        }
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                clearInterval(interval);
                setDisabled(false)
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    useEffect(() => {
        if (navigator.onLine) {
            if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
                if (authCtx.backReqImageBase64 && authCtx.backReqImage && authCtx.reqImage && authCtx.reqImageBase64) {
                    //console.log('immagini presenti')
                } else {
                    //console.log('immagini non presenti')
                    setOpenFallbackDialog(true)
                }
            } else {
                if (authCtx.reqImage && authCtx.reqImageBase64) {
                    //console.log('immagini presenti')
                } else {
                    //console.log('immagini non presenti')
                    setOpenFallbackDialog(true)
                }
            }
        }
    }, []);

    const defaultValues = {
        otpCode: "",
    };

    const handleOTPClose = () => {
        setOpenOTPDialog(false)
    }
    const formValues = useRef(defaultValues);

    const verifyOtp = () => {
        setIsLoadingSigning(true)
        let otpCode = formValues.current.otpCode;
        fetch(verifyOtpUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                phone: phoneValue,
                code: otpCode
            }),
        }).then((res) => {
            return res.json();
        }).then((data) => {
            if (data.userList) {
                setOtpError(null);
                signDocument(documentId, isCie, authCtx.snapshots, authCtx.reqImageBase64, authCtx.backReqImageBase64, sessionStorage.getItem('ocr_result'));
            } else {
                setOtpError(1)
                setIsLoadingSigning(false)
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const signDocument = (documentId, isCie, snapshots, base64Front, base64Back, ocr_result) => {
        let body;
        if (window.location.href.includes('uploadBack')) {
            // caso in cui l'utenza è una company
            body = JSON.stringify({
                wgDocId: documentId,
                isCie: isCie || false,
                req_frame: snapshots,
                ref_img: base64Front,
                ocr_img: base64Back,
                ocr_result: ocr_result || ""
            })
        } else {
            // caso in cui l'utenza non è una company
            body = JSON.stringify({
                wgDocId: documentId,
                isCie: isCie || false,
                req_frame: snapshots,
                ref_img: base64Front
            })
        }
        fetch(signDocumentV2Url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: body,
        }).then((res) => {
            return res.json();
        }).then((data) => {
            if (data[0].fileName) {
                setDocumentIsSigned(true)
                setIsLoadingSigning(false)
                sendNotificationAfterSign();
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const sendNotificationAfterSign = () => {
        fetch(sendNotificationAfterSignUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                userId: localStorage.getItem('wideAPP_userId'),
                policyId: policyId,
                documentId: documentId
            }),
        }).then((res) => {
            return res.json();
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const handleInputChange = (e) => {
        setOtpError(null)
        const {
            name,
            value
        } = e.target;
        formValues.current = {
            ...formValues.current,
            [name]: value,
        }
    };

    function reload() {
        window.location.reload();
    }

    const turnBack = () => {
        if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
            return navigate(`/signing/${personId}/uploadBack/uploadFront/identify/otp?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=${isCie}`);
        } else {
            return navigate(`/signing/${personId}/upload/personalDocuments/${documentGroupId}/identify/otp?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`);
        }
    }

    const resendOtp = () => {
        setSeconds(otpTimer)
        setDisabled(true)
        fetch(sendOtpUrl, {
            method: 'POST',
            body: JSON.stringify({
                phone: phoneValue,
                lang: phoneLang
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            return res.json();
        }).then((res) => {
            if (res.ok) {
                setOpenOTPDialog(true)
            }
        });
    }
    const reloadMenu = (token) => {
        publish('reloadMenu', token);
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {
                    errorPage ?
                        <ErrorPage/> :
                        <div className="w-100 px-4 linear-gradient">
                            <div style={{'opacity': '1', paddingBottom: 24}}>
                                <StyledTitlePage>
                                    <Col className="col-1 float-left">
                                    </Col>
                                    <Col className="col-10">
                                        <h6 className="header-title ellipsis-1-v2">{t('ConfirmOtpVerifyID.title')}</h6>
                                    </Col>
                                    <Col className="col-1">
                                        <img src={exit} onClick={() => setOpenExitDialog(true)}
                                             className="nav-top-logo float-right cursor-pointer" alt="exit-icon"
                                             height="27px"/>
                                    </Col>
                                </StyledTitlePage>
                                <Row className="pr-0 pl-0">
                                    <Col className="col-list">
                                        {
                                            isLoadingData &&
                                            <div style={{'height': '90vh'}}
                                                 className="background-loading-spinner"></div>
                                        }
                                        {
                                            !isLoadingData && !documentIsSigned &&
                                            <Container>
                                                <Row style={{'marginTop': '43.5px'}}>
                                                    <Col className="pl-5 pr-5">
                                                        <span style={{
                                                            'fontSize': '16px',
                                                            'color': themeContext.color.neutral600,
                                                            'fontWeight': 'bold'
                                                        }}>{t('ConfirmOtpVerifyID.subtitle')}</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{
                                                    'marginTop': '12px',
                                                    'marginBottom': '44.5px'
                                                }}>
                                                    <Col>
                                                        <span style={{
                                                            'fontSize': '14px',
                                                            'color': themeContext.color.neutral600,
                                                            'fontWeight': 'normal'
                                                        }}>{t('ConfirmOtpVerifyID.description')}</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{'marginTop': '54px'}}>
                                                    <Col>
                                                        <PhoneInputWithCountry
                                                            disabled
                                                            label={t('newOtp.phone')}
                                                            value={phoneValue}
                                                            defaultValue={phoneValue}
                                                            //onChange={setPhoneValue}
                                                            defaultCountry={phoneLang.toUpperCase()}
                                                            name="phoneInput"
                                                            control={control}
                                                            rules={{required: true}}
                                                            smartCaret
                                                            limitMaxLength
                                                            inputComponent={MaterialPhoneNumber}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row style={{'marginTop': '25px'}}>
                                                    <Col>
                                                        <TextField
                                                            id="otpCode"
                                                            className="custom-textfield"
                                                            fullWidth
                                                            label={t('loginOtpVerify.otp-code')}
                                                            variant="outlined"
                                                            name="otpCode"
                                                            type={"number"}
                                                            required={true}
                                                            value={formValues.otpCode}
                                                            onChange={handleInputChange}
                                                            color="primary"
                                                            error={otpError !== null}
                                                            InputProps={{
                                                                inputProps: {min: 0},
                                                                style: {
                                                                    'borderRadius': '4px'
                                                                },
                                                            }}
                                                        />
                                                        {
                                                            otpError &&
                                                            <FormHelperText style={{
                                                                color: 'red',
                                                                marginTop: '5px'
                                                            }}>{t('loginOtpVerify.otp-error')}</FormHelperText>
                                                        }
                                                    </Col>
                                                </Row>

                                                <Row style={{'marginTop': '48px'}}>
                                                    <Col>
                                                        <Button className="btn-custom-no-padding text-uppercase"
                                                                onClick={() => {
                                                                    if (!isLoadingSigning) {
                                                                        verifyOtp();
                                                                    }
                                                                }}
                                                                variant="outlined"
                                                                size="large" style={{
                                                            'color': themeContext.color.white,
                                                            'width': '260px'
                                                        }}
                                                                type="submit">
                                                            {
                                                                isLoadingSigning &&
                                                                <Fragment>
                                                                    <span>
                                                                        {t('ConfirmOtpVerifyID.verify-on-loading')}
                                                                    </span>
                                                                    <img src={spinner} className="spinner-img"
                                                                         alt="spinner"/>
                                                                </Fragment>
                                                            }
                                                            {!isLoadingSigning && t('ConfirmOtpVerifyID.button')}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                {seconds !== 60 &&
                                                    <Row className="mt-4">
                                                        <Col>
                                                            {
                                                                disabled &&
                                                                <Link
                                                                    className="login-subtitle cursor-pointer"
                                                                    target="_new"
                                                                    style={{
                                                                        'color': themeContext.color.neutral400
                                                                    }}>
                                                                    {t('sendOtp.resend-disabled-1')} {seconds}{t('sendOtp.resend-disabled-2')}
                                                                </Link>
                                                            }
                                                            {
                                                                !disabled &&
                                                                <Link
                                                                    className="login-subtitle cursor-pointer"
                                                                    onClick={resendOtp}
                                                                    target="_new"
                                                                    style={{
                                                                        'color': themeContext.color.main
                                                                    }}>
                                                                    {t('sendOtp.resend')}
                                                                </Link>
                                                            }
                                                        </Col>
                                                    </Row>
                                                }
                                            </Container>
                                        }
                                        {
                                            !isLoadingData && documentIsSigned &&
                                            <Row className="pr-0 pl-0">
                                                <Col className="col-list">
                                                    <Container>
                                                        <Row style={{'marginTop': '43.5px'}}>
                                                            <Col className="pl-5 pr-5">
                                                                <span style={{
                                                                    'fontSize': '16px',
                                                                    'color': themeContext.color.neutral600,
                                                                    'fontWeight': 'bold'
                                                                }}>{t('ConfirmOtpVerifyID.verify-ok-subtitle')}</span><br/>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{
                                                            'marginTop': '12px',
                                                            'marginBottom': '44.5px'
                                                        }}>
                                                            <Col>
                                                                <span style={{
                                                                    'fontSize': '14px',
                                                                    'color': themeContext.color.neutral600,
                                                                    'fontWeight': 'normal'
                                                                }}>{t('ConfirmOtpVerifyID.verify-ok-description')}</span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <img src={identityConfirmedPh}
                                                                     alt="identity-confirmed-placeholder"
                                                                     width="100%"
                                                                     style={{'maxHeight': '340px'}}/>
                                                            </Col>
                                                        </Row>
                                                        <div className="fixed-bottom"
                                                             style={{
                                                                 left: open ? '265px' : '81px',
                                                                 'background': 'transparent',
                                                                 'paddingBottom': "40px"
                                                             }}>
                                                            <Row>
                                                                <Col>
                                                                    <Button className="btn-custom text-uppercase"
                                                                            variant="outlined"
                                                                            size="large"
                                                                            style={{'color': themeContext.color.white}}
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                localStorage.removeItem('base64-wide')
                                                                                localStorage.removeItem('imageUrl-wide')
                                                                                reloadMenu(token);
                                                                                navigate('/signing')
                                                                            }}
                                                                    >{t('ConfirmOtpVerifyID.verify-ok-button')}</Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Container>
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                                <DialogDoubleAction open={openExitDialog}
                                                    title={t('uploadDocumentToVerify.exit-title')}
                                                    description={t('uploadDocumentToVerify.exit-description')}
                                                    handleClose={() => setOpenExitDialog(false)}
                                                    handleNo={() => setOpenExitDialog(false)}
                                                    handleYes={() => {
                                                        localStorage.removeItem('base64-wide')
                                                        localStorage.removeItem('imageUrl-wide')
                                                        navigate('/signing');
                                                    }}
                                                    buttonYesText={t('uploadDocumentToVerify.exit-yes-button')}
                                                    buttonNoText={t('uploadDocumentToVerify.exit-no-button')}/>
                                <DialogSmall open={openOTPDialog}
                                             title={t('newOtp.dialog-title')}
                                             description={t('newOtp.dialog-text')}
                                             handleClose={handleOTPClose}
                                             buttonText={t('newOtp.dialog-button')}/>
                                <DialogSmall buttonText={t('uploadDocumentToVerify.fallback-dialog-button')}
                                             title={t('uploadDocumentToVerify.fallback-dialog-title')}
                                             description={t('uploadDocumentToVerify.fallback-dialog-description')}
                                             handleClose={() => {
                                                 if (window.location.href.includes('uploadBack')) {
                                                     navigate(`/signing/${personId}/uploadBack?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&skipTakingResponsibility=true`)
                                                 } else {
                                                     navigate(`/signing/${personId}/upload?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&skipTakingResponsibility=true`)
                                                 }
                                                 setOpenFallbackDialog(false)
                                             }}
                                             open={openFallbackDialog}/>
                            </div>
                        </div>
                }
            </StyledPageCard>
        </ExternalPageLayout>
    )
}
