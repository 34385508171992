import {API_METHODS, apiFetch} from "./ApiService.js";
import {userInfoUrl} from "./EndpointService.js";


export const userInfoApi = async (token_arg) => {
    try {
        return await apiFetch(userInfoUrl, token_arg);
    } catch (error) {
        return null;
    }
};

export const updateUserVersionApi = async (token_arg) => {
    try {
        return await apiFetch(userInfoUrl, token_arg, API_METHODS.PUT, {noShow: true});
    } catch (error) {
        return null;
    }
};

