import React from "react";
import {apiFetch} from "./ApiService.js";
import {accountExecUrl, userInfoUrl} from "./EndpointService.js";


const token = sessionStorage.getItem('token');

export const removeToken = async (token_arg) => {
//
};
export const updateAndRefresh = async (token_arg) => {
//
};

export const getUserInfo = async (token_arg) => {
    try {
        const data = await apiFetch(userInfoUrl, token_arg);
        if (data.status !== -1) {
            return data;
        } else return null;
    } catch (error) {
        return null;
    }
};

export const getAccountExec = async (token_arg) => {
    try {
        const data = await apiFetch(accountExecUrl, token_arg);
        if (data) return data;
        else return null;
    } catch (error) {
        return null;
    }
};