import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import Moment from "react-moment";
import back from "../../images/back-icon.svg";
import dots from "../../images/dots-icon.svg";
import List from "@mui/material/List";
import {makeStyles} from "@mui/styles";
import {Button} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import goTo from "../../images/goto-icon.svg";
import Divider from "@mui/material/Divider";
import slimpayLogo from "../../images/slimpay-logo-blue.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import {getBranchIcon} from "../service/DashboardService.js";
import ErrorPage from "../pages/ErrorPage.js";

const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100% !important',
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "0 0 0 0 !important",
    },
    listItem: {
        color: `${themeContext.color.neutral400} !important`,
    },
    cardTextMid: {
        color: `${themeContext.color.neutral200} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '400 !important',
        fontSize: '12px !important',
        textTransform: 'uppercase !important',
        paddingTop: '10px !important',
        paddingBottom: '7px !important',
    },
}));

function MailTo({
                    email,
                    subject,
                    body,
                    ...props
                }) {
    return (
        <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
           style={{
               'textDecoration': 'none',
               'width': '100%',
               'margin': '0 auto'
           }}>
            {props.children}
        </a>
    );
}

export default function PaymentsSummary() {
    const [isLoadingData, setIsLoadingData] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getPeoplePaymentNotification();
        } else {
            //handleReadOffline(id, type);
        }
    }, []);

    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const params = useParams();
    const notificationId = params.notificationId;
    const getPolicyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies`;
    const getPeoplePaymentNotificationUrl = `${process.env.REACT_APP_BASE_URL}/payments/notifications/people?payableOnly=true`;
    const operationUrl = `${process.env.REACT_APP_BASE_URL}/management/operation`;
    const authCtx = useContext(AuthContextMiddleware);
    const [policy, setPolicy] = useState([]);
    const classes = useStyles();
    const [selectedRadio, setSelectedRadio] = useState([]);
    const [person, setPerson] = useState([]);
    const [paymentNotification, setPaymentNotification] = useState([]);
    const [paymentNotificationTitles, setPaymentNotificationTitles] = useState([]);
    const [paymentNotificationExtraTitles, setPaymentNotificationExtraTitles] = useState([]);
    const [requestedCents, setRequestedCents] = useState(0);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const [dateBill, setDateBill] = useState("");
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const [isNegativePremium, setIsNegativePremium] = useState(false);
    const [isAlreadyPaid, setIsAlreadyPaid] = useState(false);


    const getPeoplePaymentNotification = () => {
        setIsLoadingData(true)
        fetch(`${getPeoplePaymentNotificationUrl}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let minDate;
            let found = false;
            if (data.length > 0) {
                data.forEach((value, index) => {
                    if (parseInt(notificationId) === value.id) {
                        found = true;
                        if (Math.sign(Number(value.requestedPremium.replace(/[^0-9.-]+/g, ""))) > 0) {
                            setIsNegativePremium(false);
                        } else if (Math.sign(Number(value.requestedPremium.replace(/[^0-9.-]+/g, ""))) < 0) {
                            setIsNegativePremium(true);
                        }
                        setPaymentNotification(value)
                        setPaymentNotificationTitles(value.titles)
                        setPaymentNotificationExtraTitles(value.extraTitles)
                        getPeople(value.policyHolderId)
                        setIsLoadingData(false)
                        let policiesArr = []
                        value.titles.map((value, index) => {
                            if (index === 0) {
                                minDate = value.datebill
                            }
                            if (value.datebill < minDate) {
                                minDate = value.datebill
                            }
                            if (!policiesArr.includes(value.policy_id)) {
                                policiesArr.push(value.policy_id)
                            }
                        })
                        localStorage.setItem('policiesArray', JSON.stringify(policiesArr))
                        setDateBill(minDate)
                    }
                    if ((data.length === (index + 1)) && found === false) {
                        setIsLoadingData(false)
                        setIsAlreadyPaid(true)
                    }
                })
            } else {
                setIsLoadingData(false)
                setIsAlreadyPaid(true)
            }
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const getPeople = (policyHolderId) => {
        setIsLoadingPeople(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            data.map((value, index) => {
                if (policyHolderId === value.policyHolderId) {
                    setPerson(value)
                }
            })
            setIsLoadingPeople(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const turnBack = () => {
        return navigate(-1);
    }

    function reload() {
        window.location.reload();
    }

    const linkToPaymentMethod = () => {
        fetch(`${operationUrl}/createOrUpdate`, {
            method: 'POST',
            body: JSON.stringify({
                type: 'PAYMENT',
                entityId: notificationId
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            return navigate(`/payments/${notificationId}/summary/payment-method?personId=${person.id}`)
        }).catch(() => {
            setErrorPage(true);
        });
    }

    const onClickTitle = (id) => {
        return `/payments/${notificationId}/summary/${id}`;
    }


    return (
        errorPage ?
            <ErrorPage/> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className={isAlreadyPaid ? "nav-top container" : "nav-top container border-bottom"} style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Row className="first-nav-top-row-shadow">
                                <Col className="col-2 float-left">
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px"
                                         alt="back"/>
                                </Col>
                                <Col className="col-8">
                                    <h6 className="header-title ellipsis-1-v2">{t('paymentsSummary.title')}</h6>
                                </Col>
                                <Col className="col-2 float-right">
                                    <img hidden src={dots} className="nav-top-logo float-right cursor-pointer"
                                         height="27px"
                                         alt="dots"/>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '11px'}}>
                                <Col>
                                    {
                                        isLoadingData &&
                                        <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                    }
                                    {
                                        !isLoadingData && !isAlreadyPaid &&
                                        <Fragment>
                                            <Row>
                                                <Col>
                                                    <h6 className={"card-text-content"}><span
                                                        className="ellipsis-1 text-uppercase"
                                                        style={{
                                                            'paddingBottom': '1px',
                                                            'fontSize': '20px'
                                                        }}>{t('paymentsSummary.total')}<span
                                                        className="font-weight-bold blue">{paymentNotification.requestedPremium}</span></span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className={"card-text-content"}><span
                                                        className="ellipsis-1"
                                                        style={{'paddingBottom': '1px'}}>{t('paymentsSummary.expiry-date')}<span
                                                        className="font-weight-bold black"><Moment
                                                        locale="it"
                                                        format="DD/MM/YYYY">{dateBill}</Moment></span></span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className={"card-text-content"}><span
                                                        className="ellipsis-1"
                                                        style={{'paddingBottom': '1px'}}>{t('paymentsSummary.policy-holder-label')}<span
                                                        className="font-weight-bold black">
                                                        {
                                                            ((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                                <span>{person.name}</span> :
                                                                <span>{person.firstName} {person.lastName}</span>
                                                        }
                                                        </span>
                                                    </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row style={{
                                                'marginTop': '59px',
                                                'marginBottom': '27px'
                                            }}>
                                                <Col>
                                                    {paymentNotification.notificationType !== 'SDD' &&
                                                        <Button
                                                            className={isNegativePremium ? "btn-custom-disable text-uppercase" : "btn-custom-3 text-uppercase"}
                                                            variant="outlined"
                                                            size="large"
                                                            type="submit"
                                                            disabled={isNegativePremium || isLoadingData || isLoadingPeople}
                                                            style={{
                                                                'color': themeContext.color.white,
                                                                'width': '138px'
                                                            }}
                                                            onClick={linkToPaymentMethod}
                                                        >{t('paymentsSummary.button')}
                                                        </Button>
                                                    }
                                                    {paymentNotification.notificationType === 'SDD' &&
                                                        <Fragment>
                                                            <Row>
                                                                <Col style={{'marginTop': '-40px'}}>
                                                                    <span className={"card-text-content"}
                                                                          style={{'paddingBottom': '1px'}}>Metodo di pagamento selezionato:</span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col style={{'marginTop': '-20px'}}>
                                                                    <img height="80px" src={slimpayLogo}
                                                                         alt="slimpay-logo"/>
                                                                </Col>
                                                            </Row>
                                                        </Fragment>
                                                    }
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Container className="top-of-the-payment-summary"></Container>
                    <Container style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingLeft': '0',
                        'paddingRight': '0px',
                    }}>
                        <Row>
                            <Col style={{
                                'paddingLeft': '0',
                                'paddingRight': '0px',
                            }}>
                                {isAlreadyPaid ?
                                    <Row>
                                        <span
                                            className={'dialog-form-title-2 align-center p-4'}>{t('paymentsSummary.payment-already-paid')}
                                            <MailTo email={process.env.REACT_APP_MY_WIDE_SUPPORT_EMAIL}
                                                    subject={t('login.email-subject')}
                                                    body={`${t('login.email-body-problem')}%0D%0A%0D%0A`}>
                                                <br/><span className={'font-weight-bold'}
                                                           style={{
                                                               color: themeContext.color.neutral400,
                                                               textTransform: "none",
                                                               textDecoration: "underline"
                                                           }}>
                                                {t('paymentsSummary.contact-us')}
                                            </span>
                                            </MailTo>
                                        </span>
                                    </Row> :
                                    <div className={classes.rootList}>
                                        <List component="nav">
                                            {
                                                paymentNotificationTitles.map((title, index) => (
                                                    <Fragment key={index}>
                                                        {
                                                            index !== 0 && <Divider/>
                                                        }
                                                        <Link style={{'textDecoration': 'none'}}
                                                              to={onClickTitle(title.id)}>
                                                            <ListItem className={classes.listItem} button>
                                                                <Container className="col-list">
                                                                    <Row>
                                                                        <Col className="col-1 mr-0 pr-0">
                                                                            <img src={getBranchIcon(title.branchName)}
                                                                                 alt="branch-icon"
                                                                                 className="branch-icon"/>
                                                                        </Col>
                                                                        <Col className="col-8 float-left">
                                                                            <h6 className={classes.cardTextMid}>{title.branchName}</h6>
                                                                        </Col>
                                                                        <Col className="col-3">
                                                                            <ListItemIcon
                                                                                className="justify-content-end float-right mt-1">
                                                                                <img src={goTo} alt="image"
                                                                                     height="20px"/>
                                                                            </ListItemIcon>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row style={{paddingBottom: '4px'}}>
                                                                        <Col className="col-9">
                                                                            <h6 className={classes.cardTextMid}>{t('paymentsSummary.policy-type-label')} {title.titletype}</h6>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <h6 className={"card-text-content"}>
                                                                    <span
                                                                        className="ellipsis-1"
                                                                        style={{'paddingBottom': '1px'}}>{t('paymentsSummary.total')}
                                                                        <span
                                                                            className="font-weight-bold black">{title.premium}
                                                                    </span>
                                                                </span>
                                                                            </h6>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <h6 className={"card-text-content"}>
                                                                    <span
                                                                        className="ellipsis-1"
                                                                        style={{'paddingBottom': '1px'}}>{t('paymentsSummary.company-label')}
                                                                        <span
                                                                            className="font-weight-bold black">{title.company}
                                                                    </span>
                                                                </span>
                                                                            </h6>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <h6 className={"card-text-content"}>
                                                                    <span
                                                                        className="ellipsis-1"
                                                                        style={{'paddingBottom': '1px'}}>{t('paymentsSummary.policy-number-label')}
                                                                        <span
                                                                            className="font-weight-bold black">{title.policynr}
                                                                    </span>
                                                                </span>
                                                                        </h6>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </ListItem>
                                                    </Link>
                                                </Fragment>
                                            ))
                                        }
                                        {
                                            paymentNotificationExtraTitles && paymentNotificationExtraTitles.map((extraTitle, index) => (
                                                <Fragment key={index}>
                                                    <Divider/>
                                                    <Link style={{'textDecoration': 'none', 'cursor': 'initial'}}>
                                                        <ListItem className={classes.listItem}
                                                                  style={{'cursor': 'initial'}} button>
                                                            <Container className="col-list">
                                                                <Row>
                                                                    <Col className="col-9">
                                                                        <h6 className={classes.cardTextMid}>{t('paymentsSummary.policy-type-extra-title')}</h6>
                                                                    </Col>
                                                                    <Col className="col-3">
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <h6 className={"card-text-content"}>
                                                                    <span
                                                                        className="ellipsis-1"
                                                                        style={{'paddingBottom': '1px'}}>{t('paymentsSummary.total')}
                                                                        <span
                                                                            className="font-weight-bold black">{extraTitle.premium}
                                                                        </span>
                                                                    </span>
                                                                            </h6>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </ListItem>
                                                        </Link>
                                                    </Fragment>
                                                ))
                                            }
                                        </List>
                                    </div>
                                }


                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
    )
}
