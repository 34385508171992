import * as idb from "idb";
import * as React from "react";
import moment from "moment";
import {ClientJS} from "clientjs";

const client = new ClientJS();
const _userAgent = navigator.userAgent;

function base64url_encode(buffer) {
    return btoa(Array.from(new Uint8Array(buffer), b => String.fromCharCode(b)).join(''))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}

function base64url_decode(value) {
    const m = value.length % 4;
    return Uint8Array.from(atob(
        value.replace(/-/g, '+')
            .replace(/_/g, '/')
            .padEnd(value.length + (m === 0 ? 0 : 4 - m), '=')
    ), c => c.charCodeAt(0))
}

const b64toFile = (b64Data, filename, contentType) => {
    let sliceSize = 512;
    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new File(byteArrays, filename, {type: contentType});
}

const getElementFromIndexedDB = (key) => {
    const dbPromise = idb.openDB('appDB', 1, {
        upgrade(upgradeDb) {
            if (!upgradeDb.objectStoreNames.contains(`${key}`)) {
                upgradeDb.createObjectStore(`${key}`, {keyPath: 'key'});
            }
        }
    });
    return dbPromise.then((db) => {
        let tx = db.transaction(`${key}`, 'readwrite').objectStore(`${key}`);
        return tx.get(`${key}`)
    })
}

const putElementFromIndexedDB = (key, value) => {
    const dbPromise = idb.openDB('appDB', 1, {
        upgrade(upgradeDb) {
            if (!upgradeDb.objectStoreNames.contains(`${key}`)) {
                upgradeDb.createObjectStore(`${key}`, {keyPath: 'key'});
            }
        }
    });
    dbPromise.then((db) => {
        let tx = db.transaction(`${key}`, 'readwrite').objectStore(`${key}`);
        tx.put({key: `${key}`, value: value});
    });
}

const deleteElementFromIndexedDB = (key) => {
    const dbPromise = idb.openDB('appDB', 1, {
        upgrade(upgradeDb) {
            if (!upgradeDb.objectStoreNames.contains(`${key}`)) {
                upgradeDb.createObjectStore(`${key}`, {keyPath: 'key'});
            }
        }
    });
    dbPromise.then((db) => {
        let tx = db.transaction(`${key}`, 'readwrite').objectStore(`${key}`);
        tx.delete(`${key}`);
    });
}

function supportsInstall(): boolean {
    return 'relList' in HTMLLinkElement.prototype && document.createElement('link').relList.supports('manifest') && 'onbeforeinstallprompt' in window || 'BeforeInstallPromptEvent' in window || 'setAppBadge' in navigator;
}

function isOSX(): boolean {
    return /(Mac|iPhone|iPod|iPad)/i.test(_userAgent);
}

function isMac(): boolean {
    return _userAgent.toUpperCase().indexOf('MAC') >= 0 && !/(iPhone|iPod|iPad)/i.test(_userAgent);
}

function isIos(): boolean {
    return /(iPhone|iPod|iPad)/i.test(_userAgent);
}

function isIpad(): boolean {
    return (/(Macintosh)/i.test(_userAgent) && (navigator.maxTouchPoints > 1));
}

function isSafari(): boolean {
    return !!_userAgent.match(/Version\/[\d\.]+.*Safari/);
}

function isStandalone(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches;
}

function getClientDatas() {
    let clientInfo = {};
    clientInfo.userAgent = _userAgent;
    clientInfo.softwareVersion = client.getSoftwareVersion();
    clientInfo.OS = client.getOS();
    clientInfo.OSVersion = client.getOSVersion();
    clientInfo.device = client.getDevice();
    clientInfo.deviceType = client.getDeviceType();
    clientInfo.deviceVendor = client.getDeviceVendor();
    clientInfo.screenPrint = client.getScreenPrint();
    clientInfo.timeZone = client.getTimeZone();
    clientInfo.language = client.getLanguage();
    clientInfo.supportsInstall = supportsInstall();
    clientInfo.isStandalone = isStandalone();
    clientInfo.myWideEnv = process.env.REACT_APP_ENV;
    clientInfo.myWideVersion = process.env.REACT_APP_VERSION;
    return clientInfo;
}
const regexPassword = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–[\]{}:;',?/*~$^+=<>]).{8,32}$/;

export {
    base64url_encode,
    base64url_decode,
    getElementFromIndexedDB,
    putElementFromIndexedDB,
    deleteElementFromIndexedDB,
    b64toFile,
    supportsInstall,
    isOSX,
    isMac,
    isIos,
    isIpad,
    isSafari,
    isStandalone,
    getClientDatas,
    regexPassword
};