import React, {Fragment, useContext, useEffect, useState} from 'react';
import themeContext from "../ThemeContext.js";
import detailBarPlaceholder from "../../../images/detailBar-placeholder.svg";
import exportIcon from "../../../images/desktop/export.svg";
import {useTranslation} from "react-i18next";
import {
    getPersonInfo,
    getPolicyDocument,
    getPolicyTitlesHistory,
    getSignedCopy,
    setStatusString
} from "../../service/DashboardService.js";
import {StatusLabel, StyledDivider} from "../../styles/DesktopStyles.js";
import Divider from "@mui/material/Divider";
import {styled} from "@mui/material/styles";
import moment from "moment";
import List from "@mui/material/List";
import {ListItem, ListItemButton} from "@mui/material";
import {DetailBarContext} from "../../middleware/DetailBarContext.js";
import ListItemIcon from "@mui/material/ListItemIcon";
import contractListIcon from "../../../images/contracts-icon.svg";
import ListItemText from "@mui/material/ListItemText";
import goTo from "../../../images/goto-icon.svg";
import claimsList from "../../../images/claims-icon.svg";
import {Link, useNavigate} from "react-router-dom";
import historyTitle from "../../../images/history-titles-icon.svg";
import signedCopyIcon from "../../../images/signed-copy-icon.svg";
import {CARD_TYPE} from "./ListCard.js";

const PolicyDetail = ({policy}) => {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const tPrefix = 'policyDetail';
    const tPrefixDesktop = 'desktop.policyDetail';
    const {active, activeDetailBar, data, updateData, handleLoading} = useContext(DetailBarContext);
    const token = sessionStorage.getItem('token')
    const [lastTitle, setLastTitle] = useState([])
    const [hasLastTitleDocuments, setHasLastTitleDocuments] = useState([])
    const [hasSignedCopy, setHasSignedCopy] = useState(false)
    const [policyDocumentsNr, setPolicyDocumentsNr] = useState(false)
    const [titlesNr, setTitlesNr] = useState(0);
    const [person, setPerson] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const SpanKey = styled('span')(({theme}) => ({
        width: '36%',
        color: themeContext.color.neutral400,
        fontSize: '14px',
        textAlign: 'left'
    }));

    const SpanValue = styled('span')(({theme}) => ({
        width: '64%',
        color: themeContext.color.neutral600,
        fontSize: '14px',
        textAlign: 'left'
    }));

    const StyledListItemButton = styled(ListItemButton)(({theme}) => ({
        width: `100%`,
        borderRadius: `16px`,
        padding: `4px 0 4px 8px`,
        height: `40px`
    }));

    const StyledListItemText = styled(ListItemText)(({theme, main}) => ({
        color: main ? themeContext.color.main : themeContext.color.neutral400,
        textDecoration: `none`
    }));

    const ListItemIconRight =
        <ListItemIcon className="justify-content-end">
            <img src={goTo} alt="image" height="20px"/>
        </ListItemIcon>;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                if (policy.lastTitle.length > 0) {
                    policy.lastTitle.map((value, index) => {
                        console.log('last title', value)
                        setLastTitle(value);
                        getSignedCopy(token, value.id).then(res => {
                            if (res) {
                                setHasLastTitleDocuments(prevState => {
                                    let newState = [...prevState];
                                    newState[value.id] = Object.keys(res).length > 0;
                                    return newState;
                                });
                                res.map((value, index) => {
                                    if (value.type === "copia_da_restituire") {
                                        setHasSignedCopy(true)
                                    }
                                })
                            }
                        })
                    })
                }
                await Promise.all([
                    getPolicyDocument(token, policy.id).then(res => setPolicyDocumentsNr(res.length)),
                    getPolicyTitlesHistory(token, policy.id).then(res => setTitlesNr(res.length)),
                    getPersonInfo(token, policy.personId).then(res => setPerson(res)),
                ])
            } catch (e) {
                console.log('error', e)
            } finally {
                setIsLoading(false)
            }
        }
        if (policy) {
            fetchData();
        }
    }, [policy]);

    const linkToHistoryTitles = () => {
        updateData(null)
        return navigate(`/dashboard/policies/${policy.id}/titlesHistory`);
    }

    const linkToDocuments = () => {
        updateData(null)
        return navigate(`/dashboard/policies/${policy.id}/documents`);
    }

    const linkToTitleDocuments = (titleId) => {
        return navigate(`/dashboard/policies/${policy.id}/${titleId}`);
    }

    const linkToDocumentsToSigned = () => {
        updateData(null)
        return navigate(`/dashboard/policies/${policy.id}/documents-to-signed`);
    }

    const linkToClaims = () => {
        updateData(null)
        return navigate(`/dashboard/policies/${policy.id}/policyClaims`);
    }

    return (
        <Fragment>
            {isLoading && <div className="background-loading-spinner spinner-desktop"></div>}
            {!isLoading &&
                <Fragment>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <span style={{fontWeight: 700, fontSize: '18px', color: themeContext.color.neutral600}}>
                            Dettaglio:
                        </span>
                    </div>
                    {policy === null ?
                        <Fragment>
                            <h6 style={{
                                fontWeight: 600,
                                fontSize: '20px',
                                color: themeContext.color.neutral200,
                                margin: '20% auto'
                            }}>
                                {t(`${tPrefixDesktop}.Select a policy to view details and make actions`)}
                            </h6>
                            <img style={{position: "absolute", bottom: 90, right: 0}} src={detailBarPlaceholder}
                                 alt={'document-placeholder'}/>
                        </Fragment>
                        : <Fragment>
                            <div style={{gap: '16px', width: '100%'}}>
                                <span style={{
                                    display: "flex",
                                    justifyContent: 'start',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    color: themeContext.color.neutral400,
                                    textAlign: "left"
                                }}>{policy.object}</span>
                                <div style={{display: "flex", justifyContent: 'end', gap: '8px'}}>
                                    <SpanKey className={`w-auto`}>Stato: </SpanKey>
                                    <StatusLabel color={policy.status} type={CARD_TYPE.POLICY}>
                                        {t(setStatusString(policy.status))}
                                    </StatusLabel>
                                </div>
                            </div>
                            <StyledDivider/>
                            <div className={'d-flex justify-content-start align-items-center flex-column w-100'}
                                 style={{gap: '4px'}}>
                                <div className={'d-flex justify-content-start align-items-start w-100'}
                                     style={{minHeight: 32}}>
                                    <SpanKey>{t(`${tPrefix}.expiration-label`)}</SpanKey>
                                    <SpanValue>{moment(policy.insuranceend).format('DD/MM/YYYY')}</SpanValue>
                                </div>
                                <div className={'d-flex justify-content-start align-items-start w-100'}
                                     style={{minHeight: 32}}>
                                    <SpanKey>{t(`${tPrefix}.company-label`)}</SpanKey>
                                    <SpanValue>{policy.company}</SpanValue>
                                </div>
                                <div className={'d-flex justify-content-start align-items-start w-100'}
                                     style={{minHeight: 32}}>
                                    <SpanKey>{t(`${tPrefix}.person-label`)}</SpanKey>
                                    <SpanValue>{person.name}</SpanValue>
                                </div>
                                <div className={'d-flex justify-content-start align-items-start w-100'}
                                     style={{minHeight: 32}}>
                                    <SpanKey>{t(`${tPrefix}.policy-number-label`)}</SpanKey>
                                    <SpanValue>{policy.policynr}</SpanValue>
                                </div>
                            </div>
                            {policy.lastTitle.length > 0 &&
                                <Fragment>
                                    <StyledDivider/>
                                    <span style={{
                                        fontWeight: 700,
                                        fontSize: '18px',
                                        color: themeContext.color.neutral600
                                    }}>
                                        Titolo attivo
                                    </span>
                                    <div
                                        className={'d-flex justify-content-start align-items-center flex-column w-100'}>
                                        <div className={'d-flex justify-content-start align-items-start w-100'}
                                             style={{minHeight: 32}}>
                                            <SpanKey>Tipologia: </SpanKey>
                                            <SpanValue>{lastTitle.titletype}</SpanValue>
                                        </div>
                                        <div className={'d-flex justify-content-start align-items-start w-100'}
                                             style={{minHeight: 32}}>
                                            <SpanKey>{t(`${tPrefix}.date-bill`)}</SpanKey>
                                            <SpanValue>{moment(lastTitle.datebill).format('DD/MM/YYYY')}</SpanValue>
                                        </div>
                                        <div className={'d-flex justify-content-start align-items-start w-100'}
                                             style={{minHeight: 32}}>
                                            <SpanKey>{t(`${tPrefix}.expiration-label`)}</SpanKey>
                                            <SpanValue>{moment(lastTitle.dateexpiry).format('DD/MM/YYYY')}</SpanValue>
                                        </div>
                                        <div className={'d-flex justify-content-start align-items-start w-100'}
                                             style={{minHeight: 32}}>
                                            <SpanKey>{t(`${tPrefix}.premium`)}</SpanKey>
                                            <SpanValue>{lastTitle.premium}</SpanValue>
                                        </div>
                                        {
                                            hasLastTitleDocuments[lastTitle.id] &&
                                            <StyledListItemButton onClick={() => linkToTitleDocuments(lastTitle.id)}>
                                                <ListItemIcon>
                                                    <img src={contractListIcon} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <StyledListItemText main="true"
                                                    primary={`${t('policyDetail.title-documents')}`}/>
                                                {ListItemIconRight}
                                            </StyledListItemButton>
                                        }
                                    </div>
                                </Fragment>
                            }
                            {
                                <Fragment>
                                    <StyledDivider/>
                                    <List component="nav" className={`w-100`}>
                                        {policyDocumentsNr > 0 &&
                                            <StyledListItemButton onClick={linkToDocuments}>
                                                <ListItemIcon>
                                                    <img src={contractListIcon} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <StyledListItemText
                                                    primary={`${t('policyDetail.list-documents')} (${policyDocumentsNr})`}/>
                                                {ListItemIconRight}
                                            </StyledListItemButton>
                                        }
                                        <StyledListItemButton onClick={linkToClaims}>
                                            <ListItemIcon>
                                                <img src={claimsList} alt="image" width="28px"/>
                                            </ListItemIcon>
                                            <StyledListItemText primary={t('policyDetail.list-claims')}/>
                                            {ListItemIconRight}
                                        </StyledListItemButton>
                                        {
                                            titlesNr > 0 &&
                                            <StyledListItemButton onClick={linkToHistoryTitles}>
                                                <ListItemIcon>
                                                    <img src={historyTitle} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <StyledListItemText
                                                    primary={`${t(`policyDetail.list-title-history`)} (${titlesNr})`}/>
                                                {ListItemIconRight}
                                            </StyledListItemButton>
                                        }
                                        {
                                            hasSignedCopy && policy.hasPending === false &&
                                            <StyledListItemButton onClick={linkToDocumentsToSigned}>
                                                <ListItemIcon>
                                                    <img src={signedCopyIcon} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <StyledListItemText
                                                    primary={t('policyDetail.title-documents-to-signed')}/>
                                                {ListItemIconRight}
                                            </StyledListItemButton>
                                        }
                                    </List>
                                </Fragment>
                            }
                        </Fragment>
                    }
                </Fragment>
            }

        </Fragment>
    );
};

export default PolicyDetail;
