import React, {useEffect, useState} from 'react'
import Dialog from "@mui/material/Dialog";
import {useTranslation} from "react-i18next";
import {Col, Row} from "react-bootstrap";
import back from '../../images/back-icon.svg';
import DialogTitle from "@mui/material/DialogTitle";
import Container from "react-bootstrap/Container";
import {TransparentBackdrop} from "../styles/Styles.js";
import document from "../../images/documents-icon.svg";
import payment from "../../images/payment-icon.svg";
import calendar from "../../images/calendar-icon.svg";
import Moment from "react-moment";
import Divider from "@mui/material/Divider";
import ReactHtmlParser from "react-html-parser";
import DOMPurify from "dompurify";
import {StyledFixedBigDialog} from "../styles/DesktopStyles.js";
import {publish} from "../service/EventService.js";


const MessageDetailDialog = ({
                                 open,
                                 handleClose,
                                 id,
                                 type,
                                 isRequest,
                                 handleCloseParent
                             }) => {

    const {t} = useTranslation();
    const getDetailsUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/${type}`;
    const token = sessionStorage.getItem('token');
    const [message, setMessage] = useState(null);
    const getDetailById = () => {
        fetch(`${getDetailsUrl}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setMessage(data);
            if (!data.isRead) {
                fetch(`${getDetailsUrl}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        isImportant: data.isImportant,
                        isRead: true,
                    }),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    publish('reloadMessages', {});
                    return response.json();
                }).catch(() => {
                });
            }
        })
    }

    useEffect(() => {
        getDetailById()
    }, []);

    return (
        <Dialog
            open={open}
            components={{Backdrop: TransparentBackdrop}}
            keepMounted
            onClose={() => {
                handleCloseParent() &&
                handleClose()
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth={'sm'}
            PaperProps={{style: StyledFixedBigDialog}}>>
            <Container style={{
                position: 'fixed',
                zIndex: '1',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                backgroundColor: 'white',
                padding: '0',
                margin: '0',
                width: '600px'
            }}>
                <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                             style={{textAlign: 'center', paddingBottom: '0px'}}>
                    <Row>
                        <Col className="col-1 float-left"
                             style={{
                                 'paddingLeft': '14px'
                             }}>
                            <img src={back}
                                 className="nav-top-logo float-left cursor-pointer"
                                 height="32px"
                                 alt="back" onClick={handleClose}/>
                        </Col>
                        <Col className="float-left" style={{
                            'paddingLeft': '11px',
                            'alignSelf': 'center'
                        }}>
                            <h6 className="header-title ellipsis-1-v2"
                                style={{
                                    'paddingBottom': '3px',
                                    'textAlign': 'left',
                                    'fontSize': '22px'
                                }}
                            >{isRequest ? t('desktop.messages.detail-request-title') : t('desktop.messages.detail-title')}</h6>
                        </Col>
                    </Row>
                    {message && <Row className="pt-4 w-100" style={{
                        paddingBottom: '15px',
                    }}>
                        <Col style={{
                            alignSelf: 'center'
                        }}>
                            <h2 className="message-title" style={{marginBottom: 0}}>{message.title}</h2>
                        </Col>
                        <Col className="col-2 float-right">
                            {message.type === 'DOCUMENT' &&
                                <img src={document} height={'50px'}
                                     style={{'float': 'right'}}
                                     alt="document"/>}
                            {message.type === 'PAYMENT' &&
                                <img src={payment} height={'50px'} alt="payment"/>}
                            {message.type === 'CALENDAR' &&
                                <img src={calendar} height={'50px'}
                                     alt="calendar"/>}
                        </Col>
                    </Row>}
                    <Divider/>
                </DialogTitle>
            </Container>
            {message && <Container style={{marginTop: '45px', paddingLeft: '24px'}}>
                <Row className="float-left w-100" style={{marginTop: '90px'}}>
                    <Col>
                        <h6 className="font-weight-bold message-original-date"
                            style={{'textAlign': 'left'}}>
                            <Moment locale="it"
                                    format="DD MMMM YYYY">{message.originalDate}</Moment>
                        </h6>
                    </Col>
                </Row>
                {/*<Row className="w-100">*/}
                {/*    <Col>*/}
                {/*        <h6>{message.content}</h6>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row className="w-100">
                    <Col>
                        <h6 className="message-content">
                            {ReactHtmlParser(DOMPurify.sanitize(message.html))}
                        </h6>
                    </Col>
                </Row>
            </Container>}
        </Dialog>
    )
}
export default MessageDetailDialog;
