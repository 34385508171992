import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../../images/back-icon.svg";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from '@mui/material/Divider';
import {makeStyles} from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import AuthContextMiddleware from "../../middleware/AuthContextMiddleware.js";
import baseMaleAvatar from "../../../images/male-icon.svg";
import baseFemaleAvatar from "../../../images/female-icon.svg";
import slimpayBlue from "../../../images/slimpay-logo-blue.svg";
import Header from "../../components/mobile/Header.js";
import baseCompanyAvatar from "../../../images/company-avatar.svg";
import {publish} from "../../service/EventService.js";
import {useTranslation} from "react-i18next";
import themeContext from "../../components/ThemeContext.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {ExternalPageLayout, StyledPageCard, StyledPageLayout, StyledTitlePage} from "../../styles/DesktopStyles.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";

const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100% !important',
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "0 0 0 0 !important",
    },
    listItem: {
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '14px !important',
        paddingBottom: '14px !important',
    },
    listParagraph: {
        backgroundColor: `${themeContext.color.neutral10} !important`,
        color: `${themeContext.color.neutral400} !important`,
        fontSize: "12px !important",
        paddingTop: '10px !important',
        paddingBottom: '14px !important',
        paddingLeft: '38px !important',
        paddingRight: '8px !important',
    },
    avatarList: {
        width: "40px !important",
        height: "40px !important",
    },
}));

export default function PaymentsStatus({layout}) {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (navigator.onLine) {
            if (!searchMode) {
                getPeople();
            }

            if (searchMode) {
                const delayDebounceFn = setTimeout(() => {
                    searchPeople(searchValue)
                }, 1000)
                return () => clearTimeout(delayDebounceFn)
            }

        }
    }, [searchValue]);

    const {open} = useContext(SidebarContext);
    const classes = useStyles();
    const [people, setPeople] = useState([]);
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const searchPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/search`;
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const token = sessionStorage.getItem('token');
    const [searchMode, setSearchMode] = useState(false);
    const [errorPage, setErrorPage] = useState(false);


    const getPeople = () => {
        setIsLoadingData(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            data.map((item, index) => {
                if (item.status !== -1) {
                    setPeople(current => [...current, item])
                }
            })
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
            case 5:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    const searchPeople = (text) => {
        setIsLoadingData(true)
        fetch(`${searchPeopleUrl}?text=${text}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPeople([]);
            data.map((item, index) => {
                if (item.status !== -1) {
                    setPeople(current => [...current, item])
                }
            })
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function turnBack() {
        return navigate(-1);
    }

    const setGrayScale = (value) => {
        switch (value) {
            case 1:
                return 'grayscale(1)';
            case 2:
            case 3:
            case 4:
                return '';
        }
    }

    function reload() {
        window.location.reload();
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {
                    errorPage ?
                        <ErrorPage/> :
                        <Fragment>
                            <StyledPageLayout className="w-100 mx-4">
                                <StyledTitlePage>
                                    <Col className="col-2 float-left"></Col>
                                    {
                                        !searchMode && <Col className="col-8">
                                            <span className="header-title ellipsis-1-v2">{t('paymentsStatus.title')}</span>
                                        </Col>
                                    }
                                    <Col className="col float-right"></Col>
                                </StyledTitlePage>

                                {isLoadingData ?
                                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                    :
                                    <Row style={{
                                        'paddingLeft': '0',
                                        'paddingRight': '0px',
                                    }}>
                                        <Col style={{
                                            'paddingLeft': '0',
                                            'paddingRight': '0px'
                                        }}>
                                            <div className={classes.rootList}>
                                                <List component="nav" aria-label="main mailbox folders"
                                                      style={{paddingBottom: "0px"}}>
                                                    {
                                                        people.map((value, index) => (
                                                            <Fragment key={index}>
                                                                <ListItem className={classes.listItem} button
                                                                    //onClick={() => onClickPeople(value.id)}
                                                                >
                                                                    <ListItemIcon>
                                                                        {
                                                                            value.image === null && value.gender === 'M' &&
                                                                            <Avatar src={baseMaleAvatar}
                                                                                    className={classes.avatarList}
                                                                                    style={{
                                                                                        filter: `${setGrayScale(value.status)}`,
                                                                                    }}/>
                                                                        }
                                                                        {
                                                                            value.image === null && value.gender === 'F' &&
                                                                            <Avatar src={baseFemaleAvatar}
                                                                                    className={classes.avatarList}
                                                                                    style={{
                                                                                        filter: `${setGrayScale(value.status)}`,
                                                                                    }}/>
                                                                        }
                                                                        {
                                                                            value.image === null && value.gender === 'C' &&
                                                                            <Avatar src={baseCompanyAvatar}
                                                                                    className={classes.avatarList}
                                                                                    style={{
                                                                                        filter: `${setGrayScale(value.status)}`,
                                                                                    }}/>
                                                                        }
                                                                        {
                                                                            value.image === null && value.gender === null &&
                                                                            <Avatar src={baseMaleAvatar}
                                                                                    className={classes.avatarList}
                                                                                    style={{
                                                                                        filter: `${setGrayScale(value.status)}`,
                                                                                    }}/>
                                                                        }
                                                                        {
                                                                            value.image &&
                                                                            <Avatar src={value.image}
                                                                                    className={classes.avatarList}
                                                                                    style={{
                                                                                        filter: `${setGrayScale(value.status)}`,
                                                                                    }}/>
                                                                        }
                                                                    </ListItemIcon>
                                                                    {((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                                        <ListItemText>{value.name}</ListItemText> :
                                                                        <ListItemText>{value.firstName} {value.lastName}</ListItemText>}
                                                                    {
                                                                        (value.slimPayMandate || value.slimpayIsPending) &&
                                                                        <ListItemIcon className="justify-content-end">
                                                                            <img src={slimpayBlue} alt="image"
                                                                                 height="40px"/>
                                                                        </ListItemIcon>
                                                                    }
                                                                    {
                                                                        !value.slimPayMandate && !value.slimpayIsPending && value.payableNotificationsNr > 0 &&
                                                                        <ListItemIcon className="justify-content-end">
                                                                <span onClick={() => {
                                                                    publish('navigateToPayments');
                                                                    sessionStorage.setItem('root', '/payments');
                                                                    navigate('/payments')
                                                                }}
                                                                      style={{
                                                                          textDecoration: 'underline',
                                                                          color: themeContext.color.red,
                                                                          fontWeight: 'bold'
                                                                      }}>{t('paymentsStatus.to-pay')} {value.payableNotificationsNr}</span>
                                                                        </ListItemIcon>
                                                                    }
                                                                    {
                                                                        !value.slimPayMandate && !value.slimpayIsPending && value.payableNotificationsNr === 0 &&
                                                                        <ListItemIcon className="justify-content-end">
                                                                <span style={{
                                                                    color: themeContext.color.neutral200,
                                                                    fontWeight: 'bold'
                                                                }}>{t('paymentsStatus.to-pay')} {value.payableNotificationsNr}</span>
                                                                        </ListItemIcon>
                                                                    }
                                                                </ListItem>
                                                                <Divider/>
                                                                {
                                                                    value.slimPayMandate &&
                                                                    <ListItem className={classes.listParagraph}>
                                                            <span>
                                                                {t('paymentsStatus.slimpay-active-1')}<br/><br/><span
                                                                style={{fontStyle: 'oblique'}}>{t('paymentsStatus.slimpay-active-2')}</span>
                                                            </span>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    value.slimpayIsPending && !value.slimPayMandate &&
                                                                    <ListItem className={classes.listParagraph}>
                                                            <span>
                                                                {t('paymentsStatus.slimpay-pending')}
                                                            </span>
                                                                    </ListItem>
                                                                }
                                                            </Fragment>
                                                        ))
                                                    }
                                                </List>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </StyledPageLayout>
                        </Fragment>
                }
            </StyledPageCard>
        </ExternalPageLayout>
    )
}
