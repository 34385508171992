import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {SidebarContext, VIEW} from "../../middleware/SidebarContext.js";
import {styled} from "@mui/material/styles";
import ListCard, {CARD_TYPE} from "../../components/desktop/ListCard.js";
import {drawerWidth} from "../../components/desktop/DetailBar.js";
import themeContext from "../../components/ThemeContext.js";
import {useTranslation} from "react-i18next";
import GridCard from "../../components/desktop/GridCard.js";
import MuiAvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import {Button, tooltipClasses, TooltipProps} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import usersPlaceholder from "../../../images/desktop/user-placeholder.svg";
import {setPersonImage, setStatusColor} from "../../service/DashboardService.js";
import {getClaimsV2} from "../../service/ClaimsService.js";
import {useNavigate} from "react-router-dom";
import {publish} from "../../service/EventService.js";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import AvatarGroup from "../../components/desktop/AvatarGroup.js";
import {DetailBarContext} from "../../middleware/DetailBarContext.js";

const Claims = React.memo(function Claims(props) {
    const {
        people,
        isLoadingPeople,
        handleRenewPolicy,
        handleSignDocumentPolicy,
        handleRegulatePolicy,
        featuredPeoplePolicies,
        otherPeoplePolicies,
        isLoadingOtherPolicies,
        isLoadingFeaturedPolicies,
        otherCardName,
        policyTypes,
        isLoadingPolicyTypes,
        /*selectedAvatarState,
        setSelectedAvatarState,*/
        selectedChipState,
        //setSelectedChipState,
        exportType,
        setExportType,
        selectedRadioCsv,
        handleRadioCsv,
        selectedRadioXlsx,
        handleRadioXlsx,
        selectedRadioPdf,
        handleRadioPdf,
        exportFile,
        handleCheck,
        checked,
        isCheckAll,
        checkAll,
        handleBackCheck,
        isLoading,
        isLoadingScrollbar,


        isLoadingActiveClaims,
        setIsLoadingActiveClaims,
        isLoadingOtherClaims,
        setIsLoadingOtherClaims,
        activeClaims,
        setActiveClaims,
        otherClaims,
        setOtherClaims,
        handleAvatar,
        selectedAvatarState,
        setSelectedAvatarState,


    } = props;

    /*    const titleState = {
            people,
            exportType,
            setExportType,
            selectedRadioCsv,
            handleRadioCsv,
            selectedRadioXlsx,
            handleRadioXlsx,
            selectedRadioPdf,
            handleRadioPdf,
            exportFile,
            handleCheck,
            checked,
            isCheckAll,
            checkAll,
            handleBackCheck,
            isLoading,
            isLoadingPeople,
            isLoadingOtherPolicies
        }*/

    const {open, toggleSidebar, view, setOpenOpenClaimDialog} = useContext(SidebarContext);
    const {t, i18n} = useTranslation();
    const [openCards, setOpenCards] = React.useState(true);
    const root = 'desktop.claims';
    const [showAllChips, setShowAllChips] = useState(false);
    const stickyDivRef = useRef(null);
    const [stickyTop, setStickyTop] = useState('0px');
    const initialStickyHeight = 63;
    const [activeClaim, setActiveClaim] = useState(null);
    const [openClaimMessages, setOpenClaimMessages] = useState(false);
    const [openInProgressCards, setOpenInProgressCards] = React.useState(true);
    const [openOtherCards, setOpenOtherCards] = React.useState(true);

    //main title
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [exportStep, setExportStep] = useState(0);
    //const [isCheckAll, setIsCheckAll] = useState(false);
    //const [checked, setChecked] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    //const [selectedRadioCsv, setSelectedRadioCsv] = useState('');
    //const [selectedRadioXlsx, setSelectedRadioXlsx] = useState('');
    //const [selectedRadioPdf, setSelectedRadioPdf] = useState('');
    //const [isLoading, setIsLoading] = useState(false);
    const token = sessionStorage.getItem('token');
    const exportFileUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/export/policies`;
    const navigate = useNavigate();
    const {updateData} = useContext(DetailBarContext);

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [isLoadingFeaturedPolicies]);

    const updateStickyTop = () => {
        if (stickyDivRef.current) {
            const height = stickyDivRef.current.offsetHeight + initialStickyHeight - 2; // 2px is the empty cards in the grid view
            setStickyTop(`${height}px`);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", updateStickyTop);
        return () => window.removeEventListener("resize", updateStickyTop);
    }, [updateStickyTop]);


    useEffect(() => {
        updateStickyTop()
    }, [showAllChips, isLoadingScrollbar, selectedAvatarState, selectedChipState]);

    useEffect(() => {
        setShowAllChips(false)
    }, [selectedAvatarState]);


    //avatar functions

    const userPlaceholder = (
        <div className={'d-flex flex-row'} style={{gap: '8px'}}>
            <img src={usersPlaceholder} alt={'usersPlaceholder-1'}/>
            <img src={usersPlaceholder} alt={'usersPlaceholder-2'}/>
            <img src={usersPlaceholder} alt={'usersPlaceholder-3'}/>
        </div>
    )

    const handleClaimsV2 = async (token_arg, filter?, personId?, vehicleId?) => {
        if (filter === true) {
            return await getClaimsV2(token_arg, filter, personId, vehicleId);
        } else {
            return await getClaimsV2(token_arg, filter, personId, vehicleId);
        }
    };

    const linkToHistoryClaims = () => {
        return navigate(`/claims/history`);
    }

    const linkToOpenClaims = () => {
        return navigate(`/claims/open`);
    }

    const handleInProgressCards = () => {
        setOpenInProgressCards(current => !current);
    }
    const handleOtherCards = () => {
        setOpenOtherCards(current => !current);
    }

    const ExternalLayout = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginTop: '65px',
        paddingTop: '8px',
        marginLeft: open ? '269px' : '65px',
        marginRight: activeClaim ? `calc(${drawerWidth})` : 0,
        gap: '8px',
        paddingBottom: '8px',
    }));

    const StickyDiv = styled('div')(({top}) => ({
        display: 'flex',
        position: 'sticky',
        top: top,
        zIndex: '99',
        background: themeContext.color.defaultBackground,
        width: '100%',
        flexDirection: 'column',
        gap: '8px',
        paddingLeft: '20px',
        paddingRight: '20px',
    }));

    const GridCardContainer = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        gap: '16px',
        flexWrap: 'wrap',
        justifyContent: 'start'
    }));

    const ListCardContainer = styled('div')(({theme}) => ({
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '16px',
    }));

    const Layout = styled('div')(({theme}) => ({
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
    }));

    // AVATAR STYLES

    const StyleAvatarGroup = styled(MuiAvatarGroup)(({}) => ({
        display: 'flex',
        justifyContent: 'start'
    }));

    const StyledAvatar = styled(Avatar)(({color, selected, isselected, index}) => ({
        opacity: selected && isselected === 'false' ? 0.5 : 1,
        background: isselected === 'true' ? `linear-gradient(to bottom, ${color}, ${color})` :
            `linear-gradient(to bottom, ${themeContext.color.white}, ${themeContext.color.white}, ${themeContext.color.white}, ${color})`,
        marginLeft: index !== 0 ? '-8px !important' : 0,
        zIndex: isselected === 'true' ? '2 !important' : 1,
    }));

    const LightTooltip = styled(({className, ...props}: TooltipProps) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: themeContext.color.white,
            color: themeContext.color.neutral600,
            //boxShadow: theme.shadows[1],
            fontSize: 14,
        },
        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
            {
                marginTop: '-10px',
                marginLeft: '110px',
            },
    }));

    const StyledAvatarDiv = styled('div')(({}) => ({
        display: 'flex',
        alignItems: 'center',
        background: themeContext.color.white,
        borderRadius: '16px',
        marginRight: '24px',
        padding: '8px',
    }));

    // main title styles

    const ContainerTitle = styled('div')(({theme}) => ({
        marginTop: '8px',
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "center",
        gap: '16px',
        width: '100%'
    }));

    const RowTitle = styled('div')(({theme}) => ({
        display: "flex",
        alignItems: 'center',
        gap: '16px'
    }));

    const StyledTitle = styled('span')(({theme}) => ({
        fontSize: '20px',
        fontWeight: 'bold',
        textTransform: "uppercase"
    }));

    const MenuTitle = styled('span')(({theme}) => ({
        fontSize: '20px',
        fontWeight: 'bold',
        color: themeContext.color.neutral600
    }));

    // card title styles

    const ContainerCardTitle = styled('div')(({theme}) => ({
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "center",
        gap: '16px',
        width: '100%',
        paddingTop: '9px',
        paddingBottom: '9px',
    }));

    const StyledCardTitle = styled('span')(({theme}) => ({
        fontSize: '16px',
        fontWeight: 'bold',
        color: themeContext.color.neutral400,
        textTransform: "uppercase"
    }));

    return (
        <ExternalLayout>
            <StickyDiv top={`${initialStickyHeight}px`} ref={stickyDivRef}>

                {/*<MainTitle title={t(`${root}.policies`)} button={t(`${root}.export`)}
                           buttonIcon={exportIcon} {...titleState}/>*/}

                <ContainerTitle>
                    <RowTitle>
                        <StyledTitle>{t(`${root}.title`)}</StyledTitle>
                    </RowTitle>
                    <div className={'d-flex'} style={{gap: 10}}>
                        <Button
                            sx={{borderRadius: '12px'}}
                            variant="outlined"
                            disableElevation
                            onClick={() => publish('openClaimMessageDialog', {})}
                        >{t(`desktop.claims.claims-history-button`)}</Button>
                        <Button
                            sx={{borderRadius: '12px', color: themeContext.color.white, fontWeight: 'bold'}}
                            variant="contained"
                            disableElevation
                            onClick={() => setOpenOpenClaimDialog(true)}
                        >{t(`desktop.claims.open-claims-button`)}</Button>
                    </div>
                </ContainerTitle>

                <AvatarGroup people={people} isLoading={isLoadingScrollbar} selected={selectedAvatarState}
                             onChange={(value) => {
                                 setActiveClaim(null)
                                 updateData(null)
                                 setSelectedAvatarState(value)
                             }}/>


                <div onClick={() => {
                    handleInProgressCards()
                }} style={{
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: "center",
                    gap: '16px',
                    width: '100%',
                    height: '37px',
                    padding: '0',
                    borderRadius: '12px',
                    cursor: 'pointer',
                }}>
                    <ContainerCardTitle>
                        <RowTitle>
                            <StyledCardTitle>{t(`${root}.in progress`)}</StyledCardTitle>
                        </RowTitle>
                    </ContainerCardTitle>
                    {openInProgressCards ? <ExpandMore/> : <ExpandLess/>}
                </div>
            </StickyDiv>
            <Layout>
                {openInProgressCards && (view === VIEW.LIST ?
                    <ListCardContainer>
                        <ListCard type={CARD_TYPE.CLAIM} object={activeClaims} isLoading={isLoadingActiveClaims}
                                  activeCard={activeClaim} setActiveCard={setActiveClaim}
                                  noItemsText={t(`claims.nothing-text-1`)}/>
                    </ListCardContainer> :
                    <GridCardContainer>
                        <GridCard type={CARD_TYPE.CLAIM} object={activeClaims} isLoading={isLoadingActiveClaims}
                                  activeCard={activeClaim} setActiveCard={setActiveClaim}
                                  noItemsText={t(`claims.nothing-text-1`)}/>
                    </GridCardContainer>)
                }
            </Layout>
            <StickyDiv top={stickyTop}>
                <div onClick={() => {
                    handleOtherCards()
                }} style={{
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: "center",
                    width: '100%',
                    borderRadius: '12px',
                    cursor: 'pointer',
                }}>
                    <ContainerCardTitle>
                        <RowTitle>
                            <StyledCardTitle>{t(`${root}.closed claims`)}</StyledCardTitle>
                        </RowTitle>
                    </ContainerCardTitle>
                    {openOtherCards ? <ExpandMore/> : <ExpandLess/>}
                </div>
            </StickyDiv>
            <Layout>
                {openOtherCards && (view === VIEW.LIST ?
                    <ListCardContainer>
                        <ListCard type={CARD_TYPE.CLAIM} object={otherClaims} isLoading={isLoadingOtherClaims}
                                  activeCard={activeClaim} setActiveCard={setActiveClaim}
                                  noItemsText={t(`claims.nothing-text-1`)}/>
                    </ListCardContainer> :
                    <GridCardContainer>
                        <GridCard type={CARD_TYPE.CLAIM} object={otherClaims} isLoading={isLoadingOtherClaims}
                                  activeCard={activeClaim} setActiveCard={setActiveClaim}
                                  noItemsText={t(`claims.nothing-text-1`)}/>
                    </GridCardContainer>)
                }
            </Layout>
        </ExternalLayout>
    )
});

export default Claims;
