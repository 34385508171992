import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../../images/back-icon.svg";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {useTranslation} from 'react-i18next'
import {Button, Link, Slide} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import documentPlaceholder from '../../../images/document-photo-placeholder.svg';
import backdocumentPlaceholder from '../../../images/placeholder-back-document.svg';
import Divider from "@mui/material/Divider";
import Resizer from "react-image-file-resizer";
import exit from "../../../images/exit-icon.svg";
import takingResponsibilityPlaceholder from "../../../images/takingResponsibilityPlaceholder.svg";
import AuthContextMiddleware from "../../middleware/AuthContextMiddleware.js";
import checkboxUnselectedIcon from "../../../images/checkbox-unselected.svg";
import checkboxSelectedIcon from "../../../images/checkbox-selected.svg";
import {useMediaQuery} from "react-responsive";
import PlaceholderScreen from "../../components/PlaceholderScreen.js";
import DialogDoubleAction from "../../components/DialogDoubleAction.js";
import DialogSmall from "../../components/DialogSmall.js";
import CropDialog from "../../components/CropDialog.js";
import themeContext from "../../components/ThemeContext.js";
import {StyledBackdrop} from "../../styles/Styles.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";
import {ExternalPageLayout, StyledPageCard, StyledPageLayout, StyledTitlePage} from "../../styles/DesktopStyles.js";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        'textAlign': 'center !important',
        'lineHeight': '1.3 !important',
        'padding': '16px 24px !important',
        'fontSize': '15px !important',
        'fontWeight': '500 !important'
    },
}));

export default function UploadDocumentToVerify() {
    const {open} = useContext(SidebarContext);
    const getGroupDocumentByPersonIdUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/person`;
    const personInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/person`;
    const getDocumentsForTakingResponsibilityUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/documentForSign`;
    const getPolicyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies`;
    const titleDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/title`;
    const vidiValidateUrl = `https://vidi.empireos.eu/api/v1/core/validate`;
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const {t} = useTranslation();
    const params = useParams();
    const personId = params.personId;
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const documentId = query.get('documentId');
    const policyId = query.get('policyId');
    const titleId = query.get('titleId');
    const isCie = query.get('isCie');
    const skipTakingResponsibility = query.get('skipTakingResponsibility');
    const [openUploadDocumentDialog, setOpenUploadDocumentDialog] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingDialog, setIsLoadingDialog] = useState(false);
    const [isDocument, setIsDocument] = useState(false);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [isPersonalDocuments, setIsPersonalDocuments] = useState(false);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [cropper, setCropper] = useState(null);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [openExitDialog, setOpenExitDialog] = useState(false);
    const cropperRef = useRef(null);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const authCtx = useContext(AuthContextMiddleware);
    const [takingResponsibility, setTakingResponsibility] = useState(!(window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')));
    const [person, setPerson] = useState([]);
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);
    const [checkbox4, setCheckbox4] = useState(false);
    const [takingResponsibilityIdentifyString, setTakingResponsibilityIdentifyString] = useState("")
    const [documents, setDocuments] = useState([])
    const [policyDocument, setPolicyDocument] = useState([])
    const width_min = useMediaQuery({
        query: '(max-width: 522px)'
    })
    const [openFallbackDialog, setOpenFallbackDialog] = useState(false);
    const [openSizeExceededDialog, setOpenSizeExceededDialog] = useState(false);
    const [openTypeNotSupportedDialog, setOpenTypeNotSupportedDialog] = useState(false);
    const [openSignaturePermissionDialog, setOpenSignaturePermissionDialog] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getPerson();
            getDocumentPolicy();
            //getGroupDocumentByPersonId();
            if (skipTakingResponsibility === 'true') {
                setTakingResponsibility(false)
            }
            if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
                if (authCtx.backReqImageBase64 && authCtx.backReqImage) {
                    //console.log('immagini presenti')
                } else {
                    //console.log('immagini non presenti')
                    setOpenFallbackDialog(true)
                }
            }
        }
    }, []);

    const getGroupDocumentByPersonId = () => {
        setIsLoadingDialog(true)
        fetch(`${getGroupDocumentByPersonIdUrl}/${personId}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(res => {
                    throw new Error(res)
                })
            }
        }).then((data) => {
            setIsPersonalDocuments(true);
            setIsLoadingDialog(false)
        }).catch((e) => {
            if (e.status !== 500) {
                setIsPersonalDocuments(false);
                setIsLoadingDialog(false)
            } else {
                setErrorPage(true)
            }
        });
    }

    const getDocumentPolicy = () => {
        fetch(`${getPolicyUrl}/${policyId}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((res) => {
            if (res) {
                return res.json();
            }
        }).then((res) => {
            if (res !== null) {
                getTitleDocument(titleId)
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const getTitleDocument = (titleId) => {
        fetch(titleDocumentUrl, {
            method: 'POST', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({titleId: titleId})
        }).then((res) => {
            if (res) {
                return res.json();
            }
        }).then((res) => {
            if (res !== null) {
                res.map((value) => {
                    if (value.type === 'copia_da_restituire') {
                        setPolicyDocument(current => [...current, value])
                    }
                })
            }
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function getPerson() {
        setIsLoadingData(true)
        fetch(`${personInfoUrl}/${personId}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setPerson(data);
                if (data.firstName === null && data.lastName === null) {
                    setTakingResponsibilityIdentifyString(`${t('uploadDocumentToVerify.taking-responsibility-identity-1')} ${data.name} ${t('uploadDocumentToVerify.taking-responsibility-identity-2')}`)
                } else {
                    setTakingResponsibilityIdentifyString(`${t('uploadDocumentToVerify.taking-responsibility-identity-1')} ${data.firstName} ${data.lastName} ${t('uploadDocumentToVerify.taking-responsibility-identity-2')}`)
                }
            }
            getDocumentsForTakingResponsibility();
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const getDocumentsForTakingResponsibility = () => {
        fetch(getDocumentsForTakingResponsibilityUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setDocuments(data);
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        })
    }

    function reload() {
        window.location.reload();
    }

    const turnBack = () => {
        if (!window.location.href.includes('uploadFront')) {
            if (takingResponsibility === false) {
                setTakingResponsibility(true)
            } else {
                return navigate('/signing');
            }
        } else {
            return navigate('/signing');
        }
    }

    const handleCloseUploadDocumentDialog = () => {
        setOpenUploadDocumentDialog(false)
    }

    const handleInputImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    const navigateToDocumentSelection = () => {
        return navigate(`/signing/${personId}/upload/personalDocuments?documentId=${documentId}`)
    }

    const navigateToPersonalDocuments = () => {
        return navigate(`/signing/${personId}/upload/personalDocuments?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`);
    }

    const handleOnPendingImage = async (e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            if (file && !file.type.includes('image')) {
                throw 'File not supported';
            }
            if (file && file.size > 20000000) {
                throw 'Maximum upload size exceeded';
            }
            const imageResized = await resizeFile(file)
            setOnPendingImage(imageResized);
            setOpenCropDialog(true)
        } catch (err) {
            console.log(err)
            if (err === 'Maximum upload size exceeded') {
                setOpenSizeExceededDialog(true)
            } else if (err === 'File not supported') {
                setOpenTypeNotSupportedDialog(true)
            }
        }
    };

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            if (window.location.href.includes('uploadBack') && !window.location.href.includes('uploadFront')) {
                //chiamata OCR
                setOpenCropDialog(false)
                setIsLoadingData(true)
                vidiValidate(base64_string)
            } else {
                authCtx.storeReqImage(cropImage)
                authCtx.storeReqImageBase64(base64_string)
                if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
                    return navigate(`/signing/${personId}/uploadBack/uploadFront/identify?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=${isCie}`)
                } else {
                    return navigate(`/signing/${personId}/upload/identify?documentId=${documentId}`)
                }
            }
        }
    }

    const base64toBlob = (b64Data, contentType = 'image/jpeg', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: contentType});
    }

    const vidiValidate = (document) => {
        authCtx.storeBackReqImageBase64(document)
        authCtx.storeBackReqImage(base64toBlob(document))
        const formData = new FormData();
        sessionStorage.setItem('ocr_result', JSON.stringify({'codice_fiscale': 'ND'}));
        formData.append("image", base64toBlob(document))
        fetch(vidiValidateUrl, {
            method: 'POST',
            headers: {
                'api-token': process.env.REACT_APP_VIDI_AUTH_TOKEN,
            },
            body: formData,
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                console.log('error')
                setIsLoadingData(false)
                return navigate(`/signing/${personId}/uploadBack/uploadFront?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=false`)
            }
        }).then((res) => {
            console.log(res.status)
            setIsLoadingData(false)
            //todo assegnare response in sessionstorage
            //sessionStorage.setItem('ocr_result', JSON.stringify(json));

            //if (res.text === 'ID found') {
            return navigate(`/signing/${personId}/uploadBack/uploadFront?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=true`)
        }).catch((e) => {
            setIsLoadingData(false)
            return navigate(`/signing/${personId}/uploadBack/uploadFront?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=false`)
        });
    }

    const handleClickCheckboxes = (index) => {
        switch (index) {
            case 0:
                setCheckbox1(current => !current)
                break;
            case 2:
                setCheckbox2(current => !current)
                break;
        }
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {
                    <Fragment>
                        {errorPage && <ErrorPage/>}
                        {
                            !errorPage && takingResponsibility &&
                            <div className="w-100 px-4 linear-gradient">
                                <div style={{'opacity': '1'}}>
                                    <StyledTitlePage>
                                        <Col className="col-1 float-left"></Col>
                                        <Col className="col-10">
                                            <span
                                                className="header-title ellipsis-1-v2">{t('uploadDocumentToVerify.taking-responsibility-title')}</span>
                                        </Col>
                                        <Col className="col-1 float-right">
                                            <img src={exit} onClick={() => setOpenExitDialog(true)}
                                                 className="nav-top-logo float-right cursor-pointer" alt="exit-icon"
                                                 height="27px"/>
                                        </Col>
                                    </StyledTitlePage>
                                    <Row className="pr-0 pl-0">
                                        <Col className="col-list">
                                            {
                                                isLoadingData &&
                                                <div style={{'height': '90vh'}}
                                                     className="background-loading-spinner"></div>
                                            }
                                            {
                                                !isLoadingData &&
                                                <Container>
                                                    <Row style={{'marginTop': '43.5px'}}>
                                                        <Col className="pl-5 pr-5">
                                                            {
                                                                ((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                                    <span style={{
                                                                        'fontSize': '16px',
                                                                        'color': themeContext.color.neutral600,
                                                                        'fontWeight': 'bold'
                                                                    }}>{person.name},</span>
                                                                    :
                                                                    <span style={{
                                                                        'fontSize': '16px',
                                                                        'color': themeContext.color.neutral600,
                                                                        'fontWeight': 'bold'
                                                                    }}>{person.firstName} {person.lastName},</span>
                                                            }
                                                            <br/>
                                                            <span style={{
                                                                'fontSize': '16px',
                                                                'color': themeContext.color.neutral600,
                                                                'fontWeight': 'bold'
                                                            }}>{t('uploadDocumentToVerify.taking-responsibility-subtitle')}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-0">
                                                            <img src={takingResponsibilityPlaceholder}
                                                                 alt="taking-responsibility-placeholder" width="100%"
                                                                 style={{'maxHeight': '340px'}}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col style={width_min ? {} : {marginLeft: '30px'}}>
                                                <span style={{
                                                    fontWeight: '700',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                    color: themeContext.color.neutral400,
                                                    fontSize: '12px'
                                                }}>
                                                    {t('uploadDocumentToVerify.taking-responsibility-info')}
                                                </span>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        documents.map((document, index) => (
                                                            index !== 1 &&
                                                            <Row key={index}>
                                                                <Col className={width_min ? "col-1" : "col-1 pr-0"}
                                                                     style={{marginTop: '13px'}}>
                                                                    {
                                                                        index === 0 && <img className={'cursor-pointer'}
                                                                                            onClick={() => handleClickCheckboxes(index)}
                                                                                            src={checkbox1 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                                                            alt="checkbox"
                                                                                            height="20px"/>
                                                                    }
                                                                    {
                                                                        index === 2 && <img className={'cursor-pointer'}
                                                                                            onClick={() => handleClickCheckboxes(index)}
                                                                                            src={checkbox2 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                                                            alt="checkbox"
                                                                                            height="20px"/>
                                                                    }
                                                                </Col>
                                                                {
                                                                    index !== 1 &&
                                                                    <Col
                                                                        className={width_min ? "col-11" : "col-11 pl-0"}>
                                                                        <Link href={document.url} target="_blank"
                                                                              rel="noopener noreferrer"
                                                                              className="mt-3 float-left login-subtitle cursor-pointer"
                                                                              color="primary"
                                                                              style={{
                                                                                  fontSize: `14px`,
                                                                                  textDecoration: 'none',
                                                                                  color: themeContext.color.main,
                                                                                  textAlign: `left`
                                                                              }}>
                                                                            {document.name}
                                                                        </Link>
                                                                        {index === 0 && <Fragment>
                                                                <span
                                                                    className="mt-3 float-left mr-1 ml-1 font-weight-bold"
                                                                    style={{
                                                                        textDecoration: "none",
                                                                        color: themeContext.color.neutral600,
                                                                        textAlign: 'left',
                                                                        fontSize: '14px'
                                                                    }}>
                                                                    {t('uploadDocumentToVerify.and')}
                                                                </span>
                                                                            {
                                                                                width_min ?
                                                                                    <Link href={documents[1].url}
                                                                                          target="_blank"
                                                                                          rel="noopener noreferrer"
                                                                                          className="float-left login-subtitle cursor-pointer"
                                                                                          color="primary"
                                                                                          style={{
                                                                                              fontSize: `14px`,
                                                                                              textDecoration: 'none',
                                                                                              color: themeContext.color.main,
                                                                                              textAlign: `left`
                                                                                          }}>
                                                                                        {documents[1].name}
                                                                                    </Link>
                                                                                    :
                                                                                    <Link href={documents[1].url}
                                                                                          target="_blank"
                                                                                          rel="noopener noreferrer"
                                                                                          className="mt-3 float-left login-subtitle cursor-pointer"
                                                                                          color="primary"
                                                                                          style={{
                                                                                              fontSize: `14px`,
                                                                                              textDecoration: 'none',
                                                                                              color: themeContext.color.main,
                                                                                              textAlign: `left`
                                                                                          }}>
                                                                                        {documents[1].name}
                                                                                    </Link>
                                                                            }
                                                                        </Fragment>
                                                                        }
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        ))
                                                    }
                                                    <Row className="mb-2">
                                                        <Col className={width_min ? "col-1" : "col-1 pr-0"}
                                                             style={{marginTop: '13px'}}>
                                                            <img className={'cursor-pointer'}
                                                                 onClick={() => setCheckbox2(current => !current)}
                                                                 src={checkbox2 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                                 alt="checkbox-unselected" height="20px"/>
                                                        </Col>
                                                        <Col className={width_min ? "col-11" : "col-11 pl-0"}>
                                                            {
                                                                policyDocument.map((policyDoc, index) => (
                                                                    index === 0 &&
                                                                    <Link key={index} href={policyDoc.url}
                                                                          target="_blank"
                                                                          rel="noopener noreferrer"
                                                                          className="mt-3 float-left login-subtitle cursor-pointer"
                                                                          color="primary"
                                                                          style={{
                                                                              fontSize: `14px`,
                                                                              textDecoration: 'none',
                                                                              color: themeContext.color.main,
                                                                              textAlign: `left`
                                                                          }}>
                                                                        {t('uploadDocumentToVerify.taking-responsibility-checkbox-2')}
                                                                    </Link>
                                                                ))
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-2">
                                                        <Col className={width_min ? "col-1" : "col-1 pr-0"}
                                                             style={{marginTop: '13px'}}>
                                                            <img className={'cursor-pointer'}
                                                                 onClick={() => setCheckbox3(current => !current)}
                                                                 src={checkbox3 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                                 alt="checkbox-unselected" height="20px"/>
                                                        </Col>
                                                        <Col className={width_min ? "col-11" : "col-11 pl-0"}>
                                                            <h6 className="mt-3 float-left font-weight-bold" style={{
                                                                textDecoration: "none",
                                                                color: themeContext.color.neutral600,
                                                                textAlign: 'left',
                                                                fontSize: '14px'
                                                            }}>
                                                                {t('uploadDocumentToVerify.taking-responsibility-checkbox-3')}
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-4">
                                                        <Col className={width_min ? "col-1" : "col-1 pr-0"}
                                                             style={{marginTop: '13px'}}>
                                                            <img className={'cursor-pointer'}
                                                                 onClick={() => setCheckbox4(current => !current)}
                                                                 src={checkbox4 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                                 alt="checkbox-unselected" height="20px"/>
                                                        </Col>
                                                        <Col className={width_min ? "col-11" : "col-11 pl-0"}>
                                                            <h6 className="mt-3 float-left font-weight-bold" style={{
                                                                textDecoration: "none",
                                                                color: themeContext.color.neutral600,
                                                                textAlign: 'left',
                                                                fontSize: '14px'
                                                            }}>
                                                                {t('uploadDocumentToVerify.taking-responsibility-checkbox-4')}
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{paddingBottom: '30px'}}>
                                                        <Col>
                                                            <Button
                                                                className={checkbox1 && checkbox2 && checkbox3 && checkbox4 ? "btn-custom text-transform-none" : "btn-custom-disable text-transform-none"}
                                                                variant="outlined"
                                                                size="large"
                                                                style={{'color': themeContext.color.white}}
                                                                type="submit"
                                                                onClick={() => {
                                                                    if (checkbox1 && checkbox2 && checkbox3 && checkbox4) {
                                                                        if (person.gender === 'C') {
                                                                            setOpenSignaturePermissionDialog(true)
                                                                        } else {
                                                                            setTakingResponsibility(false)
                                                                        }
                                                                    }
                                                                }}
                                                            >{t('uploadDocumentToVerify.taking-responsibility-button')}</Button>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                        {
                            !errorPage && !takingResponsibility &&
                            <div className="w-100 px-4 linear-gradient" style={{paddingBottom: 24}}>
                                <div style={{'opacity': '1'}}>
                                    <StyledTitlePage>
                                        <Col className="col-1 float-left">
                                            <img src={back} onClick={turnBack}
                                                 className="nav-top-logo float-left cursor-pointer"
                                                 height="27px"
                                                 alt="back"/>
                                        </Col>
                                        <Col className="col-10">
                                            <span
                                                className="header-title ellipsis-1-v2">{t('uploadDocumentToVerify.title')}</span>
                                        </Col>
                                        <Col className="col-1 float-right">
                                            <img src={exit} onClick={() => setOpenExitDialog(true)}
                                                 className="nav-top-logo float-right cursor-pointer" alt="exit-icon"
                                                 height="27px"/>
                                        </Col>
                                    </StyledTitlePage>
                                    {
                                        (window.location.href.includes('uploadBack') && !window.location.href.includes('uploadFront')) ?
                                            <PlaceholderScreen isLoadingData={isLoadingData}
                                                               title={t('uploadDocumentToVerify.title-back')}
                                                               description={t('uploadDocumentToVerify.description-back')}
                                                               placeholder={backdocumentPlaceholder}
                                                               buttonText={t('uploadDocumentToVerify.upload-button')}
                                                               buttonAction={handleInputImage}
                                                               open={open}/> :
                                            (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront') ?
                                                    <PlaceholderScreen isLoadingData={isLoadingData}
                                                                       title={t('uploadDocumentToVerify.subtitle')}
                                                                       description={t('uploadDocumentToVerify.description-front')}
                                                                       placeholder={documentPlaceholder}
                                                                       buttonText={t('uploadDocumentToVerify.upload-button')}
                                                                       buttonAction={handleInputImage} open={open}/> :
                                                    <PlaceholderScreen isLoadingData={isLoadingData}
                                                                       title={t('uploadDocumentToVerify.subtitle')}
                                                                       description={t('uploadDocumentToVerify.description-front')}
                                                                       placeholder={documentPlaceholder}
                                                                       buttonText={t('uploadDocumentToVerify.button')}
                                                                       buttonAction={navigateToPersonalDocuments} open={open}/>
                                            )
                                    }
                                </div>
                            </div>
                        }
                        <Dialog
                            components={{Backdrop: StyledBackdrop}}
                            open={openUploadDocumentDialog}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleCloseUploadDocumentDialog}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                            PaperProps={{
                                style: {
                                    'position': 'absolute',
                                    'bottom': '0',
                                    'left': 'auto',
                                    'right': 'auto',
                                    'width': '95%',
                                    'borderRadius': '10px',
                                    'color': themeContext.color.neutral600
                                },
                            }}>
                                <span className={classes.dialogTitle}>
                                    {t('uploadDocumentToVerify.dialog-title')}
                                </span>
                            <Divider/>
                            {
                                isLoadingDialog &&
                                <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                            }
                            {
                                !isLoadingDialog &&
                                <Fragment>
                                    {isPersonalDocuments &&
                                        <Fragment>
                                            <DialogActions style={{'textTransform': 'none'}}>
                                                <Button
                                                    onClick={navigateToDocumentSelection}
                                                    style={{
                                                        'color': themeContext.color.main,
                                                        'textTransform': 'none'
                                                    }}
                                                    fullWidth>
                                                    {t('uploadDocumentToVerify.personal-document')}
                                                </Button>
                                            </DialogActions>
                                            <Divider/>
                                        </Fragment>
                                    }
                                    <DialogActions>
                                        <Button
                                            id="attachImage"
                                            style={{
                                                'color': themeContext.color.main,
                                                'textTransform': 'none'
                                            }}
                                            fullWidth
                                            onClick={handleInputImage}
                                        >
                                            {t('uploadDocumentToVerify.upload')}
                                        </Button>
                                        <input hidden id="image-file" type="file" onChange={handleOnPendingImage}
                                               accept="image/*"/>
                                    </DialogActions>
                                    <Divider/>
                                    <DialogActions>
                                        <Button onClick={handleCloseUploadDocumentDialog}
                                                style={{
                                                    'color': themeContext.color.neutral400,
                                                    'textTransform': 'none'
                                                }}
                                                fullWidth>
                                            {t('uploadDocumentToVerify.refuse-button')}
                                        </Button>
                                    </DialogActions>
                                </Fragment>
                            }
                        </Dialog>
                        <CropDialog open={openCropDialog}
                                    handleAttach={() => {
                                        if (isLoadingImg === false) {
                                            attachCroppedImage()
                                        }
                                    }}
                                    handleClose={() => {
                                        setOpenCropDialog(false)
                                        setIsLoadingImg(false)
                                    }}
                                    isLoading={isLoadingImg}
                                    onPendingImage={onPendingImage}
                                    cropperRef={cropperRef}
                                    setCropper={(data) => {
                                        if (data) {
                                            setCropper(data)
                                        }
                                    }}/>
                        <DialogDoubleAction
                            open={openExitDialog}
                            handleClose={() => setOpenExitDialog(false)}
                            title={t('dialog.interruption-title')}
                            description={t('dialog.interruption-description')}
                            buttonYesText={t('dialog.button-yes')}
                            handleYes={() => {
                                navigate(`/signing`);
                            }}
                            buttonNoText={t('dialog.button-no')}
                            handleNo={() => setOpenExitDialog(false)}/>
                        <DialogSmall buttonText={t('uploadDocumentToVerify.fallback-dialog-button')}
                                     title={t('uploadDocumentToVerify.fallback-dialog-title')}
                                     description={t('uploadDocumentToVerify.fallback-dialog-description')}
                                     handleClose={() => {
                                         if (window.location.href.includes('uploadBack')) {
                                             navigate(`/signing/${personId}/uploadBack?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&skipTakingResponsibility=true`)
                                         } else {
                                             navigate(`/signing/${personId}/upload?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&skipTakingResponsibility=true`)
                                         }
                                         setOpenFallbackDialog(false)
                                     }}
                                     open={openFallbackDialog}/>
                        <DialogSmall open={openSizeExceededDialog} title={t('Maximum upload size exceeded')}
                                     buttonText={t('dialog.button-ok')}
                                     handleClose={() => {
                                         setOpenSizeExceededDialog(false)
                                     }} description={t('The maximum file upload size is 20MB')}/>
                        <DialogSmall open={openTypeNotSupportedDialog} title={t('The uploaded format is not supported')}
                                     buttonText={t('dialog.button-ok')}
                                     handleClose={() => {
                                         setOpenTypeNotSupportedDialog(false)
                                     }} description={t('Only image formats are supported')}/>
                        <DialogDoubleAction
                            open={openSignaturePermissionDialog}
                            handleClose={() => setOpenSignaturePermissionDialog(false)}
                            title={t('uploadDocumentToVerify.Declaration of authority to sign')}
                            description={
                                <span> {t('uploadDocumentToVerify.By continuing, you declare that you have authority to sign on behalf of')}
                                    <b>{person.name ? person.name : 'NULL'}</b>{t('uploadDocumentToVerify., having VAT registration number')}
                                    <b>{person.fiscalCode ? person.fiscalCode : 'NULL'}</b>{t('uploadDocumentToVerify., assuming the related responsibilities.')}
                                    <br/><br/> {t('uploadDocumentToVerify.Automatic and manual checks will be carried out to verify signatory authority.')}</span>}
                            buttonYesText={t('dialog.button-proceed')}
                            handleYes={() => {
                                setOpenSignaturePermissionDialog(false)
                                setTakingResponsibility(false)
                            }}
                            buttonNoText={t('dialog.button-back')}
                            handleNo={() => setOpenSignaturePermissionDialog(false)}/>
                    </Fragment>
                }
            </StyledPageCard>
        </ExternalPageLayout>
    )
}