import React from "react";
import {apiFetch} from "./ApiService.js";
import {
    countActiveClaimsUrl,
    getAllRefundPremiumUrl,
    getDocumentToSignedUrl,
    getPeoplePaymentNotificationUrl,
    getRegulationsUrl
} from "./EndpointService.js";

const token = sessionStorage.getItem('token');

export const getPayments = async (token_arg) => {
    try {
        const data = await apiFetch(getPeoplePaymentNotificationUrl, !!token_arg ? token_arg : token);
        return data.length;
    } catch (error) {
        return null;
    }
};

export const getDocumentsToSigned = async (token_arg, companies) => {
    try {
        const data = await apiFetch(getDocumentToSignedUrl, token_arg);
        let finder = 0;
        data.map((person) => {
            person.policies = person.policies.map((policy) => {
                finder = finder + (policy.documents.length);
            }).filter(e => e)
        })
        return finder;
    } catch (error) {
        return null;
    }
};

export const getRegulations = async (token_arg) => {
    try {
        const data = await apiFetch(getRegulationsUrl, !!token_arg ? token_arg : token);
        let finder = 0;
        data.map((person) => {
            person.regulations.map((regulation) => {
                if (regulation.policyStatus !== 5) {
                    finder = finder + 1;
                }
            });
        })
        return finder;
    } catch (error) {
        return null;
    }
};

export const getRefunds = async (token_arg) => {
    try {
        const data = await apiFetch(getAllRefundPremiumUrl, !!token_arg ? token_arg : token);
        let finder = 0;
        data.map((person) => {
            finder += person.refund.length;
        })
        return finder;
    } catch (error) {
        return null;
    }
};

export const getCountActiveClaims = async (token_arg) => {
    try {
        const data = await apiFetch(countActiveClaimsUrl, !!token_arg ? token_arg : token);
        return data.count;
    } catch (error) {
        return null;
    }
};