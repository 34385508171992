import React, {Fragment, useContext, useRef, useState} from 'react';
import {SidebarContext, VIEW} from "../../middleware/SidebarContext.js";
import {styled} from "@mui/material/styles";
import ListCard, {CARD_TYPE} from "../../components/desktop/ListCard.js";
import {drawerWidth} from "../../components/desktop/DetailBar.js";
import themeContext from "../../components/ThemeContext.js";
import {useTranslation} from "react-i18next";
import GridCard from "../../components/desktop/GridCard.js";
import MuiAvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import {tooltipClasses, TooltipProps} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import usersPlaceholder from "../../../images/desktop/user-placeholder.svg";
import {setPersonImage, setStatusColor} from "../../service/DashboardService.js";
import AvatarGroup from "../../components/desktop/AvatarGroup.js";

const ExternalLayout = styled('div')(({theme, open, activeNotification}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: '65px',
    paddingTop: '8px',
    marginLeft: open ? '269px' : '65px',
    marginRight: activeNotification ? `calc(${drawerWidth})` : 0,
    gap: '8px',
    paddingBottom: '8px',
}));

const StickyDiv = styled('div')(({top}) => ({
    display: 'flex',
    position: 'sticky',
    top: top,
    zIndex: '99',
    background: themeContext.color.defaultBackground,
    width: '100%',
    flexDirection: 'column',
    gap: '8px',
    paddingLeft: '20px',
    paddingRight: '20px',
}));

const GridCardContainer = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    gap: '16px',
    flexWrap: 'wrap',
    justifyContent: 'start'
}));

const ListCardContainer = styled('div')(({theme}) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '16px',
}));

const Layout = styled('div')(({theme}) => ({
    paddingLeft: '20px',
    paddingRight: '20px',
    width: '100%',
}));

// AVATAR STYLES

const StyleAvatarGroup = styled(MuiAvatarGroup)(({}) => ({
    display: 'flex',
    justifyContent: 'start'
}));

const StyledAvatar = styled(Avatar)(({color, selected, isselected, index}) => ({
    opacity: selected && isselected === 'false' ? 0.5 : 1,
    background: isselected === 'true' ? `linear-gradient(to bottom, ${color}, ${color})` :
        `linear-gradient(to bottom, ${themeContext.color.white}, ${themeContext.color.white}, ${themeContext.color.white}, ${color})`,
    marginLeft: index !== 0 ? '-8px !important' : 0,
    zIndex: isselected === 'true' ? '2 !important' : 1,
}));

const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: themeContext.color.white,
        color: themeContext.color.neutral600,
        //boxShadow: theme.shadows[1],
        fontSize: 14,
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
        {
            marginTop: '-10px',
            marginLeft: '110px',
        },
}));

const StyledAvatarDiv = styled('div')(({}) => ({
    display: 'flex',
    alignItems: 'center',
    background: themeContext.color.white,
    borderRadius: '16px',
    marginRight: '24px',
    padding: '8px',
}));

// main title styles

const ContainerTitle = styled('div')(({theme}) => ({
    marginTop: '8px',
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    gap: '16px',
    width: '100%'
}));

const RowTitle = styled('div')(({theme}) => ({
    display: "flex",
    alignItems: 'center',
    gap: '16px'
}));

const StyledTitle = styled('span')(({theme}) => ({
    fontSize: '20px',
    fontWeight: 'bold',
    textTransform: "uppercase"
}));

const MenuTitle = styled('span')(({theme}) => ({
    fontSize: '20px',
    fontWeight: 'bold',
    color: themeContext.color.neutral600
}));

// card title styles

const ContainerCardTitle = styled('div')(({theme}) => ({
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    gap: '16px',
    width: '100%',
    paddingTop: '9px',
    paddingBottom: '9px',
}));

const StyledCardTitle = styled('span')(({theme}) => ({
    fontSize: '16px',
    color: themeContext.color.neutral400
}));

const Payments = React.memo(function Payments(props) {
    const {
        people,
        peopleFiltered,
        isLoadingPeople,
        isLoadingNotifications,
        paymentNotification,
        handleAvatar,
        slimpayPeople,
        selectedAvatarState
    } = props;

    const {open, view} = useContext(SidebarContext);

    const {t, i18n} = useTranslation();
    const [openCards, setOpenCards] = React.useState(true);
    const root = 'desktop.payments';
    const stickyDivRef = useRef(null);
    const initialStickyHeight = 63;
    const [activeNotification, setActiveNotification] = useState(null);
    let array = [];

    const userPlaceholder = (
        <div className={'d-flex flex-row'} style={{gap: '8px'}}>
            <img src={usersPlaceholder} alt={'usersPlaceholder-1'}/>
            <img src={usersPlaceholder} alt={'usersPlaceholder-2'}/>
            <img src={usersPlaceholder} alt={'usersPlaceholder-3'}/>
        </div>
    )

    if (people && slimpayPeople) {
        slimpayPeople.map((person) => {
            array.push({...person, person: people.find((p) => p.id === person)})
        });
    }

    return (
        <ExternalLayout open={open} activeNotification={activeNotification}>
            <StickyDiv top={`${initialStickyHeight}px`} ref={stickyDivRef}>
                <ContainerTitle>
                    <RowTitle>
                        <StyledTitle>{t(`${root}.title`)}</StyledTitle>
                    </RowTitle>
                </ContainerTitle>
                <ContainerTitle>
                    <RowTitle>
                        <StyledCardTitle>{t(`${root}.sub-title`)}</StyledCardTitle>
                    </RowTitle>
                </ContainerTitle>

                <AvatarGroup people={people} isLoading={isLoadingPeople} selected={selectedAvatarState}
                             onChange={(value, e) => {
                                 handleAvatar(e, value)
                             }}/>


                <div onClick={() => {
                    //handleCards()
                }} style={{
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: "center",
                    gap: '16px',
                    width: '100%',
                    height: '37px',
                    padding: '0',
                    borderRadius: '12px',
                    cursor: 'pointer',
                }}>
                </div>
            </StickyDiv>
            <Layout>
                {openCards && (view === VIEW.LIST ?
                    <ListCardContainer>
                        <ListCard type={CARD_TYPE.PAYMENT} object={{data: paymentNotification, people: array}}
                                  isLoading={isLoadingNotifications}
                                  activeCard={activeNotification} setActiveCard={setActiveNotification}
                                  noItemsText={t('payment.nothing-text-1')}/>
                    </ListCardContainer> :
                    <GridCardContainer>
                        <GridCard type={CARD_TYPE.PAYMENT} object={{data: paymentNotification, people: array}}
                                  isLoading={isLoadingNotifications}
                                  activeCard={activeNotification} setActiveCard={setActiveNotification}
                                  noItemsText={t('payment.nothing-text-1')}/>
                    </GridCardContainer>)
                }
            </Layout>
        </ExternalLayout>
    )
});

export default Payments;
