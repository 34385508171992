import React, {Fragment, useContext, useState} from 'react';
import themeContext from "../ThemeContext.js";
import {useTranslation} from "react-i18next";
import Divider from "@mui/material/Divider";
import {styled} from "@mui/material/styles";
import moment from "moment";
import {ListItemButton, Typography} from "@mui/material";
import {DetailBarContext} from "../../middleware/DetailBarContext.js";
import {getBranchIcon} from "../../service/DashboardService.js";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {SidebarContext, VIEW} from "../../middleware/SidebarContext.js";
import {PAYMENT_METHOD} from "../../service/PaymentsService.js";
import {Col, Row} from "react-bootstrap";
import slimpayLogo from "../../../images/slimpay-logo-blue.svg";
import {makeStyles} from "@mui/styles";
import infoPaymentIcon from "../../../images/payment-info-icon.svg";

let oldData = null;

const useStyles = makeStyles((theme) => ({
    cardTextMid: {
        color: `${themeContext.color.neutral400} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '400 !important',
        fontSize: '12px !important',
        textTransform: 'uppercase !important',
        paddingTop: '10px !important',
        paddingBottom: '7px !important',
    },
    infoText: {
        fontSize: '12px !important',
        fontWeight: 'normal !important',
        color: `${themeContext.color.neutral400} !important`,
    },
}));

const PaymentDetail = () => {
    const {t, i18n} = useTranslation();
    const {data, isLoading, setOpenCheckoutDialog} = useContext(DetailBarContext);
    const {view} = useContext(SidebarContext);
    const classes = useStyles();

    const [collapseList, setCollapseList] = useState([]);
    const SpanKey = styled('span')(({theme}) => ({
        width: '38%',
        color: themeContext.color.neutral400,
        fontSize: '14px',
        textAlign: 'left'
    }));

    const SpanValue = styled('span')(({theme, bold}) => ({
        width: '64%',
        color: themeContext.color.neutral600,
        fontSize: '14px',
        textAlign: 'left',
        fontWeight: bold ? 'bold' : 'normal',
    }));

    const SpanValueFull = styled('span')(({theme, main}) => (
        main ?
            {
                width: '100%',
                color: themeContext.color.main,
                fontSize: '22px',
                textAlign: 'left'
            } :
            {
                width: '100%',
                color: themeContext.color.neutral400,
                fontSize: '14px',
                fontWeight: '600',
                textAlign: 'left'
            }
    ));

    const StyledListItemButton = styled(ListItemButton)(({theme}) => ({
        width: `100%`,
        borderRadius: `16px`,
        padding: `4px 0 4px 8px`,
        height: `40px`,
        backgroundColor: themeContext.color.main10,
        '&:hover': {
            backgroundColor: themeContext.color.neutral100,
        }
    }));

    const StyledListItemText = styled(ListItemText)(({theme}) => ({
        color: themeContext.color.main,
        textDecoration: `none`,
        textTransform: 'uppercase',
        textAlign: 'center'
    }));

    const formatText = (text) => {
        if (!text) return '';
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    const generateAndRetrieve = (title, index) => {
        if (oldData !== data) {
            setCollapseList([]);
            oldData = data;
        }
        const handleClick = () => {
            const newList = [...collapseList];
            newList[index] = {
                ...newList[index],
                value: !newList[index].value,
                index: newList[index].index + 1
            };
            setCollapseList(newList);
        };
        if (!collapseList[index]) {
            const newList = [...collapseList];
            newList[index] = {value: true, index: 0};
            setCollapseList(newList);
        }
        return (
            <div key={index} style={{paddingBottom: '24px'}}>
                <Fragment>
                    <ListItemButton onClick={handleClick} style={{paddingLeft: '6px', borderRadius: '12px'}}>
                        <ListItemIcon style={{minWidth: '40px'}}>
                            <img src={getBranchIcon(title.branchName)}
                                 alt="branch-icon"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={'ellipsis-1-v2'}
                                        style={{color: 'text.primary', display: 'block'}}
                                    >
                                        {formatText(title.branchName)}
                                    </Typography>
                                </React.Fragment>
                            }
                            className={'ellipsis-1-v2'}
                        />
                        {collapseList[index]?.value ? <ExpandMore/> : <ExpandLess/>}
                    </ListItemButton>
                    {collapseList[index]?.value && (
                        <Collapse in={true} key={collapseList[index].index} timeout="auto" unmountOnExit>
                            <div className={'d-flex justify-content-start align-items-start w-100'}
                                 style={{minHeight: 32}}>
                                <SpanKey>{t('desktop.payments.detail.type')} </SpanKey>
                                <SpanValue>{formatText(title.titletype)}</SpanValue>
                            </div>
                            <div className={'d-flex justify-content-start align-items-start w-100'}
                                 style={{minHeight: 32}}>
                                <SpanKey>{t('desktop.payments.detail.policy-nr')} </SpanKey>
                                <SpanValue>{title.policynr}</SpanValue>
                            </div>
                            <div className={'d-flex justify-content-start align-items-start w-100'}
                                 style={{minHeight: 32}}>
                                <SpanKey>{t('desktop.payments.detail.company')} </SpanKey>
                                <SpanValue>{formatText(title.company)}</SpanValue>
                            </div>
                            <div className={'d-flex justify-content-start align-items-start w-100'}
                                 style={{minHeight: 32}}>
                                <SpanKey>{t('desktop.payments.detail.premium')} </SpanKey>
                                <SpanValue bold={true}>{title.premium}</SpanValue>
                            </div>
                        </Collapse>
                    )}
                    <Divider className={'w-100'}/>
                </Fragment>
            </div>
        );
    };

    return (
        <Fragment>
            {isLoading && <div className="background-loading-spinner spinner-desktop"></div>}
            {!isLoading &&
                <Fragment>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <span style={{fontWeight: 700, fontSize: '18px', color: themeContext.color.neutral600}}>
                            {t('desktop.payments.detail.title')}
                        </span>
                    </div>
                    {data &&
                        <Fragment>
                            <div className={'d-flex justify-content-start align-items-center flex-column w-100'}
                                 style={{gap: '4px'}}>
                                <div className={'d-flex justify-content-start align-items-start w-100'}
                                     style={{minHeight: 32}}>
                                    <SpanKey>{t('desktop.payments.date-card')} </SpanKey>
                                    <SpanValue>{moment(data.datebill).format('DD/MM/YYYY')}</SpanValue>
                                </div>
                                <div className={'d-flex justify-content-start align-items-start w-100'}
                                     style={{minHeight: 32}}>
                                    <SpanKey>{t('desktop.payments.detail.holder')} </SpanKey>
                                    <SpanValue>{(data.firstName && data.lastName) ? `${data.firstName} ${data.lastName}` : data.name}</SpanValue>
                                </div>
                            </div>
                            <Divider className={'w-100'}/>
                            {
                                <div className={'d-flex flex-column justify-content-between h-100 w-100'}>
                                    <Fragment>
                                        {
                                            data &&
                                            <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                                                {
                                                    data.titles.map((title, index) => (
                                                        generateAndRetrieve(title, index)
                                                    ))
                                                }
                                                {
                                                    data.requestedCents > 799900 &&
                                                    <div className={'d-flex flex-row'}>
                                                        <Col className="col-auto" style={{paddingRight: 0, paddingLeft: 0}}>
                                                            <img src={infoPaymentIcon} alt="info-payment-icon"/>
                                                        </Col>
                                                        <Col
                                                            style={{
                                                                textAlign: 'left',
                                                                padding: '0px 0px 24px 3px'
                                                            }}>
                                                            <span className={classes.infoText}>{t('desktop.payments.limit-exceeded-detail')}</span>
                                                        </Col>
                                                    </div>
                                                }
                                            </List>
                                        }
                                    </Fragment>
                                    {
                                        view === VIEW.GRID && !data.isSlimPay &&
                                        <div>
                                            <div className={'d-flex flex-column w-100'}
                                                 style={{gap: 8, paddingBottom: '16px'}}>
                                                <SpanValueFull main={true}
                                                               className={'font-weight-bold'}>{t('desktop.payments.detail.total')} {data.requestedPremium}</SpanValueFull>
                                                <SpanValueFull>
                                                    {t('desktop.payments.detail.pay-with')}</SpanValueFull>
                                            </div>
                                            <div className={'d-flex flex-column w-100'} style={{gap: 8}}>
                                                <StyledListItemButton
                                                    disabled={data.requestedCents > 799900}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setOpenCheckoutDialog(prevState => ({
                                                            ...prevState,
                                                            value: true,
                                                            dataType: PAYMENT_METHOD.CREDIT,
                                                            data: data
                                                        }));
                                                    }}>
                                                    <StyledListItemText primary={t('desktop.payments.credit-card')}/>
                                                </StyledListItemButton>
                                                <StyledListItemButton onClick={(event) => {
                                                    event.stopPropagation();
                                                    setOpenCheckoutDialog(prevState => ({
                                                        ...prevState,
                                                        value: true,
                                                        dataType: PAYMENT_METHOD.DEBIT,
                                                        data: data
                                                    }));
                                                }}>
                                                    <StyledListItemText primary={t('desktop.payments.debit')}/>
                                                </StyledListItemButton>
                                                <StyledListItemButton onClick={(event) => {
                                                    event.stopPropagation();
                                                    setOpenCheckoutDialog(prevState => ({
                                                        ...prevState,
                                                        value: true,
                                                        dataType: PAYMENT_METHOD.BANK,
                                                        data: data
                                                    }));
                                                }}>
                                                    <StyledListItemText primary={t('desktop.payments.iban')}/>
                                                </StyledListItemButton>
                                            </div>
                                        </div>
                                    }
                                    {
                                        data.isSlimPay &&
                                        <div style={{paddingBottom: 16}}>
                                            <Row>
                                                <Col className="col-9">
                                                    <h6 className={classes.cardTextMid}>
                                                        {(((data.person.firstName === null || data.person.firstName === "") && (data.person.lastName === null || data.person.lastName === "")) ?
                                                            <span>{data.person.name}</span> : <span>{data.person.firstName} {data.person.lastName}</span>)
                                                        }
                                                    </h6>
                                                </Col>
                                                <Col className="col-3">
                                                    <img style={{marginTop: '-7px'}} height="40px"
                                                         src={slimpayLogo} alt="slimpay-logo"/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                            <span style={{
                                                float: 'left',
                                                textAlign: 'left',
                                                color: themeContext.color.neutral600,
                                                fontSize: '12px'
                                            }}>
                                                {t('paymentsStatus.slimpay-pending')}
                                            </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </div>
                            }
                        </Fragment>
                    }
                </Fragment>
            }
        </Fragment>
    );
};

export default PaymentDetail;
