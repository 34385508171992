import React, {createContext, useContext, useEffect, useState} from 'react';
import PrivacyPolicyDialog from "../components/PrivacyPolicyDialog.js";
import MultiButtonDialog from "../components/MultiButtonDialog.js";
import {isUpdatedUrl} from "../service/EndpointService.js";
import themeContext from "../components/ThemeContext.js";
import AuthContextMiddleware from "./AuthContextMiddleware.js";
import MessageDialog from "../components/MessageDialog.js";
import {MESSAGE_TYPE} from "../service/MessagesService.js";
import {subscribe} from "../service/EventService.js";
import OpenClaimDialog from "../components/OpenClaimDialog.js";

export const VIEW = {
    LIST: 'list',
    GRID: 'grid',
}

export const SidebarContext = createContext();

export const SidebarProvider = ({children}) => {
    const [open, setOpen] = useState(true);
    const [view, setView] = useState(sessionStorage.getItem('view') || VIEW.LIST);
    const [openAppDialog, setOpenAppDialog] = useState(false);
    const [openPolicyDialog, setOpenPolicyDialog] = useState(false);
    const [openMessagesDialog, setOpenMessagesDialog] = useState(false);
    const [openClaimMessageDialog, setOpenClaimMessageDialog] = useState(false);
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const [privacyPolicyDocument, setPrivacyPolicyDocument] = useState([])
    const [messageId, setMessageId] = useState(null);
    const [openOpenClaimDialog, setOpenOpenClaimDialog] = useState(false);


    const toggleSidebar = () => {
        setOpen((prevOpen) => {
            sessionStorage.setItem('isOpenSidebar', !prevOpen);
            return !prevOpen;
        });
    };

    const toggleView = (view) => {
        setView(view);
        sessionStorage.setItem('view', view);
    };

    const authCtx = useContext(AuthContextMiddleware);
    const removeTokenUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/removeToken`;
    const getDocumentsForPrivacyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/privacy/documentForPrivacy`;
    const setPrivacyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/privacy/setPrivacy`;
    const getPrivacyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/privacy/getPrivacy`;
    const token = sessionStorage.getItem('token');

    async function handleUpdate() {
        navigator.serviceWorker.getRegistrations().then((r) => {
            return Promise.all(r.map(reg => reg.unregister()));
        }).then(() => {
            fetch(removeTokenUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: sessionStorage.getItem('firebaseToken')
                }),
            }).then((response) => {
                fetch(isUpdatedUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    return response.json();
                }).then(async (res) => {
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.reload();
                }).catch(() => {
                    authCtx.logout();
                    window.location.reload();
                })
            })
        });
    }

    const getDocumentsForPrivacy = () => {
        fetch(getDocumentsForPrivacyUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPrivacyPolicyDocument(data[0]);
            getPrivacy();
        }).catch(() => {
            setServiceError(true)
        })
    }

    const getPrivacy = (forceOpen) => {
        fetch(getPrivacyUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data) {
                setCheckbox1(data.datiParticolari)
                setCheckbox2(data.marketingDiretto)
                setCheckbox3(data.profilazione)
                if (!data.noShow) {
                    setOpenPolicyDialog(true)
                    sessionStorage.setItem('privacy_policy_dialog', true)
                } else if (forceOpen) {
                    setOpenPolicyDialog(true)
                }
                setIsLoadingData(false)
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    function setPrivacy(checkbox1, checkbox2, checkbox3) {
        fetch(setPrivacyUrl, {
            method: 'POST',
            body: JSON.stringify({
                datiParticolari: checkbox1,
                marketingDiretto: checkbox2,
                profilazione: checkbox3,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            if (response.ok) {
                setCheckbox1(checkbox1)
                setCheckbox2(checkbox2)
                setCheckbox3(checkbox3)
                setOpenPolicyDialog(false)
            }
        }).catch(e => {
        });
    }

    const messagesDialog =
        <MessageDialog
            messageId={messageId}
            open={openMessagesDialog}
            handleClose={() => setOpenMessagesDialog(false)}
            isRequest={false}
            types={[
                {
                    filter: true,
                    tab: 0,
                    value: MESSAGE_TYPE.NORMAL_NOT_READ,
                },
                {
                    filter: false,
                    tab: 0,
                    value: MESSAGE_TYPE.NORMAL,
                },
                {
                    filter: true,
                    tab: 1,
                    value: MESSAGE_TYPE.ARCHIVE_NOT_READ,
                },
                {
                    filter: false,
                    tab: 1,
                    value: MESSAGE_TYPE.ARCHIVE,
                },
            ]}
        />

    const requestClaimsDialog =
        <MessageDialog
            open={openClaimMessageDialog}
            handleClose={() => setOpenClaimMessageDialog(false)}
            isRequest={true}
            types={[
                {
                    filter: true,
                    tab: 0,
                    value: MESSAGE_TYPE.CLAIMS_NOT_READ,
                },
                {
                    filter: false,
                    tab: 0,
                    value: MESSAGE_TYPE.CLAIMS,
                },
                {
                    filter: true,
                    tab: 1,
                    value: MESSAGE_TYPE.CLAIMS_ARCHIVE_NOT_READ,
                },
                {
                    filter: false,
                    tab: 1,
                    value: MESSAGE_TYPE.CLAIMS_ARCHIVE,
                },
            ]}
        />

    const updateAppDialog =
        <MultiButtonDialog
            open={openAppDialog}
            handleClose={() => setOpenAppDialog(false)}
            title={'settings.modal-title'}
            titleStyle={{'textAlign': 'center'}}
            content={'settings.modal-update-title-info'}
            contentStyle={{'textAlign': 'center'}}
            actions={[
                {
                    type: 'button',
                    text: 'settings.modal-update-button',
                    onClick: () => handleUpdate(),
                    style: {fontSize: '18px !important', 'textTransform': 'none'}
                },
                {
                    type: 'button',
                    text: 'settings.modal-refuse-button',
                    onClick: () => setOpenAppDialog(false),
                    style: {
                        'color': themeContext.color.neutral400,
                        'textTransform': 'none'
                    }
                }
            ]}


        />;

    const privacyPolicyDialog =
        <PrivacyPolicyDialog open={openPolicyDialog} checkbox1={checkbox1} checkbox2={checkbox2}
                             checkbox3={checkbox3} document={privacyPolicyDocument}
                             handleClose={() => setOpenPolicyDialog(false)}
                             proceedLater={true}
                             handleSave={(checkbox1, checkbox2, checkbox3) => {
                                 setPrivacy(checkbox1, checkbox2, checkbox3)
                             }}/>;

    const openClaimDialog =
        <OpenClaimDialog
            open={openOpenClaimDialog}
            handleClose={() => setOpenOpenClaimDialog(false)}
            step={-1}
        />

    useEffect(() => {
        if (!sessionStorage.getItem('privacy_policy_dialog')) {
            getDocumentsForPrivacy();
        }
        subscribe('openClaimMessageDialog', (l) => {
            setOpenClaimMessageDialog(true);
        });
    }, []);

    return (
        <SidebarContext.Provider value={{
            open,
            toggleSidebar,
            view,
            toggleView,
            setOpenAppDialog,
            setOpenPolicyDialog,
            setOpenMessagesDialog,
            setMessageId,
            setOpenOpenClaimDialog
        }}>
            {children} {updateAppDialog} {openPolicyDialog && privacyPolicyDialog} {openMessagesDialog && messagesDialog} {openClaimMessageDialog && requestClaimsDialog}  {openOpenClaimDialog && openClaimDialog}
        </SidebarContext.Provider>
    );
};
