import React, {createContext, useState} from 'react';

export const DesktopLayoutContext = createContext();

export const DesktopLayoutProvider = ({children}) => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const [isDetailbarVisible, setIsDetailbarVisible] = useState(true);

    const handleSidebar = (param) => {
        setIsSidebarVisible(param);
    };

    const handleHeader = (param) => {
        setIsHeaderVisible(param);
    }

    const handleDetailbar = (param) => {
        setIsDetailbarVisible(param);
    }

    return (
        <DesktopLayoutContext.Provider value={{
            isSidebarVisible,
            isHeaderVisible,
            isDetailbarVisible,
            handleSidebar,
            handleHeader,
            handleDetailbar,
        }}>
            {children}
        </DesktopLayoutContext.Provider>
    );
};
