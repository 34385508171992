import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../../images/back-icon.svg";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import goTo from "../../../images/goto-icon.svg";
import Divider from "@mui/material/Divider";
import {makeStyles} from "@mui/styles";
import {useTranslation} from 'react-i18next'
import Moment from "react-moment";
import themeContext from "../../components/ThemeContext.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";
import {ExternalPageLayout, StyledPageCard, StyledPageLayout, StyledTitlePage} from "../../styles/DesktopStyles.js";


const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100% !important',
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "0 0 0 0 !important",
    },
    listItem: {
        textDecoration: "none !important",
        fontSize: '14px !important',
        color: `${themeContext.color.neutral600} !important`,
        paddingTop: '12.5px !important',
        paddingBottom: '10.5px !important',
    },
}));

export default function TitlesHistory() {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const {open} = useContext(SidebarContext);
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        t,
        i18n
    } = useTranslation();
    const params = useParams();
    const [titlesHistory, setTitlesHistory] = useState([])
    const [documentTitle, setDocumentTitle] = useState([])
    const policyId = params.policyId;
    const personId = params.personId;
    const vehicleId = params.vehicleId;
    const claimId = params.claimId;
    const getTitlesHistoryUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/titlesHistory`;
    const titleDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/title`;
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getTitlesHistory();
        }
    }, []);

    const getTitlesHistory = () => {
        setIsLoadingData(true)
        fetch(`${getTitlesHistoryUrl}/${policyId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            data.map((value, index) => {
                setTitlesHistory(current => [...current, value]);
            })
            return titlesHistory;
        }).then((titlesHistory) => {
            let length = Object.keys(titlesHistory).length
            for (let i = 0; i < length; i++) {
                fetch(`${titleDocumentUrl}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        titleId: titlesHistory[i].id
                    })
                }).then((res) => {
                    if (res) {
                        return res.json();
                    }
                }).then((res) => {
                    if (res !== []) {
                        setDocumentTitle(arr => [...arr, true])
                    } else {
                        setDocumentTitle(arr => [...arr, false])
                    }
                });
            }
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        })
    }

    function reload() {
        if (navigator.onLine) {
            window.location.reload();
        }
    }

    const setDocumentList = () => {
        for (let i = 0; i < titlesHistory.length; i++) {
            fetch(`${titleDocumentUrl}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    titleId: titlesHistory[i].id
                })
            }).then((res) => {
                if (res) {
                    return res.json();
                }
            }).then((res) => {
                if (res !== []) {
                    setDocumentTitle(arr => [...arr, true])
                } else {
                    setDocumentTitle(arr => [...arr, false])
                }
            });
        }
    }

    const turnBack = () => {
        return navigate(-1);
    }

    const onClickTitle = (titleId) => {
        if (window.location.href.includes('/dashboard')) {
            return `/dashboard/policies/${policyId}/titlesHistory/${titleId}`;
        } else if (window.location.href.includes('/people')) {
            return `/settings/people/${personId}/policies/${policyId}/titlesHistory/${titleId}`;
        } else if (window.location.href.includes('/vehicles')) {
            return `/settings/vehicles/${vehicleId}/policies/${policyId}/titlesHistory/${titleId}`;
        } else if (window.location.href.includes('/claims')) {
            return `/claims/claim/${claimId}/${policyId}/titlesHistory/${titleId}`;
        }
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {
                    errorPage ?
                        <ErrorPage/> :
                        <StyledPageLayout className="w-100 mx-4">
                            <StyledTitlePage>
                                <Col className="col-2 float-left">
                                    <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                                         height="27px"
                                         alt="back"/>
                                </Col>
                                <Col className="col-8">
                                    <span className="header-title ellipsis-1-v2">{t('titlesHistory.title')}</span>
                                </Col>
                                <Col className="col-2">

                                </Col>
                            </StyledTitlePage>
                            {
                                isLoadingData &&
                                <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                            }
                            {
                                !isLoadingData &&
                                <Row style={{
                                    'paddingLeft': '0',
                                    'paddingRight': '0px',
                                }}>
                                    <Col style={{
                                        'paddingLeft': '0',
                                        'paddingRight': '0px'
                                    }}>
                                        <div className={classes.rootList}>
                                            <List component="nav" aria-label="main mailbox folders">
                                                {
                                                    titlesHistory.map((title, index) => (
                                                        <Fragment key={index}>
                                                            {
                                                                index !== 0 && <Divider/>
                                                            }
                                                            <Link style={{'textDecoration': 'none'}} to={onClickTitle(title.id)}>
                                                                <ListItem className={classes.listItem} button>
                                                                    <div className={'w-100'}>
                                                                        <Row>
                                                                            <Col>
                                                                                <h6 className={'card-text-mid'}>{title.titletype}</h6>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <h6 className={"card-text-content"}>
                                                                    <span className="ellipsis-1"
                                                                          style={{'paddingBottom': '1px'}}>{t('titlesHistory.date-expirity')}
                                                                        <span className="font-weight-bold black">
                                                                            <Moment locale="it"
                                                                                    format="DD/MM/YYYY">{title.dateexpiry}</Moment>
                                                                        </span>
                                                                    </span>
                                                                                </h6>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <h6 className={"card-text-content"}>{t('titlesHistory.date-bill')}
                                                                                    <span
                                                                                        className="font-weight-bold black">
                                                                        <Moment locale="it"
                                                                                format="DD/MM/YYYY">{title.datebill}
                                                                        </Moment>
                                                                    </span>
                                                                                </h6>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <h6 className={"card-text-content"}>
                                                                    <span
                                                                        className="ellipsis-1"
                                                                        style={{'paddingBottom': '1px'}}>{t('titlesHistory.premium')}
                                                                        <span
                                                                            className="font-weight-bold black">{title.premium}
                                                                    </span>
                                                                </span>
                                                                                </h6>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <ListItemIcon className="justify-content-end">
                                                                        <img src={goTo} alt="image" height="20px"/>
                                                                    </ListItemIcon>
                                                                </ListItem>
                                                            </Link>
                                                        </Fragment>
                                                    ))
                                                }
                                            </List>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </StyledPageLayout>
                }
            </StyledPageCard>
        </ExternalPageLayout>
    )
}