import {Col, Row} from "react-bootstrap";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import goTo from "../../../images/goto-icon.svg";
import Divider from '@mui/material/Divider';
import {makeStyles} from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import AuthContextMiddleware from "../../middleware/AuthContextMiddleware.js";
import baseMaleAvatar from "../../../images/male-icon.svg";
import baseFemaleAvatar from "../../../images/female-icon.svg";
import baseCompanyAvatar from "../../../images/company-avatar.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../../components/ThemeContext.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";
import {ExternalPageLayout, StyledPageCard, StyledPageLayout, StyledTitlePage} from "../../styles/DesktopStyles.js";


const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100% !important',
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "0 0 0 0 !important",
    },
    listItem: {
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '14px !important',
        paddingBottom: '14px !important',
    },
    avatarList: {
        width: "40px !important",
        height: "40px !important",
    },
}));


export default function People({layout}) {
    const {open} = useContext(SidebarContext);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (navigator.onLine) {
            if (!searchMode) {
                //getPeople();
                getPeopleLite()
            }

            if (searchMode) {
                const delayDebounceFn = setTimeout(() => {
                    searchPeople(searchValue)
                }, 1000)
                return () => clearTimeout(delayDebounceFn)
            }

            if (localStorage.getItem('people')) {
                updateUserVersion();
                localStorage.removeItem('people')
            }
        }
    }, [searchValue]);

    const classes = useStyles();
    const [people, setPeople] = useState([]);
    const getPeopleLiteUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/peopleLite`;
    const searchPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/search`;
    const updateUserVersionUrl = `${process.env.REACT_APP_BASE_URL}/management/operation/updateUserVersion`;
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const token = sessionStorage.getItem('token');
    const [searchMode, setSearchMode] = useState(false);
    const [errorPage, setErrorPage] = useState(false);

    const getPeopleLite = () => {
        setPeople([])
        setIsLoadingData(true)
        fetch(getPeopleLiteUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPeople(data)
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const onClickPeople = (id) => {
        return navigate(`/settings/people/${id}`);
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
            case 5:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    const handleSearchMode = () => {
        if (searchMode && searchValue !== "") {
            setSearchValue("")
            document.querySelector('#search-input').value = "";
            document.querySelector('#search-input').focus();
        } else {
            setSearchMode(current => !current);
            getPeopleLite();
        }
    }

    const searchPeople = (text) => {
        setIsLoadingData(true)
        fetch(`${searchPeopleUrl}?text=${text}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPeople([]);
            data.map((item, index) => {
                if (item.status !== -1) {
                    setPeople(current => [...current, item])
                }
            })
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function turnBack() {
        return navigate('/settings');
    }

    const setGrayScale = (value) => {
        switch (value) {
            case 1:
                return 'grayscale(1)';
            case 2:
            case 3:
            case 4:
                return '';
        }
    }

    function reload() {
        window.location.reload();
    }

    const updateUserVersion = () => {
        fetch(updateUserVersionUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                newVersion: true,
                oldVersion: false
            }),
        }).then((response) => {
            return response.json();
        });
    }


    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {errorPage ?
                    <ErrorPage/> :
                    <StyledPageLayout className="w-100 mx-4">
                        <StyledTitlePage>
                            <Col className="col-2 float-left"></Col>
                            {
                                !searchMode && <Col className="col-8">
                                    <span className="header-title ellipsis-1-v2">{t('people.title')}</span>
                                </Col>
                            }
                            <Col className="col float-right"></Col>
                        </StyledTitlePage>
                        <Divider/>
                        {isLoadingData ?
                            <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                            :
                            <Row style={{
                                'paddingLeft': '0',
                                'paddingRight': '0px',
                                //'borderLeft': '1px solid #EEE9E5',
                                //'borderRight': '1px solid #EEE9E5'
                            }}>
                                <Col style={{
                                    'paddingLeft': '0',
                                    'paddingRight': '0px'
                                }}>
                                    <div className={classes.rootList}>
                                        <List component="nav" aria-label="main mailbox folders">
                                            {
                                                people.map((value, index) => (
                                                    <Fragment key={index}>

                                                        <ListItem className={classes.listItem} key={index}
                                                                  button
                                                                  onClick={() => onClickPeople(value.id)}>
                                                            <ListItemIcon>
                                                                {
                                                                    value.image === null && value.gender === 'M' &&
                                                                    <Avatar src={baseMaleAvatar}
                                                                            className={classes.avatarList}
                                                                            style={{
                                                                                filter: `${setGrayScale(value.status)}`,
                                                                                //'border': `3px solid ${setStatusColor(value.status)}`
                                                                            }}/>
                                                                }
                                                                {
                                                                    value.image === null && value.gender === 'F' &&
                                                                    <Avatar src={baseFemaleAvatar}
                                                                            className={classes.avatarList}
                                                                            style={{
                                                                                filter: `${setGrayScale(value.status)}`,
                                                                                //'border': `3px solid ${setStatusColor(value.status)}`
                                                                            }}/>
                                                                }
                                                                {
                                                                    value.image === null && value.gender === 'C' &&
                                                                    <Avatar src={baseCompanyAvatar}
                                                                            className={classes.avatarList}
                                                                            style={{
                                                                                filter: `${setGrayScale(value.status)}`,
                                                                                //'border': `3px solid ${setStatusColor(value.status)}`
                                                                            }}/>
                                                                }
                                                                {
                                                                    value.image === null && value.gender === null &&
                                                                    <Avatar src={baseMaleAvatar}
                                                                            className={classes.avatarList}
                                                                            style={{
                                                                                filter: `${setGrayScale(value.status)}`,
                                                                                //'border': `3px solid ${setStatusColor(value.status)}`
                                                                            }}/>
                                                                }
                                                                {
                                                                    value.image &&
                                                                    <Avatar src={value.image}
                                                                            className={classes.avatarList}
                                                                            style={{
                                                                                filter: `${setGrayScale(value.status)}`,
                                                                                //'border': `3px solid ${setStatusColor(value.status)}`
                                                                            }}/>
                                                                }
                                                            </ListItemIcon>
                                                            {((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                                <ListItemText>{value.name}</ListItemText> :
                                                                <ListItemText>{value.firstName} {value.lastName}</ListItemText>}
                                                            <ListItemIcon className="justify-content-end">
                                                                <img src={goTo} alt="image" height="20px"/>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                        {people.length > index + 1 && <Divider/>}
                                                    </Fragment>
                                                ))
                                            }
                                        </List>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </StyledPageLayout>
                }
            </StyledPageCard>
        </ExternalPageLayout>
    )
}
