import React, {useEffect, useState} from 'react'
import Dialog from "@mui/material/Dialog";
import {Backdrop} from "@mui/material";
import themeContext from "./ThemeContext.js";
import {useTranslation} from "react-i18next";
import {
    getArchivedUrl,
    getArchiveUrl,
    getMessagesV2Url,
    getResumeUrl,
    getUpdateUrl
} from "../service/MessagesService.js";
import {Col, Row} from "react-bootstrap";
import Chips from "./Chips.js";
import MessagesIcon from "../../images/desktop/messages.svg";
import PinIcon from "../../images/desktop/pin.svg";
import UnpinIcon from "../../images/desktop/unpin.svg";
import ArchiveIcon from "../../images/desktop/archive.svg";
import UnarchiveIcon from "../../images/desktop/unarchive.svg";
import FilterIcon from "../../images/desktop/filter.svg";
import UnfilterIcon from "../../images/desktop/unfilter.svg";
import PinnedIcon from "../../images/desktop/pinned.svg";
import exitIcon from "../../images/exit-icon.svg";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {ItemColumn, ItemContent, ItemInfoLine, ItemNameLine, ItemRow} from "../middleware/StyleMiddleware.js";
import Moment from "react-moment";
import Container from "react-bootstrap/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import toRead from "../../images/toRead-icon.png";
import IconButton from "@mui/material/IconButton";
import MessageDetailDialog from "./MessageDetailDialog.js";
import nothing from "../../images/nothing-icon.svg";
import {StyledFixedBigDialog} from "../styles/DesktopStyles.js";

export const handleMessagesNormal = async (type, token_arg, page, size) => {
    return await getMessagesV2Url(token_arg, page, size, type);
};

export const handleResumeOrArchiveCall = async (isArchive, token_arg, id) => {
    return await isArchive ? getResumeUrl(token_arg, id) : getArchiveUrl(token_arg, id);
};

export const handleArchivedOrUpdate = async (archived, token_arg, id, body) => {
    return await archived ? getArchivedUrl(token_arg, id, body) : getUpdateUrl(token_arg, id, body);
};

const MessageDialog = ({
                           open,
                           handleClose,
                           types,
                           isRequest,
                           messageId
                       }) => {

    const {t} = useTranslation();
    const root = 'desktop.messages';
    const token = sessionStorage.getItem('token');
    const [normalMessages, setNormalMessages] = useState([]);
    const [archiveMessages, setArchiveMessages] = useState([]);
    const [filter, setFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState([]);
    const [openDetail, setOpenDetail] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [type, setType] = useState(null);
    const [tab, setTab] = useState(0);
    const chips = [{value: 0, label: isRequest ? t(`${root}.tab-active-request`) : t(`${root}.tab-active`)},
        {value: 1, label: isRequest ? t(`${root}.tab-inactive-request`) : t(`${root}.tab-inactive`)}];

    const handleImportant = (element, important) => {
        handleArchivedOrUpdate(tab === 1, token, element.id, {
            isImportant: important,
            isRead: element.isRead,
        }).then(() => {
            callMessages();
        });
    }

    const handleResumeOrArchive = (isArchive, element) => {
        handleResumeOrArchiveCall(isArchive, token, element.id).then(() => {
            callMessages()
        });
    }

    function retrieveMessagesByType(type, setMethod, position) {
        setMethod([]);
        handleMessagesNormal(type, token, 0, 1).then((data) => {
            handleMessagesNormal(type, token, 0, data.totalElements).then((data) => {
                const feat_array = [];
                if (data) {
                    data.content.map((item) => {
                        feat_array.push(item);
                    })
                    setMethod(feat_array)
                }
                setIsEmpty(prevState => {
                    let newState = [...prevState];
                    newState[position] = feat_array.length === 0;
                    return newState;
                });
            }).then(() => {
                setIsLoading(false);
            })
        })
    }

    function callMessages() {
        const delayDebounceFn = setTimeout(() => {
            setIsLoading(true);
            types.map((type) => {
                if (type.filter === filter) {
                    if (type.tab === 0) retrieveMessagesByType(type.value, setNormalMessages, 0);
                    else retrieveMessagesByType(type.value, setArchiveMessages, 1);
                }
            })
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }

    useEffect(() => {
        return callMessages()
    }, [filter]);

    useEffect(() => {
        if (messageId) {
            setSelectedId(messageId);
            setType('principal');
            setOpenDetail(true);
        }
    }, []);

    const generatingList = (list, isArchive, position) => {
        return <List>
            {
                list.length === 0 && isEmpty[position] ?
                    <Container>
                        <Row>
                            <img src={nothing} className="noMessages"
                                 alt={t('messages.nothing-text-1')}
                            ></img>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <span className="no-messages-text">{t('messages.nothing-text-1')}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                                <span className="no-messages-text">{t('messages.nothing-text-2')}</span>
                            </Col>
                        </Row>
                    </Container> :
                    list.map((element, index) => (
                            <Row key={index} style={{marginLeft: '24px', marginRight: '0'}}>
                                <ListItem
                                    key={element.id}
                                    style={{width: isRequest ? '90%' : '80%', padding: '0'}}
                                    className="list-item">
                                    <ItemContent
                                        style={{border: 'none'}}
                                        onClick={() => {
                                            setSelectedId(element.id);
                                            setType(isArchive ? 'archive' : 'principal');
                                            setOpenDetail(true);
                                        }}>
                                        <ItemRow style={{paddingLeft: '0'}}>
                                            <ItemColumn style={{marginBottom: '0'}}>
                                                <ItemNameLine
                                                    className="ellipsis-1 black">
                                                    {!isRequest && element.isImportant &&
                                                        <img alt="pinned" src={PinnedIcon} width="12px"
                                                             className="mb-2 mr-2"/>}
                                                    {!element.isRead && <img alt="to-read" src={toRead} width="12px"
                                                                             className="mb-2 mr-2"/>}
                                                    {element.title}
                                                </ItemNameLine>
                                                <ItemInfoLine className="ellipsis-1"
                                                              style={{color: themeContext.color.neutral400}}>
                                                    {element.content}
                                                </ItemInfoLine>
                                            </ItemColumn>
                                            <ItemColumn className="col-min mr-0 align-items-end">
                                                <ItemNameLine className="black">
                                                    <Moment locale="it"
                                                            format="D MMM">{element.originalDate}</Moment>
                                                </ItemNameLine>
                                            </ItemColumn>
                                        </ItemRow>
                                    </ItemContent>
                                </ListItem>
                                {!isRequest &&
                                    <Col style={{alignContent: 'center', textAlign: 'center', paddingRight: '0'}}>
                                        <IconButton
                                            onClick={() => element.isImportant ? handleImportant(element, false) : handleImportant(element, true)}>
                                            <img src={element.isImportant ? UnpinIcon : PinIcon}
                                                 height="24px"
                                                 width="24px"
                                                 alt="back"/>
                                        </IconButton>
                                    </Col>}
                                <Col style={{alignContent: 'center', textAlign: 'center'}}>
                                    <IconButton style={{height: '40px', width: '40px'}}
                                                onClick={() => handleResumeOrArchive(isArchive, element)}>
                                        <img src={isArchive ? UnarchiveIcon : ArchiveIcon}
                                             height={isArchive ? "32px" : "24px"}
                                             width={isArchive ? "32px" : "24px"}
                                             alt="back"/>
                                    </IconButton>
                                </Col>
                            </Row>
                        )
                    )}
        </List>
    }

    return (
        <Dialog
            components={{Backdrop: Backdrop}}
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth={'sm'}
            PaperProps={{style: StyledFixedBigDialog}}>
            {openDetail && <MessageDetailDialog
                open={openDetail} handleClose={() => {
                setOpenDetail(false);
                callMessages();
            }}
                id={selectedId}
                type={type}
                isRequest={isRequest}
                handleCloseParent={handleClose}
            />}
            <Container style={{
                position: 'fixed',
                zIndex: '1',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                backgroundColor: 'white',
                padding: '0',
                margin: '0',
                width: '600px',
                paddingBottom: isRequest ? '30px' : '0'
            }}>
                <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                             style={{textAlign: 'center', paddingBottom: '0px'}}>
                    <Row>
                        <Col className="col-1 float-left"
                             style={{
                                 'paddingTop': '9px',
                                 'paddingLeft': '23px'
                             }}>
                            <img src={MessagesIcon}
                                 className="nav-top-logo float-left"
                                 height="16px"
                                 alt="back"/>
                        </Col>
                        <Col className="col-4 float-left" style={{
                            'paddingLeft': '9px',
                        }}>
                            <h6 className="header-title ellipsis-1-v2"
                                style={{
                                    'paddingBottom': '3px',
                                    'textAlign': 'left',
                                    'fontSize': '22px'
                                }}
                            >{isRequest ? t('desktop.messages.title-request') : t('desktop.messages.title')}</h6>
                        </Col>
                        <Col style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close" height="32px"
                                 onClick={handleClose} style={{float: 'inline-end'}}/>
                        </Col>
                    </Row>
                </DialogTitle>
                <Row className="container px-0" style={{paddingTop: '9px'}}>
                    <Col style={{'paddingLeft': '37px'}}>
                        <Chips style={{paddingTop: '8px'}} chips={chips}
                               selected={sessionStorage.getItem('tab_claimsHistory')}
                               onChange={(value) => {
                                   setTab(value);
                               }}/>
                    </Col>
                </Row>
                {!isRequest && <Row className="container px-0" style={{paddingTop: '8px'}}>
                    <Col style={{justifyContent: 'end'}}>
                        {filter &&
                            <Button className="filter-btn-alt float-right" color="primary"
                                    style={{'color': 'themeContext.color.main'}}
                                    onClick={() => setFilter(false)}><span
                                className="material-symbols-outlined"
                                style={{
                                    'fontSize': '22px',
                                    'paddingRight': '2px',
                                    'paddingBottom': '8px'
                                }}><img src={UnfilterIcon} alt={'unfilter'}
                                        style={{paddingRight: '4px'}}/></span>{t('desktop.messages.filter-active')}
                            </Button>}
                        {!filter &&
                            <Button className="filter-btn-alt float-right"
                                    style={{'color': 'themeContext.color.main'}}
                                    onClick={() => setFilter(true)}><span
                                className="material-symbols-outlined"
                                style={{
                                    'fontSize': '22px',
                                    'paddingRight': '2px',
                                    'paddingBottom': '8px'
                                }}><img src={FilterIcon} alt={'filter'}
                                        style={{paddingRight: '4px'}}/></span>{t('desktop.messages.filter-inactive')}
                            </Button>}
                    </Col>
                </Row>}
            </Container>
            <Container style={{marginTop: isRequest ? '120px' : '150px', paddingLeft: '0'}}>
                {isLoading &&
                    <div style={{'height': '40vh'}} className="background-loading-spinner"></div>
                }
                {
                    tab === 0 && !isLoading &&
                    generatingList(normalMessages, false, 0)
                }
                {
                    tab === 1 && !isLoading &&
                    generatingList(archiveMessages, true, 1)
                }
            </Container>
        </Dialog>
    )
}
export default MessageDialog;
