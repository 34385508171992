import * as React from 'react';
import {Fragment, useContext} from 'react';
import {styled} from "@mui/system";
import MuiCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import themeContext from "../ThemeContext.js";
import {DetailBarContext} from "../../middleware/DetailBarContext.js";
import {
    CTA_CATEGORIES,
    getBranchIcon,
    getPolicy,
    setPersonImage,
    setStatusColorDesktop,
    setStatusString
} from "../../service/DashboardService.js";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import autoCvtIcon from "../../../images/policy_branches/auto-cvt-icon.svg";
import {useTranslation} from "react-i18next";
import paymentIcon from "../../../images/nav-payment-hover.svg";
import signingIcon from "../../../images/nav-signing-hover.svg";
import regulationIcon from "../../../images/nav-regulations-hover.svg";
import {Button} from "@mui/material";
import {StatusLabel, StyledButtonAction, StyledDivider, StyledNoItemsDiv} from "../../styles/DesktopStyles.js";
import {CARD_TYPE} from "./ListCard.js";
import {getClaim, setClaimStatus} from "../../service/ClaimsService.js";
import {GridCardContainer} from "../../pages/desktop/Dashboard.js";
import skeletonGrid from "../../../images/desktop/skeletons/card_grid_skeleton.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {useNavigate} from "react-router-dom";
import {getNotification} from "../../service/PaymentsService.js";


const StyledCard = styled(MuiCard)(({theme, mini}) => ({
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '24px',
    outline: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
    gap: '16px',
    flexGrow: 1,
    width: mini === CARD_TYPE.PAYMENT ? '272px' : '400px',
    height: mini === CARD_TYPE.PAYMENT ? '124px' : '224px',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
    }
}));

const StyledEmptyCard = styled(MuiCard)(({theme, mini}) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '0px',
    flexGrow: 1,
    width: mini === CARD_TYPE.PAYMENT ? '272px' : '400px',
    height: '0.1px',
    opacity: 0,
}));

const StyledCardContent = styled(CardContent)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '0 !important',
    width: '100%',
}));

const CardContentTop = styled('div')(({theme, mini}) => ({
    padding: '16px',
    height: '160px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'start',
    fontSize: '14px',
    justifyContent: 'start',
    gap: mini ? '8px' : '16px'
}));

const CardContentBottom = styled('div')(({theme}) => ({
    paddingLeft: '16px',
    paddingRight: '16px',
    height: '64px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
}));

const BackgroundLeftImage = styled('div')(({theme}) => ({
    background: themeContext.color.neutral100,
    borderRadius: '100%',
    width: '32px',
    height: '32px',
    minWidth: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const CardRow = styled('div')(({theme, left}) => ({
    display: 'flex',
    justifyContent: left ? 'left' : 'space-between',
    alignItems: 'center',
    gap: '8px',
    width: "100%",
}));

const CardColumnLeft = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'start',
    //alignItems: 'center',
    gap: '8px',
    width: "70%",
}));

const CardColumn = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'start',
}));

const CardColumnRight = styled('div')(({theme, ignore}) => ({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '8px',
    width: ignore ? 'auto' : '30%',
}));


const CardText = styled('span')(({theme}) => ({
    fontSize: '14px',
}));

const CardTextBold = styled('span')(({theme, main}) => (
    main ?
        {
            fontSize: '18px',
            fontWeight: 'bold',
            color: themeContext.color.main
        } :
        {
            fontSize: '14px',
            fontWeight: 'bold'
        }
));

const CardTextBigBold = styled('span')(({theme}) => ({
    fontSize: '18px',
    fontWeight: 'bold'
}));

export default function GridCard({
                                     type,
                                     object,
                                     isLoading,
                                     handleSignDocumentPolicy,
                                     handleRegulatePolicy,
                                     handleRenewPolicy,
                                     activeCard,
                                     setActiveCard,
                                     noItemsText
                                 }) {
    const token = sessionStorage.getItem('token')
    const {t, i18n} = useTranslation();
    const {active, activeDetailBar, data, updateData, handleLoading, updateDataAndType} = useContext(DetailBarContext);
    const emptyCards = Array(8).fill(null);
    const skeletonNr = Array(6).fill(null);
    const navigate = useNavigate();
    let peopleArray = [];

    if (object && type === CARD_TYPE.PAYMENT) {
        peopleArray = object.people;
        object = object.data;
    }

    const handleCard = (value) => {
        if (data && (data.id === value.id)) {
            setActiveCard(null)
            updateData(null)
        } else {
            setActiveCard(value.id)
            switch (type) {
                case CARD_TYPE.POLICY:
                    getPolicy(token, value.id).then((res) => {
                        updateDataAndType(res, type)
                    });
                    break;
                case CARD_TYPE.CLAIM:
                    getClaim(token, value.id).then((res) => {
                        updateDataAndType(res, type)
                    });
                    break;
                case CARD_TYPE.PAYMENT:
                    getNotification(object, value.id, peopleArray).then((res) => {
                        updateDataAndType(res, type)
                    });
                    break;
            }
        }
    }

    const handleCTA = (event, value) => {
        event.stopPropagation();
        updateData(null)
        switch (value) {
            case CTA_CATEGORIES.PAYMENTS:
                handleRenewPolicy(value.id);
                break;
            case CTA_CATEGORIES.SIGNING:
            case CTA_CATEGORIES.PENDING:
                handleSignDocumentPolicy(value.id);
                break;
            case CTA_CATEGORIES.REGULATIONS:
                handleRegulatePolicy();
                break;
        }
    }

    const cardPlaceholderNew = (
        <GridCardContainer>
            {skeletonNr.map((_, index) => (
                <div className={'d-flex'} key={index}>
                    <img
                        src={skeletonGrid}
                        alt="card"
                        style={{
                            width: '100%',
                            height: '223px',
                        }}
                    />
                </div>
            ))}
        </GridCardContainer>
    );

    const getStyleFromType = (value, type) => {
        switch (type) {
            case CARD_TYPE.POLICY:
                return {outline: `${themeContext.spacing.borders.border2} ${setStatusColorDesktop(value.status)}`}
            case CARD_TYPE.CLAIM:
                return {outline: `${themeContext.spacing.borders.border2} ${setClaimStatus(value.closeType, value.settledValueCents).color}`}
            case CARD_TYPE.PAYMENT:
                return {outline: `${themeContext.spacing.borders.border2} ${themeContext.color.main}`}
        }
    }

    const noItems = (
        <StyledNoItemsDiv>
            <span className={'d-flex align-items-center font-weight-bold font-italic'}
                  style={{color: themeContext.color.neutral400}}>{noItemsText}</span>
        </StyledNoItemsDiv>
    )

    return (
        isLoading < 2 ? cardPlaceholderNew : (object.length === 0 ? noItems :
            <Fragment>
                {object.map((value, index) => (
                    <StyledCard onClick={() => handleCard(value)} key={index}
                                mini={type}
                                style={(activeCard && (value.id === activeCard)) ? getStyleFromType(value, type) : {}}>
                        {type === CARD_TYPE.POLICY &&
                            <StyledCardContent>
                                <CardContentTop>
                                    <CardRow>
                                        <CardColumnLeft style={{alignItems: 'center'}}>
                                            <BackgroundLeftImage>
                                                <img src={getBranchIcon(value.type)} alt={'type'}/>
                                            </BackgroundLeftImage>
                                            <CardText className={'ellipsis-1-v2'}>
                                                {getBranchIcon(value.type) === autoCvtIcon ? value.plate : value.type}
                                            </CardText>
                                        </CardColumnLeft>
                                        <CardColumnRight>
                                            <Avatar sx={{width: 32, height: 32}} alt={value.name}
                                                    src={setPersonImage(value.gender, value.image)} key={value.id}/>
                                        </CardColumnRight>
                                    </CardRow>
                                    <CardRow>
                                        <CardColumnLeft style={{flexDirection: 'column', alignItems: 'start'}}>
                                            <CardTextBigBold className={'ellipsis-1-v2'}>
                                                {moment(value.insuranceEnd).format('DD/MM/YYYY')}
                                            </CardTextBigBold>
                                            <CardText className={'text-left ellipsis-2-v2'}>
                                                {value.company}
                                            </CardText>
                                        </CardColumnLeft>
                                        <CardColumnRight style={{
                                            display: '-webkit-box',
                                            WebkitLineClamp: 4,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            textAlign: 'end'
                                        }}>
                                            {(value.firstName && value.lastName) ? `${value.firstName} ${value.lastName}` : value.name}
                                        </CardColumnRight>
                                    </CardRow>
                                </CardContentTop>
                                <Fragment>
                                    <StyledDivider/>
                                    <CardContentBottom>
                                        <StatusLabel color={value.status} type={CARD_TYPE.POLICY}>
                                            {t(setStatusString(value.status))}
                                        </StatusLabel>
                                        {
                                            value.cta.map((cta, index) => (
                                                <Fragment key={index}>
                                                    {cta === CTA_CATEGORIES.PENDING &&
                                                        <StyledButtonAction className={'text-uppercase font-weight-bold '}
                                                                            endIcon={<ChevronRightIcon/>}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                updateData(null)
                                                                                navigate('/signing')
                                                                            }}>
                                                            {t('dashboard.revision-button')}
                                                        </StyledButtonAction>}
                                                    {cta && cta !== CTA_CATEGORIES.PENDING &&
                                                        <Button onClick={(event) => handleCTA(event, cta)} variant="text"
                                                                sx={{borderRadius: '12px', border: 'none', fontWeight: 'bold'}}
                                                                startIcon={(cta !== CTA_CATEGORIES.PENDING && cta !== CTA_CATEGORIES.FUTURE) &&
                                                                    <img
                                                                        src={cta === CTA_CATEGORIES.PAYMENTS ? paymentIcon : (cta === CTA_CATEGORIES.SIGNING ? signingIcon : (cta === CTA_CATEGORIES.REGULATIONS ? regulationIcon : {}))}
                                                                        alt={cta}/>}>
                                                            {cta === CTA_CATEGORIES.PAYMENTS && t('dashboard.renew-button')}
                                                            {cta === CTA_CATEGORIES.SIGNING && t('dashboard.sign-button')}
                                                            {cta === CTA_CATEGORIES.PENDING && t('dashboard.revision-button')}
                                                            {cta === CTA_CATEGORIES.REGULATIONS && t('dashboard.regulations-button')}
                                                        </Button>}
                                                </Fragment>
                                            ))
                                        }
                                    </CardContentBottom>
                                </Fragment>
                            </StyledCardContent>}
                        {type === CARD_TYPE.CLAIM &&
                            <StyledCardContent>
                                <CardContentTop>
                                    <CardRow>
                                        <CardColumnLeft style={{alignItems: 'center'}}>
                                            <BackgroundLeftImage>
                                                <img src={value.plate ? autoCvtIcon : getBranchIcon(value.claimtype)}
                                                     alt={'type'}/>
                                            </BackgroundLeftImage>
                                            <CardText className={'ellipsis-1-v2'}>
                                                {value.plate ? value.plate : value.claimtype}
                                            </CardText>
                                        </CardColumnLeft>
                                        <CardColumnRight>
                                            <Avatar sx={{width: 32, height: 32}} alt={value.name}
                                                    src={setPersonImage(value.gender, value.image)} key={value.id}/>
                                        </CardColumnRight>
                                    </CardRow>


                                    <CardRow>
                                        <CardColumn style={{flexDirection: 'column', alignItems: 'start'}}>
                                            <CardTextBigBold className={'ellipsis-1-v2'}>
                                                {moment(value.eventdate).format('DD/MM/YYYY')}
                                            </CardTextBigBold>
                                            <CardText className={'text-left ellipsis-2-v2'}>
                                                {value.controparte ? t(`desktop.claims.counterpart`) : ''} <CardTextBold
                                                className={'ellipsis-1-v2'}>{value.controparte}</CardTextBold>
                                            </CardText>
                                            <CardText className={'text-left ellipsis-2-v2'}>
                                                {t(`desktop.claims.open date`)} <CardTextBold
                                                className={'ellipsis-1-v2'}>{value.claimnr}</CardTextBold>
                                            </CardText>
                                        </CardColumn>
                                    </CardRow>
                                </CardContentTop>
                                <Fragment>
                                    <StyledDivider/>
                                    <CardContentBottom>
                                        <StatusLabel
                                            color={setClaimStatus(value.closeType, value.settledValueCents)}
                                            type={CARD_TYPE.CLAIM}>
                                            {t(setClaimStatus(value.closeType, value.settledValueCents).label)}
                                        </StatusLabel>
                                    </CardContentBottom>
                                </Fragment>
                            </StyledCardContent>
                        }
                        {type === CARD_TYPE.PAYMENT &&
                            <StyledCardContent>
                                <CardContentTop mini={true}>
                                    <CardRow style={{marginBottom: '8px'}}>
                                        <CardColumnLeft style={{alignItems: 'center'}}>
                                            <BackgroundLeftImage>
                                                <Avatar sx={{width: 32, height: 32}} alt={value.name}
                                                        src={setPersonImage(value.gender, value.image)} key={value.id}/>
                                            </BackgroundLeftImage>
                                        </CardColumnLeft>
                                        <CardColumnRight ignore={true}>
                                            <CardTextBold className={'ellipsis-1-v2'} main={true}>
                                                {value.requestedPremium}
                                            </CardTextBold>
                                        </CardColumnRight>
                                    </CardRow>
                                    <CardRow>
                                        <CardTextBold className={'ellipsis-1-v2'}
                                                      style={{flexDirection: 'column', alignItems: 'start'}}>
                                            {value.nr}
                                        </CardTextBold>
                                    </CardRow>
                                    <CardRow left={true}>
                                        <CardColumn style={{flexDirection: 'column', alignItems: 'start'}}>
                                            {`${t(`desktop.payments.date-card`)}`}
                                        </CardColumn>
                                        <CardColumn style={{flexDirection: 'column', alignItems: 'start'}}>
                                            <CardTextBold className={'ellipsis-1-v2'}>
                                                {moment(value.datebill).format('DD/MM/YYYY')}
                                            </CardTextBold>
                                        </CardColumn>
                                    </CardRow>
                                </CardContentTop>
                            </StyledCardContent>
                        }
                    </StyledCard>
                ))}
                {emptyCards.map((_, index) => (
                    <StyledEmptyCard key={index} mini={type}/>
                ))}
            </Fragment>)
    );
}
