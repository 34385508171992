import {Navigate, Route, Routes} from "react-router-dom";
import DashboardController from "../controllers/DashboardController.js";
import Settings from "../pages/Settings.js";
import Profile from "../pages/Profile.js";
import TestCameraRecognition from "../pages/TestCameraRecognition.js";
import Password from "../pages/Password.js";
import People from "../pages/People.js";
import PersonDetail from "../pages/PersonDetail.js";
import PeoplePolicies from "../pages/PeoplePolicies.js";
import PolicyDetails from "../pages/PolicyDetails.js";
import PolicyClaims from "../pages/PolicyClaims.js";
import Documents from "../pages/Documents.js";
import TitlesHistory from "../pages/TitlesHistory.js";
import PeopleClaims from "../pages/PeopleClaims.js";
import ClaimDetail from "../pages/ClaimDetail.js";
import InvitationScreen from "../pages/InvitationScreen.js";
import PersonalDocuments from "../pages/PersonalDocuments.js";
import PersonalDocumentsSelection from "../pages/PersonalDocumentsSelection.js";
import UploadNewDocument from "../pages/UploadNewDocument.js";
import CameraRecognition from "../pages/CameraRecognition.js";
import BankReferences from "../pages/BankReferences.js";
import UploadBankRefs from "../pages/UploadBankRefs.js";
import Language from "../pages/Language.js";
import Vehicles from "../pages/Vehicles.js";
import PaymentsStatus from "../pages/PaymentsStatus.js";
import VehicleDetail from "../pages/VehicleDetail.js";
import VehiclePolicies from "../pages/VehiclePolicies.js";
import VehicleClaims from "../pages/VehicleClaims.js";
import MessagePreferences from "../pages/MessagePreferences.js";
import PersonalDocumentsList from "../pages/PersonalDocumentsList.js";
import Contacts from "../pages/Contacts.js";
import Messages from "../pages/Messages.js";
import MessageDetails from "../pages/MessageDetails.js";
import Claims from "../pages/Claims.js";
import Pin from "../pages/Pin.js";
import ConfirmedPin from "../pages/ConfirmedPin.js";
import Payments from "../pages/Payments.js";
import PaymentsMethod from "../pages/PaymentsMethod.js";
import PaymentsCheckout from "../pages/PaymentsCheckout.js";
import PaymentsSummary from "../pages/PaymentsSummary.js";
import PaymentsTitleDetail from "../pages/PaymentsTitleDetail.js";
import Signing from "../pages/Signing.js";
import UploadDocumentToVerify from "../pages/UploadDocumentToVerify.js";
import OtpVerifyID from "../pages/OtpVerifyID.js";
import ConfirmOtpVerifyID from "../pages/ConfirmOtpVerifyID.js";
import Regulations from "../pages/Regulations.js";
import OpenClaim from "../pages/OpenClaim.js";
import CarClaimForm from "../pages/CarClaimForm.js";
import GenericClaimForm from "../pages/GenericClaimForm.js";
import AttachImagesToClaimForm from "../pages/AttachImagesToClaimForm.js";
import Refunds from "../pages/Refunds.js";
import ClaimsHistory from "../pages/ClaimsHistory.js";
import TelegramHook from "../pages/TelegramHook.js";
import React from "react";
import Support from "../pages/Support.js";
import TicketDetail from "../pages/TicketDetail.js";
import PaymentsController from "../controllers/PaymentsController.js";
import ErrorPage from "../pages/ErrorPage.js";
import ServiceError from "../pages/ServiceError.js";
import ClaimsController from "../controllers/ClaimsController.js";

export default function MobileRoutes() {
    const layout = 'mobile';
    return (
        <Routes>
            <Route path="/dashboard" element={<DashboardController layout={layout}/>}></Route>
            <Route path="/settings" element={<Settings/>}></Route>
            <Route path="/settings/profile" element={<Profile/>}></Route>
            <Route path="/settings/loggedCamera/:documentGroupId"
                   element={<TestCameraRecognition/>}></Route>
            <Route path="/settings/profile/password" element={<Password/>}></Route>
            <Route path="/settings/people" element={<People/>}></Route>
            <Route path="/settings/people/:personId" element={<PersonDetail/>}></Route>
            <Route path="/settings/people/:personId/policies" element={<PeoplePolicies/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId" element={<PolicyDetails/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/policyClaims"
                   element={<PolicyClaims/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/titlesHistory"
                   element={<TitlesHistory/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/titlesHistory/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/documents"
                   element={<Documents/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/documents-to-signed"
                   element={<Documents/>}></Route>
            <Route path="/settings/people/:personId/claims" element={<PeopleClaims/>}></Route>
            <Route path="/settings/people/:personId/claims/:claimId" element={<ClaimDetail/>}></Route>
            <Route path="/settings/people/:personId/claims/:claimId/:policyId"
                   element={<PolicyDetails/>}></Route>
            {/*<Route path="/settings/people/:personId/claims/:claimId/:policyId/policyClaims"
                               element={<PolicyClaims/>}></Route>*/}
            <Route path="/settings/people/:personId/invitation" element={<InvitationScreen/>}></Route>
            <Route path="/settings/people/:personId/invitation/personalDocuments"
                   element={<PersonalDocuments/>}></Route>
            <Route path="/settings/people/:personId/invitation/personalDocuments/:documentGroupId"
                   element={<PersonalDocumentsSelection/>}></Route>
            <Route path="/settings/people/:personId/invitation/personalDocuments/new"
                   element={<UploadNewDocument/>}></Route>
            <Route path="/settings/people/:personId/invitation/personalDocuments/:documentGroupId/identify"
                   element={<CameraRecognition/>}></Route>
            <Route path="/settings/people/:personId/invitation/personalDocuments/identify"
                   element={<CameraRecognition/>}></Route>
            <Route path="/settings/people/:personId/bank-references"
                   element={<BankReferences/>}></Route>
            <Route path="/settings/people/:personId/bank-references/upload"
                   element={<UploadBankRefs/>}></Route>
            <Route path="/settings/people/:personId/bank-references/upload/personalDocuments"
                   element={<PersonalDocuments/>}></Route>
            <Route
                path="/settings/people/:personId/bank-references/upload/personalDocuments/:documentGroupId"
                element={<PersonalDocumentsSelection/>}></Route>
            <Route path="/settings/people/:personId/bank-references/upload/personalDocuments/new"
                   element={<UploadNewDocument/>}></Route>
            <Route
                path="/settings/people/:personId/bank-references/upload/personalDocuments/:documentGroupId/identify"
                element={<CameraRecognition/>}></Route>
            {/*dalla policyClaims ritorno al claimDetail /settings/people/:personId/claims/:claimId */}
            <Route path="/settings/language" element={<Language/>}></Route>
            <Route path="/settings/vehicles" element={<Vehicles/>}></Route>
            <Route path="/settings/payments" element={<PaymentsStatus/>}></Route>
            <Route path="/settings/vehicles/:vehicleId" element={<VehicleDetail/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies" element={<VehiclePolicies/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId"
                   element={<PolicyDetails/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/policyClaims"
                   element={<PolicyClaims/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/titlesHistory"
                   element={<TitlesHistory/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/titlesHistory/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/documents"
                   element={<Documents/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/documents-to-signed"
                   element={<Documents/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/claims" element={<VehicleClaims/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/claims/:claimId" element={<ClaimDetail/>}></Route>
            <Route path="/settings/message-preferences" element={<MessagePreferences/>}></Route>
            <Route path="/settings/personal-documents" element={<PersonalDocuments/>}></Route>
            <Route path="/settings/personal-documents/:documentGroupId"
                   element={<PersonalDocumentsList/>}></Route>
            <Route path="/settings/contacts"
                   element={<Contacts/>}></Route>
            <Route path="/messages" element={<Messages/>}></Route>
            <Route path="/messages/:type/:id" element={<MessageDetails/>}></Route>
            <Route path="/claims" element={<ClaimsController/>}></Route>
            <Route path="/claims/claim/:claimId" element={<ClaimDetail/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId" element={<PolicyDetails/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId/:policyTitleId" element={<Documents/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId/titlesHistory" element={<TitlesHistory/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId/titlesHistory/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId/documents" element={<Documents/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId/policyClaims" element={<PolicyClaims/>}></Route>
            <Route path="/dashboard/policies/:policyId" element={<PolicyDetails/>}></Route>
            <Route path="/dashboard/policies/:policyId/:policyTitleId" element={<Documents/>}></Route>
            <Route path="/dashboard/policies/:policyId/titlesHistory" element={<TitlesHistory/>}></Route>
            <Route path="/dashboard/policies/:policyId/titlesHistory/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/dashboard/policies/:policyId/documents" element={<Documents/>}></Route>
            <Route path="/dashboard/policies/:policyId/documents-to-signed" element={<Documents/>}></Route>
            <Route path="/dashboard/policies/:policyId/policyClaims" element={<PolicyClaims/>}></Route>
            <Route path="/contacts" element={<Contacts/>}></Route>
            <Route path="/settings/profile/code" element={<Pin/>}></Route>
            <Route path="/settings/profile/code/confirm" element={<ConfirmedPin/>}></Route>
            <Route path="/payments" element={<PaymentsController/>}></Route>
            <Route path="/payments/payment-method" element={<PaymentsMethod/>}></Route>
            <Route path="/payments/payment-method/checkout" element={<PaymentsCheckout/>}></Route>
            <Route path="/payments/:notificationId/summary" element={<PaymentsSummary/>}></Route>
            <Route path="/payments/:notificationId/summary/:titleId"
                   element={<PaymentsTitleDetail/>}></Route>
            <Route path="/payments/:notificationId/summary/payment-method"
                   element={<PaymentsMethod/>}></Route>
            <Route path="/payments/:notificationId/summary/payment-method/checkout"
                   element={<PaymentsCheckout/>}></Route>
            <Route path="/signing" element={<Signing/>}></Route>
            <Route path="/signing/:personId/upload" element={<UploadDocumentToVerify/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments"
                   element={<PersonalDocuments/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId"
                   element={<PersonalDocumentsSelection/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments/new"
                   element={<UploadNewDocument/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId/identify"
                   element={<CameraRecognition/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId/identify/otp"
                   element={<OtpVerifyID/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId/identify/otp/confirm"
                   element={<ConfirmOtpVerifyID/>}></Route>
            <Route path="/signing/:personId/upload/identify" element={<CameraRecognition/>}></Route>
            <Route path="/signing/:personId/upload/identify/otp" element={<OtpVerifyID/>}></Route>
            <Route path="/signing/:personId/upload/identify/otp/confirm"
                   element={<ConfirmOtpVerifyID/>}></Route>
            <Route path="/signing/:personId/uploadBack" element={<UploadDocumentToVerify/>}></Route>
            <Route path="/signing/:personId/uploadBack/uploadFront"
                   element={<UploadDocumentToVerify/>}></Route>
            <Route path="/signing/:personId/uploadBack/uploadFront/identify"
                   element={<CameraRecognition/>}></Route>
            <Route path="/signing/:personId/uploadBack/uploadFront/identify/otp"
                   element={<OtpVerifyID/>}></Route>
            <Route path="/signing/:personId/uploadBack/uploadFront/identify/otp/confirm"
                   element={<ConfirmOtpVerifyID/>}></Route>
            <Route path="/regulations" element={<Regulations/>}></Route>
            <Route path="/claims/open"
                   element={<OpenClaim/>}></Route>
            <Route path="/claims/open/carClaim"
                   element={<CarClaimForm/>}></Route>
            <Route path="/claims/open/carClaim/:claimId"
                   element={<CarClaimForm/>}></Route>
            <Route path="/claims/open/genericClaim"
                   element={<GenericClaimForm/>}></Route>
            <Route path="/claims/open/genericClaim/:claimId"
                   element={<GenericClaimForm/>}></Route>
            <Route path="/claims/open/carClaim/:claimId/attachImages"
                   element={<AttachImagesToClaimForm/>}></Route>
            <Route path="/claims/open/carClaim/:claimId/attachAccidentReportImages"
                   element={<AttachImagesToClaimForm/>}></Route>
            <Route path="/claims/open/genericClaim/:claimId/attachImages"
                   element={<AttachImagesToClaimForm/>}></Route>
            <Route path="/refunds" element={<Refunds/>}></Route>
            <Route path="/refunds/:personId/upload" element={<UploadBankRefs/>}></Route>
            <Route path="/refunds/:personId/upload/personalDocuments"
                   element={<PersonalDocuments/>}></Route>
            <Route path="/refunds/:personId/upload/personalDocuments/:documentGroupId"
                   element={<PersonalDocumentsSelection/>}></Route>
            <Route path="/refunds/:personId/upload/personalDocuments/new"
                   element={<UploadNewDocument/>}></Route>
            <Route path="/refunds/:personId/upload/personalDocuments/:documentGroupId/identify"
                   element={<CameraRecognition/>}></Route>
            <Route path="/settings/support"
                   element={<Support/>}></Route>
            <Route path="/settings/support/:ticketId"
                   element={<TicketDetail/>}></Route>
            <Route path="/claims/history"
                   element={<ClaimsHistory/>}></Route>
            <Route path="/telegram-hook"
                   element={<TelegramHook/>}></Route>
            <Route path="/error-page" element={<ErrorPage/>}></Route>
            <Route path="/service-error" element={<ServiceError/>}></Route>
            <Route path="/" element={<Navigate to="/dashboard" replace/>}/>
            <Route path="/*" element={<Navigate to="/dashboard" replace/>}/>
        </Routes>
    )
}
