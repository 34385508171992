import React from 'react';

const Layout = ({ children }) => {
    return (
        <div></div>
    );
};

//todo navbar

export default Layout;