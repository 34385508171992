import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import AuthContextMiddleware from "../../middleware/AuthContextMiddleware.js";
import Moment from "react-moment";
import back from "../../../images/back-icon.svg";
import dots from "../../../images/dots-icon.svg";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import goTo from "../../../images/goto-icon.svg";
import Divider from "@mui/material/Divider";
import claimsList from "../../../images/claims-icon.svg";
import {makeStyles} from "@mui/styles";
import contractListIcon from "../../../images/contracts-icon.svg";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import historyTitle from "../../../images/history-titles-icon.svg";
import FileSaver from "file-saver";
import iOSitWallet from "../../../images/IT-WALLET.png";
import iOSusWallet from "../../../images/US-WALLET.png";
import iOSdeWallet from "../../../images/DE-WALLET.png";
import androiditWallet from "../../../images/Save to Google Pay - Italian (Dark).svg";
import androidenWallet from "../../../images/Save to Google Pay - English (Dark).svg";
import androiddeWallet from "../../../images/Save to Google Pay - German (Dark).svg";
import {ClientJS} from 'clientjs';
import signedCopyIcon from "../../../images/signed-copy-icon.svg";
import useAnalyticsEventTracker from "../../middleware/useAnalyticsEventTracker.js";
import spinner from "../../../images/spinner.svg";
import moment from "moment";
import addToCalendar from "../../../images/add-to-calendar.svg";
import 'moment-timezone';
import 'moment/locale/it';
import {useTranslation} from "react-i18next";
import themeContext from "../../components/ThemeContext.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";
import {
    ExternalPageLayout,
    StyledPageCard,
    StyledPageLayout,
    StyledTitlePage,
    StyledTitlePageWithImage
} from "../../styles/DesktopStyles.js";

const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100% !important',
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "0 0 0 0 !important",
    },
    listItem: {
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '18px !important',
        paddingBottom: '18px !important',
    },
    listText: {
        fontSize: '18px !important'
    },
    card: {
        margin: "0 auto !important",
        marginTop: "0px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        marginBottom: "30px !important"
    },
}));

export default function PolicyDetails() {
    const {open} = useContext(SidebarContext);
    const [isLoadingData, setIsLoadingData] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getPolicy(policyId, type);
            if (sessionStorage.getItem("notificationId") != null) {
                sessionStorage.removeItem("notificationId")
            }
        } else {
            //handleReadOffline(id, type);
        }
    }, []);

    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const params = useParams();
    const policyId = params.policyId;
    const personId = params.personId;
    const vehicleId = params.vehicleId;
    const claimId = params.claimId;
    const type = params.type;
    const getDetailsUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies`;
    const personInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/person`;
    const getPolicyTitlesHistoryUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/titlesHistory`;
    const getIOSPassUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/pass`;
    const getAndroidPassUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/androidPass`;
    const policyDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/policy`;
    const updatePassUrl1 = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/v1/devices`;
    const updatePassUrl2 = `registrations/pass.co.eggon.wideapp`;
    const forceUpdatePassUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/v1/passes/pass.co.eggon.wideapp`;
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const authCtx = useContext(AuthContextMiddleware);
    const [policy, setPolicy] = useState([]);
    const [person, setPerson] = useState([]);
    const [titlesNr, setTitlesNr] = useState(0);
    const [lastTitle, setLastTitle] = useState([]);
    const classes = useStyles();
    const client = new ClientJS();
    const [hasSignedCopy, setHasSignedCopy] = useState(false)
    const [hasDocuments, setHasDocuments] = useState(false)
    const titleDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/title`;
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [isLoadingPass, setIsLoadingPass] = useState(false);
    const gaEventTrackerAddToAppleWallet = useAnalyticsEventTracker('AddToAppleWallet')
    moment.locale("it");
    const {t, i18n} = useTranslation();
    const [hasLastTitleDocuments, setHasLastTitleDocuments] = useState(false)


    const getPolicy = (id) => {
        setIsLoadingData(true);
        fetch(`${getDetailsUrl}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setPolicy(data)
                if (data.lastTitle.length > 0) {
                    data.lastTitle.map((value, index) => {
                        setLastTitle(current => [...current, value]);
                        getSignedCopy(value.id)
                    })
                }
                fetch(`${policyDocumentUrl}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        policyId: policyId
                    }),
                }).then((res) => {
                    if (res) {
                        return res.json();
                    }
                }).then((res) => {
                    if (res !== null) {
                        setHasDocuments(Object.keys(res).length > 0)
                    }
                }).catch(() => {
                    setErrorPage(true)
                });

                fetch(`${getPolicyTitlesHistoryUrl}/${data.id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    let count = 0;
                    data.map((value, index) => {
                        count++;
                    })
                    setTitlesNr(count)
                }).catch(() => {
                    setErrorPage(true)
                });
                fetch(`${personInfoUrl}/${data.personId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    setPerson(data);
                    setIsLoadingData(false);
                }).catch(() => {
                    setErrorPage(true)
                })
            }
            //putElementFromIndexedDB('notificationDetails', data)
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const onClickLastTitle = (id) => {
        if (window.location.href.includes('/dashboard')) {
            return navigate(`/dashboard/policies/${policyId}/${id}`);
        } else if (window.location.href.includes('/people')) {
            return navigate(`/settings/people/${personId}/policies/${policyId}/${id}`);
        } else if (window.location.href.includes('/vehicles')) {
            return navigate(`/settings/vehicles/${vehicleId}/policies/${policyId}/${id}`);
        } else if (window.location.href.includes('/claims')) {
            return navigate(`/claims/claim/${claimId}/${policyId}/${id}`);
        }
    }
    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
            case 5:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    const handleIOSPass = () => {
        setIsLoadingPass(true)
        fetch(`${getIOSPassUrl}/${policyId}`, {
            method: 'GET',
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/vnd.apple.pkpass',
                //'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res) {
                return res.blob();
            }
        }).then((res) => {
            if (res) {
                const file = new Blob([res], {type: "application/vnd.apple.pkpass"})
                FileSaver.saveAs(file, `${policy.policynr}.pkpass`);
                setIsLoadingPass(false)
                gaEventTrackerAddToAppleWallet('Click', 'Policy added to Apple Wallet')
            }
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const handleAndroidPass = () => {
        setIsLoadingPass(true)
        fetch(`${getAndroidPassUrl}/${policyId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res) {
                return res.text();
            }
        }).then((res) => {
            window.open(res, '_blank', 'noopener', 'noreferrer');
            setIsLoadingPass(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const turnBack = () => {
        return navigate(-1);
    }

    const linkToHistoryTitles = () => {
        if (window.location.href.includes('/dashboard')) {
            return `/dashboard/policies/${policyId}/titlesHistory`;
        } else if (window.location.href.includes('/people')) {
            return `/settings/people/${personId}/policies/${policyId}/titlesHistory`;
        } else if (window.location.href.includes('/vehicles')) {
            return `/settings/vehicles/${vehicleId}/policies/${policyId}/titlesHistory`;
        } else if (window.location.href.includes('/claims')) {
            return `/claims/claim/${claimId}/${policyId}/titlesHistory`;
        }
    }

    const linkToClaims = () => {
        if (window.location.href.includes('/claims')) {
            return navigate(`/claims/claim/${claimId}/${policyId}/policyClaims`);
        } else if (window.location.href.includes('/people')) {
            return navigate(`/settings/people/${personId}/policies/${policyId}/policyClaims`);
        } else if (window.location.href.includes('/dashboard')) {
            return navigate(`/dashboard/policies/${policyId}/policyClaims`);
        } else if (window.location.href.includes('/vehicles')) {
            return navigate(`/settings/vehicles/${vehicleId}/policies/${policyId}/policyClaims`);
        }
    }

    const linkToDocuments = () => {
        if (window.location.href.includes('/vehicles')) {
            return navigate(`/settings/vehicles/${vehicleId}/policies/${policyId}/documents`);
        } else if (window.location.href.includes('/people')) {
            return navigate(`/settings/people/${personId}/policies/${policyId}/documents`);
        } else if (window.location.href.includes('/dashboard')) {
            return navigate(`/dashboard/policies/${policyId}/documents`);
        } else if (window.location.href.includes('/claims')) {
            return navigate(`/claims/claim/${claimId}/${policyId}/documents`);
        }
    }

    const linkToDocumentsToSigned = () => {
        if (window.location.href.includes('/vehicles')) {
            return navigate(`/settings/vehicles/${vehicleId}/policies/${policyId}/documents-to-signed`);
        } else if (window.location.href.includes('/people')) {
            return navigate(`/settings/people/${personId}/policies/${policyId}/documents-to-signed`);
        } else if (window.location.href.includes('/dashboard')) {
            return navigate(`/dashboard/policies/${policyId}/documents-to-signed`);
        } else if (window.location.href.includes('/claims')) {
            return navigate(`/claims/claim/${claimId}/${policyId}/documents-to-signed`);
        }
    }

    const getLang = () => {
        switch (navigator.language) {
            case 'it':
            case 'it-IT':
                return 'it';
            case 'en':
            case 'en-EN':
            case 'en-US':
                return 'en';
            case 'de':
            case 'de-DE':
                return 'de';
            default:
                return 'it';
        }
    }

    const getSignedCopy = (id) => {
        fetch(`${titleDocumentUrl}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                titleId: id
            })
        }).then((res) => {
            if (res) {
                return res.json();
            }
        }).then((res) => {
            if (res !== null) {
                setHasLastTitleDocuments(Object.keys(res).length > 0);
                res.map((value, index) => {
                    if (value.type === "copia_da_restituire") {
                        setHasSignedCopy(true)
                    }
                })
            }
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function reload() {
        window.location.reload();
    }

    const updatePass = () => {
        fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            return data.id;
        }).then((userId) => {
            fetch(`${updatePassUrl1}/${userId}/${updatePassUrl2}/${policyId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/vnd.apple.pkpass'
                },
            }).then((res) => {
                if (res) {
                    return res.blob();
                }
            }).then((res) => {
                if (res) {
                    const file = new Blob([res], {type: "application/vnd.apple.pkpass"})
                    FileSaver.saveAs(file, `${policy.policynr}.pkpass`);
                }
            }).catch(() => {
                setErrorPage(true)
            });
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const forceUpdatePass = () => {
        fetch(`${forceUpdatePassUrl}/${policyId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/vnd.apple.pkpass'
            },
        }).then((res) => {
            if (res) {
                return res.blob();
            }
        }).then((res) => {
            if (res) {
                const file = new Blob([res], {type: "application/vnd.apple.pkpass"})
                FileSaver.saveAs(file, `${policy.policynr}.pkpass`);
            }
        }).catch(() => {
            setErrorPage(true)
        });
    }


    const generateICSFile = (policy) => {
        const event = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Your Organization//Your Product//IT
CALSCALE:GREGORIAN
METHOD:PUBLISH
BEGIN:VEVENT
UID:${new Date().toISOString()}
DTSTAMP:${new Date().toISOString().replace(/[-:]/g, '').split('.')[0]}Z
DTSTART:${moment(policy.insuranceend).toISOString().replace(/[-:]/g, '').split('.')[0]}
DTEND:${moment(policy.insuranceend).add(1, 'day').toISOString().replace(/[-:]/g, '').split('.')[0]}
SUMMARY:Scadenza polizza ${policy.type} - ${policy.object ? policy.object : ''}
DESCRIPTION:${moment(policy.insuranceend).format('DD-MM-YYYY')}\\n${policy.company}\\n${policy.policynr}\\n${(policy.lastTitle && policy.lastTitle[0].premium) ? policy.lastTitle[0].premium : ''}
LOCATION:
END:VEVENT
END:VCALENDAR
    `.trim();

        const blob = new Blob([event], {type: 'text/calendar'});
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${policy.id}.ics`;
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {
                    errorPage ?
                        <ErrorPage/> :
                        <StyledPageLayout className="w-100 mx-2">
                            <StyledTitlePageWithImage height={275}>
                                <Col>
                                    <Row className="first-nav-top-row-shadow">
                                        <Col className="col-2 float-left">
                                            <img src={back} onClick={turnBack}
                                                 className="nav-top-logo float-left cursor-pointer"
                                                 height="27px"
                                                 alt="back"/>
                                        </Col>
                                        <Col className="col-8">
                                            <span className="header-title ellipsis-1-v2">{t('policyDetail.title')}</span>
                                        </Col>
                                        <Col className="col-2 float-right">
                                            <img hidden src={dots}
                                                 className="nav-top-logo float-right cursor-pointer"
                                                 height="27px"
                                                 alt="dots"/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: '11px'}}>
                                        <Col>
                                            {
                                                isLoadingData &&
                                                <div style={{'height': '90vh'}}
                                                     className="background-loading-spinner"></div>
                                            }
                                            {
                                                !isLoadingData &&
                                                <Fragment>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="message-type-title"
                                                                style={{'textAlign': 'left'}}>
                                                                {policy.object}
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col>
                                                            <h6 className="font-weight-bold message-original-date"
                                                                style={{'textAlign': 'left'}}>
                                                                <Moment
                                                                    locale="it"
                                                                    format="DD/MM/YYYY"
                                                                    className="black">{policy.insurancestart}</Moment>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row className={'d-flex align-items-center'}
                                                         style={{gap: '3px', marginBottom: '8px'}}>
                                                        <div className={'col-auto'}>
                                                            <span
                                                                className="text-normal-detail">{t('policyDetail.expiration-label')}<Moment
                                                                locale="it"
                                                                format="DD/MM/YYYY"
                                                                className="text-bold-detail black">{policy.insuranceend}</Moment>
                                                            </span>
                                                        </div>
                                                        <div className={'d-flex justify-content-start'}>
                                                            <img className={'cursor-pointer'}
                                                                 alt={'add-to-calendar'}
                                                                 src={addToCalendar}
                                                                 onClick={() => generateICSFile(policy)}></img>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="text-normal-detail ellipsis-1-v2">{t('policyDetail.person-label')}
                                                                {
                                                                    ((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                                        <span
                                                                            className="text-bold-detail black">{person.name}</span> :
                                                                        <span
                                                                            className="text-bold-detail black">{person.firstName} {person.lastName}</span>
                                                                }
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="text-normal-detail ellipsis-1-v2">
                                                                {t('policyDetail.policy-number-label')}
                                                                <span className="text-bold-detail black">
                                                                        {policy.policynr}
                                                                    </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{'marginBottom': '20px'}}>
                                                        <Col>
                                                            <h6 className="text-normal-detail ellipsis-1-v2">{t('policyDetail.company-label')}<span
                                                                className="text-bold-detail black">{policy.company}</span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </StyledTitlePageWithImage>
                            <div style={{
                                'backgroundColor': themeContext.color.white,
                                'paddingLeft': '0',
                                'paddingRight': '0px',
                            }}>
                                {
                                    lastTitle.length !== 0 &&
                                    <Row style={{
                                        'marginTop': "30px",
                                    }}>
                                        <Col>
                                            <h6 className="card-text-mid">
                                                {t('policyDetail.last-title')}
                                            </h6>
                                        </Col>
                                    </Row>
                                }
                                {
                                    lastTitle !== [] && lastTitle.map((title, index) => (
                                        <Fragment key={index}>
                                            <Card className={classes.card}
                                                  onClick={() => {
                                                      if (hasLastTitleDocuments) {
                                                          onClickLastTitle(title.id)
                                                      }
                                                  }}
                                                  style={{'borderRight': `12px solid ${setStatusColor(title.status)}`}}>
                                                <CardContent style={{
                                                    'paddingTop': '0px',
                                                    'paddingBottom': '0px'
                                                }}>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-mid ellipsis-1-v2">{title.titletype}</h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content">{t('policyDetail.date-bill')}
                                                                <span className="font-weight-bold black"><Moment
                                                                    locale="it"
                                                                    format="DD/MM/YYYY">{title.datebill}</Moment></span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">
                                                                {t('policyDetail.date-expirity')}
                                                                <span className="font-weight-bold black"><Moment
                                                                    locale="it"
                                                                    format="DD/MM/YYYY">{title.dateexpiry}</Moment></span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="col-auto">
                                                            <h6 className="card-text-content ellipsis-1-v2">
                                                                {t('policyDetail.premium')}
                                                                <span className="font-weight-bold black">
                                                       {title.premium}
                                                        </span>
                                                            </h6>
                                                        </Col>
                                                        {hasLastTitleDocuments &&
                                                            <Col>
                                                                <Row>
                                                                    <Col>
                                                                        <h6 style={{
                                                                            'color': themeContext.color.main,
                                                                            'textAlign': 'right'
                                                                        }}>
                                                                            {t('policyDetail.title-button')}</h6>
                                                                    </Col>
                                                                </Row>
                                                            </Col>}
                                                    </Row>
                                                </CardContent>
                                            </Card>
                                        </Fragment>
                                    ))
                                }
                                <Row>
                                    <Col style={{
                                        'paddingLeft': '0',
                                        'paddingRight': '0px',
                                    }}>
                                        <div className={classes.rootList}>
                                            <List component="nav">
                                                {hasDocuments &&
                                                    <Fragment>
                                                        <Divider/>
                                                        <ListItem className={classes.listItem} button
                                                                  onClick={linkToDocuments}>
                                                            <ListItemIcon>
                                                                <img src={contractListIcon} alt="image"
                                                                     width="28px"/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={t('policyDetail.list-documents')}
                                                                          className={classes.listText}/>
                                                            <ListItemIcon className="justify-content-end">
                                                                <img src={goTo} alt="image" height="20px"/>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    </Fragment>
                                                }
                                                <Divider/>
                                                <ListItem className={classes.listItem} button
                                                          onClick={linkToClaims}>
                                                    <ListItemIcon>
                                                        <img src={claimsList} alt="image" width="28px"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={t('policyDetail.list-claims')}
                                                                  className={classes.listText}/>
                                                    <ListItemIcon className="justify-content-end">
                                                        <img src={goTo} alt="image" height="20px"/>
                                                    </ListItemIcon>
                                                </ListItem>
                                                <Divider/>
                                                {
                                                    titlesNr !== 0 &&
                                                    <Link to={linkToHistoryTitles()}
                                                          style={{'textDecoration': 'none'}}>
                                                        <ListItem className={classes.listItem} button>
                                                            <ListItemIcon>
                                                                <img src={historyTitle} alt="image" width="28px"/>
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={t('policyDetail.list-title-history')}/>
                                                            <ListItemIcon className="justify-content-end">
                                                                <span>{titlesNr}</span>
                                                                <img src={goTo} alt="image" height="20px"/>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                        <Divider/>
                                                    </Link>
                                                }
                                                {
                                                    hasSignedCopy && policy.hasPending === false &&
                                                    <Fragment>
                                                        <ListItem className={classes.listItem} button
                                                                  onClick={linkToDocumentsToSigned}>
                                                            <ListItemIcon>
                                                                <img src={signedCopyIcon} alt="image" width="28px"/>
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={t('policyDetail.title-documents-to-signed')}/>
                                                            <ListItemIcon className="justify-content-end">
                                                                <img src={goTo} alt="image" height="20px"/>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                        <Divider/>
                                                    </Fragment>
                                                }
                                            </List>
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    client.isMobileIOS() &&
                                    <Fragment>
                                        <Row className="row-border" style={{
                                            'paddingTop': '22px',
                                            'paddingBottom': '20px'
                                        }}>
                                            <Col onClick={handleIOSPass}>
                                <span className="wallet-description">
                                    {t('policyDetail.wallet-description-1')}
                                </span>
                                                <br/>
                                                <span className="wallet-description">
                                    {t('policyDetail.wallet-description-2')}
                                </span>
                                            </Col>
                                        </Row>
                                        <Row className="row-border" style={{
                                            'paddingTop': '22px',
                                            'paddingBottom': '22px'
                                        }}>
                                            <Col className="col-auto align-center">
                                                {
                                                    getLang() === 'it' && !isLoadingPass &&
                                                    <img src={iOSitWallet} alt="it-wallet" width="148"
                                                         onClick={handleIOSPass}/>
                                                }
                                                {
                                                    getLang() === 'it' && isLoadingPass &&
                                                    <img src={spinner} className="spinner-img" alt="spinner"/>
                                                }
                                                {
                                                    getLang() === 'en' && !isLoadingPass &&
                                                    <img src={iOSusWallet} alt="us-wallet" width="148"
                                                         onClick={handleIOSPass}/>
                                                }
                                                {
                                                    getLang() === 'en' && isLoadingPass &&
                                                    <img src={spinner} className="spinner-img" alt="spinner"/>
                                                }
                                                {
                                                    getLang() === 'de' && !isLoadingPass &&
                                                    <img src={iOSdeWallet} alt="de-wallet" width="148"
                                                         onClick={handleIOSPass}/>
                                                }
                                                {
                                                    getLang() === 'de' && isLoadingPass &&
                                                    <img src={spinner} className="spinner-img" alt="spinner"/>
                                                }
                                            </Col>
                                        </Row>
                                    </Fragment>
                                }
                                {
                                    !client.isMobileIOS() && client.isMobile() &&
                                    <Fragment>
                                        <Row className="row-border" style={{
                                            'paddingTop': '22px',
                                            'paddingBottom': '20px'
                                        }}>
                                            <Col onClick={handleAndroidPass}>
                                                <span className="wallet-description">
                                                    {t('policyDetail.wallet-description-1')}
                                                </span>
                                                <br/>
                                                <span className="wallet-description">
                                                    {t('policyDetail.wallet-description-2')}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className="row-border" style={{
                                            'paddingTop': '22px',
                                            'paddingBottom': '22px'
                                        }}>
                                            <Col className="col-auto align-center">
                                                {
                                                    getLang() === 'it' && !isLoadingPass &&
                                                    <img src={androiditWallet} alt="it-wallet"
                                                         onClick={handleAndroidPass}/>
                                                }
                                                {
                                                    getLang() === 'it' && isLoadingPass &&
                                                    <img src={spinner} className="spinner-img" alt="spinner"/>
                                                }
                                                {
                                                    getLang() === 'en' && !isLoadingPass &&
                                                    <img src={androidenWallet} alt="us-wallet"
                                                         onClick={handleAndroidPass}/>
                                                }
                                                {
                                                    getLang() === 'en' && isLoadingPass &&
                                                    <img src={spinner} className="spinner-img" alt="spinner"/>
                                                }
                                                {
                                                    getLang() === 'de' && !isLoadingPass &&
                                                    <img src={androiddeWallet} alt="de-wallet"
                                                         onClick={handleAndroidPass}/>
                                                }
                                                {
                                                    getLang() === 'de' && isLoadingPass &&
                                                    <img src={spinner} className="spinner-img" alt="spinner"/>
                                                }
                                            </Col>
                                        </Row>
                                    </Fragment>
                                }
                            </div>
                        </StyledPageLayout>
                }
            </StyledPageCard>
        </ExternalPageLayout>
    )
}