import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Button} from "@mui/material";
import logoAlter from "../../../images/logo-alter.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import themeContext from "../../components/ThemeContext.js";
import {NavbarContext} from "../../middleware/NavbarContext.js";
import {getClientDatas} from "../../service/UtilityService.js";
import {InterceptorContext} from "../../middleware/InterceptorContext.js";
import spinner from "../../../images/spinner.svg";
import {ExternalPageLayout, StyledPageCard} from "../../styles/DesktopStyles.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";
import Container from "react-bootstrap/Container";


const useStyles = makeStyles((theme) => ({
    contentContainer: {
        display: 'flex !important',
        justifyContent: 'space-around !important',
        flexDirection: 'column !important',
        height: '105vh !important',
        padding: '24px 0px !important',
    },
    content: {
        padding: '24px !important',
        display: 'flex !important',
        flexDirection: 'column !important',
        alignItems: 'center !important',
        marginBottom: '30px !important',
    },
    contentTitle: {
        justifySelf: 'center !important',
        textAlign: 'center !important',
        font: 'normal normal 600 22px Inter !important',
        paddingBottom: '26px !important',
        lineHeight: '26.63px !important',
        color: `${themeContext.color.main} !important`
    },
    contentDescription: {
        textAlign: 'center !important',
        font: 'normal normal 400 16px Inter !important',
        lineHeight: '22px !important',
        paddingBottom: '24px !important',
        maxWidth: '424px !important',
    },
    contentButton: {
        width: '90% !important',
        height: '44px !important',
        maxWidth: '350px !important',
        cursor: 'pointer !important',
        textTransform: 'none !important',
        borderRadius: '12px !important',
        font: 'normal normal 700 16px/28px Inter !important',
        color: `${themeContext.color.white} !important`,
        backgroundColor: `${themeContext.color.main} !important`,
    },
}));


const ErrorPage = ({}) => {
    const {open} = useContext(SidebarContext);
    const {visibility, toggleNavbar} = useContext(NavbarContext);
    const token = sessionStorage.getItem(`token`)
    const newErrorUrl = `${process.env.REACT_APP_BASE_URL}/profile/support/newError`;
    const {title, message} = useContext(InterceptorContext);

    useEffect(() => {
        toggleNavbar(false)
    }, []);

    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)

    const turnBack = () => {
        setIsLoading(false)
        toggleNavbar(true)
        return navigate(-2); // otherwise redirect to '/dashboard'
    }

    const newError = () => {
        if (title && message) {
            console.log('newError')
            setIsLoading(true)
            fetch(`${newErrorUrl}`, {
                method: 'POST',
                body: JSON.stringify({
                    title: title,
                    description: message,
                    deviceData: JSON.stringify(getClientDatas()),
                }),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                return response.json();
            }).then(() => {
                turnBack();
            })
        } else {
            console.log('turn back')
            turnBack();
        }
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                <Container className="mt-0 full-height d-flex justify-content-around flex-column"
                     style={{'backgroundColor': themeContext.color.white}}>
                    <div className={classes.content}>
                        <img className={classes.contentDescription} src={logoAlter} width={'133px'}
                             alt={logoAlter}></img>
                    </div>
                    <div className={classes.content}>
                        <h6 className={classes.contentTitle}>{t('Qualcosa è andato storto!')}</h6>
                        <h6 className={classes.contentDescription}>
                            {t('Sembra che la funzione non sia attualmente disponibile a causa di alcuni problemi, è stata inviata una segnalazione al team WideGROUP che risolverà al più presto il problema.')}</h6>
                    </div>
                    <div className={classes.content}>
                        <Button className='primary-button' size="large" style={{'width': '288px'}}
                                onClick={() => newError()}>{isLoading ? <img src={spinner} className="spinner-img"
                                                                             alt="spinner"/> : t('Ho capito!')}</Button>
                    </div>
                </Container>
            </StyledPageCard>
        </ExternalPageLayout>

    )
}
export default ErrorPage;
