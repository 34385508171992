import React, {useState} from 'react';
import {styled} from "@mui/material/styles";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import themeContext from "../ThemeContext.js";
import IconButton from "@mui/material/IconButton";
import spinner from "../../../images/spinner.svg";

const Wrapper = styled('div')(({theme}) => ({
    position: 'relative',
    background: themeContext.color.defaultBackground,
    borderRadius: '16px',
    overflow: 'hidden', // Hide overflow content initially
    transition: 'height 0.3s ease', // Add transition for height
}));

const ChipsContainer = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '8px',
    zIndex: 2,
    background: themeContext.color.defaultBackground,
}));

const Chips = React.memo(function Chips({
                                            isLoading,
                                            chips,
                                            onChange,
                                            selected,
                                            showAll,
                                            onToggleShowAll,
                                            maintainSelection
                                        }) {
    const [selectedChip, setSelectedChip] = useState(selected);

    const handleChip = (e, item) => {
        e.preventDefault();
        if (maintainSelection) {
            setSelectedChip(item);
            onChange(item);
        } else {
            if (selectedChip === item) {
                setSelectedChip(null);
                onChange(null);
            } else {
                setSelectedChip(item);
                onChange(item);
            }
        }
    };

    return (
        <div className={'d-flex flex-row align-items-start justify-content-between'}
             style={{background: themeContext.color.defaultBackground, marginTop: '8px', marginBottom: '8px'}}>
            <Wrapper style={showAll ? {height: 'auto'} : {height: '35px'}}>
                {isLoading ? <img src={spinner} className="spinner-img-inner-button"
                                  alt="spinner"/> : <ChipsContainer>
                    {chips.map((chip, index) => (
                        <div key={index}
                             className={selectedChip === chip.value ? 'desktop-chip-selected' : 'desktop-chip'}
                             onClick={(e) => handleChip(e, chip.value)}>
                            {chip.icon && <img src={selectedChip === chip.value ? chip.iconSelected : chip.icon}
                                               alt={chip.label}/>}
                            {chip.label}
                        </div>
                    ))}
                </ChipsContainer>}
            </Wrapper>
            {showAll !== null && <IconButton onClick={onToggleShowAll} className={'p-0'}>
                {showAll ? <ExpandMore/> : <ExpandLess/>}
            </IconButton>}
        </div>
    );
});

export default Chips;
