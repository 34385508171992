import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../../images/back-icon.svg";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {useTranslation} from 'react-i18next'
import {Button, Slide} from "@mui/material";
import identityConfirmedPh from "../../../images/identityConfirmed-ph.svg";
import identityNotConfirmedPh from "../../../images/identityNotConfirmed-ph.svg";
import startRecognitionPh from "../../../images/startRecognition-ph.svg";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import exit from "../../../images/exit-icon.svg";
import frame from "../../../images/frame.svg";
import Divider from "@mui/material/Divider";
import {putElementFromIndexedDB} from "../../service/UtilityService.js";
import AuthContextMiddleware from "../../middleware/AuthContextMiddleware.js";
import {Camera} from "react-camera-pro";
import 'react-camera-ios/build/styles.css';
import VideoSnapshot from "video-snapshot";
import {useMediaQuery} from "react-responsive";
import DialogDoubleAction from "../../components/DialogDoubleAction.js";
import PlaceholderScreen from "../../components/PlaceholderScreen.js";
import DialogSmall from "../../components/DialogSmall.js";
import themeContext from "../../components/ThemeContext.js";
import {StyledBackdrop} from "../../styles/Styles.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";
import {ExternalPageLayout, StyledPageCard, StyledTitlePage} from "../../styles/DesktopStyles.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        'textAlign': 'center !important',
        'paddingTop': '10px !important',
        'paddingBottom': '2px !important',
    },
    dialogDescription: {
        'textAlign': 'center !important',
        'paddingTop': '0px !important',
        'paddingBottom': '15px !important',
    }
}));

function MailTo({
                    email,
                    subject,
                    body,
                    ...props
                }) {
    return (
        <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
           style={{
               'textDecoration': 'none',
               'width': '100%',
               'margin': '0 auto'
           }}>
            {props.children}
        </a>
    );
}

export default function CameraRecognition() {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const [screenshot, setScreenshot] = useState(null)
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const webcamRef = useRef(null);
    const params = useParams();
    const documentGroupId = params.documentGroupId;
    const personId = params.personId;
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const documentId = query.get('documentId');
    const policyId = query.get('policyId');
    const titleId = query.get('titleId');
    const isCie = query.get('isCie');
    const vidiIdentifyUrl = `https://vidi.empireos.eu/api/v1/core/identify`;
    const accountExecUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/accountExec`;
    const isBlockedUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/isDocumentBlocked`;
    const updateDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/updateDocument`;
    const updateIBANUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/updateBankRef`;
    const saveIBANIntoDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/vidi/saveIban`;
    const saveInviteIntoDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/vidi/saveInvite`;
    const createOperationUrl = `${process.env.REACT_APP_BASE_URL}/management/operation/createOrUpdate`;
    const completeOperationUrl = `${process.env.REACT_APP_BASE_URL}/management/operation/complete`;
    const inviteUserUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/inviteUser`;
    const updateBankRefUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/updateBankRef`;
    const [identityConfirmed, setIdentityConfirmed] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const [remaining, setRemaining] = useState("0");
    const [identityNotConfirmed, setIdentityNotConfirmed] = useState(0);
    const [startRecognition, setStartRecognition] = useState(false);
    const [openNoCameraDialog, setOpenNoCameraDialog] = useState(false);
    const authCtx = useContext(AuthContextMiddleware);
    const ref_url = authCtx.reqImage
    const ref_img = authCtx.reqImageBase64
    const [devices, setDevices] = useState([]);
    const [openExitDialog, setOpenExitDialog] = useState(false);
    const [accountExec, setAccountExec] = useState([]);
    const [openCustomerService, setOpenCustomerService] = useState(false);
    const [phone, setPhone] = useState("");
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'});
    const invitation_fiscalCode = sessionStorage.getItem('invitation_fiscalCode');
    const invitation_email = sessionStorage.getItem('invitation_email');
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [placeholderError, setPlaceholderError] = useState(false);
    const bankRefToUpdate = authCtx.bankRefsToUpdate;
    const {open} = useContext(SidebarContext);

    let defaultValues = {
        accountHolder: "",
        iban: "",
        swift: "",
        currency: "EUR"
    };
    const [openFallbackDialog, setOpenFallbackDialog] = useState(false);

    const twoTrysDescriptionText = (
        <div>
            {t('cameraRecognition.verify-not-ok-description-1')}
            <span className="font-weight-bold">{t('cameraRecognition.two-trys')}</span>
            {t('cameraRecognition.verify-not-ok-description-2')}
        </div>
    );

    const oneTryDescriptionText = (
        <div>
            {t('cameraRecognition.verify-not-ok-1-description-1')}
            <span className="font-weight-bold">{t('cameraRecognition.one-trys')}</span>
            {t('cameraRecognition.verify-not-ok-1-description-2')}
        </div>
    );

    const zeroTrysDescriptionText = (
        <div>
            {t('cameraRecognition.verify-not-ok-description-1')}
            <span className="font-weight-bold">{t('cameraRecognition.zero-trys')}</span>
            {t('cameraRecognition.verify-not-ok-description-2')}
        </div>
    );

    const blockedDescriptionText = (
        <div>
            {t('cameraRecognition.blocked-document-description-1')}
            <span className="font-weight-bold">{remaining}</span>
            {t('cameraRecognition.blocked-document-description-2')}
        </div>
    );

    const handleDevices = React.useCallback(
        mediaDevices =>
            setDevices(mediaDevices.filter(({kind}) => kind === "videoinput")),
        [setDevices]
    );

    useEffect(() => {
        if (navigator.onLine) {
            getAccountExec();
            if (window.location.href.includes('signing')) {
                isDocumentBlocked();
            }
            navigator.mediaDevices.enumerateDevices().then(handleDevices);

            if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
                if (authCtx.backReqImageBase64 && authCtx.backReqImage && authCtx.reqImage && authCtx.reqImageBase64) {
                    //console.log('immagini presenti')
                } else {
                    //console.log('immagini non presenti')
                    setOpenFallbackDialog(true)
                }
            } else {
                if (authCtx.reqImage && authCtx.reqImageBase64) {
                    //console.log('immagini presenti')
                } else {
                    //console.log('immagini non presenti')
                    setOpenFallbackDialog(true)
                }
            }
        }
    }, [handleDevices]);

    const startTimer = () => {
        let runCount = 0;

        function timerMethod() {
            runCount++;
            if (identityNotConfirmed === 11 || identityConfirmed) clearInterval(timerId);
            if (webcamRef.current) {
                if (webcamRef.current && webcamRef.current.takePhoto()) {
                    let base64_fullString = webcamRef.current.takePhoto().split(",");
                    setScreenshot(base64_fullString[1]);
                    authCtx.storeSnapshots(base64_fullString[1]);
                    vidiIdentify(base64_fullString[1], ref_img);
                }
            }
        }

        let timerId = setInterval(timerMethod, 4000);
    }

    const restartTimer = () => {
        setIdentityNotConfirmed(current => current + 1)
        //startTimer()
    }

    const turnBack = () => {
        if (window.location.href.includes('signing')) {
            if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
                return navigate(`/signing/${personId}/uploadBack/uploadFront?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=${isCie}`)
            } else {
                if (documentGroupId === 'new') {
                    return navigate(`/signing/${personId}/upload/personalDocuments?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
                } else {
                    return navigate(`/signing/${personId}/upload/personalDocuments/${documentGroupId}?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
                }
            }
        } else if (window.location.href.includes('refunds')) {
            if (documentGroupId === 'new') {
                return navigate(`/refunds/${personId}/upload/personalDocuments`)
            } else {
                return navigate(`/refunds/${personId}/upload/personalDocuments/${documentGroupId}`)
            }
        } else if (window.location.href.includes('bank-references')) {
            if (documentGroupId === 'new') {
                return navigate(`/settings/people/${personId}/bank-references/upload/personalDocuments`)
            } else {
                return navigate(`/settings/people/${personId}/bank-references/upload/personalDocuments/${documentGroupId}`)
            }
        } else if (window.location.href.includes('invitation')) {
            if (documentGroupId === 'new') {
                return navigate(`/settings/people/${personId}/invitation/personalDocuments`)
            } else {
                return navigate(`/settings/people/${personId}/invitation/personalDocuments/${documentGroupId}`)
            }
        }
    }

    const base64toBlob = (b64Data, contentType = 'image/jpeg', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: contentType});
    }

    function reload() {
        window.location.reload();
    }

    const vidiIdentify = (frame, document) => {
        const formData = new FormData();
        formData.append("req_frame", base64toBlob(frame))
        formData.append("ref_img", base64toBlob(document))
        fetch(vidiIdentifyUrl, {
            method: 'POST',
            headers: {
                'api-token': process.env.REACT_APP_VIDI_AUTH_TOKEN,
            },
            body: formData,
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                return res.json().then(res => {
                    throw new Error(res)
                })
            }
        }).then((res) => {
            if (res.text === 'ID found') {
                //console.log('identity confirmed')
                if ((window.location.href.includes('refunds') || window.location.href.includes('bank-references')) && bankRefToUpdate) {
                    setIsLoadingData(true);
                    updateBankRef(bankRefToUpdate, frame);
                } else if ((window.location.href.includes('refunds') || window.location.href.includes('bank-references')) && !!bankRefToUpdate) {
                    setIsLoadingData(true)
                    setPlaceholderError(true)
                } else if (window.location.href.includes('invitation')) {
                    setIsLoadingData(true)
                    inviteUser(invitation_fiscalCode, invitation_email);
                } else {
                    setIdentityConfirmed(true)
                }
            } else {
                setIdentityNotConfirmed(current => current + 1)
            }
        }).catch((e) => {
            if (e.status !== 500) {
                setIdentityNotConfirmed(current => current + 1)
            } else {
                setErrorPage(true)
            }
        });
    }

    const updateBankRef = (data, frame) => {
        //update bank ref
        fetch(updateBankRefUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                personId: data.personId,
                accountHolder: data.accountHolder,
                iban: data.iban,
                swiftCode: data.swiftCode,
                currency: data.currency
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                saveOperationIntoDocument(saveIBANIntoDocumentUrl, 'IBAN', frame);
            } else {
                setIsLoadingData(false)
                setPlaceholderError(true)
            }
        }).catch(() => {
            setIsLoadingData(false)
            setErrorPage(true)
        })
    }

    function updateIBAN(iban, frame) {
        fetch(`${updateIBANUrl}`, {
            method: 'POST',
            body: JSON.stringify({
                personId: personId,
                iban: iban,
                currency: 'EUR'
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                saveOperationIntoDocument(saveIBANIntoDocumentUrl, 'IBAN', frame);
            } else {
                setIsLoadingData(false)
                setPlaceholderError(true)
            }
        }).catch(() => {
            setIsLoadingData(false)
            setErrorPage(true)
        });
    }

    function saveOperationIntoDocument(url, type, frame, userId) {
        let body;
        if (type === 'IBAN') {
            body = {
                personId: personId,
                ref_img: ref_img,
                req_frame: frame,
            };
        }
        if (type === 'INVITE-USER') {
            body = {
                userId: userId,
                ref_img: ref_img,
                req_frame: frame,
                fiscalCode: invitation_fiscalCode
            };
        }
        fetch(`${url}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                createOperation(type, data.message);
            } else {
                setIsLoadingData(false)
                setPlaceholderError(true)
            }
        }).catch(() => {
            setIsLoadingData(false)
            setErrorPage(true)
        });
    }

    function createOperation(type, entityId) {
        fetch(`${createOperationUrl}`, {
            method: 'POST',
            body: JSON.stringify({
                type: type,
                entityId: entityId,
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                completeOperation(type, entityId);
            } else {
                setIsLoadingData(false)
                setPlaceholderError(true)
            }
        }).catch(() => {
            setIsLoadingData(false)
            setErrorPage(true)
        });
    }

    function completeOperation(type, entityId) {
        fetch(`${completeOperationUrl}`, {
            method: 'POST',
            body: JSON.stringify({
                type: type,
                entityId: entityId,
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                setIsLoadingData(false)
                setIdentityConfirmed(true)
            } else {
                setIsLoadingData(false)
                setPlaceholderError(true)
            }
        }).catch(() => {
            setIsLoadingData(false)
            setErrorPage(true)
        });
    }

    const inviteUser = (fiscalCode, email) => {
        /*setIsLoadingData(false)
        setIdentityConfirmed(true)*/
        fetch(inviteUserUrl, {
            method: 'POST',
            body: JSON.stringify({
                fiscalNumber: fiscalCode,
                email: email
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }).then((response) => {
            return response.json();
        }).then((res) => {
            if (res.ok) {
                saveOperationIntoDocument(saveInviteIntoDocumentUrl, 'INVITE-USER', frame, res.userId)
            } else {
                setIsLoadingData(false)
                setPlaceholderError(true)
            }
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function getAccountExec() {
        fetch(accountExecUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.phone && data.phone.startsWith("+39")) {
                setPhone(data.phone.slice(3))
            }
            setAccountExec(data);
            putElementFromIndexedDB('accountExec', data)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function isDocumentBlocked() {
        fetch(`${isBlockedUrl}/${documentId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json();
        }).then((res) => {
            setBlocked(res.isBlocked);
            let time = Math.round(res.remaining / 3600000);
            if (time < 1) setRemaining("1");
            else setRemaining(time);
        }).catch(() => {
            setErrorPage(true);
        });
    }

    useEffect(() => {
        if (identityNotConfirmed >= 11 && !identityConfirmed && window.location.href.includes('signing')) {
            fetch(`${updateDocumentUrl}/${documentId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                return response.json();
            }).then(() => {
            }).catch(() => {
                setErrorPage(true);
            });
        }
    }, [identityNotConfirmed])

    const proceedAfterIdentityConfirmed = () => {
        if (window.location.href.includes('signing')) {
            if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
                return navigate(`/signing/${personId}/uploadBack/uploadFront/identify/otp?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=${isCie}`)
            } else {
                return navigate(`/signing/${personId}/upload/personalDocuments/${documentGroupId}/identify/otp?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
            }
        } else if (window.location.href.includes('refunds')) {
            authCtx.storeBankRefsToUpdate(defaultValues)
            return navigate(`/refunds`)
        } else if (window.location.href.includes('bank-references')) {
            authCtx.storeBankRefsToUpdate(defaultValues)
            return navigate(`/settings/people/${personId}/bank-references`)
        } else if (window.location.href.includes('invitation')) {
            sessionStorage.removeItem('invitation_fiscalCode')
            sessionStorage.removeItem('invitation_email')
            return navigate(`/settings/people/${personId}`)
        }
    }

    const handleOnPendingVideo = async (e) => {
        try {
            e.preventDefault();
            const file = e.target.files[0];
            validateFileDuration(file)
        } catch (err) {
            console.log(err)
        }
    };

    const sendVideo = async (file) => {
        const snapshoter = new VideoSnapshot(file.target.files[0]);
        const firstSnapshot = await snapshoter.takeSnapshot('start');
        const secondSnapshot = await snapshoter.takeSnapshot('middle');
        const thirdSnapshot = await snapshoter.takeSnapshot('end');
        authCtx.storeSnapshots({
            "firstSnapshot": firstSnapshot,
            "secondSnapshot": secondSnapshot,
            "thirdSnapshot": thirdSnapshot
        })
    }

    const validateFileDuration = (file) => {
        let video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            if (video.duration > 15) {
                alert("Invalid Video! video is more than 15 second");
                return;
                //setVideoNotValid(true)
            } else {
                alert("Valid Video!");
                //setVideoNotValid(false)
            }
            sendVideo(file);
            //methodToCallIfValid();
        }
        video.src = URL.createObjectURL(file);
    }

    const startRecognitionFun = () => {
        if (devices.length > 0) {
            setStartRecognition(true);
            startTimer();
        } else {
            setOpenNoCameraDialog(true)
        }
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {
                    errorPage ?
                        <ErrorPage/> :
                        <div className="w-100 px-4 linear-gradient">
                            <div style={{'opacity': '1', paddingBottom: 24}}>
                                <StyledTitlePage>
                                    <Col className="col-1 float-left">
                                        {identityConfirmed === false &&
                                            <img src={back} onClick={turnBack}
                                                 className="nav-top-logo float-left cursor-pointer"
                                                 height="27px"
                                                 alt="back"/>}
                                    </Col>
                                    <Col className="col-10">
                                        <span
                                            className="header-title ellipsis-1-v2">{t('cameraRecognition.title')}</span>
                                    </Col>
                                    <Col className="col-1">
                                        <img src={exit} onClick={() => setOpenExitDialog(true)}
                                             className="nav-top-logo float-right cursor-pointer" alt="exit-icon"
                                             height="27px"/>
                                    </Col>
                                </StyledTitlePage>
                                {
                                    isLoadingData &&
                                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                }
                                {
                                    !isLoadingData && !startRecognition && !blocked && !placeholderError &&
                                    <PlaceholderScreen isLoadingData={false}
                                                       title={[t('cameraRecognition.start-title-1'), t('cameraRecognition.start-title-2')]}
                                                       description={t('cameraRecognition.start-description')}
                                                       placeholder={startRecognitionPh}
                                                       buttonText={t('cameraRecognition.start-button')}
                                                       buttonAction={startRecognitionFun} open={open}/>
                                }
                                {
                                    !isLoadingData && startRecognition && !identityConfirmed && (identityNotConfirmed !== 3
                                        && identityNotConfirmed !== 7 && identityNotConfirmed !== 11)
                                    && !blocked && !placeholderError &&
                                    <div className="col-list position-relative">
                                        {isDesktop &&
                                            <Row>
                                                <Col style={{margin: '0 auto', borderRadius: 16}}>
                                                    <Camera ref={webcamRef}
                                                            aspectRatio={4 / 3}
                                                            facingMode="user"
                                                            playsInline={true}
                                                            errorMessages={(e) => console.log(e)}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                        {!isDesktop &&
                                            <div className={'div-camera'}>
                                                <Camera ref={webcamRef}
                                                        aspectRatio={4 / 3}
                                                        facingMode="user"
                                                        playsInline={true}
                                                        errorMessages={(e) => console.log(e)}
                                                />
                                            </div>
                                        }
                                        <div className={'div-frame'}>
                                            <Row>
                                                <h6 style={{
                                                    margin: '10px auto',
                                                    textAlign: 'center',
                                                    font: 'normal normal bold 16px/20px Inter',
                                                    letterSpacing: '0px',
                                                    color: themeContext.color.white,
                                                    opacity: '1',
                                                }}>{t('cameraRecognition.frame-title')}</h6>
                                            </Row>
                                            <Row>
                                                <img src={frame} style={{margin: '0 auto'}} alt='frame'/>
                                            </Row>
                                        </div>
                                        <div className={'div-document'}>
                                            <Row>
                                                <h6 style={{
                                                    margin: '10px auto',
                                                    textAlign: 'center',
                                                    font: 'normal normal bold 16px/20px Inter',
                                                    letterSpacing: '0px',
                                                    color: themeContext.color.white,
                                                    opacity: '1',
                                                }}>{t('cameraRecognition.document-title')}</h6>
                                            </Row>
                                            <Row>
                                                <img src={ref_url} width="200px" style={{margin: '0 auto'}}
                                                     alt='document'/>
                                            </Row>
                                        </div>
                                    </div>
                                }
                                {
                                    !isLoadingData && startRecognition && identityConfirmed && !blocked && !placeholderError &&
                                    <PlaceholderScreen isLoadingData={false}
                                                       title={[t('cameraRecognition.verify-ok-title-1'), t('cameraRecognition.verify-ok-title-2')]}
                                                       description={window.location.href.includes('signing') ? t('cameraRecognition.verify-ok-description') : ((window.location.href.includes('refunds') || window.location.href.includes('bank-references')) ? t('changeIBAN.confirm-operation') : t('personDetail.add-user-completed'))}
                                                       placeholder={identityConfirmedPh}
                                                       buttonText={t('cameraRecognition.verify-ok-button')}
                                                       buttonAction={proceedAfterIdentityConfirmed} open={open}/>
                                }
                                {
                                    !isLoadingData && startRecognition && !identityConfirmed && (identityNotConfirmed === 3 || identityNotConfirmed === 7) && !blocked && !placeholderError &&
                                    <PlaceholderScreen isLoadingData={false}
                                                       title={[t('cameraRecognition.verify-not-ok-title-1'), t('cameraRecognition.verify-not-ok-title-2')]}
                                                       description={identityNotConfirmed === 3 ? twoTrysDescriptionText : (identityNotConfirmed === 7 ? oneTryDescriptionText : {})}
                                                       placeholder={identityNotConfirmedPh}
                                                       buttonText={t('cameraRecognition.verify-not-ok-button')}
                                                       buttonAction={restartTimer} open={open}/>
                                }
                                {
                                    !isLoadingData && startRecognition && !identityConfirmed && identityNotConfirmed === 11 && !blocked && !placeholderError &&
                                    <PlaceholderScreen isLoadingData={false}
                                                       title={[t('cameraRecognition.verify-not-ok-title-1'), t('cameraRecognition.verify-not-ok-title-2')]}
                                                       description={zeroTrysDescriptionText}
                                                       placeholder={identityNotConfirmedPh}
                                                       buttonText={t('cameraRecognition.verify-not-ok-final-button')}
                                                       buttonAction={() => setOpenCustomerService(true)} open={open}/>
                                }
                                {
                                    blocked && !placeholderError &&
                                    <PlaceholderScreen isLoadingData={false}
                                                       title={[t('cameraRecognition.verify-not-ok-title-1'), t('cameraRecognition.verify-not-ok-title-3')]}
                                                       description={blockedDescriptionText}
                                                       placeholder={identityNotConfirmedPh}
                                                       buttonText={t('cameraRecognition.verify-not-ok-final-button')}
                                                       buttonAction={() => setOpenCustomerService(true)} open={open}/>
                                }
                                {
                                    !isLoadingData && placeholderError &&
                                    <PlaceholderScreen isLoadingData={false}
                                                       title={t('cameraRecognition.placeholder-error-title')}
                                                       description={t('cameraRecognition.placeholder-error-description')}
                                                       placeholder={identityNotConfirmedPh}
                                                       buttonText={t('cameraRecognition.verify-not-ok-final-button')}
                                                       buttonAction={() => setOpenCustomerService(true)} open={open}/>
                                }
                            </div>
                            <DialogSmall open={openNoCameraDialog}
                                         title={t('cameraRecognition.no-camera-title')}
                                         handleClose={() => setOpenNoCameraDialog(false)}
                                         buttonText={t('cameraRecognition.no-camera-button')}/>
                            <DialogDoubleAction
                                open={openExitDialog}
                                handleClose={() => setOpenExitDialog(false)}
                                title={t('dialog.interruption-title')}
                                description={t('dialog.interruption-description')}
                                buttonYesText={t('dialog.button-yes')}
                                handleYes={() => {
                                    if (window.location.href.includes('signing')) {
                                        navigate('/signing');
                                    } else if (window.location.href.includes('refunds')) {
                                        navigate(`/refunds`);
                                    } else if (window.location.href.includes('invitation')) {
                                        navigate(`/settings/people/${personId}`);
                                    } else if (window.location.href.includes('bank-references')) {
                                        navigate(`/settings/people/${personId}/bank-references`);
                                    }
                                }}
                                buttonNoText={t('dialog.button-no')}
                                handleNo={() => setOpenExitDialog(false)}
                            ></DialogDoubleAction>
                            <Dialog
                                components={{Backdrop: StyledBackdrop}}
                                open={openCustomerService}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => setOpenCustomerService(false)}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                                PaperProps={{
                                    style: {
                                        'position': 'absolute',
                                        'bottom': '0',
                                        'left': 'auto',
                                        'right': 'auto',
                                        'width': '95%',
                                        'borderRadius': '10px',
                                        'color': themeContext.color.neutral600
                                    },
                                }}>
                                <DialogTitle id="alert-dialog-slide-title" className={classes.dialogTitle}>
                    <span style={{'fontSize': '15px',}}>
                        {t('contacts.modal-title')}
                    </span>
                                </DialogTitle>
                                <DialogContent className={classes.dialogDescription}>
                    <span style={{
                        'fontSize': '20px',
                        'fontWeight': "bold",
                    }}>
                        {accountExec.firstName} {accountExec.lastName}
                    </span>
                                </DialogContent>
                                <Divider/>
                                {
                                    accountExec.phone !== null &&
                                    <Fragment>
                                        <DialogActions>
                                            <a href={`tel:${accountExec.phone}`}
                                               style={{
                                                   'textDecoration': 'none',
                                                   'width': '100%',
                                                   'margin': '0 auto'
                                               }}>
                                                <Button color="primary" fullWidth
                                                        style={{
                                                            'textTransform': 'none',
                                                            'color': themeContext.color.green
                                                        }}>
                                                    {t('claimDetail.modal-tel-button')}
                                                </Button>
                                            </a>
                                        </DialogActions>
                                        <Divider/>
                                    </Fragment>
                                }
                                <DialogActions>
                                    <MailTo email={`${accountExec.email}`}
                                            subject={t('contacts.customer-service-email-subject')}
                                            body={`${t('contacts.customer-service-email-text-1')}%0D%0A%0D%0A%0D%0A${t('contacts.customer-service-email-text-2')}%0D%0A${t('contacts.customer-service-email-text-3')}%0D%0A%0D%0A%0D%0A${t('contacts.customer-service-email-text-4')}%0D%0A${t('contacts.customer-service-email-text-5')}%0D%0A`}>
                                        <Button color="primary" style={{'textTransform': 'none'}}
                                                fullWidth>
                                            {t('claimDetail.modal-email-button')}
                                        </Button>
                                    </MailTo>
                                </DialogActions>
                                <Divider/>
                                <DialogActions>
                                    <Button onClick={() => setOpenCustomerService(false)}
                                            style={{
                                                'color': themeContext.color.neutral400,
                                                'textTransform': 'none'
                                            }}
                                            fullWidth>
                                        {t('claimDetail.modal-refuse-button')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <DialogSmall buttonText={t('uploadDocumentToVerify.fallback-dialog-button')}
                                         title={t('uploadDocumentToVerify.fallback-dialog-title')}
                                         description={t('uploadDocumentToVerify.fallback-dialog-description')}
                                         handleClose={() => {
                                             if (window.location.href.includes('signing')) {
                                                 if (window.location.href.includes('uploadBack')) {
                                                     navigate(`/signing/${personId}/uploadBack?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&skipTakingResponsibility=true`)
                                                 } else {
                                                     navigate(`/signing/${personId}/upload?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&skipTakingResponsibility=true`)
                                                 }
                                             } else if (window.location.href.includes('refunds')) {
                                                 navigate(`/refunds`);
                                             } else if (window.location.href.includes('bank-references')) {
                                                 navigate(`/settings/people/${personId}/bank-references`);
                                             } else if (window.location.href.includes('invitation')) {
                                                 navigate(`/settings/people/${personId}/invitation/personalDocuments`);
                                             }
                                             setOpenFallbackDialog(false)
                                         }}
                                         open={openFallbackDialog}/>
                        </div>}
            </StyledPageCard>
        </ExternalPageLayout>
    )
}