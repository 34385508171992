import React, {Fragment, useEffect, useRef, useState} from 'react'
import Dialog from "@mui/material/Dialog";
import {
    Button,
    FormControl, FormHelperText, InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Slide,
    Step,
    StepLabel,
    Stepper,
    TextField
} from "@mui/material";
import themeContext from "./ThemeContext.js";
import {StyledBackdrop} from "../styles/Styles.js";
import {Col, Row} from "react-bootstrap";
import exitIcon from "../../images/exit-icon.svg";
import DialogTitle from "@mui/material/DialogTitle";
import {useTranslation} from "react-i18next";
import carClaimCard from "../../images/car-claim-card.svg";
import notCarClaimCard from "../../images/not-car-claim-card.svg";
import {styled} from "@mui/system";
import MuiCard from "@mui/material/Card";
import {LocalizationProvider, DatePicker, TimePicker} from "@mui/x-date-pickers";
import moment from "moment";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import CropDialog from "./CropDialog.js";
import Resizer from "react-image-file-resizer";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import spinner from "../../images/spinner.svg";
import fileIconSquared from "../../images/file-icon-squared.svg";
import FsLightbox from "fslightbox-react";
import DialogSmall from "./DialogSmall.js";
import trashIcon from "../../images/desktop/trash-icon.svg";
import DialogDoubleAction from "./DialogDoubleAction.js";
import Container from "react-bootstrap/Container";
import emailPlaceholder from "../../images/email-placeholder.svg";
import {StyledBigDialog} from "../styles/DesktopStyles.js";
import {makeStyles} from "@mui/styles";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const useStyles = makeStyles((theme) => ({
    datePicker: {
        '& .MuiPickersBasePicker-container': {
            width: '300px', // Adjust the width as needed
        },
    },
    timePicker: {
        '& .MuiPickersBasePicker-container': {
            width: '300px', // Adjust the width as needed
        },
    },
}));

const StyledPicker = styled(TimePicker)(({theme}) => ({
    '& .MuiPickersBasePicker-container': {
        width: '100px', // Adjust the width as needed
    },
}));

const StyledCard = styled(MuiCard)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '24px',
    outline: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
    width: '100%',
    boxShadow: 'none',
    cursor: 'pointer',
    padding: '24px 32px 24px 32px',
    gap: 48,
    '&:hover': {
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
    }
}));

const StyledTitle = styled('span')(({theme}) => ({
    fontSize: 24,
    color: themeContext.color.main,
    fontWeight: '700',
    fontStyle: 'italic',
    width: '70%',
    lineHeight: '29.05px'
}));

const StyledSubtitle = styled('span')(({theme}) => ({
    fontSize: '16px',
    color: themeContext.color.neutral600,
    fontWeight: '400',
    lineHeight: '22.04px'
}));

const ExternalLayout = styled('div')(({theme}) => ({
    gap: 16,
    padding: 24,
    //paddingTop: '56px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
}));

const StyledStep = styled(Step)(({theme}) => ({
    '& .MuiStep-paper': {
        color: themeContext.color.white
    }

}));

const StyledTrashButton = styled(IconButton)(({theme}) => ({
    position: 'absolute',
    right: 0,
    top: 0
}));


const CLAIM_TYPES = {
    CAR: 'car',
    GENERIC: 'generic'
}

const OPTIONS_TYPES = {
    PERSON: 'personId',
    PLATE: 'plate',
    POLICY: 'policyId',
    PLATES_INVOLVED: 'platesInvolved',
    PLATE_INVOLVED: 'plate',
    HAS_ACCIDENT_REPORT: 'hasAccidentReport'
}

const OpenClaimDialog = ({
                             open,
                             handleClose,
                             step,
                             type
                         }) => {
    const [locale, setLocale] = useState("it");
    moment.locale(locale);
    const {t} = useTranslation();
    const [currentStep, setStep] = useState(step);
    const [currentType, setType] = useState(type);
    const defaultValues = {
        claimType: "car",
        policyId: "",
        personId: "",
        plate: "",
        dateTime: "",
        address: "",
        province: "",
        city: "",
        platesInvolved: [],
        hasAccidentReport: "false",
        description: ""
    };
    const genericDefaultValues = {
        claimType: "generic",
        policyId: "",
        personId: "",
        dateTime: "",
        description: ""
    };
    const getClaimAttachmentUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide`;
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const newClaimUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide/new`;
    const addAttachmentToClaimUrl = `${process.env.REACT_APP_BASE_URL}/media/claims`;
    const removeAttachmentFromClaimUrl = `${process.env.REACT_APP_BASE_URL}/media/claims`;
    const updateClaimUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide`;
    const sendClaimUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide`;
    const peoplePoliciesUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/people`;


    const token = sessionStorage.getItem('token');
    const formValues = useRef(defaultValues);
    const platesInvolved = useRef({});
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [peopleWithVehicles, setPeopleWithVehicles] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isPlatesInvolved, setIsPlatesInvolved] = useState(false);

    const [images, setImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);
    const [imagesAccidentalReport, setImagesAccidentalReport] = useState([]);
    const [imagesUrlAccidentalReport, setImagesUrlAccidentalReport] = useState([])
    const [selectedPlate, setSelectedPlate] = useState("");
    const [selectedPolicy, setSelectedPolicy] = useState("");
    const [policies, setPolicies] = useState([]);
    const [isLoadingVehicles, setIsLoadingVehicles] = useState(false);
    const [isAccidentReportNote, setIsAccidentReportNote] = useState(false);
    const [openAccidentReportDialog, setOpenAccidentReportDialog] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [serviceError, setServiceError] = useState(false);
    const [claimId, setClaimId] = useState(undefined);

    const [tempImages, setTempImages] = useState([]);
    const [tempImagesUrl, setTempImagesUrl] = useState([]);
    const [tempImagesAccidentReport, setTempImagesAccidentReport] = useState([]);
    const [tempImagesUrlAccidentReport, setTempImagesUrlAccidentReport] = useState([]);
    const [openPhotoLimitsDialog, setOpenPhotoLimitsDialog] = useState(false);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [cropper, setCropper] = useState(null);
    const cropperRef = useRef(null);
    const [uploadError, setUploadError] = useState(null);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [activeDialog, setActiveDialog] = useState("attach");
    const ids = useRef([]);
    const ids_accidentReport = useRef([]);
    const new_ids = useRef([]);
    const new_ids_accidentReport = useRef([]);
    const [openDeleteImageDialog, setOpenDeleteImageDialog] = useState(false);
    const [openDoNotSaveDialog, setOpenDoNotSaveDialog] = useState(false);
    const [openRetryDialog, setOpenRetryDialog] = useState(false);
    const [imageIdToDelete, setImageIdToDelete] = useState(-1);
    const [isLoadingSend, setIsLoadingSend] = useState(false);
    const [people, setPeople] = useState([]);
    const [isLoadingRemoveAccidentReportImages, setIsLoadingRemoveAccidentReportImages] = useState(false);
    const [isLoadingPolicy, setIsLoadingPolicy] = useState(false);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    const responsive = {
        mobile: {
            breakpoint: {max: 4000, min: 0},
            items: 5
        }
    };

    function openLightboxOnSlide(index) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index
        });
    }

    const openLightboxFun = (list, index) => {
        // counts how many elements in the list have the key ‘file’ before index
        let count = 0;
        for (let i = 0; i < index; i++) {
            if (list[i].file) {
                count++;
            }
        }
        openLightboxOnSlide(index - count);
    }

    const initialPlatesInvolved = [
        {
            type: 'input',
            name: 'plate1',
            value: formValues.current.plate1,
            placeholder: `${t('carClaimForm.plate-label')} 1`,
            required: true,
            width: '100%',
            disabled: false,
            visibility: false
        },
        {
            type: 'input',
            name: 'plate2',
            value: formValues.current.plate2,
            placeholder: `${t('carClaimForm.plate-label')} 2`,
            required: true,
            width: '100%',
            disabled: false,
            visibility: false
        },
        {
            type: 'input',
            name: 'plate3',
            value: formValues.current.plate3,
            placeholder: `${t('carClaimForm.plate-label')} 3`,
            required: true,
            width: '100%',
            disabled: false,
            visibility: false
        },
        {
            type: 'input',
            name: 'plate4',
            value: formValues.current.plate4,
            placeholder: `${t('carClaimForm.plate-label')} 4`,
            required: true,
            width: '100%',
            disabled: false,
            visibility: false
        },
        {
            type: 'input',
            name: 'plate5',
            value: formValues.current.plate5,
            placeholder: `${t('carClaimForm.plate-label')} 5`,
            required: true,
            width: '100%',
            disabled: false,
            visibility: false
        },
        {
            type: 'input',
            name: 'plate6',
            value: formValues.current.plate6,
            placeholder: `${t('carClaimForm.plate-label')} 6`,
            required: true,
            width: '100%',
            disabled: false,
            visibility: false
        },
    ]


    const [platesInvolvedGroup, setPlatesInvolvedGroup] = useState(initialPlatesInvolved);

    useEffect(() => {
        if (navigator.onLine) {
            getPeopleWithVehicles();
            checkValidForm();
        }
    }, []);

    const getPeopleWithVehicles = () => {
        setIsLoadingData(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let vehicleFound = false;
            data.map((person, index) => {
                if (person.status !== -1) {
                    setPeople(current => [...current, person])
                    person.vehicles.map((vehicle, index) => {
                        if (vehicle.status !== -1 && vehicle.status !== 1) {
                            vehicleFound = true;
                        }
                        if ((index + 1 === person.vehicles.length) && vehicleFound) {
                            setPeopleWithVehicles(current => [...current, person])
                        }
                        if ((index + 1 === person.vehicles.length) && formValues.current.plate && vehicleFound) {
                            setSelectedPlate(formValues.current.plate)
                        }
                    })
                }
            })
            if (vehicleFound === false) {
                setPeopleWithVehicles([{id: -1, label: t('desktop.claims.no-policy-holders-with-associated-vehicles')}])
            }
            if (formValues.current.personId) {
                data.filter(person => person.id === formValues.current.personId).map((person, index) => {
                    let activeVehicles = person.vehicles.filter(vehicle => vehicle.status !== -1 && vehicle.status !== 1);
                    setVehicles(activeVehicles)
                    activeVehicles.map((vehicle, index) => {
                        if (index === 0) {
                            setSelectedPlate(vehicle.plate)
                            let activePolicies = vehicle.policies.filter(policy => policy.status !== -1 && policy.status !== 1);
                            setPolicies(activePolicies)
                            activePolicies.map((policy, index) => {
                                if (index === 0) {
                                    setSelectedPolicy(policy.id)
                                    setIsLoadingVehicles(false)
                                }
                            })
                        }
                    })
                });
            }
            setIsLoadingData(false)
        }).catch(() => {
            handleClose()
        });
    }

    const getPoliciesByPersonId = (personId) => {
        setIsLoadingPolicy(true)
        fetch(`${peoplePoliciesUrl}?filter=STATUS_DEFAULT&personId=${personId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.length > 0) {
                //let activePolicies = data.filter(p => p.status !== -1 && p.status !== 1 && p.plate === null);
                let activePolicies = data.filter(p => p.status !== -1 && p.status !== 1);
                setPolicies(activePolicies)
                activePolicies.map((policy, index) => {
                    if (index === 0) {
                        setSelectedPolicy(policy.id)
                    }
                })
            } else {
                setSelectedPolicy("")
                setPolicies([])
            }
            setIsLoadingPolicy(false)
        }).catch(() => {
            handleClose()
        });
    }


    const getClaimAttachment = (attachmentId, type) => {
        setIsLoadingData(true)
        fetch(`${getClaimAttachmentUrl}/${claimId}/attachment/${attachmentId}/download`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.blob();
        }).then((data) => {
            if (data) {
                let urlCreator = window.URL || window.webkitURL;
                let imageUrl = urlCreator.createObjectURL(data);
                if (type === 'images') {
                    if (data.type === 'application/pdf') {
                        setImages(current => [...current, {id: attachmentId, url: fileIconSquared, file: imageUrl}])
                    } else {
                        setImages(current => [...current, {id: attachmentId, url: imageUrl}])
                        setImagesUrl(current => [...current, imageUrl])
                    }
                } else if (type === 'accidentReportImages') {
                    if (data.type === 'application/pdf') {
                        setImagesAccidentalReport(current => [...current, {
                            id: attachmentId,
                            url: fileIconSquared,
                            file: imageUrl
                        }])
                    } else {
                        setImagesAccidentalReport(current => [...current, {id: attachmentId, url: imageUrl}])
                        setImagesUrlAccidentalReport(current => [...current, imageUrl])
                    }
                }
                setIsLoadingData(false)
            }
        }).catch(() => {
            handleClose()
        });
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        switch (name) {
            case 'personId':
                if (value !== -1) {
                    formValues.current = {...formValues.current, [name]: value,}
                    peopleWithVehicles.filter(person => person.id === value).map((person, index) => {
                        let activeVehicles = person.vehicles.filter(vehicle => vehicle.status !== -1 && vehicle.status !== 1);
                        setVehicles(activeVehicles)
                        activeVehicles.map((vehicle, index) => {
                            if (index === 0) {
                                setSelectedPlate(vehicle.plate)
                                let activePolicies = vehicle.policies.filter(policy => policy.status !== -1 && policy.status !== 1);
                                setPolicies(activePolicies)
                                activePolicies.map((policy, index) => {
                                    if (index === 0) {
                                        setSelectedPolicy(policy.id)
                                        setIsLoadingVehicles(false)
                                    }
                                })
                            }
                        })
                    });
                }
                break;
            case 'plate':
                formValues.current = {...formValues.current, [name]: value,}
                setSelectedPlate(value)
                vehicles.filter(vehicle => vehicle.plate === value).map((vehicle, index) => {
                    let activePolicies = vehicle.policies.filter(policy => policy.status !== -1 && policy.status !== 1);
                    setPolicies(activePolicies)
                    activePolicies.map((policy, index) => {
                        if (index === 0) {
                            setSelectedPolicy(policy.id)
                            setIsLoadingVehicles(false)
                        }
                    })
                })
                break;
            case 'policyId':
                formValues.current = {...formValues.current, [name]: value,}
                setSelectedPolicy(value)
                break;
            case 'hasAccidentReport':
                if (value === true) {
                    setIsAccidentReportNote(true)
                } else {
                    if (imagesAccidentalReport.length > 0) {
                        setOpenAccidentReportDialog(true)
                    } else {
                        setIsAccidentReportNote(false)
                    }
                }
                break;
        }
        if (!(name === 'hasAccidentReport' && imagesAccidentalReport.length > 0)) {
            formValues.current = {...formValues.current, [name]: value,}
            checkValidForm();
        }
    };

    const handleGenericInputChange = (e) => {
        const {name, value} = e.target;
        switch (name) {
            case 'personId':
                getPoliciesByPersonId(value)
                break;
            case 'policyId':
                setSelectedPolicy(value)
                break;
        }
        formValues.current = {...formValues.current, [name]: value,}
        checkValidForm();
    };

    const checkValidForm = () => {
        if (formValues.current.personId !== '' && formValues.current.personId !== 0)
            setIsFormInvalid(false);
    };

    const validateGenericStep0 = () => {
        return formValues.current.personId && formValues.current.personId !== '' || (formValues.current.personId && formValues.current.personId !== '' && selectedPolicy && selectedPolicy !== '')
    };

    const validateStep0 = () => {
        return formValues.current.personId && formValues.current.personId !== '' && selectedPlate && selectedPlate !== '' && selectedPolicy && selectedPolicy !== ''
    };

    const validateStep1 = () => {
        // Se non ci sono targhe coinvolte nell'incidente
        if (isPlatesInvolved === "0" || isPlatesInvolved === false) {
            return true;
        }

        // Controllo che tutte le targhe visibili abbiano un valore
        const visiblePlates = platesInvolvedGroup.filter(plate => plate.visibility === true);

        // Se non ci sono targhe visibili ma isPlatesInvolved è true, ritorna false
        if (visiblePlates.length === 0) {
            return false;
        }

        // Verifica che tutte le targhe visibili abbiano un valore valido
        return visiblePlates.every(plate =>
            plate.value &&
            typeof plate.value === 'string' &&
            plate.value.trim() !== ''
        );
    };

    const handleNext = (currentStep, type) => {
        setUploadError(false)
        if (type === CLAIM_TYPES.CAR) {
            if (currentStep === 0) {
                if (validateStep0()) {
                    setStep((prev) => prev + 1);
                }
            } else if (currentStep === 1) {
                if (validateStep1()) {
                    setStep((prev) => prev + 1);
                }
            }
        } else {
            if (validateGenericStep0()) {
                setStep((prev) => prev + 1);
            }
        }
    };

    const handleBack = (step) => {
        setUploadError(false)
        //se mi trovo nello step 0, devo aprire il dialog di conferma di chiusura
        if (step === 0) {
            if (currentType === CLAIM_TYPES.CAR) {
                if (validateStep0()) {
                    setOpenRetryDialog(true)
                } else {
                    setStep((prev) => prev - 1);
                }
            } else {
                if (validateGenericStep0()) {
                    setOpenRetryDialog(true)
                } else {
                    setStep((prev) => prev - 1);
                }
            }
        } else {
            setStep((prev) => prev - 1);
        }
    };

    const handlePlatesInvolvedChange = (value) => {
        setIsPlatesInvolved(value);
        // Create new array with updated visibility and cleared values
        const updatedPlatesGroup = platesInvolvedGroup.map((plate, index) => {
            const isVisible = index + 1 <= parseInt(value);
            return {
                ...plate,
                visibility: isVisible,
                // Clear value if plate becomes hidden
                value: isVisible ? plate.value : ''
            };
        });
        // Update formValues for consistency

        /*if (formValues.current) {
            for (let i = parseInt(value) + 1; i <= 6; i++) {
                const plateKey = `plate${i}`;
                formValues.current[plateKey] = '';
            }
        }*/


        // Update platesInvolvedGroup state with new array
        setPlatesInvolvedGroup(updatedPlatesGroup);
    };


    const handlePlateTextChange = (name, value) => {
        // a partire da index es. plate1, estrapola solo il numero
        const index = parseInt(name.replace('plate', '')) - 1;

        const updatedPlatesGroup = platesInvolvedGroup.map((plate, i) => {
            if (i === index) {
                return {
                    ...plate,
                    value: value,
                    visibility: true
                };
            }
            return plate;
        });

        setPlatesInvolvedGroup(updatedPlatesGroup);

        // Update platesInvolved.current
        if (platesInvolved.current) {
            platesInvolved.current[`plate${index + 1}`] = value;
        }
    };

    const requiredData = [
        {
            type: 'select',
            name: 'personId',
            value: formValues.current.personId,
            options: peopleWithVehicles,
            placeholder: t('carClaimForm.person-label'),
            required: true,
            width: '100%',
            disabled: isLoadingData,
            loading: isLoadingData
        },
        {
            type: 'select',
            name: 'plate',
            value: selectedPlate,
            options: vehicles,
            placeholder: t('carClaimForm.plate-label'),
            required: true,
            width: '100%',
            disabled: isLoadingData || formValues.current.personId === "" || formValues.current.personId === -1,
            loading: false
        },
        {
            type: 'select',
            name: 'policyId',
            value: selectedPolicy,
            options: policies,
            placeholder: t('carClaimForm.claimed-policy'),
            required: true,
            width: '100%',
            disabled: isLoadingData || formValues.current.personId === "" || formValues.current.personId === -1,
            loading: false,
            helperText: t('carClaimForm.claimed-policy-helper-text'),
        },
    ]

    const requiredDataForGeneric = [
        {
            type: 'select',
            name: 'personId',
            value: formValues.current.personId,
            options: people,
            placeholder: t('carClaimForm.person-label'),
            required: true,
            width: '100%',
            disabled: isLoadingData,
            loading: isLoadingData
        },
        {
            type: 'select',
            name: 'policyId',
            value: selectedPolicy,
            options: policies,
            placeholder: t('carClaimForm.claimed-policy'),
            required: true,
            width: '100%',
            disabled: isLoadingPolicy || formValues.current.personId === "" || selectedPolicy === "",
            loading: isLoadingPolicy,
            helperText: t('carClaimForm.claimed-policy-helper-text'),
        },
    ]

    const dateTimeData = [
        {
            type: 'date',
            name: 'date',
            values: null,
            placeholder: t('carClaimForm.date-label'),
            required: false,
            width: '100%'
        },
        {
            type: 'time',
            name: 'time',
            values: null,
            placeholder: t('carClaimForm.time-label'),
            required: false,
            width: '100%'
        },
    ]

    const locationData = [
        {
            type: 'input',
            name: 'address',
            value: formValues.current.address,
            placeholder: t('carClaimForm.address'),
            required: false,
            width: '100%',
            disabled: false
        },
        {
            type: 'input',
            name: 'province',
            value: formValues.current.province,
            placeholder: t('carClaimForm.province-label'),
            required: false,
            width: '100%',
            disabled: false
        },
        {
            type: 'input',
            name: 'city',
            value: formValues.current.city,
            placeholder: t('carClaimForm.city-label'),
            required: false,
            width: '100%',
            disabled: false
        },
    ]

    const platesInvolvedSelection = [
        {
            type: 'select',
            name: 'platesInvolved',
            value: isPlatesInvolved,
            placeholder: '',
            options: [
                {value: false, label: t('dialog.button-no')},
                {value: "1", label: "1"},
                {value: "2", label: "2"},
                {value: "3", label: "3"},
                {value: "4", label: "4"},
                {value: "5", label: "5"},
                {value: "6", label: "6"},
            ],
            required: false,
            width: '100%',
            disabled: false,
            visibility: true
        },
    ]

    const hasAccidentReportSelection = [
        {
            type: 'select',
            name: 'hasAccidentReport',
            value: formValues.current.hasAccidentReport,
            placeholder: '',
            options: [
                {value: false, label: t('dialog.button-no')},
                {value: true, label: t('dialog.button-yes')},
            ],
            required: false,
            width: '100%',
            disabled: isLoadingRemoveAccidentReportImages,
            visibility: true
        },
    ]

    const description = [
        {
            type: 'textarea',
            name: 'description',
            value: formValues.current.description,
            placeholder: t('carClaimForm.description-label'),
            required: false,
            width: '100%',
            disabled: false,
        },
    ]

    const handleDateChange = (date) => {
        const utcDate = moment.utc(date).format();
        setSelectedDate(utcDate);
    };

    const renderField = (field) => {
        const commonStyles = {
            backgroundColor: themeContext.color.white,
            width: field.width,
            borderRadius: 16
        };

        if (field.visibility !== false) {
            switch (field.type) {
                case 'select':
                    return (
                        <FormControl fullWidth required={field.required}>
                            <InputLabel id={`${field.name}-label`}>{t(field.placeholder)}</InputLabel>
                            <Select
                                required={field.required}
                                disabled={field.disabled}
                                labelId={`${field.name}-label`}
                                label={t(field.placeholder)}
                                id={`${field.name}-label`}
                                name={field.name}
                                style={commonStyles}
                                value={field.value}
                                endAdornment={field.loading &&
                                    <InputAdornment position="end" style={{marginRight: 10}}>
                                        <img src={spinner} className="spinner-img"
                                             alt="spinner"/>
                                    </InputAdornment>
                                }
                                onChange={(e) => {
                                    if (currentType === CLAIM_TYPES.CAR) {
                                        if (field.name === OPTIONS_TYPES.PLATES_INVOLVED) {
                                            handlePlatesInvolvedChange(e.target.value)
                                        } else {
                                            handleInputChange(e)
                                        }
                                    } else {
                                        handleGenericInputChange(e)
                                    }
                                }}
                                fullWidth
                                SelectDisplayProps={{
                                    style: {
                                        textAlign: 'left',
                                        borderRadius: '16px',
                                        color: themeContext.color.neutral600,
                                    }
                                }}
                                displayEmpty={(field.name === OPTIONS_TYPES.PLATES_INVOLVED)}
                                //defaultValue={(field.name === OPTIONS_TYPES.PLATES_INVOLVED) ? "NO" : null}
                                variant={'outlined'}>
                                {
                                    field.options.map((option, index) => {
                                            if (field.name === OPTIONS_TYPES.PERSON) {
                                                return (option.id === -1 ?
                                                    <MenuItem key={index + 1} value={option.id}>
                                                        <span>{option.label}</span>
                                                    </MenuItem> :
                                                    <MenuItem key={index + 1} value={option.id}>
                                                        {((option.firstName === null || option.firstName === "") && (option.lastName === null || option.lastName === "")) ?
                                                            <span>{option.name}</span> :
                                                            <span>{option.firstName} {option.lastName}</span>}
                                                    </MenuItem>)
                                            }
                                            if (field.name === OPTIONS_TYPES.PLATE) {
                                                return <MenuItem key={index + 1} value={option.plate}>
                                                    {option.plate} - {option.modellocostruttore}
                                                </MenuItem>
                                            }
                                            if (field.name === OPTIONS_TYPES.POLICY) {
                                                return <MenuItem key={index + 1} value={option.id}>
                                                    {option.type} - {option.company}
                                                </MenuItem>
                                            }
                                            if (field.name === OPTIONS_TYPES.PLATES_INVOLVED || field.name === OPTIONS_TYPES.HAS_ACCIDENT_REPORT) {
                                                return <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            }
                                        }
                                    )
                                }
                            </Select>
                            {field.helperText && <FormHelperText>{field.helperText}</FormHelperText>}
                        </FormControl>
                    )
                        ;
                case 'date':
                    return (
                        <FormControl fullWidth>
                            <DatePicker
                                id={field.name}
                                name={field.name}
                                required={field.required}
                                label={t(field.placeholder)}
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '16px',
                                                width: field.width
                                            },
                                            backgroundColor: themeContext.color.white,
                                        }}
                                    />
                                )}
                                PopperProps={{
                                    sx: {
                                        '& .MuiPaper-root': {
                                            transform: 'translate(49px, 8px) !important',
                                        }
                                    }
                                }}

                                format="DD/MM/YYYY"
                                disableFuture
                            />
                        </FormControl>
                    );
                case 'time':
                    return (
                        <FormControl fullWidth>
                            <TimePicker
                                id={field.name}
                                name={field.name}
                                required={field.required}
                                label={t(field.placeholder)}
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '16px',
                                            },
                                            backgroundColor: themeContext.color.white,
                                        }}
                                    />
                                )}
                                PopperProps={{
                                    sx: {
                                        '& .MuiPaper-root': {
                                            transform: 'translate(-4px, 8px) !important',
                                            width: '322px',
                                            height: '250px',
                                            padding: 0,
                                            margin: 0
                                        },
                                    }
                                }}
                                ampm={false}
                            />
                        </FormControl>
                    );
                case 'input':
                    return (
                        <FormControl fullWidth required={field.required}>
                            <TextField
                                id={field.name}
                                name={field.name}
                                required={field.required}
                                fullWidth
                                label={t(field.placeholder)}
                                variant="outlined"
                                type="text"
                                defaultValue={field.value}
                                onChange={(e) => {
                                    field.name.includes(OPTIONS_TYPES.PLATE_INVOLVED) ? handlePlateTextChange(field.name, e.target.value) : handleInputChange(e)
                                }}
                                color="primary"
                                style={{background: themeContext.color.white}}
                                InputProps={{
                                    style: {
                                        borderRadius: '16px',
                                        color: themeContext.color.neutral600
                                    }
                                }}
                            />
                        </FormControl>
                    )
                case 'textarea':
                    return (
                        <TextField
                            multiline={true}
                            minRows={7}
                            id={field.name}
                            name={field.name}
                            required={field.required}
                            fullWidth
                            label={t(field.placeholder)}
                            variant="outlined"
                            type="text"
                            defaultValue={field.value}
                            onChange={(e) => {
                                handleInputChange(e)
                            }}
                            color="primary"
                            style={{background: themeContext.color.white}}
                            InputProps={{
                                style: {
                                    borderRadius: '16px',
                                    color: themeContext.color.neutral600
                                }
                            }}
                        />
                    )
                default:
                    return null;
            }
        }
    };

    const nextStep = (type) => {
        setStep(currentStep + 1);
        setType(type)

        if (type === CLAIM_TYPES.CAR) {
            formValues.current = defaultValues
        } else {
            formValues.current = genericDefaultValues
        }
    }

    const disabledNextButton = (step, type) => {
        if (type === CLAIM_TYPES.CAR) {
            if (step === 0) {
                return !validateStep0()
            } else if (step === 1) {
                return !validateStep1()
            } else if (step === 2) {
                return (!validateStep0() && !validateStep1()) || isLoadingSend
            }
        } else {
            return !validateGenericStep0() || isLoadingSend
        }
    }

    const createClaim = (type) => {
        if (type === 'attach') {
            setTempImagesUrl(imagesUrl)
            setTempImages(images)
        } else {
            setTempImagesUrlAccidentReport(imagesUrlAccidentalReport)
            setTempImagesAccidentReport(imagesAccidentalReport)
        }
        let plateArray = [];
        if (Object.keys(platesInvolvedGroup).length > 0) {
            /*for (const key in platesInvolved) {
                for (const k in platesInvolved[key]) {
                    plateArray.push(platesInvolved[key][k])
                }
            }*/
            for (let i = 0; i < platesInvolvedGroup.length; i++) {
                if (platesInvolvedGroup[i].visibility === true) {
                    plateArray.push(platesInvolvedGroup[i].value)
                }
            }
        }
        formValues.current = {...formValues.current, ['platesInvolved']: plateArray,}
        formValues.current = {...formValues.current, ['dateTime']: selectedDate,}
        formValues.current = {...formValues.current, ['plate']: selectedPlate,}

        if (claimId === undefined) {
            fetch(newClaimUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues.current)
            }).then((response) => {
                return response.json();
            }).then((res) => {
                if (res.id) {
                    sessionStorage.setItem(`${res.id}`, JSON.stringify(formValues.current))
                    if (type && type === 'attach') {
                        attachImageActions(type, images.length)
                        setActiveDialog('attach')
                        setClaimId(res.id)
                    } else if (type && type === 'attachAccidentReport') {
                        attachImageActions(type, imagesAccidentalReport.length)
                        setClaimId(res.id)
                        setActiveDialog('attachAccidentReport')
                    }
                }
            }).catch(() => {
                handleClose()
            });
        } else {
            //reopen attach without create
            sessionStorage.setItem(`${claimId}`, JSON.stringify(formValues.current))
            if (type && type === 'attach') {
                attachImageActions(type, images.length)
                setActiveDialog('attach')
            } else if (type && type === 'attachAccidentReport') {
                attachImageActions(type, imagesAccidentalReport.length)
                setActiveDialog('attachAccidentReport')
            }
        }
    }

    const attachImageActions = (documentType, imagesLength) => {
        if (documentType === 'attach') {
            if (imagesLength >= 15) {
                setOpenPhotoLimitsDialog(true);
            } else {
                handleChangeImage();
            }
        } else {
            if (imagesLength >= 5) {
                setOpenPhotoLimitsDialog(true);
            } else {
                handleChangeAccidentReportImage();
            }
        }
    }

    const handleChangeAccidentReportImage = () => {
        const input = document.querySelector("#image-file-accident-report");
        input.click();
    }

    const handleChangeImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    const handleOnPendingImage = async (e) => {
        setUploadError(null)
        try {
            e.preventDefault();
            let file = e.target.files[0];
            if (file && !file.type.includes('image') && file.type !== 'application/pdf') {
                throw 'File not supported';
            }
            if (file && file.size > 20000000) {
                throw 'Maximum upload size exceeded';
            }
            if (file.type === 'application/pdf') {
                const randomName = Math.floor((Math.random() * 100000000) + 1)
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    addAttachment(randomName, file.type, base64String);
                };
                reader.readAsDataURL(file);
            } else {
                const imageResized = await resizeFile(file)
                setOnPendingImage(imageResized);
                setOpenCropDialog(true)
            }
        } catch (err) {
            if (err === 'Maximum upload size exceeded') {
                setUploadError(t('File not supported') + '<br />' + t('The maximum file upload size is 20MB'))
            } else if ('File not supported') {
                setUploadError(t('The uploaded format is not supported') + '<br />' + t('Only pdf and image formats are supported'))
            }
        }
    }

    const addAttachment = (randomName, mimetype, base64_string) => {
        fetch(`${addAttachmentToClaimUrl}/${claimId}/attachments/new`, {
            method: 'POST',
            body: JSON.stringify({
                name: randomName,
                mimeType: mimetype,
                base64: base64_string,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': true
            },
        }).then((res) => {
            if (res.ok) {
                //alert upload completato
                return res.json();
            }
        }).then((res) => {
            setIsLoadingImg(false);
            setOpenCropDialog(false);
            if (activeDialog === 'attach') {
                ids.current.push(res.id)
                new_ids.current.push(res.id)
            } else {
                ids_accidentReport.current.push(res.id)
                new_ids_accidentReport.current.push(res.id)
            }
            return res.id;
        }).then((attachment) => {
            if (activeDialog === 'attach') {
                getClaimAttachment(attachment, 'images');
            } else {
                getClaimAttachment(attachment, 'accidentReportImages');
            }
        }).catch(() => {
            handleClose()
        });
    }

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            const randomName = Math.floor((Math.random() * 100000000) + 1)
            let split1 = cropImage.split(":");
            let split2 = split1[1].split(";");
            let mimetype = split2[0];
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            setIsLoadingImg(true);
            addAttachment(randomName, mimetype, base64_string);
        }
    }

    //delete
    const handleOpenDeleteImageDialog = (id) => {
        setImageIdToDelete(id);
        setOpenDeleteImageDialog(true)
    }

    const handleDeleteImage = (attachmentId) => {
        fetch(`${removeAttachmentFromClaimUrl}/${claimId}/attachments/${attachmentId}/remove`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                setUploadError(null)
                if (activeDialog === 'attach') {
                    ids.current = ids.current.filter(id => {
                        return id !== attachmentId;
                    })
                    setImages([])
                    setImagesUrl([])
                    if (window.location.href.includes('attachAccidentReportImages')) {
                        sessionStorage.setItem(`${claimId}_accident_report_images`, JSON.stringify(ids.current))
                    } else {
                        sessionStorage.setItem(`${claimId}_images`, JSON.stringify(ids.current))
                    }
                    if (ids.current.length > 0) {
                        for (let i = 0; i < ids.current.length; i++) {
                            if (ids.current[i] !== attachmentId) {
                                getClaimAttachment(ids.current[i], 'images');
                            }
                        }
                    }
                } else {
                    ids_accidentReport.current = ids_accidentReport.current.filter(id => {
                        return id !== attachmentId;
                    })
                    setImagesAccidentalReport([])
                    setImagesUrlAccidentalReport([])
                    if (window.location.href.includes('attachAccidentReportImages')) {
                        sessionStorage.setItem(`${claimId}_accident_report_images`, JSON.stringify(ids.current))
                    } else {
                        sessionStorage.setItem(`${claimId}_images`, JSON.stringify(ids.current))
                    }
                    if (ids_accidentReport.current.length > 0) {
                        for (let i = 0; i < ids_accidentReport.current.length; i++) {
                            if (ids_accidentReport.current[i] !== attachmentId) {
                                getClaimAttachment(ids_accidentReport.current[i], 'accidentReportImages');
                            }
                        }
                    }
                }
            }
            setOpenDeleteImageDialog(false)
        }).catch(() => {
            handleClose()
        });
    }

    const submitForm = (step, type) => {
        const checkImages = JSON.parse(sessionStorage.getItem(`${claimId}_images`))
        /*
        if (claimId === undefined) {
            setImagesRequired(true)
            return;
        }  else if (checkImages.length === 0) {
            setImagesRequired(true)
            return;
        }
        */
        let plateArray = [];
        if (Object.keys(platesInvolvedGroup).length > 0) {
            for (const key in platesInvolved) {
                for (const k in platesInvolved[key]) {
                    plateArray.push(platesInvolved[key][k]);
                }
            }
        }

        formValues.current = {...formValues.current, ['platesInvolved']: plateArray,}
        formValues.current = {...formValues.current, ['dateTime']: selectedDate,}
        formValues.current = {...formValues.current, ['plate']: selectedPlate,}
        formValues.current = {...formValues.current, ['policyId']: selectedPolicy,}
        //update
        setIsLoadingSend(true)
        if (claimId === undefined) {
            fetch(`${newClaimUrl}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues.current)
            }).then((response) => {
                return response.json();
            }).then((res) => {
                fetch(`${updateClaimUrl}/${res.id}`, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formValues.current)
                }).then((response) => {
                    return response.json();
                }).then((res) => {
                    if (res.id) {
                        fetch(`${sendClaimUrl}/${res.id}/send`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            },
                        }).then((response) => {
                            return response.json();
                        }).then((res) => {
                            if (res.ok) {
                                sessionStorage.setItem('root', '/claims')
                                setStep(current => current + 1)
                            }
                        }).catch(() => {
                            setIsLoadingSend(false)
                            handleClose()
                        });
                    }
                }).catch(() => {
                    setIsLoadingSend(false)
                    handleClose()
                });
            }).catch(() => {
                setIsLoadingSend(false)
                handleClose()
            });
        } else {
            fetch(`${updateClaimUrl}/${claimId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues.current)
            }).then((response) => {
                return response.json();
            }).then((res) => {
                if (res.id) {
                    fetch(`${sendClaimUrl}/${res.id}/send`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                    }).then((response) => {
                        return response.json();
                    }).then((res) => {
                        if (res.ok) {
                            sessionStorage.setItem('root', '/claims')
                            setStep(current => current + 1)
                        }
                    }).catch(() => {
                        setIsLoadingSend(false)
                        handleClose()
                    });
                }
            }).catch(() => {
                setIsLoadingSend(false)
                handleClose()
            });
        }
    }

    const submitGenericForm = () => {
        const checkImages = JSON.parse(sessionStorage.getItem(`${claimId}_images`))
        /*
        if (claimId === undefined) {
            setImagesRequired(true)
            return;
        } else if (checkImages.length === 0) {
            setImagesRequired(true)
            return;
        }
         */
        formValues.current = {...formValues.current, ['dateTime']: selectedDate,}
        formValues.current = {...formValues.current, ['policyId']: selectedPolicy,}
        //update
        setIsLoadingSend(true)
        if (claimId === undefined) {
            fetch(newClaimUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues.current)
            }).then((response) => {
                return response.json();
            }).then((res) => {
                fetch(`${updateClaimUrl}/${res.id}`, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formValues.current)
                }).then((response) => {
                    return response.json();
                }).then((res) => {
                    if (res.id) {
                        fetch(`${sendClaimUrl}/${res.id}/send`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            },
                        }).then((response) => {
                            return response.json();
                        }).then((res) => {
                            if (res.ok) {
                                sessionStorage.setItem('root', '/claims')
                                setStep(current => current + 1)
                            }
                        }).catch(() => {
                            setIsLoadingSend(false)
                            handleClose()
                        });
                    }
                }).catch(() => {
                    setIsLoadingSend(false)
                    handleClose()
                });
            }).catch(() => {
                setIsLoadingSend(false)
                handleClose()
            });
        } else {
            fetch(`${updateClaimUrl}/${claimId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues.current)
            }).then((response) => {
                return response.json();
            }).then((res) => {
                if (res.id) {
                    fetch(`${sendClaimUrl}/${res.id}/send`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                    }).then((response) => {
                        return response.json();
                    }).then((res) => {
                        if (res.ok) {
                            sessionStorage.setItem('root', '/claims')
                            setStep(current => current + 1)
                        }
                    }).catch(() => {
                        setIsLoadingSend(false)
                        handleClose()
                    });
                }
            }).catch(() => {
                setIsLoadingSend(false)
                handleClose()
            });
        }
    }

    const cleanGenericAttachments = () => {
        setImages(tempImages)
        setImagesUrl(tempImagesUrl)

        ids.current = ids.current.filter(id_to_maintain => {
            if (!new_ids.current.includes(id_to_maintain)) {
                return id_to_maintain
            }
        })
        new_ids.current = []
    }

    const cleanAccidentalReportAttachments = () => {
        setImagesAccidentalReport(tempImagesAccidentReport)
        setImagesUrlAccidentalReport(tempImagesUrlAccidentReport)

        ids_accidentReport.current = ids_accidentReport.current.filter(id_to_maintain => {
            if (!new_ids_accidentReport.current.includes(id_to_maintain)) {
                return id_to_maintain
            }
        })
        new_ids_accidentReport.current = []
    }


    const doNotSave = (type, action) => {
        if (type === CLAIM_TYPES.CAR) {
            cleanAccidentalReportAttachments()
            setSelectedPolicy('')
            setSelectedPlate('')
            setIsPlatesInvolved(false)
            setPlatesInvolvedGroup(initialPlatesInvolved)
            setSelectedDate(new Date())
        } else {
            setSelectedPolicy('')
            setSelectedDate(new Date())
        }
        // set all form values to default values
        formValues.current = {...defaultValues};
        cleanGenericAttachments()
        setOpenRetryDialog(false)
        setOpenDoNotSaveDialog(false)
        if (action === 'exit') {
            handleClose();
        } else {
            setStep(-1)
        }
    }

    const handleCloseDialog = () => {
        if (currentStep === -1) {
            handleClose()
        } else if (currentType === CLAIM_TYPES.CAR && currentStep === 3) {
            handleClose()
        } else if (currentType === CLAIM_TYPES.GENERIC && currentStep === 2) {
            handleClose()
        } else {
            if (currentType === CLAIM_TYPES.CAR) {
                if (!validateStep0()) {
                    handleClose()
                } else {
                    setOpenDoNotSaveDialog(true)
                }
            } else {
                if (!validateGenericStep0()) {
                    handleClose()
                } else {
                    setOpenDoNotSaveDialog(true)
                }
            }
        }
    }

    const removeAccidentReportImages = () => {
        imagesAccidentalReport.map((image, index) => {
            setIsLoadingRemoveAccidentReportImages(true)
            handleDeleteImage(image.id)
            if (index + 1 === imagesAccidentalReport.length) {
                formValues.current.hasAccidentReport = false;
                //checkValidForm();
                setImagesAccidentalReport([])
                setImagesUrlAccidentalReport([])
                setTempImagesAccidentReport([])
                setTempImagesUrlAccidentReport([])
                setIsAccidentReportNote(false)
                setIsLoadingRemoveAccidentReportImages(false)
                setOpenAccidentReportDialog(false)
            }
        });
    }

    return (
        <>
            <Dialog
                components={{Backdrop: StyledBackdrop}}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    handleCloseDialog()
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
                maxWidth={'sm'}
                PaperProps={{style: StyledBigDialog}}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}
                                      localeText={{
                                          cancelButtonLabel: t('dialog.button-cancel'),
                                          okButtonLabel: t('dialog.button-confirm'),
                                      }}>
                    <Container style={{
                        position: 'fixed',
                        zIndex: '99',
                        borderTopLeftRadius: '24px',
                        borderTopRightRadius: '24px',
                        backgroundColor: 'white',
                        padding: '0',
                        margin: '0',
                        width: '600px'
                    }}>
                        <DialogTitle className="dialog-title pb-0">
                            <Row>
                                <Col className="col float-left" style={{
                                    'paddingLeft': '9px',
                                }}>
                                    <h6 className="header-title ellipsis-1-v2"
                                        style={{
                                            'paddingBottom': '3px',
                                            'textAlign': 'left',
                                            'fontSize': '22px'
                                        }}>
                                        {currentStep === -1 && t('openClaim.title')}
                                        {currentType === CLAIM_TYPES.CAR ? (
                                            currentStep > -1 && currentStep <= 3 && t('openClaim.type-1')
                                        ) : (
                                            currentStep > -1 && currentStep <= 3 && t('openClaim.type-2')
                                        )}
                                    </h6>
                                </Col>
                                <Col style={{justifyContent: 'end'}}>
                                    <img src={exitIcon} className="exit-button" alt="close" height="32px"
                                         onClick={() => {
                                             handleCloseDialog()
                                         }} style={{float: 'inline-end'}}/>
                                </Col>
                            </Row>
                            {currentType === CLAIM_TYPES.CAR && currentStep >= 0 && currentStep < 3 &&
                                <div className={'w-100 mt-4 mb-2'}>
                                    <Stepper activeStep={currentStep}>
                                        <StyledStep key={1}>
                                            <StepLabel></StepLabel>
                                        </StyledStep>
                                        <Step key={2}>
                                            <StepLabel></StepLabel>
                                        </Step>
                                        <Step key={3}>
                                            <StepLabel></StepLabel>
                                        </Step>
                                    </Stepper>
                                </div>
                            }
                            {currentType === CLAIM_TYPES.GENERIC && currentStep >= 0 && currentStep < 2 &&
                                <div className={'w-100 mt-4 mb-2'}>
                                    <Stepper activeStep={currentStep}>
                                        <StyledStep key={1}>
                                            <StepLabel></StepLabel>
                                        </StyledStep>
                                        <Step key={2}>
                                            <StepLabel></StepLabel>
                                        </Step>
                                    </Stepper>
                                </div>
                            }
                        </DialogTitle>
                    </Container>
                    {currentStep === -1 &&
                        <ExternalLayout style={{marginTop: '38px'}}>
                            <StyledCard onClick={() => nextStep(CLAIM_TYPES.CAR)}>
                                <StyledTitle>{t('openClaim.type-1')}</StyledTitle>
                                <img src={carClaimCard} alt="car-claim-card"/>
                            </StyledCard>
                            <StyledCard onClick={() => nextStep(CLAIM_TYPES.GENERIC)}>
                                <StyledTitle>{t('openClaim.type-2')}</StyledTitle>
                                <img src={notCarClaimCard} alt="not-car-claim-card"/>
                            </StyledCard>
                        </ExternalLayout>
                    }
                    {currentType === CLAIM_TYPES.CAR && currentStep > -1 &&
                        <form style={currentStep < 3 ? {marginTop: '100px'} : {marginTop: 0}}>
                            {currentStep === 0 &&
                                <div className={'d-flex flex-column'} style={{gap: 24, padding: 24}}>
                                    <StyledSubtitle style={{width: '60%'}}>{t('carClaimForm.payoff-1')}<StyledSubtitle
                                        style={{
                                            color: themeContext.color.main,
                                            fontWeight: "bold"
                                        }}>{t('carClaimForm.payoff-2')}</StyledSubtitle></StyledSubtitle>
                                    <div className={'d-flex flex-column'} style={{gap: 16}}>
                                        {requiredData.map((field, index) => (
                                            <div key={index} style={{width: field.width}}>
                                                {field && renderField(field)}
                                            </div>
                                        ))}
                                        <div className={'d-flex flex-row'} style={{gap: 16}}>
                                            {dateTimeData.map((field, index) => (
                                                <div key={index} style={{width: field.width}}>
                                                    {field && renderField(field)}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={'d-flex flex-column'} style={{gap: 16}}>
                                        <StyledSubtitle>{t('carClaimForm.location-label')}</StyledSubtitle>
                                        <div className={'d-flex flex-column'} style={{gap: 16}}>
                                            {locationData.map((field, index) => (
                                                <div key={index} style={{width: field.width}}>
                                                    {field && renderField(field)}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                            {currentStep === 1 &&
                                <div className={'d-flex flex-column'} style={{gap: 16, padding: 24}}>
                                    <StyledSubtitle>{t('carClaimForm.platesInvolved-label')}</StyledSubtitle>
                                    {platesInvolvedSelection.map((field, index) => (
                                        <div key={index} style={{width: field.width}}>
                                            {field && renderField(field)}
                                        </div>
                                    ))}
                                    {platesInvolvedGroup.map((field, index) => (
                                        <div key={index} style={{width: field.width}}>
                                            {field && renderField(field)}
                                        </div>
                                    ))}
                                    <StyledSubtitle>{t('carClaimForm.hasAccidentReport-label')}</StyledSubtitle>
                                    {hasAccidentReportSelection.map((field, index) => (
                                        <div key={index} style={{width: field.width}}>
                                            {field && renderField(field)}
                                        </div>
                                    ))}
                                    {formValues.current.hasAccidentReport === true &&
                                        <>
                                            <div className={'d-flex align-items-center'}>
                                                <StyledSubtitle>{t('carClaimForm.accident-report-attachments')}</StyledSubtitle>
                                                <IconButton onClick={(e) => {
                                                    createClaim('attachAccidentReport')
                                                }}><AddCircleOutlineIcon/></IconButton>
                                                {uploadError && <StyledSubtitle className={'red'}
                                                                                dangerouslySetInnerHTML={{__html: uploadError}}/>}
                                                <input hidden id="image-file-accident-report" type="file"
                                                       onChange={handleOnPendingImage}
                                                       accept="image/*,application/pdf"/>
                                            </div>
                                            <Carousel
                                                swipeable={false}
                                                draggable={false}
                                                responsive={responsive}
                                                ssr={true} // means to render carousel on server-side.
                                                infinite={false}
                                                keyBoardControl={true}
                                                customTransition="all .5"
                                                transitionDuration={500}
                                                containerClass="carousel-container"
                                                dotListClass="custom-dot-list-style"
                                                itemClass="carousel-style-custom">
                                                {imagesAccidentalReport.map((item, index) => (
                                                    <div key={index}>
                                                        <img className={'image-fit'} style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            borderRadius: '16px',
                                                        }} src={item.url} alt={`claim_${index}`}
                                                             onClick={() => {
                                                                 if (item.file) {
                                                                     window.open(item.file, '_blank', 'noopener', 'noreferrer');
                                                                 } else {
                                                                     openLightboxFun(imagesAccidentalReport, index)
                                                                 }
                                                             }}
                                                        />
                                                        <StyledTrashButton color="error"
                                                                           onClick={() => handleOpenDeleteImageDialog(item.id)}>
                                                            <img src={trashIcon} alt="trash-icon"/>
                                                        </StyledTrashButton>
                                                    </div>
                                                ))}
                                            </Carousel>
                                            <FsLightbox
                                                toggler={lightboxController.toggler}
                                                sources={imagesUrlAccidentalReport}
                                                type="image"
                                                sourceIndex={lightboxController.slide}
                                                key={imagesUrlAccidentalReport.length}
                                            />
                                        </>
                                    }
                                </div>
                            }
                            {currentStep === 2 &&
                                <div className={'d-flex flex-column'} style={{gap: 16, padding: 24}}>
                                    {description.map((field, index) => (
                                        <div key={index} style={{width: field.width}}>
                                            {field && renderField(field)}
                                        </div>
                                    ))}
                                    <div className={'d-flex align-items-center'}>
                                        <StyledSubtitle>{t('carClaimForm.attachments')}</StyledSubtitle>
                                        <IconButton onClick={(e) => {
                                            createClaim('attach')
                                        }}><AddCircleOutlineIcon/></IconButton>
                                        {uploadError && <StyledSubtitle className={'red'}
                                                                        dangerouslySetInnerHTML={{__html: uploadError}}/>}
                                        <input hidden id="image-file" type="file"
                                               onChange={handleOnPendingImage}
                                               accept="image/*,application/pdf"/>
                                    </div>
                                    <Carousel
                                        swipeable={false}
                                        draggable={false}
                                        responsive={responsive}
                                        ssr={true} // means to render carousel on server-side.
                                        infinite={false}
                                        keyBoardControl={true}
                                        customTransition="all .5"
                                        transitionDuration={500}
                                        containerClass="carousel-container"
                                        dotListClass="custom-dot-list-style"
                                        itemClass="carousel-style-custom">
                                        {images.map((item, index) => (
                                            <div key={index}>
                                                <img className={'image-fit'} style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    borderRadius: '16px',
                                                }} src={item.url} alt={`claim_${index}`}
                                                     onClick={() => {
                                                         if (item.file) {
                                                             window.open(item.file, '_blank', 'noopener', 'noreferrer');
                                                         } else {
                                                             openLightboxFun(images, index)
                                                         }
                                                     }}
                                                />
                                                <StyledTrashButton color="error"
                                                                   onClick={() => handleOpenDeleteImageDialog(item.id)}>
                                                    <img src={trashIcon} alt="trash-icon"/>
                                                </StyledTrashButton>
                                            </div>
                                        ))}
                                    </Carousel>
                                    <FsLightbox
                                        toggler={lightboxController.toggler}
                                        sources={imagesUrl}
                                        type="image"
                                        sourceIndex={lightboxController.slide}
                                        key={imagesUrl.length}
                                    />
                                </div>
                            }
                            {currentStep === 3 &&
                                <div className={'d-flex flex-column align-items-center'} style={{padding: 24}}>
                                    <img src={emailPlaceholder} width={475} height={425} style={{
                                        marginTop: 80,
                                        marginBottom: 80
                                    }} alt={'sent-successfully'}/>
                                    <div className={'d-flex flex-column align-items-center justify-content-center'}
                                         style={{gap: 16, padding: 24}}>
                                        <StyledSubtitle style={{fontSize: 24, fontWeight: 700}}>
                                            {t('carClaimForm.send-title')}
                                        </StyledSubtitle>
                                        <div className={'d-flex flex-column align-items-center justify-content-center'}>
                                            <StyledSubtitle>
                                                {t('carClaimForm.send-payoff-1')}
                                            </StyledSubtitle>
                                            <StyledSubtitle>
                                                {t('carClaimForm.send-payoff-2')}
                                            </StyledSubtitle>
                                        </div>
                                    </div>
                                </div>
                            }
                            {(currentStep >= 0 && currentStep < 3) &&
                                <ExternalLayout className={'justify-content-between'}>
                                    <div className={'d-flex align-items-end'}
                                         style={{fontSize: 14, color: themeContext.color.neutral400}}>
                                        {t('carClaimForm.required')}
                                    </div>
                                    <div className={'d-flex'} style={{gap: 8}}>
                                        <Button
                                            style={{
                                                width: 100,
                                                height: 36,
                                                alignSelf: 'flex-end',
                                                borderRadius: 12,
                                                color: themeContext.color.white,
                                                background: themeContext.color.neutral400
                                            }}
                                            variant="contained"
                                            onClick={() => handleBack(currentStep)}
                                        >{t('dialog.button-back')}
                                        </Button>
                                        <Button
                                            style={{
                                                width: 100,
                                                height: 36,
                                                alignSelf: 'flex-end',
                                                borderRadius: 12,
                                                color: themeContext.color.white
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                if (currentStep === 2) {
                                                    submitForm(currentStep)
                                                } else {
                                                    handleNext(currentStep, currentType)
                                                }
                                            }}
                                            disabled={disabledNextButton(currentStep, currentType)}>
                                            {currentStep === 2 ?
                                                (isLoadingSend ? <img src={spinner} className="spinner-img-inner-button"
                                                                      alt="spinner"/> : t('carClaimForm.send-button'))
                                                : t('dialog.button-next')}
                                        </Button>
                                    </div>
                                </ExternalLayout>
                            }
                        </form>
                    }
                    {currentType === CLAIM_TYPES.GENERIC && currentStep > -1 &&
                        <form style={currentStep < 2 ? {marginTop: '100px'} : {marginTop: 0}}>
                            {currentStep === 0 &&
                                <div className={'d-flex flex-column'} style={{gap: 24, padding: 24}}>
                                    <div className={'d-flex flex-column'} style={{gap: 16}}>
                                        <StyledSubtitle
                                            style={{width: '70%'}}>{`${t('genericClaimForm.payoff-1')} ${t('genericClaimForm.payoff-2')}`}<StyledSubtitle
                                            style={{
                                                color: themeContext.color.main, fontWeight: "bold"
                                            }}>{t('genericClaimForm.payoff-3')}</StyledSubtitle></StyledSubtitle>
                                        {requiredDataForGeneric.map((field, index) => (
                                            <div key={index} style={{width: field.width}}>
                                                {field && renderField(field)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className={'d-flex flex-row'} style={{gap: 16}}>
                                        {dateTimeData.map((field, index) => (
                                            <div key={index} style={{width: field.width}}>
                                                {field && renderField(field)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {currentStep === 1 &&
                                <div className={'d-flex flex-column'} style={{gap: 16, padding: 24}}>
                                    {description.map((field, index) => (
                                        <div key={index} style={{width: field.width}}>
                                            {field && renderField(field)}
                                        </div>
                                    ))}
                                    <div className={'d-flex align-items-center'}>
                                        <StyledSubtitle>{t('carClaimForm.attachments')}</StyledSubtitle>
                                        <IconButton onClick={(e) => {
                                            createClaim('attach')
                                        }}><AddCircleOutlineIcon/></IconButton>
                                        {uploadError && <StyledSubtitle className={'red'}
                                                                        dangerouslySetInnerHTML={{__html: uploadError}}/>}
                                        <input hidden id="image-file" type="file"
                                               onChange={handleOnPendingImage}
                                               accept="image/*,application/pdf"/>
                                    </div>
                                    <Carousel
                                        swipeable={false}
                                        draggable={false}
                                        responsive={responsive}
                                        ssr={true} // means to render carousel on server-side.
                                        infinite={false}
                                        keyBoardControl={true}
                                        customTransition="all .5"
                                        transitionDuration={500}
                                        containerClass="carousel-container"
                                        dotListClass="custom-dot-list-style"
                                        itemClass="carousel-style-custom">
                                        {images.map((item, index) => (
                                            <div key={index}>
                                                <img className={'image-fit'} style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    borderRadius: '16px',
                                                }} src={item.url} alt={`claim_${index}`}
                                                     onClick={() => {
                                                         if (item.file) {
                                                             window.open(item.file, '_blank', 'noopener', 'noreferrer');
                                                         } else {
                                                             openLightboxFun(images, index)
                                                         }
                                                     }}
                                                />
                                                <StyledTrashButton color="error"
                                                                   onClick={() => handleOpenDeleteImageDialog(item.id)}>
                                                    <img src={trashIcon} alt="trash-icon"/>
                                                </StyledTrashButton>
                                            </div>
                                        ))}
                                    </Carousel>
                                    <FsLightbox
                                        toggler={lightboxController.toggler}
                                        sources={imagesUrl}
                                        type="image"
                                        sourceIndex={lightboxController.slide}
                                        key={imagesUrl.length}
                                    />
                                </div>
                            }
                            {currentStep === 2 &&
                                <div className={'d-flex flex-column align-items-center'} style={{padding: 24}}>
                                    <img src={emailPlaceholder} width={475} height={425} style={{
                                        marginTop: 80,
                                        marginBottom: 80
                                    }} alt={'sent-successfully'}/>
                                    <div className={'d-flex flex-column align-items-center justify-content-center'}
                                         style={{gap: 16, padding: 24}}>
                                        <StyledSubtitle style={{fontSize: 24, fontWeight: 700}}>
                                            {t('carClaimForm.send-title')}
                                        </StyledSubtitle>
                                        <div className={'d-flex flex-column align-items-center justify-content-center'}>
                                            <StyledSubtitle>
                                                {t('carClaimForm.send-payoff-1')}
                                            </StyledSubtitle>
                                            <StyledSubtitle>
                                                {t('carClaimForm.send-payoff-2')}
                                            </StyledSubtitle>
                                        </div>
                                    </div>
                                </div>
                            }
                            {(currentStep >= 0 && currentStep < 2) &&
                                <ExternalLayout className={'justify-content-between'}>
                                    <div className={'d-flex align-items-end'}
                                         style={{fontSize: 14, color: themeContext.color.neutral400}}>
                                        {t('carClaimForm.required')}
                                    </div>
                                    <div className={'d-flex'} style={{gap: 8}}>
                                        <Button
                                            style={{
                                                width: 100,
                                                height: 36,
                                                alignSelf: 'flex-end',
                                                borderRadius: 12,
                                                color: themeContext.color.white,
                                                background: themeContext.color.neutral400
                                            }}
                                            variant="contained"
                                            onClick={() => handleBack(currentStep)}
                                        >{t('dialog.button-back')}
                                        </Button>
                                        <Button
                                            style={{
                                                width: 100,
                                                height: 36,
                                                alignSelf: 'flex-end',
                                                borderRadius: 12,
                                                color: themeContext.color.white
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                if (currentStep === 1) {
                                                    submitGenericForm(currentStep)
                                                } else {
                                                    handleNext(currentStep, currentType)
                                                }
                                            }}
                                            disabled={disabledNextButton(currentStep, currentType)}>
                                            {currentStep === 1 ?
                                                (isLoadingSend ? <img src={spinner} className="spinner-img-inner-button"
                                                                      alt="spinner"/> : t('carClaimForm.send-button'))
                                                : t('dialog.button-next')}
                                        </Button>
                                    </div>
                                </ExternalLayout>
                            }
                        </form>
                    }
                </LocalizationProvider>
            </Dialog>
            <CropDialog open={openCropDialog}
                        handleAttach={() => {
                            if (isLoadingImg === false) {
                                attachCroppedImage()
                            }
                        }}
                        handleClose={() => {
                            setOpenCropDialog(false)
                            setIsLoadingImg(false)
                        }}
                        isLoading={isLoadingImg}
                        onPendingImage={onPendingImage}
                        cropperRef={cropperRef}
                        setCropper={(data) => {
                            if (data) {
                                setCropper(data)
                            }
                        }}/>
            <DialogSmall open={openPhotoLimitsDialog}
                         title={t('documentsList.limit-photo-modal-title')}
                         description={t('documentsList.limit-photo-modal-description')}
                         handleClose={() => setOpenPhotoLimitsDialog(false)}
                         buttonText={t('documentsList.limit-photo-modal-button')}/>
            <DialogDoubleAction open={openDeleteImageDialog}
                                title={t('genericClaimForm.irreversible-action-title')}
                                description={t('genericClaimForm.irreversible-action-description')}
                                handleClose={() => setOpenDeleteImageDialog(false)}
                                handleNo={() => setOpenDeleteImageDialog(false)}
                                deleteAction={true}
                                handleYes={() => handleDeleteImage(imageIdToDelete)}
                                buttonYesText={t('genericClaimForm.irreversible-action-done')}
                                buttonNoText={t('genericClaimForm.irreversible-action-undone')}/>
            <DialogDoubleAction open={openDoNotSaveDialog}
                                title={t('genericClaimForm.do-not-save-title')}
                                description={t('genericClaimForm.do-not-save-description')}
                                handleClose={() => setOpenDoNotSaveDialog(false)}
                                handleNo={() => setOpenDoNotSaveDialog(false)}
                                deleteAction={true}
                                handleYes={() => {
                                    doNotSave(currentType, 'exit')
                                }}
                                buttonYesText={t('genericClaimForm.do-not-save-confirm-button')}
                                buttonNoText={t('genericClaimForm.do-not-save-refuse-button')}/>
            <DialogDoubleAction open={openRetryDialog}
                                title={'Sicuro di voler tornare indietro?'}
                                description={t('genericClaimForm.do-not-save-description')}
                                handleClose={() => setOpenRetryDialog(false)}
                                handleNo={() => setOpenRetryDialog(false)}
                                deleteAction={true}
                                handleYes={() => doNotSave(currentType, 'retry')}
                                buttonYesText={t('genericClaimForm.do-not-save-confirm-button')}
                                buttonNoText={t('genericClaimForm.do-not-save-refuse-button')}/>
            <DialogDoubleAction open={openAccidentReportDialog}
                                title={t('carClaimForm.accident-report-dialog-title')}
                                description={t('carClaimForm.accident-report-dialog-description')}
                                handleClose={() => setOpenAccidentReportDialog(false)}
                                handleNo={() => setOpenAccidentReportDialog(false)}
                                deleteAction={true}
                                handleYes={removeAccidentReportImages}
                                buttonYesText={t('carClaimForm.accident-report-dialog-done')}
                                buttonNoText={t('carClaimForm.accident-report-dialog-undone')}/>
        </>
    )
}
export default OpenClaimDialog;
