import React, {Fragment, useContext, useEffect, useState} from 'react';
import themeContext from "../ThemeContext.js";
import detailBarPlaceholder from "../../../images/detailBar-placeholder.svg";
import {useTranslation} from "react-i18next";
import {StatusLabel} from "../../styles/DesktopStyles.js";
import Divider from "@mui/material/Divider";
import {styled} from "@mui/material/styles";
import moment from "moment";
import {ListItemButton} from "@mui/material";
import {DetailBarContext} from "../../middleware/DetailBarContext.js";
import {CARD_TYPE} from "./ListCard.js";
import {setClaimStatus} from "../../service/ClaimsService.js";
import Moment from "react-moment";
import MultiButtonDialog from "../MultiButtonDialog.js";
import {getAccountExec} from "../../service/ProfileService.js";
import DOMPurify from "dompurify";

const ClaimDetail = ({claim}) => {
    const {t, i18n} = useTranslation();
    const tPrefix = 'claimDetail';
    const tPrefixDesktop = 'desktop.claimDetail';
    const token = sessionStorage.getItem('token');
    const {active, activeDetailBar, data, updateData, handleLoading} = useContext(DetailBarContext);
    const [isLoading, setIsLoading] = useState(false)
    const [needHelpDialog, setNeedHelpDialog] = useState(false)
    const [accountExec, setAccountExec] = useState(null)
    const handleClaimActions = (claim) => {
        return claim.claimActions.filter(claimAction => !(claimAction.actionType === 'personalizzata_privata' || claimAction.actionType === 'nota_riserva'));
    }
    const [claimActions, setClaimActions] = useState(handleClaimActions(claim))

    const SpanKey = styled('span')(({theme}) => ({
        width: '36%',
        color: themeContext.color.neutral400,
        fontSize: '14px',
        textAlign: 'left'
    }));

    const SpanValue = styled('span')(({theme}) => ({
        width: '64%',
        color: themeContext.color.neutral600,
        fontSize: '14px',
        textAlign: 'left'
    }));

    const SpanValueFull = styled('span')(({theme}) => ({
        width: '100%',
        color: themeContext.color.neutral600,
        fontSize: '14px',
        textAlign: 'left'
    }));

    const StyledListItemButton = styled(ListItemButton)(({theme}) => ({
        width: `100%`,
        borderRadius: `16px`,
        padding: `4px 0 4px 8px`,
        height: `40px`
    }));


    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                console.log('claim', claim)
                await Promise.all([
                    getAccountExec(token).then(res => {
                        console.log(res, 'accountExec')
                        setAccountExec(res)
                    }),
                ])
            } catch (e) {
                console.log('error', e)
            } finally {
                setIsLoading(false)
            }
        }
        if (claim) {
            fetchData();
            setClaimActions(handleClaimActions(claim))
        }
    }, [claim]);

    return (
        <Fragment>
            {isLoading && <div className="background-loading-spinner spinner-desktop"></div>}
            {!isLoading &&
                <Fragment>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <span style={{fontWeight: 700, fontSize: '18px', color: themeContext.color.neutral600}}>
                            Sinistro:
                        </span>
                        {/*{claim && <img src={exportIcon} alt={'export-icon'}/>}*/}
                    </div>
                    {claim === null ?
                        <Fragment>
                            <h6 style={{
                                fontWeight: 600,
                                fontSize: '20px',
                                color: themeContext.color.neutral200,
                                margin: '20% auto'
                            }}>
                                {t(`${tPrefixDesktop}.Select a policy to view details and make actions`)}
                            </h6>
                            <img style={{position: "absolute", bottom: 90, right: 0}} src={detailBarPlaceholder}
                                 alt={'document-placeholder'}/>
                        </Fragment>
                        : <Fragment>
                            <div style={{gap: '16px', width: '100%'}}>
                                <span style={{
                                    display: "flex",
                                    justifyContent: 'start',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    color: themeContext.color.neutral400,
                                    textAlign: "left"
                                }}>{claim.policy.object}</span>
                                <div style={{display: "flex", justifyContent: 'end', gap: '8px'}}>
                                    <SpanKey className={`w-auto`}>Stato: </SpanKey>
                                    <StatusLabel
                                        color={setClaimStatus(claim.closeType, claim.settledValueCents)}
                                        type={CARD_TYPE.CLAIM}>
                                        {t(setClaimStatus(claim.closeType, claim.settledValueCents).label)}
                                    </StatusLabel>
                                </div>
                            </div>
                            <Divider className={'w-100'}/>
                            <div className={'d-flex justify-content-start align-items-center flex-column w-100'}
                                 style={{gap: '4px'}}>
                                <div className={'d-flex justify-content-start align-items-start w-100'}
                                     style={{minHeight: 32}}>
                                    <SpanKey>{t(`${tPrefix}.open-date`)}</SpanKey>
                                    <SpanValue>{moment(claim.eventdate).format('DD/MM/YYYY')}</SpanValue>
                                </div>
                                <div className={'d-flex justify-content-start align-items-start w-100'}
                                     style={{minHeight: 32}}>
                                    <SpanKey>{t(`${tPrefix}.type`)}</SpanKey>
                                    <SpanValue>{claim.claimtype}</SpanValue>
                                </div>
                                <div className={'d-flex justify-content-start align-items-start w-100'}
                                     style={{minHeight: 32}}>
                                    <SpanKey>{t(`${tPrefix}.policy-holder`)}</SpanKey>
                                    <SpanValue>{claim.name}</SpanValue>
                                </div>
                                <div className={'d-flex justify-content-start align-items-start w-100'}
                                     style={{minHeight: 32}}>
                                    <SpanKey>{t(`${tPrefix}.policy-number-label`)}</SpanKey>
                                    <SpanValue>{claim.policy.policynr}</SpanValue>
                                </div>
                                <div className={'d-flex justify-content-start align-items-start w-100'}
                                     style={{minHeight: 32}}>
                                    <SpanKey>{t(`${tPrefix}.number`)}</SpanKey>
                                    <SpanValue>{claim.claimnr}</SpanValue>
                                </div>
                                {claim.settledValueCents > 0 &&
                                    <div className={'d-flex justify-content-start align-items-start w-100'}
                                         style={{minHeight: 32}}>
                                        <SpanKey>{t(`${tPrefix}.settled-value`)}</SpanKey>
                                        <SpanValue>{claim.settledValue}</SpanValue>
                                    </div>}
                                {claim.controparte &&
                                    <div className={'d-flex justify-content-start align-items-start w-100'}
                                         style={{minHeight: 32}}>
                                        <SpanKey>{t(`${tPrefix}.counterpart`)}</SpanKey>
                                        <SpanValue>{claim.controparte}</SpanValue>
                                    </div>}
                                <div className={'d-flex justify-content-end w-100'}>
                                    <a className="footer-text-login-desk cursor-pointer"
                                       style={{'color': themeContext.color.main, fontSize: '14px'}}
                                       onClick={() => {
                                           if (!isLoading) {
                                               setNeedHelpDialog(true)
                                           }
                                       }}>{t('claimDetail.need-help')}</a>
                                </div>
                            </div>
                            {
                                <Fragment>
                                    <Divider className={'w-100'}/>
                                    <span style={{
                                        fontWeight: 700,
                                        fontSize: '18px',
                                        color: themeContext.color.neutral600
                                    }}>Dettagli:</span>
                                    {
                                        claimActions.length > 0 && (claimActions.map((value, index) => (
                                            <Fragment key={index}>
                                                <div className={'d-flex flex-column w-100'} style={{gap: 8}}>
                                                    <SpanValueFull
                                                        className={'font-weight-bold'}>{value.actionType}</SpanValueFull>
                                                    <SpanValueFull>{value.note}</SpanValueFull>
                                                    <div className={'d-flex justify-content-end'}
                                                         style={{color: themeContext.color.neutral400, fontSize: 14}}>
                                                        <Moment locale="it"
                                                                format="DD/MM/YYYY">{value.actionDate}</Moment>
                                                    </div>
                                                </div>
                                                <Divider className={'w-100'}/>
                                            </Fragment>
                                        )))
                                    }
                                    {
                                        claimActions.length === 0 && claim.note !== null &&
                                        <div className={'d-flex flex-column w-100'} style={{gap: 8}}>
                                            <SpanValueFull
                                                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(claim.note)}}></SpanValueFull>
                                        </div>
                                    }
                                </Fragment>
                            }
                        </Fragment>
                    }
                </Fragment>
            }
            {accountExec && <MultiButtonDialog
                open={needHelpDialog}
                handleClose={() => setNeedHelpDialog(false)}
                title={t(`${tPrefix}.modal-title`)}
                titleStyle={{'textAlign': 'center'}}
                content={`${accountExec.firstName} ${accountExec.lastName}`}
                contentStyle={{'textAlign': 'center'}}
                actions={[
                    {
                        type: 'phone',
                        text: `Chiama`,
                        phone: `${accountExec.phone}`,
                        style: {fontSize: '18px !important', 'textTransform': 'none'}
                    },
                    {
                        type: 'mail',
                        text: `Scrivi email`,
                        mail: accountExec.email,
                        style: {fontSize: '18px !important', 'textTransform': 'none'}
                    },
                    {
                        type: 'button',
                        text: 'settings.modal-refuse-button',
                        onClick: () => setNeedHelpDialog(false),
                        style: {
                            'color': themeContext.color.neutral400,
                            'textTransform': 'none'
                        }
                    }
                ]}
            />}
        </Fragment>
    );
};

export default ClaimDetail;