import * as React from 'react';
import {setPersonImage, setStatusColor} from "../../service/DashboardService.js";
import {styled} from "@mui/system";
import Avatar from "@mui/material/Avatar";
import MuiAvatarGroup from "@mui/material/AvatarGroup";
import themeContext from "../ThemeContext.js";
import {tooltipClasses, TooltipProps} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {Fragment, useState} from "react";
import usersPlaceholder from "../../../images/desktop/user-placeholder.svg"
import {SpanNoWrap} from "../../styles/DesktopStyles.js";

const StyleAvatarGroup = styled(MuiAvatarGroup)(({}) => ({
    display: 'flex',
    justifyContent: 'start',
}));

const StyledAvatar = styled(Avatar)(({color, selected, isselected, index}) => ({
    opacity: selected && isselected === 'false' ? 0.5 : 1,
    background: isselected === 'true' ? `linear-gradient(to bottom, ${color}, ${color})` :
        `linear-gradient(to bottom, ${themeContext.color.white}, ${themeContext.color.white}, ${themeContext.color.white}, ${color})`,
    marginLeft: index !== 0 ? '-8px !important' : 0,
    zIndex: isselected === 'true' ? '2 !important' : 1,
}));

const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: themeContext.color.white,
        color: themeContext.color.neutral600,
        //boxShadow: theme.shadows[1],
        fontSize: 14,
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
        {
            marginTop: '-10px',
            marginLeft: '110px',
        },
}));

const StyledAvatarDiv = styled('div')(({}) => ({
    display: 'flex',
    alignItems: 'center',
    background: themeContext.color.white,
    borderRadius: '16px',
    marginRight: '24px',
    padding: '8px',
}));

const userPlaceholder = (
    <div className={'d-flex flex-row'} style={{gap: '8px'}}>
        <img src={usersPlaceholder} alt={'usersPlaceholder-1'}/>
        <img src={usersPlaceholder} alt={'usersPlaceholder-2'}/>
        <img src={usersPlaceholder} alt={'usersPlaceholder-3'}/>
    </div>
)

const AvatarGroup = React.memo(function AvatarGroup({people, isLoading, selected, onChange}) {
    const [selectedAvatar, setSelectedAvatar] = useState(selected || false);

    const handleAvatar = (e, item) => {
        e.preventDefault();
        if (selectedAvatar === item) {
            setSelectedAvatar(false);
            onChange(false, e);
            return;
        }
        setSelectedAvatar(item);
        onChange(item, e);
    };

    return (
        isLoading ? userPlaceholder : (people.length > 1 ?
            <div style={{
                overflowX: 'auto',
                width: '100%',
                paddingBottom: '10px' // Space for scrollbar
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    minWidth: 'min-content',
                    gap: '8px'
                }}>
                    <StyleAvatarGroup total={people.length} max={people.length || 2}>
                        {people.map((person, index) => (
                            selectedAvatar !== person.id ? (
                                <LightTooltip title={
                                    <Fragment>{person.firstName && person.lastName ?
                                        <Fragment><span>{person.lastName}</span>
                                            <br/> <span>{person.firstName}</span></Fragment> :
                                        <span>{person.name}</span>}</Fragment>} key={person.id}>
                                    <div className={'d-flex flex-row'}
                                         style={selectedAvatar === person.id ? {gap: '8px'} : {}}>
                                        <StyledAvatar sx={{width: 56, height: 56}} color={setStatusColor(person.status)}
                                                      selected={selectedAvatar} index={index}
                                                      isselected={(selectedAvatar === person.id).toString()}
                                                      className={'desktop-avatar'} alt={person.name}
                                                      src={person ? setPersonImage(person.gender, person.image) : null}
                                                      key={person.id}
                                                      onClick={(e) => {
                                                          handleAvatar(e, person.id)

                                                      }}/>
                                        {selectedAvatar === person.id && <StyledAvatarDiv>
                                            <>{person.firstName && person.lastName ?
                                                <div className={'d-flex flex-column align-items-start'}>
                                                    <span>{person.lastName} {person.firstName}
                                                        </span>
                                                </div> :
                                                <span>{person.name}</span>}</>
                                        </StyledAvatarDiv>}
                                    </div>
                                </LightTooltip>
                            ) : (
                                <div className={'d-flex flex-row'}
                                     style={people.indexOf(person) === 0 ? {gap: '8px', paddingLeft: 8} : {gap: '8px'}}
                                     key={person.id}>
                                    <StyledAvatar sx={{width: 56, height: 56}} color={setStatusColor(person.status)}
                                                  selected={selectedAvatar} index={index}
                                                  isselected={(selectedAvatar === person.id).toString()}
                                                  className={'desktop-avatar'} alt={person.name}
                                                  src={person ? setPersonImage(person.gender, person.image) : null}
                                                  key={person.id}
                                                  onClick={(e) => {
                                                      handleAvatar(e, person.id)
                                                  }}/>
                                    {selectedAvatar === person.id && <StyledAvatarDiv>
                                        <>{person.firstName && person.lastName ?
                                            <div className={'d-flex flex-column align-items-start'}>
                                                <SpanNoWrap>{person.lastName}</SpanNoWrap>
                                                <SpanNoWrap>{person.firstName}</SpanNoWrap></div> :
                                            <SpanNoWrap>{person.name}</SpanNoWrap>}</>
                                    </StyledAvatarDiv>}
                                </div>
                            )
                        ))}
                    </StyleAvatarGroup></div>
            </div> : (people.map((person) => (
                selectedAvatar !== person.id ? (
                    <div className={'d-flex flex-row'} style={selectedAvatar === person.id ? {gap: '8px'} : {}}
                         key={person.id}>
                        <LightTooltip title={
                            <Fragment>{person.firstName && person.lastName ?
                                <Fragment><span>{person.lastName}</span>
                                    <br/> <span>{person.firstName}</span></Fragment> :
                                <span>{person.name}</span>}</Fragment>} key={person.id}>
                            <StyledAvatar sx={{width: 56, height: 56}} color={setStatusColor(person.status)}
                                          selected={selectedAvatar} index={0}
                                          isselected={(selectedAvatar === person.id).toString()}
                                          className={'desktop-avatar'} alt={person.name}
                                          src={person ? setPersonImage(person.gender, person.image) : null}
                                          key={person.id}
                                          onClick={(e) => {
                                              handleAvatar(e, person.id)
                                          }}/>
                        </LightTooltip>
                        {selectedAvatar === person.id && <StyledAvatarDiv>
                            <>{person.firstName && person.lastName ?
                                <div className={'d-flex flex-column align-items-start'}>
                                    <span>{person.lastName}</span>
                                    <span>{person.firstName}</span></div> :
                                <span>{person.name}</span>}</>
                        </StyledAvatarDiv>}
                    </div>
                ) : (
                    <div className={'d-flex flex-row'} style={selectedAvatar === person.id ? {gap: '8px'} : {}}
                         key={person.id}>
                        <StyledAvatar sx={{width: 56, height: 56}} color={setStatusColor(person.status)}
                                      selected={selectedAvatar} index={0}
                                      isselected={(selectedAvatar === person.id).toString()}
                                      className={'desktop-avatar'} alt={person.name}
                                      src={person ? setPersonImage(person.gender, person.image) : null}
                                      key={person.id}
                                      onClick={(e) => {
                                          handleAvatar(e, person.id)
                                      }}/>
                        {selectedAvatar === person.id && <StyledAvatarDiv>
                            <>{person.firstName && person.lastName ?
                                <div className={'d-flex flex-column align-items-start'}>
                                    <SpanNoWrap>{person.lastName}</SpanNoWrap>
                                    <SpanNoWrap>{person.firstName}</SpanNoWrap></div> :
                                <SpanNoWrap>{person.name}</SpanNoWrap>}</>
                        </StyledAvatarDiv>}
                    </div>
                )
            ))))
    );
})

export default AvatarGroup;
