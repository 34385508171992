import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../../images/back-icon.svg";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import {makeStyles} from "@mui/styles";
import {Radio} from "@mui/material";
import {useTranslation} from 'react-i18next'
import themeContext from "../../components/ThemeContext.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {ExternalPageLayout, StyledPageCard, StyledPageLayout, StyledTitlePage} from "../../styles/DesktopStyles.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";


const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100% !important',
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "0 0 0 0 !important",
    },
    listItem: {
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '6px !important',
        paddingBottom: '6px !important',
    },
    listTextSelected: {
        color: `${themeContext.color.neutral600} !important`,
        fontSize: '16px !important'
    },
    listText: {
        fontSize: '16px !important'
    },
}));

export default function Language({layout}) {
    const {open} = useContext(SidebarContext);
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedRadio, setSelectedRadio] = useState('');
    const {t, i18n} = useTranslation();
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const [errorPage, setErrorPage] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getInfo();
        }
    }, []);


    function turnBack() {
        return navigate(-1);
    }

    function handleRadio(event) {
        setSelectedRadio(event.target.value)
        i18n.changeLanguage(event.target.value).then(r => {
            updateInfo(i18n.language);
        })
    }

    function handleListItem(lang) {
        setSelectedRadio(lang)
        i18n.changeLanguage(lang).then(r => {
            updateInfo(i18n.language);
        })
    }

    function getInfo() {
        fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            i18n.language = data.preferredLanguage
            setSelectedRadio(data.preferredLanguage)
        }).catch(() => {
            setErrorPage(true)
        })
    }

    function updateInfo(language) {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({preferredLanguage: language})
        };
        fetch(userInfoUrl, requestOptions)
            .then(r => {
            }).catch(() => {
            setErrorPage(true)
        });
    }

    function reload() {
        window.location.reload();
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {
                    errorPage ?
                        <ErrorPage/> :
                        <StyledPageLayout className="w-100 mx-4">
                            <StyledTitlePage>
                                <Col className="col-2 float-left">
                                    {
                                        layout !== 'desktop' &&
                                        <img src={back} onClick={turnBack}
                                             className="nav-top-logo float-left cursor-pointer"
                                             height="27px"
                                             alt="back"/>}
                                </Col>
                                <Col className="col-8">
                                    <span className="header-title ellipsis-1-v2">{t('language.title')}</span></Col>
                                <Col className="col-2"></Col>
                            </StyledTitlePage>
                            <Row style={{
                                'paddingLeft': '0',
                                'paddingRight': '0px',
                            }}>
                                <Col style={{
                                    'paddingLeft': '0',
                                    'paddingRight': '0px'
                                }}>
                                    <div className={classes.rootList}>
                                        <List component="nav" aria-label="main mailbox folders">
                                            <Divider/>
                                            <ListItem className={classes.listItem} button>

                                                <ListItemText onClick={() => handleListItem('it')}
                                                              primary={t('language.italian-radio')}
                                                              className={selectedRadio === 'it' ? classes.listTextSelected : classes.listText}/>
                                                <Radio
                                                    checked={selectedRadio === 'it'}
                                                    onChange={handleRadio}
                                                    value="it"
                                                    name="radio-buttons"
                                                    inputProps={{'aria-label': 'it'}}
                                                    className="justify-content-end"
                                                    color="primary"
                                                    size="small"
                                                />
                                            </ListItem>
                                            <Divider/>
                                            <ListItem className={classes.listItem} button>
                                                <ListItemText onClick={() => handleListItem('en')}
                                                              primary={t('language.english-radio')}
                                                              className={selectedRadio === 'en' ? classes.listTextSelected : classes.listText}/>
                                                <Radio
                                                    checked={selectedRadio === 'en'}
                                                    onChange={handleRadio}
                                                    value="en"
                                                    name="radio-buttons"
                                                    inputProps={{'aria-label': 'en'}}
                                                    className="justify-content-end"
                                                    color="primary"
                                                    size="small"
                                                />
                                            </ListItem>
                                            <Divider/>
                                            <ListItem className={classes.listItem} button>
                                                <ListItemText onClick={() => handleListItem('de')}
                                                              primary={t('language.german-radio')}
                                                              className={selectedRadio === 'de' ? classes.listTextSelected : classes.listText}/>

                                                <Radio
                                                    checked={selectedRadio === 'de'}
                                                    onChange={handleRadio}
                                                    value="de"
                                                    name="radio-buttons"
                                                    inputProps={{'aria-label': 'de'}}
                                                    className="justify-content-end"
                                                    color="primary"
                                                    size="small"
                                                />
                                            </ListItem>
                                        </List>
                                    </div>
                                </Col>
                            </Row>
                        </StyledPageLayout>
                }
            </StyledPageCard>
        </ExternalPageLayout>
    )
}
