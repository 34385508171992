import React, {useState} from 'react'
import Dialog from "@mui/material/Dialog";
import {Button, Slide} from "@mui/material";
import {t} from "i18next";
import DialogContent from "@mui/material/DialogContent";
import {Col, Row} from "react-bootstrap";
import takingResponsibilityPlaceholder from "../../images/takingResponsibilityPlaceholder.svg";
import Container from "react-bootstrap/Container";
import checkboxSelectedIcon from "../../images/checkbox-selected.svg";
import checkboxUnselectedIcon from "../../images/checkbox-unselected.svg";
import {useMediaQuery} from "react-responsive";
import themeContext from "./ThemeContext.js";
import {StyledBackdrop} from "../styles/Styles.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PrivacyPolicyDialog = ({
                                 open,
                                 handleClose,
                                 checkbox1,
                                 checkbox2,
                                 checkbox3,
                                 document,
                                 proceedLater,
                                 handleSave,
                             }) => {
    const permissions = 'permissions';
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [currentCheckbox1, setCurrentCheckbox1] = useState(checkbox1);
    const [currentCheckbox2, setCurrentCheckbox2] = useState(checkbox2);
    const [currentCheckbox3, setCurrentCheckbox3] = useState(checkbox3);

    return (
        <Dialog
            components={{Backdrop: StyledBackdrop}}
            fullScreen={!isDesktop}
            fullWidth={isDesktop}
            maxWidth={'sm'}
            PaperProps={{
                style: {
                    bottom: isDesktop ? '5%' : '0',
                    color: themeContext.color.neutral600,
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                    borderRadius: isDesktop ? '20px' : 'none',
                    height: isDesktop ? '90%' : '100%',
                    marginTop: '10%'
                }
            }}
            open={open}
            onClose={!proceedLater ? handleClose : () => {}}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">

            <h6 style={{
                padding: 24,
                textAlign: "center",
                fontSize: '18px',
                color: themeContext.color.neutral600,
                fontWeight: '600',
            }}>{t(`${permissions}.title`)}</h6>

            <DialogContent className={'privacy-policy-container'}>
                <Col className="col-list">
                    <Col>
                        <img src={takingResponsibilityPlaceholder}
                             style={isDesktop ? {maxHeight: '400px'} : {maxHeight: '270px'}}
                             alt="taking-responsibility-placeholder" width="100%"/>
                    </Col>
                    <Container style={proceedLater ? {padding: '24px 24px 188px 24px'} : {padding: '24px 24px 148px 24px'}}>
                        <Row>
                            <Col>
                                <a href={document.url} target="_blank"
                                   rel="noopener noreferrer"
                                   className="login-subtitle cursor-pointer float-left"
                                   color="primary"
                                   style={{
                                       textDecoration: 'none',
                                       color: themeContext.color.main,
                                       textAlign: 'left'
                                   }}>{document.name}</a>
                            </Col>
                        </Row>
                        <Row className={'mt-4 d-flex align-items-start cursor-pointer'}
                             onClick={() => {
                                 setCurrentCheckbox1(current => !current)
                             }}>
                            <Col className={'col-1'}>
                                <img
                                    src={currentCheckbox1 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                    alt="checkbox-unselected" height="20px"/>
                            </Col>
                            <Col className={'col d-flex justify-content-start'}>
                                        <span className={'checkbox-text'}>
                                            {t(`${permissions}.checkbox-1`)}
                                        </span>
                            </Col>
                        </Row>
                        <Row className={'mt-2'}>
                            <Col className={'col-1'}>

                            </Col>
                            <Col className={'col d-flex justify-content-start'}>
                                        <span className={'checkbox-text font-weight-normal red font-italic'}
                                              style={{lineHeight: "19.1px"}}>
                                            {t(`${permissions}.datiParticolari-info`)}
                                        </span>
                            </Col>
                        </Row>
                        <Row className={'mt-4 d-flex align-items-start cursor-pointer'}
                             onClick={() => {
                                 setCurrentCheckbox2(current => !current)
                             }}>
                            <Col className={'col-1'}>
                                <img
                                    src={currentCheckbox2 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                    alt="checkbox-unselected" height="20px"/>
                            </Col>
                            <Col className={'col d-flex justify-content-start'}>
                                       <span className={'checkbox-text'}>
                                            {t(`${permissions}.checkbox-2`)}
                                        </span>
                            </Col>
                        </Row>
                        <Row className={'mt-4 d-flex align-items-start cursor-pointer'}
                             onClick={() => {
                                 setCurrentCheckbox3(current => !current)
                             }}>
                            <Col className={'col-1'}>
                                <img
                                    src={currentCheckbox3 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                    alt="checkbox-unselected" height="20px"/>
                            </Col>
                            <Col className={'col d-flex justify-content-start'}>
                                        <span
                                            className={'checkbox-text'}>
                                            {t(`${permissions}.checkbox-3`)}
                                        </span>
                            </Col>
                        </Row>
                        <Row className={'mt-4 d-flex align-items-center'}>
                            <Col>
                                <span className={'checkbox-text font-weight-normal'}
                                      style={{color: themeContext.color.neutral400, fontSize: '12px'}}>
                                  {t(`${permissions}.info-2`)}
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Container className="position-absolute linear-gradient-white"
                           style={
                               isDesktop ? {
                                   opacity: '1',
                                   left: 0,
                                   bottom: 0,
                                   right: 0,
                                   paddingBottom: 32,
                                   display: "flex",
                                   flexDirection: "column",
                                   gap: 24
                               } : {
                                   opacity: '1',
                                   left: 0,
                                   bottom: 0,
                                   paddingBottom: 68,
                                   right: 0,
                                   display: "flex",
                                   flexDirection: "column",
                                   gap: 24
                               }
                           }>
                    <Row style={{textAlign: 'center'}}>
                        <Col>
                            <Button
                                className={(!currentCheckbox1 && !currentCheckbox2 && !currentCheckbox3) ? "disable-button" : "primary-button"}
                                disabled={(!currentCheckbox1 && !currentCheckbox2 && !currentCheckbox3)}
                                variant="outlined"
                                size="large"
                                style={{
                                    //color: themeContext.color.white,
                                    width: '288px',
                                    textTransform: 'none'
                                }}
                                type="button"
                                onClick={() => {
                                    handleSave(currentCheckbox1, currentCheckbox2, currentCheckbox3)
                                }}
                            >{t(`${permissions}.confirm`)}</Button>
                        </Col>
                    </Row>
                    {proceedLater && <Row style={{textAlign: 'center'}}>
                        <Col>
                             <span className={'checkbox-text cursor-pointer'}
                                   onClick={() => {
                                       handleClose(true)
                                   }}>
                                 {t(`${permissions}.proceed-later`)}
                             </span>
                        </Col>
                    </Row>}
                </Container>
            </DialogContent>
        </Dialog>
    )
}
export default PrivacyPolicyDialog;
