import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import AuthContextMiddleware from '../middleware/AuthContextMiddleware.js';
import {useNavigate} from "react-router-dom";
import {fetchToken} from "../middleware/Firebase.js";
import {
    deleteElementFromIndexedDB,
    getElementFromIndexedDB,
    isIpad,
    isMac,
    isStandalone,
    putElementFromIndexedDB
} from "../service/UtilityService.js";
import {ClientJS} from 'clientjs';
import {useMediaQuery} from "react-responsive";
import {useTranslation} from "react-i18next";
import ClaimsMobile from "../pages/mobile/Claims.js";
import ClaimsDesktop from "../pages/desktop/Claims.js";
import {getClaimsV2} from "../service/ClaimsService.js";

export default function ClaimsController() {
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const checkReadUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/checkRead`;
    const personalClaimsUrl = `${process.env.REACT_APP_BASE_URL}/claims/personal`;
    const peopleClaimsUrl = `${process.env.REACT_APP_BASE_URL}/claims/people`;
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const webAuthNRegistrationUrl = `${process.env.REACT_APP_BASE_URL}/management/webauthn/registration`;
    const searchPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/search`;

    const token = sessionStorage.getItem('token');
    const {t, i18n} = useTranslation();
    const [isLoadingPersonal, setIsLoadingPersonal] = useState(false);
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [isLoadingScrollbar, setIsLoadingScrollbar] = useState(false);
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [isNothingPersonal, setIsNothingPersonal] = useState(false);
    const [isNothingPeople, setIsNothingPeople] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})

    const [isLoadingActiveClaims, setIsLoadingActiveClaims] = useState(0);
    const [isLoadingOtherClaims, setIsLoadingOtherClaims] = useState(0);
    const [activeClaims, setActiveClaims] = useState([]);
    const [otherClaims, setOtherClaims] = useState([]);
    const [selectedAvatarState, setSelectedAvatarState] = useState(null);


    const width_1199 = useMediaQuery({
        query: '(min-width: 1199px)'
    })
    const width_991 = useMediaQuery({
        query: '(min-width: 991px)'
    })
    const width_767 = useMediaQuery({
        query: '(min-width: 766px)'
    })
    const width_min = useMediaQuery({
        query: '(min-width: 0px)'
    })

    const handleClaimsV2 = async (token_arg, page, size, filter?, personId?, vehicleId?) => {
        if (filter === true) {
            return await getClaimsV2(token_arg, page, size, filter, personId, vehicleId);
        } else {
            return await getClaimsV2(token_arg, page, size, filter, personId, vehicleId);
        }
    };

    useEffect(() => {
        sessionStorage.setItem('root', '/claims');
        if (navigator.onLine) {
            if (sessionStorage.getItem("notificationId") != null) {
                const url = sessionStorage.getItem("notificationId").replaceAll('"', '')
                navigateToMessageDetails(url);
            }
            getInfo();
            if (!searchMode) {
                getPeople();
            }
            handleRinging();
            if (!searchMode) {
                getPeopleClaims();
            }
        }
        if (!localStorage.getItem('firstLogin')) {
            setOpenDialog(true);
        }
        localStorage.setItem('firstLogin', true);

        if (searchMode) {
            const delayDebounceFn = setTimeout(() => {
                searchPeople(searchValue)
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }

    }, [searchValue]);

    useEffect(() => {
        //console.log('selectedAvatarState', selectedAvatarState);

        /*const delayDebounceFn = setTimeout(() => {
            setIsLoadingActiveClaims(true)
            setIsLoadingOtherClaims(true)
                handleClaimsV2(token, true, selectedAvatarState).then((data) => {
                    //const feat_array = [];
                    if (data) {
                        setActiveClaims(data)
                    }
                    setIsLoadingActiveClaims(false);
                })
        }, 500);
        return () => clearTimeout(delayDebounceFn);*/

    }, [/*selectedAvatarState*/]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsLoadingActiveClaims(1)
            setIsLoadingOtherClaims(1)
            handleClaimsV2(token, 0, 1, true, selectedAvatarState).then((data) => {
                const totalElements = data.totalElements;
                handleClaimsV2(token, 0, totalElements, true, selectedAvatarState).then((data) => {
                    if (data) {
                        setActiveClaims(data.content)
                    }
                    setIsLoadingActiveClaims(2);
                })
            })

            handleClaimsV2(token, 0, 1, false, selectedAvatarState).then((data) => {
                const totalElements = data.totalElements;
                handleClaimsV2(token, 0, totalElements, false, selectedAvatarState).then((data) => {
                    if (data) {
                        setOtherClaims(data.content)
                    }
                    setIsLoadingOtherClaims(2);
                })
            })

        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [selectedAvatarState]);


    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [userID, setUserID] = useState("");
    const [user, setUser] = useState("");
    const authCtx = useContext(AuthContextMiddleware);
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({
        title: '',
        body: ''
    });
    const client = new ClientJS();
    const [openDialog, setOpenDialog] = useState(false);
    const [bellRinging, setBellRinging] = useState(false);
    const [personalClaims, setPersonalClaims] = useState([]);
    const [peopleClaims, setPeopleClaims] = useState([]);
    const [people, setPeople] = useState([]);
    //const [selectedAvatar, setSelectedAvatar] = useState("");
    const selectedAvatar = useRef("null");
    const [searchMode, setSearchMode] = useState(false);
    const [timeoutAvatar, setTimeoutAvatar] = useState(false);
    const [noPeople, setNoPeople] = useState(false);
    const [getPermission, setGetPermission] = useState(false);


    const handleAvatar = (e, item) => {
        e.preventDefault();
        if (selectedAvatarState === item) {
            setSelectedAvatarState(false);
            //onChange(false);
            return;
        }
        setSelectedAvatarState(item);

        /*const delayDebounceFn = setTimeout(() => {
            setIsLoadingActiveClaims(true)
            setIsLoadingOtherClaims(true)
            handleClaimsV2(token, 0, 1, true, item).then((res) => {
                const totalElements = res.totalElements;
                //const feat_array = [];
                handleClaimsV2(token, 0, totalElements, true, item).then((data) => {
                    if (data) {
                        setActiveClaims(data.content)
                    }
                    setIsLoadingActiveClaims(false);
                })
            })

            handleClaimsV2(token, 0, 1, false, item).then((res) => {
                const totalElements = res.totalElements;
                //const feat_array = [];
                handleClaimsV2(token, 0, totalElements, false, item).then((data) => {
                    if (data) {
                        setOtherClaims(data.content)
                    }
                    setIsLoadingOtherClaims(false);
                })
            })
        }, 500);
        return () => clearTimeout(delayDebounceFn);*/

        //onChange(item);
    };


    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone()) || (isMac() && client.getOSVersion() < "13")) {
        //console.log('notification not allowed')
    } else if (!getPermission) {
        requestPermission();
        setGetPermission(true);
    }
    fetchToken();

    function requestPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
            }
        })
    }

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone()) || (isMac() && client.getOSVersion() < "13")) {
        //console.log('notification not allowed')
    } else {
        const channel = new BroadcastChannel('sw-messages');
        channel.onmessage = (event) => {
            setBellRinging(true)
        };
        channel.onmessageerror = (event) => {
        };
    }

    const navigateToMessageDetails = (url) => {
        return navigate(`/messages/principal/${url}`);
    }

    function getInfo() {
        fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            i18n.changeLanguage(data.preferredLanguage).then(r => {
            })
            if (data.status !== -1) {
                setUser(data)
                putElementFromIndexedDB('profileInfo', data)
                if (!!data.pinCode) {
                    putElementFromIndexedDB('pin', data.pinCode)
                    localStorage.setItem('pinIsActive', true)
                } else {
                    localStorage.removeItem('pinIsActive')
                    deleteElementFromIndexedDB('pin')
                }
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    function getInfoOffline() {
        getElementFromIndexedDB('profileInfo').then((response) => {
            return response.value;
        }).then((data) => {
            setUser([])
            setUser(data)
        });
    }

    function reload() {
        window.location.reload();
    }

    function getPeople() {
        setIsLoadingScrollbar(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeople([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!personalClaims.includes(item)) {
                        setPeople(current => [...current, item])
                    }
                }
            })
            if (count === 0) {
                sessionStorage.setItem('noPeople', true);
                setNoPeople(true);
            } else {
                sessionStorage.removeItem('noPeople');
                setNoPeople(false);
            }
            putElementFromIndexedDB('people', data)
            setIsLoadingScrollbar(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPeopleOffline() {
        getElementFromIndexedDB('people').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                sessionStorage.setItem('noPeople', true);
            } else {
                sessionStorage.removeItem('noPeople');
            }
            setPeople([])
            for (let i = 0; i < length; i++) {
                if (!personalClaims.includes(data[i])) {
                    setPeople(current => [...current, data[i]])
                }
            }
        });
    }

    function getPersonalClaims() {
        setIsLoadingPersonal(true)
        fetch(personalClaimsUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPersonalClaims([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!personalClaims.includes(item)) {
                        setPersonalClaims(current => [...current, item])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPersonal(true)
            } else {
                setIsNothingPersonal(false)
            }

            setIsLoadingPersonal(false)
            putElementFromIndexedDB('personalClaims', data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPeopleClaims() {
        setIsLoadingPeople(true)
        fetch(peopleClaimsUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeopleClaims([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!peopleClaims.includes(item)) {
                        setPeopleClaims(current => [...current, item])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPeople(true)
            } else {
                setIsNothingPeople(false)
            }
            setIsLoadingPeople(false)
            putElementFromIndexedDB('peopleClaims', data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPersonClaims(id) {
        setIsLoadingPeople(true)
        fetch(`${peopleClaimsUrl}?personId=${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeopleClaims([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!peopleClaims.includes(item)) {
                        setPeopleClaims(current => [...current, item])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPeople(true)
            } else {
                setIsNothingPeople(false)
            }
            setIsLoadingPeople(false)
            putElementFromIndexedDB('peopleClaims', data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPersonalClaimsOffline() {
        setIsLoadingPersonal(true)
        getElementFromIndexedDB('personalClaims').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            setPersonalClaims([])
            for (let i = 0; i < length; i++) {
                if (!personalClaims.includes(data[i])) {
                    setPersonalClaims(current => [...current, data[i]])
                }
            }
            setIsLoadingPersonal(false)
        });
    }

    function getPeopleClaimsOffline() {
        getElementFromIndexedDB('peopleClaims').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            setPeopleClaims([])
            for (let i = 0; i < length; i++) {
                if (!peopleClaims.includes(data[i])) {
                    setPeopleClaims(current => [...current, data[i]])
                }
            }
        });
    }

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleRinging = () => {
        fetch(checkReadUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.toRead) {
                setBellRinging(true);
            } else {
                setBellRinging(false);
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    const handleClickPersonal = () => {
        setTimeoutAvatar(true);
        setPeopleClaims([]);
        if (selectedAvatar.current === 'personal') {
            selectedAvatar.current = "null";
            getPersonalClaims();
            getPeopleClaims()
        } else {
            selectedAvatar.current = 'personal';
            getPersonalClaims();
        }
        setTimeout(function () {
            setTimeoutAvatar(false);
        }, 1000);
    }

    const handleClickPerson = (id) => {
        setTimeoutAvatar(true);
        setPersonalClaims([]);
        setPeopleClaims([]);
        if (selectedAvatar.current === id) {
            selectedAvatar.current = "null";
            getPeopleClaims()
        } else {
            selectedAvatar.current = id;
            getPersonClaims(id);
        }
        setTimeout(function () {
            setTimeoutAvatar(false);
        }, 1000);

    }

    const handleSearchMode = () => {
        if (searchMode && searchValue !== "") {
            setSearchValue("")
            document.querySelector('#search-input').value = "";
            document.querySelector('#search-input').focus();
        } else {
            setSearchMode(current => !current);
            getPeople();
            getPeopleClaims();
        }
    }

    const searchPeople = (text) => {
        setIsLoadingSearch(true)
        fetch(`${searchPeopleUrl}?text=${text}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPeople([]);
            data.map((item, index) => {
                if (item.status !== -1) {
                    setPeople(current => [...current, item])
                }
            })
            setIsLoadingSearch(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const onClickClaim = (id) => {
        return navigate(`/claims/claim/${id}`);
    }

    const controllerState = {
        serviceError,
        people,
        isLoadingPeople,
        peopleClaims,
        isLoadingPersonal,
        isLoadingSearch,
        searchMode,
        handleSearchMode,
        setSearchValue,
        handleClickPerson,
        selectedAvatar,
        timeoutAvatar,
        handleClickPersonal,
        //handleClickClaim,
        onClickClaim,
        //handleReload,
        width_min,
        width_767,
        width_991,
        width_1199,
        isNothingPeople,
        noPeople,
        searchValue,
        reload,
        isLoadingScrollbar,
        bellRinging,
        setBellRinging,
        user,

        selectedAvatarState,
        setSelectedAvatarState,
        isLoadingActiveClaims,
        setIsLoadingActiveClaims,
        isLoadingOtherClaims,
        setIsLoadingOtherClaims,
        activeClaims,
        setActiveClaims,
        otherClaims,
        setOtherClaims,
        handleAvatar
    };

    if (isDesktop) {
        return (
            <Fragment>
                <ClaimsDesktop {...controllerState}/>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <ClaimsMobile {...controllerState}/>
            </Fragment>
        );
    }
}