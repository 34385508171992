import {claimsUrl, claimsV2Url} from "./EndpointService.js";
import {apiFetch} from "./ApiService.js";
import themeContext from "../components/ThemeContext.js";

export const getClaimsV2 = async (token_arg, page, size, filter, personId, vehicleId) => {
    let url = `${claimsV2Url}`;

    if (page === 0 || page) url += `?page=${page}`;
    if (size) url += `${(page === 0 || page) ? '&' : '?'}size=${size}`;
    if (filter) url += `${(page === 0 || page) || size ? '&' : '?'}filter=${filter}`;
    if (personId) url += `${(page === 0 || page) || size || filter ? '&' : '?'}personId=${personId}`;
    if (vehicleId) url += `${(page === 0 || page) || size || filter || personId ? '&' : '?'}vehicleId=${vehicleId}`;

    try {
        return await apiFetch(url, token_arg);
    } catch (error) {
        return null;
    }
};

export const CLAIM_STATUS = {
    CLOSED_GREEN: {
        label: 'desktop.claims.closed claim',
        color: themeContext.color.green,
        background: themeContext.color.green10
    },
    CLOSED_RED: {
        label: 'desktop.claims.closed claim',
        color: themeContext.color.red,
        background: themeContext.color.red10
    },
    OPEN: {
        label: 'desktop.claims.open',
        color: themeContext.color.main,
        background: themeContext.color.main10
    },
    WITHOUT_SEQUEL: {
        label: 'desktop.claims.without sequel',
        color: themeContext.color.neutral400,
        background: themeContext.color.neutral100
    },
}

export const setClaimStatus = (value, settledValueCents) => {
    if (value === 'SINISTRO CHIUSO' && settledValueCents > 0) {
        return CLAIM_STATUS.CLOSED_GREEN
    } else if (value === 'SINISTRO CHIUSO') {
        return CLAIM_STATUS.CLOSED_RED
    } else if (value === 'SINISTRO APERTO') {
        return CLAIM_STATUS.OPEN
    } else if (value === 'SENZA SEGUITO') {
        return CLAIM_STATUS.WITHOUT_SEQUEL
    }
}

export const getClaim = async (token_arg, claimId) => {
    try {
        return await apiFetch(`${claimsUrl}/${claimId}`, token_arg);
    } catch (error) {
        return null;
    }
};