import React, {Fragment} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import BellOff from '../../../images/bell-icon.svg'
import BellOn from '../../../images/bellOn-icon.svg'
import baseFemaleAvatar from '../../../images/female-icon.svg'
import wideLogoLight from '../../../images/wideLogo-icon.svg';
import {makeStyles} from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Moment from "react-moment";
import car from "../../../images/car-blue.svg";
import baseMaleAvatar from "../../../images/male-icon.svg";
import {upperCaseFirst} from "upper-case-first";
import searchIcon from "../../../images/search-icon.svg";
import exitIcon from "../../../images/exit-icon.svg";
import nothing from "../../../images/nothing-icon.svg";
import scrollbarUsersPlaceholder from "../../../images/user-placeholder.svg";
import cardPlaceholder_min from "../../../images/card-placeholder_min.svg";
import cardPlaceholder_678 from "../../../images/card-placeholder_678.svg";
import cardPlaceholder_918 from "../../../images/card-placeholder_918.svg";
import cardPlaceholder_full from "../../../images/card-placeholder_full.svg";
import {Button} from "@mui/material";
import Header from "../../components/mobile/Header.js";
import baseCompanyAvatar from "../../../images/company-avatar.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../../components/ThemeContext.js";
import {setGrayScale, setStatusColor} from "../../service/DashboardService.js";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: "0 auto !important",
        marginTop: "10px !important",
        marginBottom: "18px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        minHeight: "140px !important"
    },
    titles: {
        color: `${themeContext.color.neutral200} !important`,
        textAlign: 'left !important',
        /*lineHeight: '1',*/
        fontWeight: '500 !important',
        fontSize: '12px !important',
        textTransform: 'uppercase !important',
    },
    cardAvatar: {
        float: "right !important",
        width: "35px !important",
        height: "35px !important",
        /*marginTop: "-8px"*/
    },
    avatar: {
        width: '56px !important',
        height: '56px !important',
        margin: '4px 7px 4px 7px !important',
    },
    bigAvatar: {
        width: '66px !important',
        height: '66px !important',
        margin: '0 0 0 5px !important',
        boxShadow: `0px 2px 5px ${themeContext.color.boxShadow} !important`,
    },
}));

export default function Claims(props) {
    const {
        serviceError,
        people,
        isLoadingPeople,
        peopleClaims,
        isLoadingPersonal,
        isLoadingSearch,
        searchMode,
        handleSearchMode,
        setSearchValue,
        handleClickPerson,
        selectedAvatar,
        timeoutAvatar,
        handleClickPersonal,
        handleClickClaim,
        onClickClaim,
        handleReload,
        width_min,
        width_767,
        width_991,
        width_1199,
        isNothingPeople,
        noPeople,
        searchValue,
        reload,
        isLoadingScrollbar,
        bellRinging,
        setBellRinging,
        user,
        selectedChipState,
        selectedAvatarState,
        selectedChip,
    } = props;

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();

    return (
        serviceError ?
            <Container className="pt-5 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Container style={{'paddingTop': '10%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Row className="nav-top container" style={{
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Header iconSrc={wideLogoLight}
                                    rightSrc={bellRinging ? BellOn : BellOff}
                                    rightAction={() => navigate('/messages')}/>
                            {!noPeople &&
                                <Fragment>
                                    <Row style={{'marginTop': '11px'}}>
                                        <Col className="col-auto float-left">
                                            <h6 className={classes.titles}>{t('claims.beneficiaries-title')}
                                            </h6>
                                        </Col>
                                        <Col className="col float-right" style={{'marginTop': '-7px'}}>
                                            {
                                                searchMode &&
                                                <Row className="div-search" style={{'marginRight': '0'}}>
                                                    <Col className="col-10">
                                                        <input id="search-input" className="input-search"
                                                               onChange={e => setSearchValue(e.target.value)}
                                                               autoFocus
                                                               style={{
                                                                   'border': 'none',
                                                                   'marginLeft': '-5px'
                                                               }}></input>
                                                    </Col>
                                                    <Col className="col-2"
                                                         style={{
                                                             'paddingRight': '2px',
                                                             'textAlign': 'right'
                                                         }}>
                                                        <img src={exitIcon} alt="close" onClick={handleSearchMode}
                                                             height="25px" width="25px"/>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                !searchMode &&
                                                <img src={searchIcon}
                                                     className="nav-top-logo float-right cursor-pointer"
                                                     height="27px"
                                                     alt="search-icon"
                                                     onClick={handleSearchMode}/>
                                            }

                                        </Col>
                                    </Row>

                                    {isLoadingSearch &&
                                        <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                    }
                                    {
                                        !isLoadingSearch &&
                                        <Row style={{'marginTop': '10px'}}>
                                            <Col className="col-list">
                                                <Container className="people-scrollable-row">
                                                    {
                                                        isLoadingScrollbar &&
                                                        <Row className="mt-2 w-100">
                                                            <Col style={{textAlign: 'left'}}>
                                                                <img src={scrollbarUsersPlaceholder}
                                                                     alt="card-scrollbar" className="mr-1"/>
                                                                <img src={scrollbarUsersPlaceholder}
                                                                     alt="card-scrollbar" className="mr-1"/>
                                                                <img src={scrollbarUsersPlaceholder}
                                                                     alt="card-scrollbar" className="mr-1"/>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {!isLoadingScrollbar &&
                                                        <Container className="scrolling-wrapper-flexbox no-scrollbar"
                                                                   id="scroll-container">
                                                            {
                                                                people.map((person, index) => (
                                                                    <div className="card card-avatar" key={index}
                                                                         style={{backgroundColor: themeContext.color.neutral10}}>
                                                                        <CardContent className="card-avatar-content"
                                                                                     style={{backgroundColor: themeContext.color.neutral10}}>
                                                                            <Row style={{'width': '50px'}}>

                                                                                {
                                                                                    person.image === null && person.gender === 'M' &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseMaleAvatar}
                                                                                            className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                                }
                                                                                {
                                                                                    person.image === null && person.gender === 'F' &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseFemaleAvatar}
                                                                                            className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                                }
                                                                                {
                                                                                    person.image === null && person.gender === 'C' &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseCompanyAvatar}
                                                                                            className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                                }
                                                                                {
                                                                                    person.image === null && person.gender === null &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseMaleAvatar}
                                                                                            className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                                }
                                                                                {
                                                                                    person.image &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={person.image}
                                                                                            className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : {}}/>
                                                                                }
                                                                            </Row>
                                                                            <Row
                                                                                style={selectedAvatar.current === person.id ? {'width': '73px'} : {'width': '67px'}}>
                                                                                <h6 className={selectedAvatar.current === person.id ? "avatar-text-big text-uppercase" : "avatar-text text-uppercase"}
                                                                                    style={selectedAvatar.current === person.id ? {color: `${setStatusColor(person.status)}`} : {}}>
                                                                                    {((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                                                        upperCaseFirst(`${person.name}`)
                                                                                        : <span><span
                                                                                            className="ellipsis-1">{upperCaseFirst(`${person.firstName}`)} </span><span
                                                                                            className="ellipsis-1">{upperCaseFirst(`${person.lastName}`)}</span></span>
                                                                                    }
                                                                                </h6>
                                                                            </Row>
                                                                        </CardContent>
                                                                    </div>
                                                                ))
                                                            }
                                                        </Container>
                                                    }
                                                </Container>
                                            </Col>
                                        </Row>
                                    }
                                </Fragment>
                            }
                            <Row className={'d-flex align-items-center my-2'}>
                                <Col className={'d-flex justify-content-start'}>
                                    <span className={classes.titles}>{t('claims.title')}</span>
                                </Col>
                                <Col className={'d-flex justify-content-end'}>
                                    <button onClick={() => navigate('/claims/history')}
                                            className={'small-outline-button'}>{t('claims.claims-history-button')}</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {
                        !noPeople ?
                            <Container className="top-of-the-dashboard"></Container>
                            :
                            <Container className="top-of-the-dashboard" style={{'paddingTop': '112px'}}></Container>
                    }
                    <Row className="mt-2" style={{
                        paddingBottom: '57px'
                    }}>
                        <Col>
                            {
                                isLoadingPersonal &&
                                <Fragment>
                                    <Row className="mt-2">
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {
                                isLoadingPeople &&
                                <Fragment>
                                    <Row className="mt-2">
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {
                                !isLoadingPeople && isNothingPeople && selectedAvatar.current === 'null' &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('messages.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('claims.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {
                                !isLoadingPeople && isNothingPeople && selectedAvatar.current !== 'null' &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('messages.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('claims.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {!isLoadingPeople && !isNothingPeople &&
                                peopleClaims.map((value, index) => (
                                    <Card className={classes.card}
                                          key={index}
                                          onClick={() => onClickClaim(value.id)}>
                                        <CardContent style={{'paddingBottom': '16px'}}>
                                            <Row>
                                                <Col className="col-8">
                                                    <h6 className={'card-text-mid ellipsis-1-v2'}>{value.claimtype}</h6>
                                                </Col>
                                                <Col className="col-4">
                                                    {
                                                        value.image === null && value.gender === 'M' &&
                                                        <Avatar src={baseMaleAvatar} className={classes.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === 'F' &&
                                                        <Avatar src={baseFemaleAvatar} className={classes.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === 'C' &&
                                                        <Avatar src={baseCompanyAvatar} className={classes.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === null &&
                                                        <Avatar src={baseMaleAvatar} className={classes.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image &&
                                                        <Avatar src={value.image} className={classes.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className={'card-text-content ellipsis-1-v2'}>
                                                        {t('claims.practise-number')}
                                                        <span
                                                            className="font-weight-bold black">
                                                                {value.claimnr}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className={'card-text-content ellipsis-1-v2'}>{t('claims.open-date')}<span
                                                        className="font-weight-bold black">
                                                        <Moment locale="it"
                                                                format="DD/MM/YYYY">{value.eventdate}</Moment>
                                                </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            {value.controparte && <Row>
                                                <Col>
                                                    <h6 className={'card-text-content ellipsis-1-v2'}>
                                                        {t('claims.counterpart')}
                                                        <span className="font-weight-bold black">
                                                            {value.controparte}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>}
                                            <Row>
                                                <Col>
                                                    <h6 className={'card-text-content ellipsis-1-v2'}>
                                                        {t('claims.status')}
                                                        <span className="font-weight-bold">
                                                            <span
                                                                style={(value.settledValueCents > 0 && value.closeType === 'SINISTRO CHIUSO') ? {color: themeContext.color.green}
                                                                    : value.closeType === 'SINISTRO APERTO' ? {color: themeContext.color.mainActive}
                                                                        : (value.closeType === 'SINISTRO CHIUSO' ? {color: themeContext.color.red}
                                                                            : (value.closeType === 'SENZA SEGUITO' ? {color: themeContext.color.neutral200} : {}))}>
                                                                {value.closeType}
                                                            </span>
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            {value.settledValueCents > 0 && <Row>
                                                <Col>
                                                    <h6 className={'card-text-content ellipsis-1-v2'}>
                                                        {t('claims.settled-value')}
                                                        <span className="font-weight-bold black">
                                                            {value.settledValue}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>}
                                            {
                                                value.plate !== null &&
                                                <Row>
                                                    <div className="col-plate">
                                                        <img src={car} alt="car icon"/>
                                                        <span className={'card-text-content'}
                                                              style={{'marginLeft': '7px'}}>{value.plate}</span>
                                                    </div>
                                                </Row>
                                            }
                                        </CardContent>
                                    </Card>
                                ))
                            }
                        </Col>
                    </Row>
                    <Container className="fixed-bottom"
                               style={{
                                   'background': 'transparent',
                                   'opacity': '1',
                                   'paddingBottom': "100px"
                               }}>
                        <Row>
                            <Col>
                                <Button className="primary-button"
                                        variant="outlined"
                                        size="large"
                                        style={{width: 288}} type="submit"
                                        onClick={() => navigate('/claims/open')}
                                >{t('claims.open-claims-button')}</Button>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="bottom-with-navbar"
                               style={{'backgroundColor': themeContext.color.neutral10}}></Container>
                </Container>
            </Fragment>
    )
}