import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import DashboardController from "../controllers/DashboardController.js";
import Settings from "../pages/Settings.js";
import Profile from "../pages/desktop/Profile.js";
import Password from "../pages/desktop/Password.js";
import People from "../pages/desktop/People.js";
import PersonDetail from "../pages/desktop/PersonDetail.js";
import PeoplePolicies from "../pages/desktop/PeoplePolicies.js";
import PolicyDetails from "../pages/desktop/PolicyDetails.js";
import PolicyClaims from "../pages/desktop/PolicyClaims.js";
import Documents from "../pages/desktop/Documents.js";
import TitlesHistory from "../pages/desktop/TitlesHistory.js";
import PeopleClaims from "../pages/desktop/PeopleClaims.js";
import ClaimDetail from "../pages/desktop/ClaimDetail.js";
import InvitationScreen from "../pages/InvitationScreen.js";
import PersonalDocuments from "../pages/desktop/PersonalDocuments.js";
import PersonalDocumentsSelection from "../pages/PersonalDocumentsSelection.js";
import UploadNewDocument from "../pages/UploadNewDocument.js";
import CameraRecognition from "../pages/desktop/CameraRecognition.js";
import BankReferences from "../pages/desktop/BankReferences.js";
import UploadBankRefs from "../pages/UploadBankRefs.js";
import Language from "../pages/desktop/Language.js";
import Vehicles from "../pages/desktop/Vehicles.js";
import PaymentsStatus from "../pages/desktop/PaymentsStatus.js";
import VehicleDetail from "../pages/desktop/VehicleDetail.js";
import VehiclePolicies from "../pages/desktop/VehiclePolicies.js";
import VehicleClaims from "../pages/desktop/VehicleClaims.js";
import MessagePreferences from "../pages/desktop/MessagePreferences.js";
import PersonalDocumentsList from "../pages/desktop/PersonalDocumentsList.js";
import Contacts from "../pages/Contacts.js";
import Messages from "../pages/Messages.js";
import MessageDetails from "../pages/MessageDetails.js";
import Pin from "../pages/Pin.js";
import ConfirmedPin from "../pages/ConfirmedPin.js";
import PaymentsMethod from "../pages/PaymentsMethod.js";
import PaymentsCheckout from "../pages/PaymentsCheckout.js";
import PaymentsSummary from "../pages/PaymentsSummary.js";
import PaymentsTitleDetail from "../pages/PaymentsTitleDetail.js";
import Signing from "../pages/desktop/Signing.js";
import UploadDocumentToVerify from "../pages/desktop/UploadDocumentToVerify.js";
import OtpVerifyID from "../pages/desktop/OtpVerifyID.js";
import ConfirmOtpVerifyID from "../pages/desktop/ConfirmOtpVerifyID.js";
import Regulations from "../pages/desktop/Regulations.js";
import OpenClaim from "../pages/OpenClaim.js";
import CarClaimForm from "../pages/CarClaimForm.js";
import GenericClaimForm from "../pages/GenericClaimForm.js";
import AttachImagesToClaimForm from "../pages/AttachImagesToClaimForm.js";
import Refunds from "../pages/desktop/Refunds.js";
import ClaimsHistory from "../pages/ClaimsHistory.js";
import TelegramHook from "../pages/TelegramHook.js";
import Support from "../pages/desktop/Support.js";
import TicketDetail from "../pages/desktop/TicketDetail.js";
import ClaimsController from "../controllers/ClaimsController.js";
import PaymentsController from "../controllers/PaymentsController.js";
import ErrorPage from "../pages/desktop/ErrorPage.js";
import ServiceError from "../pages/ServiceError.js";

export default function DesktopRoutes() {
    const layout = 'desktop';
    return (
        <Routes>
            <Route path="/dashboard" element={<DashboardController layout={layout}/>}></Route>
            <Route path="/settings" element={<Settings/>}></Route>
            <Route path="/settings/profile" element={<Profile/>}></Route>
            <Route path="/settings/profile/password" element={<Password/>}></Route>
            <Route path="/settings/people" element={<People layout={layout}/>}></Route>
            <Route path="/settings/people/:personId" element={<PersonDetail/>}></Route>
            <Route path="/settings/people/:personId/policies" element={<PeoplePolicies/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId" element={<PolicyDetails/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/policyClaims"
                   element={<PolicyClaims/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/titlesHistory"
                   element={<TitlesHistory/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/titlesHistory/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/documents"
                   element={<Documents/>}></Route>
            <Route path="/settings/people/:personId/policies/:policyId/documents-to-signed"
                   element={<Documents/>}></Route>
            <Route path="/settings/people/:personId/claims" element={<PeopleClaims/>}></Route>
            <Route path="/settings/people/:personId/claims/:claimId" element={<ClaimDetail/>}></Route>
            <Route path="/settings/people/:personId/claims/:claimId/:policyId"
                   element={<PolicyDetails/>}></Route>
            {/*<Route path="/settings/people/:personId/claims/:claimId/:policyId/policyClaims"
                               element={<PolicyClaims/>}></Route>*/}
            <Route path="/settings/people/:personId/invitation" element={<InvitationScreen/>}></Route>
            <Route path="/settings/people/:personId/invitation/personalDocuments"
                   element={<PersonalDocuments/>}></Route>
            <Route path="/settings/people/:personId/invitation/personalDocuments/:documentGroupId"
                   element={<PersonalDocumentsSelection/>}></Route>
            <Route path="/settings/people/:personId/invitation/personalDocuments/new"
                   element={<UploadNewDocument/>}></Route>
            <Route path="/settings/people/:personId/invitation/personalDocuments/:documentGroupId/identify"
                   element={<CameraRecognition/>}></Route>
            <Route path="/settings/people/:personId/invitation/personalDocuments/identify"
                   element={<CameraRecognition/>}></Route>
            <Route path="/settings/people/:personId/bank-references"
                   element={<BankReferences/>}></Route>
            <Route path="/settings/people/:personId/bank-references/upload"
                   element={<UploadBankRefs/>}></Route>
            <Route path="/settings/people/:personId/bank-references/upload/personalDocuments"
                   element={<PersonalDocuments/>}></Route>
            <Route
                path="/settings/people/:personId/bank-references/upload/personalDocuments/:documentGroupId"
                element={<PersonalDocumentsSelection/>}></Route>
            <Route path="/settings/people/:personId/bank-references/upload/personalDocuments/new"
                   element={<UploadNewDocument/>}></Route>
            <Route
                path="/settings/people/:personId/bank-references/upload/personalDocuments/:documentGroupId/identify"
                element={<CameraRecognition/>}></Route>
            {/*dalla policyClaims ritorno al claimDetail /settings/people/:personId/claims/:claimId */}
            <Route path="/settings/language" element={<Language layout={layout}/>}></Route>
            <Route path="/settings/vehicles" element={<Vehicles layout={layout}/>}></Route>
            <Route path="/settings/payments" element={<PaymentsStatus layout={layout}/>}></Route>
            <Route path="/settings/vehicles/:vehicleId" element={<VehicleDetail/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies" element={<VehiclePolicies/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId"
                   element={<PolicyDetails/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/policyClaims"
                   element={<PolicyClaims/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/titlesHistory"
                   element={<TitlesHistory/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/titlesHistory/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/documents"
                   element={<Documents/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/policies/:policyId/documents-to-signed"
                   element={<Documents/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/claims" element={<VehicleClaims/>}></Route>
            <Route path="/settings/vehicles/:vehicleId/claims/:claimId" element={<ClaimDetail/>}></Route>
            <Route path="/settings/message-preferences" element={<MessagePreferences layout={layout}/>}></Route>
            <Route path="/settings/personal-documents" element={<PersonalDocuments layout={layout}/>}></Route>
            <Route path="/settings/personal-documents/:documentGroupId"
                   element={<PersonalDocumentsList/>}></Route>
            <Route path="/settings/contacts"
                   element={<Contacts/>}></Route>
            <Route path="/messages" element={<Messages/>}></Route>
            <Route path="/messages/:type/:id" element={<MessageDetails/>}></Route>
            <Route path="/claims" element={<ClaimsController/>}></Route>
            <Route path="/claims/claim/:claimId" element={<ClaimDetail/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId" element={<PolicyDetails/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId/:policyTitleId" element={<Documents/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId/titlesHistory" element={<TitlesHistory/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId/titlesHistory/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId/documents" element={<Documents/>}></Route>
            <Route path="/claims/claim/:claimId/:policyId/policyClaims" element={<PolicyClaims/>}></Route>
            <Route path="/dashboard/policies/:policyId" element={<PolicyDetails/>}></Route>
            <Route path="/dashboard/policies/:policyId/:policyTitleId" element={<Documents/>}></Route>
            <Route path="/dashboard/policies/:policyId/titlesHistory" element={<TitlesHistory/>}></Route>
            <Route path="/dashboard/policies/:policyId/titlesHistory/:policyTitleId"
                   element={<Documents/>}></Route>
            <Route path="/dashboard/policies/:policyId/documents" element={<Documents/>}></Route>
            <Route path="/dashboard/policies/:policyId/documents-to-signed" element={<Documents/>}></Route>
            <Route path="/dashboard/policies/:policyId/policyClaims" element={<PolicyClaims/>}></Route>
            <Route path="/contacts" element={<Contacts/>}></Route>
            <Route path="/settings/profile/code" element={<Pin/>}></Route>
            <Route path="/settings/profile/code/confirm" element={<ConfirmedPin/>}></Route>
            <Route path="/payments" element={<PaymentsController/>}></Route>
            <Route path="/payments/payment-method" element={<PaymentsMethod/>}></Route>
            <Route path="/payments/payment-method/checkout" element={<PaymentsCheckout/>}></Route>
            <Route path="/payments/:notificationId/summary" element={<PaymentsSummary/>}></Route>
            <Route path="/payments/:notificationId/summary/:titleId"
                   element={<PaymentsTitleDetail/>}></Route>
            <Route path="/payments/:notificationId/summary/payment-method"
                   element={<PaymentsMethod/>}></Route>
            <Route path="/payments/:notificationId/summary/payment-method/checkout"
                   element={<PaymentsCheckout/>}></Route>
            <Route path="/signing" element={<Signing layout={layout}/>}></Route>
            <Route path="/signing/:personId/upload" element={<UploadDocumentToVerify/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments"
                   element={<PersonalDocuments/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId"
                   element={<PersonalDocumentsSelection/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments/new"
                   element={<UploadNewDocument/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId/identify"
                   element={<CameraRecognition/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId/identify/otp"
                   element={<OtpVerifyID/>}></Route>
            <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId/identify/otp/confirm"
                   element={<ConfirmOtpVerifyID/>}></Route>
            <Route path="/signing/:personId/upload/identify" element={<CameraRecognition/>}></Route>
            <Route path="/signing/:personId/upload/identify/otp" element={<OtpVerifyID/>}></Route>
            <Route path="/signing/:personId/upload/identify/otp/confirm"
                   element={<ConfirmOtpVerifyID/>}></Route>
            <Route path="/signing/:personId/uploadBack" element={<UploadDocumentToVerify/>}></Route>
            <Route path="/signing/:personId/uploadBack/uploadFront"
                   element={<UploadDocumentToVerify/>}></Route>
            <Route path="/signing/:personId/uploadBack/uploadFront/identify"
                   element={<CameraRecognition/>}></Route>
            <Route path="/signing/:personId/uploadBack/uploadFront/identify/otp"
                   element={<OtpVerifyID/>}></Route>
            <Route path="/signing/:personId/uploadBack/uploadFront/identify/otp/confirm"
                   element={<ConfirmOtpVerifyID/>}></Route>
            <Route path="/regulations" element={<Regulations layout={layout}/>}></Route>
            <Route path="/claims/open"
                   element={<OpenClaim layout={layout}/>}></Route>
            <Route path="/claims/open/carClaim"
                   element={<CarClaimForm/>}></Route>
            <Route path="/claims/open/carClaim/:claimId"
                   element={<CarClaimForm/>}></Route>
            <Route path="/claims/open/genericClaim"
                   element={<GenericClaimForm/>}></Route>
            <Route path="/claims/open/genericClaim/:claimId"
                   element={<GenericClaimForm/>}></Route>
            <Route path="/claims/open/carClaim/:claimId/attachImages"
                   element={<AttachImagesToClaimForm/>}></Route>
            <Route path="/claims/open/carClaim/:claimId/attachAccidentReportImages"
                   element={<AttachImagesToClaimForm/>}></Route>
            <Route path="/claims/open/genericClaim/:claimId/attachImages"
                   element={<AttachImagesToClaimForm/>}></Route>
            <Route path="/refunds" element={<Refunds layout={layout}/>}></Route>
            <Route path="/refunds/:personId/upload" element={<UploadBankRefs/>}></Route>
            <Route path="/refunds/:personId/upload/personalDocuments"
                   element={<PersonalDocuments/>}></Route>
            <Route path="/refunds/:personId/upload/personalDocuments/:documentGroupId"
                   element={<PersonalDocumentsSelection/>}></Route>
            <Route path="/refunds/:personId/upload/personalDocuments/new"
                   element={<UploadNewDocument/>}></Route>
            <Route path="/refunds/:personId/upload/personalDocuments/:documentGroupId/identify"
                   element={<CameraRecognition/>}></Route>
            <Route path="/settings/support"
                   element={<Support layout={layout}/>}></Route>
            <Route path="/settings/support/:ticketId"
                   element={<TicketDetail layout={layout}/>}></Route>
            <Route path="/claims/history"
                   element={<ClaimsHistory/>}></Route>
            <Route path="/telegram-hook"
                   element={<TelegramHook/>}></Route>
            <Route path="/error-page" element={<ErrorPage/>}></Route>
            <Route path="/service-error" element={<ServiceError/>}></Route>
            <Route path="/" element={<Navigate to="/dashboard" replace/>}/>
            <Route path="/*" element={<Navigate to="/dashboard" replace/>}/>
        </Routes>
    )
}
