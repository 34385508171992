import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import AuthContextMiddleware from "../../middleware/AuthContextMiddleware.js";
import Moment from "react-moment";
import back from "../../../images/back-icon.svg";
import Divider from "@mui/material/Divider";
import {makeStyles} from '@mui/styles';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button, Slide} from "@mui/material";
import useAnalyticsEventTracker from "../../middleware/useAnalyticsEventTracker.js";
import {useTranslation} from "react-i18next";
import FsLightbox from "fslightbox-react";
import {StyledBackdrop} from "../../styles/Styles.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";
import {
    ExternalPageLayout,
    StyledPageCardColumn,
    StyledPageLayout,
    StyledTitlePage
} from "../../styles/DesktopStyles.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    titles: {
        color: '#C7CDD9 !important',
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '400 !important',
        fontSize: '12px !important',
        textTransform: 'uppercase !important',
    },
    dialogTitle: {
        textAlign: 'center !important',
        paddingTop: '10px !important',
        paddingBottom: '2px !important',

    },
    dialogDescription: {
        textAlign: 'center !important',
        paddingTop: '0px !important',
        paddingBottom: '15px !important',
    }
}));

export default function TicketDetail({layout}) {
    const root = 'ticketDetail';
    const [isLoadingData, setIsLoadingData] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getTicket(ticketId);
            getAccountExec();
        }
    }, []);

    const {open} = useContext(SidebarContext);
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const params = useParams();
    const ticketId = params.ticketId;
    const baseTicketUrl = `${process.env.REACT_APP_BASE_URL}/profile/support`;
    const authCtx = useContext(AuthContextMiddleware);
    const [ticket, setTicket] = useState([]);
    const [policy, setPolicy] = useState([]);
    const [comments, setComments] = useState([]);
    const [attachments, setAttachments] = useState([])
    const [attachmentsUrl, setAttachmentsUrl] = useState([])
    const classes = useStyles();
    const accountExecUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/accountExec`;
    const [accountExec, setAccountExec] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const gaEventTrackerPolicyDetail = useAnalyticsEventTracker('PolicyDetail');
    const statusMap = {
        R: {color: '#00C288', text: 'Risolto'},
        A: {color: '#0071B9', text: 'Aperto'},
        C: {color: '#8998AC', text: 'Chiuso'},
        P: {color: '#3FB6E8', text: 'Preso in carico'},
    };
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    const getTicket = (id) => {
        setIsLoadingData(true);
        fetch(`${baseTicketUrl}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setTicket(data);
            setAttachments(data.attachments)
            data.attachments.map(attachment => {
                setAttachmentsUrl(current => [...current, attachment.url])
            })
            setIsLoadingData(false);
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const turnBack = () => {
        return navigate(-1);
    }

    const onClickPolicynr = (policyId) => {
        gaEventTrackerPolicyDetail('Click', 'Clicked Policy Detail Button')
        if (window.location.href.includes('/claims')) {
            return `/claims/claim/${ticketId}/${policyId}`;
        }
    }

    function getAccountExec() {
        fetch(accountExecUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setAccountExec(data);
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    function reload() {
        window.location.reload();
    }

    function openLightboxOnSlide(index) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index
        });
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCardColumn>
                {
                    errorPage ?
                        <ErrorPage/> :
                        <Fragment>
                            <StyledPageLayout className="mx-4">
                                <StyledTitlePage>

                                    <Col className="col-2 float-left">
                                        <img src={back} onClick={turnBack}
                                             className="nav-top-logo float-left cursor-pointer"
                                             height="27px"
                                             alt="back"/>
                                    </Col>
                                    <Col className="col-8">
                                        <span className="header-title ellipsis-1-v2">{t(`${root}.title`)}</span>
                                    </Col>

                                </StyledTitlePage>
                                <Row className={'pt-2 pb-4'}>
                                    <Col>
                                        {
                                            isLoadingData &&
                                            <div style={{'height': '90vh'}}
                                                 className="background-loading-spinner"></div>
                                        }
                                        {
                                            !isLoadingData &&
                                            <Fragment>
                                                <Row>
                                                    <Col>
                                                        <h6 className="text-normal-detail ellipsis-1-v2">{t('claimDetail.status')}
                                                            <span className={'text-bold-detail'}
                                                                  style={{color: statusMap[ticket.status]?.color}}>
                                                        {statusMap[ticket.status]?.text || ticket.status}
                                                      </span>
                                                        </h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h6 className="text-normal-detail ellipsis-1-v2">{t('claimDetail.policy-holder')}<span
                                                            className="text-bold-detail black">{ticket.name}</span></h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h6 className="text-normal-detail ellipsis-1">{t('claimDetail.ticket-name-operator')}<span
                                                            className="text-bold-detail black">{ticket.nameOperator || "-"}</span>
                                                        </h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h6 className="text-normal-detail">{t('claimDetail.open-date')}<Moment
                                                            locale="it"
                                                            format="DD/MM/YYYY"
                                                            className="text-bold-detail black">{ticket.createdAt}</Moment>
                                                        </h6>
                                                    </Col>
                                                </Row>
                                                {
                                                    ticket.settledValueCents > 0 &&
                                                    <Row>
                                                        <Col>
                                                            <h6 className="text-normal-detail ellipsis-1">{t('claims.settled-value')}
                                                                <span
                                                                    className="text-bold-detail black">{ticket.settledValue}</span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Divider className={'mt-2'}/>
                                                <Row className={'mt-3'}>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <h6 className="text-normal-detail ellipsis-1-v2">{t('claimDetail.title-ticket-detail')}</h6>
                                                                <h6 className="text-bold-detail black">
                                                                    {ticket.title}
                                                                </h6>

                                                            </Col>
                                                        </Row>
                                                        <Row className={'mt-2'}>
                                                            <Col className={'justify-content-start'}>
                                                                <h6 className="text-normal-detail ellipsis-1">{t('claimDetail.description-ticket-detail')}</h6>
                                                                <h6 className="text-normal-detail black">
                                                                    {ticket.description}
                                                                </h6>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={'mt-3'}>
                                                    <Col className="col" style={{'marginTop': '-14px'}}>
                                                    <span className="float-right">
                                                        <a className="footer-link" style={{'color': '#3FB6E8'}}
                                                           onClick={handleClickOpen}>{t('claimDetail.need-help')}</a>
                                                    </span>
                                                    </Col>
                                                </Row>
                                                {attachments && attachments.length > 0 && (
                                                    <Fragment>
                                                        <Row className={'mt-3'}>
                                                            <Col className="col-6 float-left">
                                                                <h6 className={classes.titles}>{t(`${root}.attachments`)}
                                                                </h6>
                                                            </Col>
                                                        </Row>
                                                        <Divider/>
                                                        <Row className={'mt-2'}>
                                                            <Col className={'d-flex justify-content-start'}
                                                                 style={{gap: 10}}>
                                                                {attachments.map((attachment, index) => (
                                                                    <div key={index} className={'square-container'}>
                                                                        <img alt="thumb-img" className={'image-grid'}
                                                                             onClick={() => {
                                                                                 openLightboxOnSlide(index);
                                                                             }}
                                                                             src={attachment.url}/>
                                                                    </div>
                                                                ))}
                                                                <FsLightbox
                                                                    toggler={lightboxController.toggler}
                                                                    sources={attachmentsUrl}
                                                                    type="image"
                                                                    sourceIndex={lightboxController.slide}
                                                                    key={0}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        }
                                    </Col>
                                </Row>
                            </StyledPageLayout>
                            <Dialog
                                components={{Backdrop: StyledBackdrop}}
                                open={openDialog}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                                PaperProps={{
                                    style: {
                                        'position': 'absolute',
                                        'bottom': '0',
                                        'left': 'auto',
                                        'right': 'auto',
                                        'width': '95%',
                                        'borderRadius': '10px',
                                        'color': '#545E75'
                                    },
                                }}>
                                <DialogTitle id="alert-dialog-slide-title" className={classes.dialogTitle}>
                    <span style={{'fontSize': '15px',}}>
                        {t('claimDetail.modal-title')}
                    </span>
                                </DialogTitle>
                                <DialogContent className={classes.dialogDescription}>
                    <span style={{
                        'fontSize': '20px',
                        'fontWeight': "bold",
                    }}>
                        {accountExec.firstName} {accountExec.lastName}
                    </span>
                                </DialogContent>
                                <Divider/>
                                {
                                    accountExec.phone !== null &&
                                    <Fragment>
                                        <DialogActions>
                                            <a href={`tel:${accountExec.phone}`}
                                               style={{
                                                   'textDecoration': 'none',
                                                   'width': '100%',
                                                   'margin': '0 auto'
                                               }}>
                                                <Button color="primary" fullWidth
                                                        style={{
                                                            'textTransform': 'none',
                                                            'color': '#00C288'
                                                        }}>
                                                    {t('claimDetail.modal-tel-button')}
                                                </Button>
                                            </a>
                                        </DialogActions>
                                        <Divider/>
                                    </Fragment>
                                }
                                <DialogActions>
                                    <a href={`mailto:${accountExec.email}'`}
                                       style={{
                                           'textDecoration': 'none',
                                           'width': '100%',
                                           'margin': '0 auto'
                                       }}>
                                        <Button color="primary" style={{'textTransform': 'none'}}
                                                fullWidth>
                                            {t('claimDetail.modal-email-button')}
                                        </Button>
                                    </a>
                                </DialogActions>
                                <Divider/>
                                <DialogActions>
                                    <Button onClick={handleClose} style={{
                                        'color': '#8998AC',
                                        'textTransform': 'none'
                                    }} fullWidth>
                                        {t('claimDetail.modal-refuse-button')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Fragment>
                }
            </StyledPageCardColumn>
        </ExternalPageLayout>
    )
}
