import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {fetchToken} from "../middleware/Firebase.js";
import FileSaver from "file-saver";
import {
    b64toFile,
    base64url_decode,
    base64url_encode,
    deleteElementFromIndexedDB,
    getElementFromIndexedDB,
    isIpad,
    isMac,
    isStandalone,
    putElementFromIndexedDB,
} from "../service/UtilityService.js";
import {ClientJS} from 'clientjs';
import useAnalyticsEventTracker from "../middleware/useAnalyticsEventTracker.js";
import {useMediaQuery} from 'react-responsive'
import {publish} from "../service/EventService.js";
import {useTranslation} from "react-i18next";
import DashboardMobile from "../pages/mobile/Dashboard.js";
import DashboardDesktop from "../pages/desktop/Dashboard.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import {
    formattedPolicy,
    getBranchIcon, getBranchIconSelected,
    getPeoplePoliciesV2,
    getPolicyTypes,
    POLICY_TYPE
} from "../service/DashboardService.js";
import {NavbarContext} from "../middleware/NavbarContext.js";

export default function DashboardController({layout}) {
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const exportFileUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/export/policies`;
    const checkReadUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/checkRead`;
    const personalPoliciesUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/personal`;
    const peoplePoliciesUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/people`;
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const webAuthNRegistrationUrl = `${process.env.REACT_APP_BASE_URL}/management/webauthn/registration`;
    const searchPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/search`;
    const getPeopleVehiclesUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/peopleVehicles`;
    const getWhitelistPoliciesUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/whitelistPolicies`;
    const updateUserVersionUrl = `${process.env.REACT_APP_BASE_URL}/management/operation/updateUserVersion`;
    const token = sessionStorage.getItem('token');
    const [isLoadingPersonalData, setIsLoadingPersonalData] = useState(false);
    const [isLoadingPeopleData, setIsLoadingPeopleData] = useState(false);
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [people, setPeople] = useState([]);
    const gaEventTrackerPolicyDetail = useAnalyticsEventTracker('PolicyDetail')
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [noShow, setNoShow] = useState(false);
    const {t, i18n} = useTranslation();
    const width_1199 = useMediaQuery({
        query: '(min-width: 1199px)'
    });
    const width_991 = useMediaQuery({
        query: '(min-width: 991px)'
    });
    const width_767 = useMediaQuery({
        query: '(min-width: 766px)'
    });
    const width_min = useMediaQuery({
        query: '(min-width: 0px)'
    });
    const [checked, setChecked] = useState([]);
    const [exportType, setExportType] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(true);
    const [policyTypes, setPolicyTypes] = useState([{value: 0, label: '', icon: ''}]);
    const [isLoadingPolicyTypes, setIsLoadingPolicyTypes] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const {toggleNavbar} = useContext(NavbarContext);


    const handlePolicyTypes = async (token_arg, personId?) => {
        setIsLoadingPolicyTypes(true);
        const response = await getPolicyTypes(token_arg, personId);
        setIsLoadingPolicyTypes(false);
        return response;
    };

    const handlePeoplePoliciesV2 = async (type, token_arg, page, size, filter?, personId?, branchId?) => {
        if (type === POLICY_TYPE.FEATURED) {
            return await getPeoplePoliciesV2(token_arg, page, size, filter, personId, branchId);
        } else if (type === POLICY_TYPE.OTHER) {
            return await getPeoplePoliciesV2(token_arg, page, size, filter, personId, branchId);
        } else if (type === POLICY_TYPE.INACTIVE) {
            return await getPeoplePoliciesV2(token_arg, page, size, filter, personId, branchId);
        }
        return null;
    };

    useEffect(() => {
        if (layout === 'mobile') {
            toggleNavbar(true);
        }
        sessionStorage.setItem('root', '/dashboard');
        if (navigator.onLine) {
            if (sessionStorage.getItem("notificationId") != null) {
                const url = sessionStorage.getItem("notificationId").replaceAll('"', '')
                navigateToMessageDetails(url);
            }
            getInfo();
            handleRinging();
            if (!searchMode) {
                getPeople();
                getWhitelistPolicies();
            }
            updateUserVersion();
        } else {
            getInfoOffline();
            getPeopleOffline();
            getPersonalPoliciesOffline();
            getPeoplePoliciesOffline();
        }
        if (!localStorage.getItem('firstLogin') && localStorage.getItem('biometrics')) {
            getVehicles();
            setOpenDialog(true);
        }
        localStorage.setItem('firstLogin', true);
        if (searchMode) {
            const delayDebounceFn = setTimeout(() => {
                searchPeople(searchValue)
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchValue]);

    const [user, setUser] = useState("");
    const client = new ClientJS();
    const [openDialog, setOpenDialog] = useState(false);
    const [bellRinging, setBellRinging] = useState(false);
    const [personalPolicies, setPersonalPolicies] = useState([]);
    const [peoplePolicies, setPeoplePolicies] = useState([]);
    const selectedAvatar = useRef("null");
    const [searchMode, setSearchMode] = useState(false);
    const [timeoutAvatar, setTimeoutAvatar] = useState(false);
    const [noPeople, setNoPeople] = useState(false);
    const [getPermission, setGetPermission] = useState(false);
    const [isNothingPersonal, setIsNothingPersonal] = useState(false);
    const [isNothingPeople, setIsNothingPeople] = useState(false);
    const filter = useRef('STATUS_DEFAULT');
    const [openMenu, setOpenMenu] = useState(null);
    const [openMenuExport, setOpenMenuExport] = useState(null);
    const [selectedRadioCsv, setSelectedRadioCsv] = useState('');
    const [selectedRadioXlsx, setSelectedRadioXlsx] = useState('');
    const [selectedRadioPdf, setSelectedRadioPdf] = useState('');
    const [serviceError, setServiceError] = useState(false);
    const [acceptedSigningCompanies, setAcceptedSigningCompanies] = useState([])

    //DESKTOP VARS
    const [featuredPeoplePolicies, setFeaturedPeoplePolicies] = useState([]);
    const [isLoadingFeaturedPolicies, setIsLoadingFeaturedPolicies] = useState(0);
    const [otherPeoplePolicies, setOtherPeoplePolicies] = useState([]);
    const [isLoadingOtherPolicies, setIsLoadingOtherPolicies] = useState(0);
    const [inactivePeoplePolicies, setInactivePeoplePolicies] = useState([]);
    const [isLoadingInactivePolicies, setIsLoadingInactivePolicies] = useState(0);
    const [selectedChipState, setSelectedChipState] = useState(null);
    const [selectedAvatarState, setSelectedAvatarState] = useState(0);
    const [selectedPolicyStatus, setSelectedPolicyStatus] = useState(0);

    useEffect(() => {
        setSelectedChipState(null)
        const delayDebounceFn = setTimeout(() => {
            handlePolicyTypes(token, selectedAvatarState).then((data) => {
                if (data) {
                    data.map((item, index) => {
                        if (index === 0) {
                            setPolicyTypes([{value: item.id, label: item.name, icon: getBranchIcon(item.name), iconSelected: getBranchIconSelected(item.name)}])
                        } else {
                            setPolicyTypes(current => [...current, {
                                value: item.id,
                                label: item.name,
                                icon: getBranchIcon(item.name),
                                iconSelected: getBranchIconSelected(item.name)
                            }])
                        }
                    })
                }
            })
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [selectedAvatarState, selectedPolicyStatus]);


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsLoadingFeaturedPolicies(1);
            setIsLoadingOtherPolicies(1);
            setIsLoadingInactivePolicies(1);
            handlePeoplePoliciesV2(POLICY_TYPE.FEATURED, token, 0, 1, 'HIGHLIGHTED', selectedAvatarState, selectedChipState).then((data) => {
                const totalElements = data.totalElements;
                handlePeoplePoliciesV2(POLICY_TYPE.FEATURED, token, 0, totalElements, 'HIGHLIGHTED', selectedAvatarState, selectedChipState).then((data) => {
                    const feat_array = [];
                    if (data) {
                        data.content.map((item) => {
                            feat_array.push(formattedPolicy(item, true));
                        })
                        setFeaturedPeoplePolicies(feat_array)
                    }
                    setIsLoadingFeaturedPolicies(2);
                })
            })
            handlePeoplePoliciesV2(POLICY_TYPE.INACTIVE, token, 0, 1, 'STATUS_INACTIVE', selectedAvatarState, selectedChipState).then((data) => {
                const totalElements = data.totalElements;
                handlePeoplePoliciesV2(POLICY_TYPE.INACTIVE, token, 0, totalElements, 'STATUS_INACTIVE', selectedAvatarState, selectedChipState).then((data) => {
                    const feat_array = [];
                    if (data) {
                        data.content.map((item) => {
                            feat_array.push(formattedPolicy(item, true));
                        })
                        setInactivePeoplePolicies(feat_array)
                    }
                    setIsLoadingInactivePolicies(2);
                })
            })
            handlePeoplePoliciesV2(POLICY_TYPE.OTHER, token, 0, 1, 'STATUS_DEFAULT', selectedAvatarState, selectedChipState).then((data) => {
                const totalElements = data.totalElements;
                handlePeoplePoliciesV2(POLICY_TYPE.OTHER, token, 0, totalElements, 'STATUS_DEFAULT', selectedAvatarState, selectedChipState).then((data) => {
                    const other_array = [];
                    if (data) {
                        data.content.map((item) => {
                            other_array.push(formattedPolicy(item, true));
                        })
                        setOtherPeoplePolicies(other_array)
                    }
                    setIsLoadingOtherPolicies(2);
                })
            })
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [selectedAvatarState, selectedChipState]);


    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || ((isMac() && client.getOSVersion() < "13")) || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
    } else if (!getPermission) {
        requestPermission();
        setGetPermission(true);
    }
    fetchToken();

    function requestPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
            }
        })
    }

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || (isMac() && client.getOSVersion() < "13") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
    } else {
        const channel = new BroadcastChannel('sw-messages');
        channel.onmessage = (event) => {
            setBellRinging(true)
        };
        channel.onmessageerror = (event) => {
        };
    }

    const navigateToMessageDetails = (url) => {
        return navigate(`/messages/principal/${url}`);
    }

    const handleCheck = (event) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, parseInt(event.target.value)];
        } else {
            updatedList.splice(checked.indexOf(parseInt(event.target.value)), 1);
            setIsCheckAll(false)
        }
        setChecked(updatedList);
    };

    const checkAll = () => {
        setIsCheckAll(!isCheckAll);
        setChecked(people.map(li => li.id));
        if (isCheckAll) {
            setChecked([]);
        }
    };

    const handleBackCheck = () => {
        setExportType(false);
        setSelectedRadioCsv('')
        setSelectedRadioXlsx('')
        setSelectedRadioPdf('')
        setChecked([]);
        setIsCheckAll(false);
    };

    function handleRadioCsv(event) {
        setSelectedRadioCsv(event.target.value)
        setSelectedRadioXlsx('')
        setSelectedRadioPdf('')
    }

    function handleRadioXlsx(event) {
        setSelectedRadioXlsx(event.target.value)
        setSelectedRadioCsv('')
        setSelectedRadioPdf('')
    }

    function handleRadioPdf(event) {
        setSelectedRadioPdf(event.target.value)
        setSelectedRadioCsv('')
        setSelectedRadioXlsx('')

    }

    function exportFile() {
        setIsLoading(true);
        let format = '';
        if (selectedRadioCsv !== '') {
            format = 'csv';
        }
        if (selectedRadioXlsx !== '') {
            format = 'xlsx';
        }
        if (selectedRadioPdf !== '') {
            format = 'pdf';
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                personIds: checked,
                policyFilter: filter.current,
                format: format
            })
        };
        fetch(exportFileUrl, requestOptions)
            .then((response) => {
                return response.json();
            }).then((data) => {
            if (data.filename.includes('.pdf')) {
                FileSaver.saveAs(b64toFile(data.base64, data.filename, "application/pdf"), data.filename);
                setIsLoading(false);
            }
            if (data.filename.includes('.csv')) {
                FileSaver.saveAs(b64toFile(data.base64, data.filename, "text/csv;charset=utf-8"), data.filename);
                setIsLoading(false);
            }
            if (data.filename.includes('.xlsx')) {
                FileSaver.saveAs(b64toFile(data.base64, data.filename, "application/octet-stream"), data.filename);
                setIsLoading(false);
            }
        }).catch(() => {
            setServiceError(true)
        });
    }


    function getInfo() {
        fetch(userInfoUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            i18n.changeLanguage(data.preferredLanguage).then(r => {
            })
            if (data.status !== -1) {
                setUser(data)
                localStorage.setItem('wideAPP_userId', data.id)
                putElementFromIndexedDB('profileInfo', data)
                if (!!data.pinCode) {
                    putElementFromIndexedDB('pin', data.pinCode)
                    localStorage.setItem('pinIsActive', true)
                } else {
                    localStorage.removeItem('pinIsActive')
                    deleteElementFromIndexedDB('pin')
                }
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    function getInfoOffline() {
        getElementFromIndexedDB('profileInfo').then((response) => {
            return response.value;
        }).then((data) => {
            setUser([])
            setUser(data)
        });
    }

    function getPeople() {
        setIsLoadingPeople(true)
        fetch(getPeopleUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeople([])
            data.map((item) => {
                if (item.status !== -1) {
                    count++;
                    if (!personalPolicies.includes(item)) {
                        setPeople(current => [...current, item])
                        setChecked(current => [...current, item.id])
                    }
                }
            })
            if (count === 0) {
                sessionStorage.setItem('noPeople', true);
                setNoPeople(true);
            } else {
                sessionStorage.removeItem('noPeople');
                setNoPeople(false);
            }
            putElementFromIndexedDB('people', data)
            setIsLoadingPeople(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPeopleOffline() {
        getElementFromIndexedDB('people').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                sessionStorage.setItem('noPeople', true);
            } else {
                sessionStorage.removeItem('noPeople');
            }
            setPeople([])
            for (let i = 0; i < length; i++) {
                if (!personalPolicies.includes(data[i])) {
                    setPeople(current => [...current, data[i]])
                }
            }
        });
    }

    function getPersonalPolicies() {
        let url;
        if (filter.current !== null) {
            url = `${personalPoliciesUrl}?filter=${filter.current}`;
        } else {
            url = `${personalPoliciesUrl}`;
        }
        setIsLoadingPersonalData(true)
        fetch(url, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPersonalPolicies([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!personalPolicies.includes(item)) {
                        setPersonalPolicies(current => [...current, item])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPersonal(true)
            } else {
                setIsNothingPersonal(false)
            }
            setIsLoadingPersonalData(false)
            putElementFromIndexedDB('personalPolicies', data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPersonalPoliciesOffline() {
        setIsLoadingPersonalData(true)
        getElementFromIndexedDB('personalPolicies').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                setIsNothingPersonal(true)
            } else {
                setIsNothingPersonal(false)
            }
            setPersonalPolicies([])
            for (let i = 0; i < length; i++) {
                if (!personalPolicies.includes(data[i])) {
                    setPersonalPolicies(current => [...current, data[i]])
                }
            }
            setIsLoadingPeopleData(false)
        });
    }

    function getPeoplePolicies() {
        let url;
        if (filter.current !== null) {
            url = `${peoplePoliciesUrl}?filter=${filter.current}`;
        } else {
            url = `${peoplePoliciesUrl}`;
        }
        setIsLoadingPeopleData(true)
        fetch(url, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeoplePolicies([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!peoplePolicies.includes(item)) {
                        setPeoplePolicies(current => [...current, formattedPolicy(item)])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPeople(true)
            } else {
                setIsNothingPeople(false)
            }
            setIsLoadingPeopleData(false)
            putElementFromIndexedDB('peoplePolicies', data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPeoplePoliciesOffline() {
        setIsLoadingPeopleData(true)
        getElementFromIndexedDB('peoplePolicies').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            setPeoplePolicies([])
            for (let i = 0; i < length; i++) {
                if (!peoplePolicies.includes(data[i])) {
                    setPeoplePolicies(current => [...current, formattedPolicy(data[i])])
                }
            }
            setIsLoadingPeopleData(false);
        });
    }

    function getPersonPolicies(id) {
        let url;
        if (filter.current !== null) {
            url = `${peoplePoliciesUrl}?personId=${id}&filter=${filter.current}`;
        } else {
            url = `${peoplePoliciesUrl}?personId=${id}`;
        }
        setIsLoadingPeopleData(true)
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeoplePolicies([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!peoplePolicies.includes(item)) {
                        setPeoplePolicies(current => [...current, formattedPolicy(item)])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPeople(true)
            } else {
                setIsNothingPeople(false)
            }
            setIsLoadingPeopleData(false)
            putElementFromIndexedDB('peoplePolicies', data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function registerCredentials() {
        let username = localStorage.getItem('username-wide');
        fetch(`${webAuthNRegistrationUrl}/start`, {
            method: 'POST',
            body: JSON.stringify({
                username: username,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            if (!response.ok) {
                return;
            }
            response.json().then(
                (registrationResponse) => {
                    let keyData = registrationResponse.publicKeyCredentialCreationOptions;
                    let excludeCredentials = keyData.excludeCredentials
                        .map(c => {
                            c.id = base64url_decode(c.id);
                            c.transports = undefined;
                            return c;
                        });
                    let cred = navigator.credentials.create({
                        publicKey: {
                            rp: keyData.rp,
                            user: {
                                id: base64url_decode(keyData.user.id),
                                name: keyData.user.name,
                                displayName: keyData.user.displayName
                            },
                            challenge: base64url_decode(keyData.challenge),
                            pubKeyCredParams: keyData.pubKeyCredParams,
                            excludeCredentials: excludeCredentials,
                            timeout: 60000,
                            authenticatorSelection: {
                                userVerification: 'required',
                                authenticatorAttachment: 'platform',
                            },
                        },
                    }).catch((e) => {
                        if (e.name === 'InvalidStateError') {
                            localStorage.setItem('webauthn', true);
                        }
                    });
                    cred.then((credData) => {
                        if (!credData) {
                            return;
                        }
                        let finishRequest = {
                            credential: {
                                clientExtensionResults: {},
                                id: credData.id,
                                rawId: base64url_encode(credData.rawId),
                                response: {
                                    attestationObject: base64url_encode(credData.response.attestationObject),
                                    clientDataJSON: base64url_encode(credData.response.clientDataJSON)
                                },
                                type: "public-key"
                            },
                            registrationId: registrationResponse.registrationId,
                            userAgent: navigator.userAgent
                        };
                        fetch(`${webAuthNRegistrationUrl}/finish`, {
                            method: 'POST',
                            body: JSON.stringify(finishRequest),
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        }).then((finishResponse) => {
                            finishResponse.json().then((r) => console.log("finish registration:", r));
                            localStorage.setItem('webauthn', credData.id);
                        })
                    }).catch(() => {
                        setServiceError(true)
                    });
                }
            );
        });
    }

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleBiometrics = () => {
        setOpenDialog(false);
        registerCredentials();
    };

    const handleRinging = () => {
        fetch(checkReadUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.toRead) {
                setBellRinging(true);
            } else {
                setBellRinging(false);
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    const handleClickPersonal = () => {
        setTimeoutAvatar(true);
        setPeoplePolicies([]);
        if (selectedAvatar.current === 'personal') {
            selectedAvatar.current = "null";
            getWhitelistPolicies()
        } else {
            selectedAvatar.current = 'personal';
        }
        setTimeout(function () {
            setTimeoutAvatar(false);
        }, 1000);
    }

    const handleClickPerson = (id) => {
        setTimeoutAvatar(true);
        setPersonalPolicies([]);
        setPeoplePolicies([]);
        if (selectedAvatar.current === id) {
            selectedAvatar.current = "null";
            getWhitelistPolicies();
            setChecked(people.map(li => li.id));
            setIsCheckAll(true)
        } else {
            selectedAvatar.current = id;
            getWhitelistPolicies(id);
            setChecked([])
            setChecked(current => [...current, id])
            setIsCheckAll(false)
        }
        setTimeout(function () {
            setTimeoutAvatar(false);
        }, 1000);
    }

    const handleSearchMode = () => {
        if (searchMode && searchValue !== "") {
            setSearchValue("")
            document.querySelector('#search-input').value = "";
            document.querySelector('#search-input').focus();
        } else {
            setSearchMode(current => !current);
            getPeople();
            getWhitelistPolicies();
        }
    }

    const searchPeople = (text) => {
        setIsLoadingPeople(true)
        fetch(`${searchPeopleUrl}?text=${text}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPeople([]);
            data.map((item, index) => {
                if (item.status !== -1) {
                    setPeople(current => [...current, item])
                }
            })
            setIsLoadingPeople(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const nothingFunction = () => {
    }

    const onClickPolicy = (id) => {
        gaEventTrackerPolicyDetail('Click', 'Clicked Policy Detail Button')
        return navigate(`/dashboard/policies/${id}`);
    }

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setOpenMenu(null);
    }

    const handleOpenMenuExport = (event) => {
        setOpenMenuExport(event.currentTarget);
    }

    const handleCloseMenuExport = () => {
        setOpenMenuExport(null);
    }

    const handleFilter = (selectedFilter) => {
        if (filter.current === selectedFilter) {
            filter.current = 'STATUS_DEFAULT';
        } else {
            filter.current = selectedFilter;
        }
        handleCloseMenu();
        if (selectedAvatar.current === "null") {
            getWhitelistPolicies();
        } else if (selectedAvatar.current === "personal") {
            //
        } else {
            getWhitelistPolicies(selectedAvatar.current);
        }
    }

    const calculateMarginRight = () => {
        switch (filter.current) {
            case 'INSURANCE_END_ASC':
                return `160px`;
            case 'INSURANCE_END_DESC':
                return `160px`;
            case 'STATUS_ACTIVE':
                return `130px`;
            case 'STATUS_EXPIRING':
                return `170px`;
            case 'STATUS_EXPIRED':
                return `150px`;
            case 'STATUS_INACTIVE':
                return `160px`;
        }
    }

    const calculateMinWidth = () => {
        switch (filter.current) {
            case 'INSURANCE_END_ASC':
                return `151px`;
            case 'INSURANCE_END_DESC':
                return `151px`;
            case 'STATUS_ACTIVE':
                return `110px`;
            case 'STATUS_EXPIRING':
                return `151px`;
            case 'STATUS_EXPIRED':
                return `130px`;
            case 'STATUS_INACTIVE':
                return `140px`;
        }
    }

    const getVehicles = () => {
        fetch(getPeopleVehiclesUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                }
            })
            if (count === 0) {
                sessionStorage.setItem('noVehicles', true)
            }
        }).catch(() => {
            setServiceError(true)
        });
    }

    function reload() {
        window.location.reload();
    }

    const handleRenewPolicy = (personId) => {
        publish('navigateToPayments');
        sessionStorage.setItem('root', '/payments');
        return navigate(`/payments`);
    }

    const handleSignDocumentPolicy = (personId) => {
        publish('navigateToSigning');
        sessionStorage.setItem('root', '/signing');
        return navigate(`/signing`);
    }

    const handleRegulatePolicy = () => {
        publish('navigateToRegulations');
        sessionStorage.setItem('root', '/regulations');
        return navigate(`/regulations`);
    }

    const getWhitelistPolicies = (id) => {
        setIsLoadingPeopleData(true)
        fetch(getWhitelistPoliciesUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setAcceptedSigningCompanies(data)
        }).catch(() => {
            setServiceError(true)
        }).then(() => {
            if (id !== undefined) {
                getPersonPolicies(id)
            } else {
                getPeoplePolicies();
            }
        });
    }


    const updateUserVersion = () => {
        fetch(updateUserVersionUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                newVersion: true,
                oldVersion: false
            }),
        }).then((response) => {
            return response.json();
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const stringFilter = (filter) => {
        switch (filter) {
            case 'INSURANCE_END_ASC':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-ascending-date')}`;
            case 'INSURANCE_END_DESC':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-descending-date')}`;
            case 'STATUS_ACTIVE':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-active')}`;
            case 'STATUS_EXPIRING':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-expirity')}`;
            case 'STATUS_EXPIRED':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-expired')}`;
            case 'STATUS_INACTIVE':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-killed')}`;
        }
    }

    const controllerState = {
        peoplePolicies,
        serviceError,
        bellRinging,
        noPeople,
        searchMode,
        isLoadingPeople,
        people,
        selectedAvatar,
        timeoutAvatar,
        filter,
        openMenuExport,
        exportType,
        isCheckAll,
        selectedRadioCsv,
        selectedRadioXlsx,
        selectedRadioPdf,
        isLoading,
        isLoadingPeopleData,
        width_min,
        width_767,
        width_991,
        width_1199,
        isNothingPeople,
        user,
        openMenu,
        checked,
        handleSearchMode,
        handleClickPerson,
        handleOpenMenuExport,
        handleOpenMenu,
        handleCloseMenu,
        handleFilter,
        handleCloseMenuExport,
        handleCheck,
        checkAll,
        handleRadioCsv,
        handleRadioXlsx,
        handleRadioPdf,
        handleBackCheck,
        onClickPolicy,
        handleRenewPolicy,
        handleSignDocumentPolicy,
        handleRegulatePolicy,
        exportFile,
        stringFilter,
        reload,
        setSearchValue,
        setExportType,
        policyTypes,
        isLoadingPolicyTypes,
        featuredPeoplePolicies,
        isLoadingFeaturedPolicies,
        otherPeoplePolicies,
        isLoadingOtherPolicies,
        selectedAvatarState,
        setSelectedAvatarState,
        selectedChipState,
        setSelectedChipState,
        errorPage,
        inactivePeoplePolicies,
        isLoadingInactivePolicies,
        selectedPolicyStatus,
        setSelectedPolicyStatus
    };

    const dialogDoubleAction =
        <DialogDoubleAction open={openDialog}
                            title={t('dashboard.biometrics-modal-title')}
                            description={
                                <span>{t('dashboard.biometrics-modal-description-1')}<br/><br/>{t('dashboard.biometrics-modal-description-2')}</span>}
                            handleClose={handleClose} handleNo={handleClose} handleYes={handleBiometrics}
                            buttonYesText={t('dashboard.biometrics-modal-confirm-button')}
                            buttonNoText={t('dashboard.biometrics-modal-refuse-button')}/>;


    if (isDesktop) {
        return (
            <Fragment>
                <DashboardDesktop {...controllerState}/>
                {dialogDoubleAction}
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <DashboardMobile {...controllerState}/>
                {dialogDoubleAction}
            </Fragment>
        );
    }
}
