import React from 'react';
import themeContext from "../ThemeContext.js";
import {styled} from "@mui/system";
import {useTranslation} from "react-i18next";
import spinner from "../../../images/spinner.svg";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import phoneBroker from "../../../images/desktop/phone-broker.svg";
import emailBroker from "../../../images/desktop/email-broker.svg";

const StyledContainer = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    width: '100%',
    alignItems: 'start',
}));

const BrokerTitle = styled('span')(({theme}) => ({
    fontSize: '10px',
    padding: '8px 0 8px 8px',
    color: themeContext.color.neutral400,
}));

const BrokerName = styled('span')(({theme}) => ({
    fontSize: '14px',
    color: themeContext.color.white,
    fontWeight: '600',
}));

const StyledCard = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '8px',
    borderRadius: '16px',
    width: '100%',
}));

const StyledIconButton = styled(IconButton)(({theme}) => ({
    background: themeContext.color.white,
    width: '24px',
    height: '24px',
    padding: 0,
    margin: 0,
    borderRadius: "100%",
    '&:hover': {
        background: themeContext.color.neutral100,
    }
}));

const BrokerCard = ({isLoading, data, isOpen}) => {
    const {t, i18n} = useTranslation();
    const handlePhone = (phone) => {
        window.location.href = `tel:${phone}`;
    }
    const handleEmail = (data) => {
        window.location.href = `mailto:${data.email}?subject=${t('profile.email-subject')}&body=${t('profile.email-body-1')} ${data.firstName} ${data.lastName},%0D%0A${t('profile.email-body-2')}%0D%0A}`;
    }

    return (
        <StyledContainer>
            {isOpen &&
                <BrokerTitle className={'text-uppercase'}>{t('desktop.sidebar.your Wide Group Broker')}</BrokerTitle>}
            <StyledCard className={'radialGradient'}>
                {isLoading ? <img src={spinner} className="spinner-img" alt="spinner"/> :
                    isOpen ?
                        <div className={'d-flex flex-column'} style={{gap: 8}}>
                            <div className={'d-flex flex-row'} style={{gap: 8}}>
                                <Avatar src={data.photoUrl} alt={data.name} sx={{width: 24, height: 24}}/>
                                <BrokerName> {data.name} </BrokerName>
                            </div>
                            <div className={'d-flex justify-content-end'} style={{gap: 8}}>
                                <StyledIconButton onClick={() => handlePhone(data.phone)}>
                                    <img src={phoneBroker} className="spinner-img" alt="spinner" width={14}
                                         height={14}/>
                                </StyledIconButton>
                                <StyledIconButton onClick={() => handleEmail(data)}>
                                    <img src={emailBroker} className="spinner-img" alt="spinner" width={16}
                                         height={12}/>
                                </StyledIconButton>
                            </div>
                        </div> :
                        <div className={'d-flex flex-column align-items-center'} style={{gap: 8}}>
                            <Avatar src={data.photoUrl} alt={data.name} sx={{width: 24, height: 24}}/>
                            <StyledIconButton onClick={() => handleEmail(data)}>
                                <img src={emailBroker} className="spinner-img" alt="spinner" width={16}
                                     height={12}/>
                            </StyledIconButton>
                        </div>
                }
            </StyledCard>
        </StyledContainer>

    );
};

export default BrokerCard;