import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {fetchToken} from "../middleware/Firebase.js";
import {
    getElementFromIndexedDB,
    isIpad,
    isMac,
    isStandalone,
    putElementFromIndexedDB
} from "../service/UtilityService.js";
import {useMediaQuery} from "react-responsive";
import {useTranslation} from "react-i18next";
import PaymentsMobile from "../pages/mobile/Payments.js";
import PaymentsDesktop from "../pages/desktop/Payments.js";
import {ClientJS} from "clientjs";
import themeContext from "../components/ThemeContext.js";
import {getPayments} from "../service/PaymentsService.js";
import {DetailBarContext} from "../middleware/DetailBarContext.js";

let first = true;

export default function PaymentsController() {
    const checkReadUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/checkRead`;
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const searchPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/search`;
    const operationUrl = `${process.env.REACT_APP_BASE_URL}/management/operation`;


    const token = sessionStorage.getItem('token');
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [isLoadingNotifications, setIsLoadingNotifications] = useState(0);
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [searchValue, setSearchValue] = useState("");
    const context = useContext(DetailBarContext);
    const width_1199 = useMediaQuery({
        query: '(min-width: 1199px)'
    })
    const width_991 = useMediaQuery({
        query: '(min-width: 991px)'
    })
    const width_767 = useMediaQuery({
        query: '(min-width: 766px)'
    })
    const width_min = useMediaQuery({
        query: '(min-width: 0px)'
    })

    useEffect(() => {
        sessionStorage.setItem('root', '/payments');
        if (navigator.onLine) {
            if (sessionStorage.getItem("notificationId") != null) {
                const url = sessionStorage.getItem("notificationId").replaceAll('"', '')
                navigateToMessageDetails(url);
            }
            handleRinging();
            getPeoplePaymentNotification();
            if (!searchMode) {
                getPeople();
            }
        } else {
            getPeopleOffline();
        }
        if (searchMode) {
            const delayDebounceFn = setTimeout(() => {
                searchPeople(searchValue)
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchValue]);

    useEffect( () => {
        if(isDesktop) {
            if(!first && context.openCheckoutDialog.isCompleted) {
                getPeoplePaymentNotification();
                getPeople();
            } else {
                first = false;
            }
        }
    }, [context?.openCheckoutDialog?.isCompleted]);

    const client = new ClientJS();
    const [bellRinging, setBellRinging] = useState(false);
    const [personalPolicies, setPersonalPolicies] = useState([]);
    const [peopleFiltered, setPeopleFiltered] = useState([]);
    const [people, setPeople] = useState([]);
    const selectedAvatar = useRef("null");
    const [searchMode, setSearchMode] = useState(false);
    const [timeoutAvatar, setTimeoutAvatar] = useState(false);
    const [noPeople, setNoPeople] = useState(false);
    const [getPermission, setGetPermission] = useState(false);
    const filter = useRef('STATUS_DEFAULT');
    const [openMenu, setOpenMenu] = useState(null);
    const [selectedRadio, setSelectedRadio] = useState('');
    const [serviceError, setServiceError] = useState(false);
    const [paymentNotification, setPaymentNotification] = useState([])
    const [requestedCents, setRequestedCents] = useState(0)
    const [currency, setCurrency] = useState("€");
    const [overallPremium, setOverallPremium] = useState(0);
    const [personId, setPersonId] = useState(null);
    const [slimpayPeople, setSlimpayPeople] = useState([])
    const [disablePaymentButton, setDisablePaymentButton] = useState(false)

    //desktop variables
    const [selectedAvatarState, setSelectedAvatarState] = useState(null);



    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone()) || (isMac() && client.getOSVersion() < "13")) {
        //console.log('notification not allowed')
    } else if (!getPermission) {
        requestPermission();
        setGetPermission(true);
    }
    fetchToken();

    function requestPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
            }
        })
    }

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone()) || (isMac() && client.getOSVersion() < "13")) {
        //console.log('notification not allowed')
    } else {
        const channel = new BroadcastChannel('sw-messages');
        channel.onmessage = (event) => {
            setBellRinging(true)
        };
        channel.onmessageerror = (event) => {
        };
    }

    const navigateToMessageDetails = (url) => {
        return navigate(`/messages/principal/${url}`);
    }

    function getPeople() {
        setIsLoadingPeople(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                sessionStorage.setItem('noPeople', true);
                setNoPeople(true);
            } else {
                sessionStorage.removeItem('noPeople');
                setNoPeople(false);
            }
            setPeople(data)
            setPeopleFiltered(data.filter(person => person.payableNotificationsNr > 0))
            putElementFromIndexedDB('people', data)
            setIsLoadingPeople(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPeopleOffline() {
        getElementFromIndexedDB('people').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                sessionStorage.setItem('noPeople', true);
            } else {
                sessionStorage.removeItem('noPeople');
            }
            setPeople([])
            for (let i = 0; i < length; i++) {
                if (!personalPolicies.includes(data[i])) {
                    setPeople(current => [...current, data[i]])
                }
            }
        });
    }

    const handleRinging = () => {
        fetch(checkReadUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.toRead) {
                setBellRinging(true);
            } else {
                setBellRinging(false);
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    const setGrayScale = (value) => {
        switch (value) {
            case 1:
                return 'grayscale(1)';
            case 2:
            case 3:
            case 4:
                return '';
        }
    }

    const handleClickPerson = (id) => {
        setCurrency('')
        setOverallPremium(0)
        setTimeoutAvatar(true);
        setPaymentNotification([])
        selectedAvatar.current = selectedAvatar.current === id ? null : id;
        getPeoplePaymentNotification(selectedAvatar.current);
        setTimeout(function () {
            setTimeoutAvatar(false);
        }, 1000);
    }

    const handleSearchMode = () => {
        if (searchMode && searchValue !== "") {
            setSearchValue("")
            document.querySelector('#search-input').value = "";
            document.querySelector('#search-input').focus();
        } else {
            setSearchMode(current => !current);
            getPeople();
        }
    }

    const searchPeople = (text) => {
        setIsLoadingPeople(true)
        fetch(`${searchPeopleUrl}?text=${text}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPeople([]);
            setPeople(data);
            setIsLoadingPeople(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setOpenMenu(null);
    }

    const handleFilter = (selectedFilter) => {
        if (filter.current === selectedFilter) {
            filter.current = 'STATUS_DEFAULT';
        } else {
            filter.current = selectedFilter;
        }
        handleCloseMenu();
        if (selectedAvatar.current === "null") {
            //getPersonalPolicies();
            //getPeoplePolicies();
        } else if (selectedAvatar.current === "personal") {
            //getPersonalPolicies();
        } else {
            //getPersonPolicies(selectedAvatar.current);
        }
    }

    const stringFilter = () => {
        switch (filter.current) {
            case 'INSURANCE_END_ASC':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-ascending-date')}`;
            case 'INSURANCE_END_DESC':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-descending-date')}`;
            case 'STATUS_ACTIVE':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-active')}`;
            case 'STATUS_EXPIRING':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-expirity')}`;
            case 'STATUS_EXPIRED':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-expired')}`;
            case 'STATUS_INACTIVE':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-killed')}`;
        }
    }

    const getPeoplePaymentNotification = (item) => {
        setPaymentNotification([]);
        setIsLoadingNotifications(1)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let arr = []
            data.map((person, index) => {
                if (person.slimpayIsPending === true) {
                    setSlimpayPeople(current => [...current, person.id])
                    arr.push(person.id)
                }
            })
            return arr;
        }).then((slimpayPeopleData) => {
            getPayments(token, item).then((data) => {
                let count = 0;
                data.map((value, index) => {
                    let removeCurrency;
                    switch (value.requestedCurrency) {
                        case 'EUR':
                            removeCurrency = value.requestedPremium.split('€');
                            break;
                        case 'USD':
                            removeCurrency = value.requestedPremium.split('$');
                            break;
                        case 'GBD':
                            removeCurrency = value.requestedPremium.split('£');
                            break;
                    }
                    let currentValue = removeCurrency[0].replace(',', '.')
                    let floatCurrentValue = parseFloat(currentValue)
                    if (!slimpayPeopleData.includes(value.personId)) {
                        setOverallPremium(current => current + floatCurrentValue)
                    }
                    let minDate;
                    count = count + 1;
                    let policiesArr = []
                    value.titles.map((value, index) => {
                        if (index === 0) {
                            minDate = value.datebill
                        }
                        if (value.datebill < minDate) {
                            minDate = value.datebill
                        }
                        if (!policiesArr.includes(value.policy_id)) {
                            policiesArr.push(value.policy_id)
                        }
                    })
                    localStorage.setItem('policiesArray', JSON.stringify(policiesArr))
                    value.datebill = minDate
                    setPaymentNotification(current => [...current, value])
                    if (index === 0 && !slimpayPeopleData.includes(value.personId)) {
                        if (value.notificationType !== 'SDD') {
                            setPersonId(value.personId)
                            setSelectedRadio(value.id)
                            setRequestedCents(value.requestedPremium)
                            switch (value.requestedCurrency) {
                                case 'EUR':
                                    setCurrency("€");
                                    break;
                                case 'USD':
                                    setCurrency("$");
                                    break;
                                case 'GBD':
                                    setCurrency("£");
                                    break;
                            }
                        } else {
                            setDisablePaymentButton(true)
                        }
                    } else if (index === 0 && slimpayPeopleData.includes(value.personId)) {
                        setDisablePaymentButton(true)
                    }
                    setIsLoadingNotifications(2)
                })
                setOverallPremium(current => current.toString().replace('.', ','))

                if (count === 0) {
                    setIsLoadingNotifications(2)
                }
            }).catch(() => {
                setServiceError(true)
            });
        }).catch(() => {
            setServiceError(true)
        });
    }

    function reload() {
        window.location.reload();
    }

    const linkToPaymentMethod = () => {
        fetch(`${operationUrl}/createOrUpdate`, {
            method: 'POST',
            body: JSON.stringify({
                type: 'PAYMENT',
                entityId: selectedRadio
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(() => {
            return navigate(`/payments/payment-method?notificationIds=${selectedRadio}&personId=${personId}`)
        }).catch(() => {
            setServiceError(true);
        });
    }

    const onClickPaymentNotificationSummary = (id) => {
        return navigate(`/payments/${id}/summary`)
    }

    const onClickPaymentNotification = (id, premium, personId, policiesArr) => {
        setSelectedRadio(id)
        setRequestedCents(premium)
        setPersonId(personId)
        localStorage.setItem('policiesArray', JSON.stringify(policiesArr))
    }

    //desktop functions

    const handleAvatar = (e, item) => {
        e.preventDefault();
        if (selectedAvatarState === item) {
            getPeoplePaymentNotification();
            setSelectedAvatarState(false);
            return;
        }
        setSelectedAvatarState(item);
        getPeoplePaymentNotification(item);
    };

    const controllerState = {
        serviceError,
        people,
        peopleFiltered,
        filter,
        selectedAvatar,
        timeoutAvatar,
        paymentNotification,
        requestedCents,
        currency,
        overallPremium,
        slimpayPeople,
        selectedRadio,
        disablePaymentButton,
        bellRinging,
        isLoadingNotifications,
        isLoadingPeople,
        searchMode,
        stringFilter,
        linkToPaymentMethod,
        setSearchValue,
        setStatusColor,
        setGrayScale,
        handleClickPerson,
        handleSearchMode,
        handleOpenMenu,
        handleCloseMenu,
        handleFilter,
        noPeople,
        openMenu,
        reload,
        onClickPaymentNotificationSummary,
        onClickPaymentNotification,
        width_min,
        width_767,
        width_991,
        width_1199,

        //desktop functions
        handleAvatar,
        selectedAvatarState

    };

    if (isDesktop) {
        return (
            <Fragment>
                <PaymentsDesktop {...controllerState}/>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <PaymentsMobile {...controllerState}/>
            </Fragment>
        );
    }
}
