import React from 'react';
import {Button} from '@mui/material';
import {styled} from "@mui/system";
import themeContext from "../ThemeContext.js";

const ContainerTitle = styled('div')(({theme}) => ({
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    gap: '16px',
    width: '100%',
    paddingTop: '9px',
    paddingBottom: '9px',
}));

const RowTitle = styled('div')(({theme}) => ({
    display: "flex",
    alignItems: 'center',
    gap: '16px'
}));

const StyledTitle = styled('span')(({theme}) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeContext.color.neutral400,
    textTransform: "uppercase"
}));

const CardTitle = React.memo(function CardTitle({title, button, buttonAction, buttonIcon}) {
    return (
        <ContainerTitle>
            <RowTitle>
                <StyledTitle>{title}</StyledTitle>
            </RowTitle>
            {button && <div>
                <Button variant="outlined" sx={{borderRadius: '12px'}} onClick={() => buttonAction()}
                        endIcon={<img src={buttonIcon} alt={button}/>}>{button}</Button>
            </div>}
        </ContainerTitle>
    );
});

export default CardTitle;