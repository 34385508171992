import React from 'react'
import Dialog from "@mui/material/Dialog";
import {useTranslation} from "react-i18next";
import {Col, Row} from "react-bootstrap";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import {StyledBigDialog} from "../styles/DesktopStyles.js";
import {StyledBackdrop} from "../styles/Styles.js";
import DialogContent from "@mui/material/DialogContent";
import wideLogoLight from "../../images/wideLogo-icon-new.svg";
import spinner from "../../images/spinner.svg";
import {makeStyles} from "@mui/styles";
import themeContext from "./ThemeContext.js";
import {Slide} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    accessDialogTitle: {
        color: `${themeContext.color.mainDark} !important`,
        textAlign: 'center !important',
        leadingTrim: 'both !important',
        textEdge: 'cap !important',
        fontSize: '26px !important',
        fontStyle: 'normal !important',
        fontWeight: '700 !important',
        lineHeight: '220% !important',
    },
    standardDescriptionDialog: {
        padding: '0 2% 0 2%',
        fontSize: '16px',
        textAlign: 'center',
    },
    mobileDescriptionDialog: {
        fontSize: '16px',
        textAlign: 'center'
    }
}));


const DesktopFirstAccessDialog = ({
                           open,
                           isDesktop,
                           isLoadingUpdateUserVersion,
                           setIsLoadingUpdateUserVersion,
                           updateUserVersion
                       }) => {

    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <Dialog
            components={{Backdrop: StyledBackdrop}}
            fullScreen={!isDesktop}
            fullWidth={!isDesktop}
            maxWidth={'sm'}
            PaperProps={{style: StyledBigDialog}}
            open={open}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            style={{top: isDesktop ? 0: '8%'}}>
            <DialogContent className={'no-scrollbar'}>
                <Container className="dialog-main-container" style={{paddingBottom: "30px"}}>
                    <div className={'d-flex justify-content-center'} style={isDesktop ? {padding: 40} : {padding: 40, marginTop: 50}}>
                        <img src={wideLogoLight} alt="wide-logo" height={50}/>
                    </div>
                    <div style={isDesktop ? {marginTop: '-20px'} : {}}>
                        <Row>
                            <Col>
                                <h6 className={classes.accessDialogTitle}>
                                    {t('login.dialog-new-desktop-version.title')}
                                </h6>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '10px'}}>
                            <Col>
                                <h6 className={isDesktop ? classes.standardDescriptionDialog : classes.mobileDescriptionDialog}
                                style={{fontWeight: 'bold', fontSize: '20px'}}>
                                    {t('login.dialog-new-desktop-version.description-1')}
                                </h6>
                            </Col>
                        </Row>
                        <Row  style={{marginTop: '50px'}}>
                            <Col>
                                <h6 className={isDesktop ? classes.standardDescriptionDialog : classes.mobileDescriptionDialog}
                                    style={{color: themeContext.color.neutral400, fontSize: '18px'}}>
                                    {t('login.dialog-new-desktop-version.description-2.title')}
                                </h6>
                                <h6 className={isDesktop ? classes.standardDescriptionDialog : classes.mobileDescriptionDialog}
                                    style={{color: themeContext.color.neutral400, fontSize: '18px'}}>
                                    {t('login.dialog-new-desktop-version.description-2.value-1')}
                                </h6>
                                <h6 className={isDesktop ? classes.standardDescriptionDialog : classes.mobileDescriptionDialog}
                                    style={{color: themeContext.color.neutral400, fontSize: '18px'}}>
                                    {t('login.dialog-new-desktop-version.description-2.value-2')}
                                </h6>
                            </Col>
                        </Row>
                        <Row style={{textAlign: 'center', marginTop: '50px'}}>
                            <Col>
                                <Button className="close-button-no-padding"
                                        variant="outlined"
                                        size="large"
                                        style={{
                                            color: '#ffffff',
                                            width: '288px',
                                            textTransform: 'none',
                                            fontSize: '16px',
                                        }}
                                        onClick={() => {
                                            if (isLoadingUpdateUserVersion === false) {
                                                setIsLoadingUpdateUserVersion(true)
                                                updateUserVersion()
                                            }
                                        }}>
                                    {isLoadingUpdateUserVersion ? <img src={spinner} className="spinner-img"
                                                                       alt="spinner"/> : t('login.dialog-new-desktop-version.button-try-now')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </DialogContent>
        </Dialog>
    )
}
export default DesktopFirstAccessDialog;
