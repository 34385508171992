import React, {Fragment, useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import nothing from '../../../images/nothing-icon.svg';
import AuthContextMiddleware from "../../middleware/AuthContextMiddleware.js";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/it';
import back from '../../../images/back-icon.svg';
import 'react-swipeable-list/dist/styles.css';
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import car from "../../../images/car-blue.svg";
import {useMediaQuery} from "react-responsive";
import cardPlaceholder_min from "../../../images/card-placeholder_min.svg";
import cardPlaceholder_678 from "../../../images/card-placeholder_678.svg";
import cardPlaceholder_918 from "../../../images/card-placeholder_918.svg";
import cardPlaceholder_full from "../../../images/card-placeholder_full.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../../components/ThemeContext.js";
import ErrorPage from "../../pages/ErrorPage.js";
import {
    ExternalPageLayout,
    StyledPageCard,
    StyledPageLayout,
    StyledTitlePage,
    StyledTitlePageWithImage
} from "../../styles/DesktopStyles.js";
import {SidebarContext} from "../../middleware/SidebarContext.js";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important",
        width: "100% !important",
    },
    card: {
        margin: "0 auto !important",
        marginTop: "10px !important",
        marginBottom: "18px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        minHeight: "140px !important"
    },
}));


export default function PolicyClaims(styles) {
    const [isLoadingSX, setIsLoadingSX] = useState(true);
    const [isLoadingDX, setIsLoadingDX] = useState(true);
    const params = useParams();
    const personId = params.personId;
    const policyId = params.policyId;
    const vehicleId = params.vehicleId;

    useEffect(() => {
        getPolicy();
        getPolicyClaims();
    }, []);

    const {open} = useContext(SidebarContext);
    const [activeClaims, setActiveClaims] = useState([]);
    const [notActiveClaims, setNotActiveClaims] = useState([]);
    const [policy, setPolicy] = useState([]);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [tab, setTab] = useState(sessionStorage.getItem('tab_policyClaims') ? parseInt(sessionStorage.getItem('tab_policyClaims')) : 0);
    const classes = useStyles();
    const getPolicyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies`;
    const policyClaimsUrl = `${process.env.REACT_APP_BASE_URL}/claims/policy`;
    //const tabLabels = ["Ricevuti", "Archiviati"];
    let tabStyle = {
        borderBottom: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
    };
    let tabClass = {
        //
    };
    let labelStyle = {
        textTransform: "none",
        fontWeight: "700"
    };

    const token = sessionStorage.getItem('token');

    const width_1199 = useMediaQuery({
        query: '(min-width: 1199px)'
    })
    const width_991 = useMediaQuery({
        query: '(min-width: 991px)'
    })
    const width_767 = useMediaQuery({
        query: '(min-width: 766px)'
    })
    const width_min = useMediaQuery({
        query: '(min-width: 0px)'
    })


    function handleTabs(event, value) {
        setTab(value);
        sessionStorage.setItem('tab_policyClaims', value);
    }

    function reload() {
        if (navigator.onLine) {
            window.location.reload();
        }
    }

    function getPolicyClaims() {
        setIsLoadingSX(true);
        setIsLoadingDX(true);
        fetch(`${policyClaimsUrl}/${policyId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            data.map((item, index) => {
                if (item.status !== -1) {
                    if (item.closedate !== null) {
                        setNotActiveClaims(current => [...current, item]);
                    } else {
                        setActiveClaims(current => [...current, item]);
                    }
                }
            })
            setIsLoadingDX(false);
            setIsLoadingSX(false);
        }).catch(() => {
            setErrorPage(true)
        })
    }

    function getPolicy() {
        fetch(`${getPolicyUrl}/${policyId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setPolicy(data);
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
            case 5:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    function turnBack() {
        sessionStorage.removeItem('tab_policyClaims');
        return navigate(-1);
    }

    const onClickClaim = (id) => {
        if (window.location.href.includes('dashboard')) {
            return navigate(`/claims/claim/${id}`);
        } else if (window.location.href.includes('claims')) {
            return navigate(`/claims/claim/${id}`);
        } else if (window.location.href.includes('people')) {
            return navigate(`/settings/people/${personId}/claims/${id}`);
        } else if (window.location.href.includes('vehicles')) {
            return navigate(`/settings/vehicles/${vehicleId}/claims/${id}`);
        }
    }

    return (
        <ExternalPageLayout open={open}>
            <StyledPageCard>
                {
                    errorPage ?
                        <ErrorPage/> :
                        <StyledPageLayout className="w-100">
                            <StyledTitlePageWithImage height={110}>
                                <Col>
                                    <Row className="first-nav-top-row-shadow">
                                        <Col className='col-2 float-left'>
                                            <img src={back} onClick={turnBack}
                                                 className="nav-top-logo float-left cursor-pointer"
                                                 height="27px" alt="back"/>
                                        </Col>
                                        <Col className="col-8">
                                            <span className="header-title ellipsis-1-v2">
                                                {t('claim.title')}
                                                </span>
                                        </Col>
                                        <Col className="col-2"></Col>
                                    </Row>
                                    <Row>
                                        <div className={classes.root}>
                                            <Tabs
                                                value={tab}
                                                onChange={handleTabs}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="fullWidth">
                                                <Tab
                                                    key="Attive"
                                                    label={<span
                                                        style={labelStyle}>{t('claim.opened-claims-tab')}</span>}
                                                    classes={tabClass}
                                                    style={tabStyle}/>
                                                <Tab
                                                    key="Non attive"
                                                    label={<span
                                                        style={labelStyle}>{t('claim.closed-claims-tab')}</span>}
                                                    classes={tabClass}
                                                    style={tabStyle}/>
                                            </Tabs>
                                        </div>
                                    </Row>
                                </Col>
                            </StyledTitlePageWithImage>

                            <Row className="px-4" style={{background: themeContext.color.neutral10}}>
                                <Col>
                                    {
                                        isLoadingSX && tab === 0 &&
                                        <Fragment>
                                            <Row className="mt-2">
                                                <Col className="col-list">
                                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && width_1199 &&
                                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-list">
                                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && width_1199 &&
                                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-list">
                                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && width_1199 &&
                                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-list">
                                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && width_1199 &&
                                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    }
                                    {
                                        isLoadingDX && tab === 1 &&
                                        <Fragment>
                                            <Row className="mt-2">
                                                <Col className="col-list">
                                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && width_1199 &&
                                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-list">
                                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && width_1199 &&
                                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-list">
                                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && width_1199 &&
                                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-list">
                                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && !width_1199 &&
                                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                                    {width_min && width_767 && width_991 && width_1199 &&
                                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    }
                                    {
                                        !isLoadingSX && activeClaims.length === 0 && tab === 0 &&
                                        <Container>
                                            <Row>
                                                <img src={nothing} className="noMessages"
                                                     alt={t('claim.nothing-text-1')}
                                                ></img>
                                            </Row>
                                            <Row>
                                                <Col>
                                                        <span
                                                            className="no-messages-text">{t('claim.nothing-text-1')}</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                        <span
                                                            className="no-messages-text">{t('claim.nothing-text-2')}</span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    }
                                    {
                                        !isLoadingDX && notActiveClaims.length === 0 && tab === 1 &&
                                        <Container>
                                            <Row>
                                                <img src={nothing} className="noMessages"
                                                     alt={t('claim.nothing-text-1')}
                                                ></img>
                                            </Row>
                                            <Row>
                                                <Col>
                                                        <span
                                                            className="no-messages-text">{t('claim.nothing-text-1')}</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                        <span
                                                            className="no-messages-text">{t('claim.nothing-text-2')}</span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    }
                                    {
                                        !isLoadingSX && tab === 0 && activeClaims.length > 0 &&
                                        activeClaims.map((value, index) => (
                                            <Card className={classes.card}
                                                  key={index}
                                                  onClick={() => onClickClaim(value.id)}
                                                /*style={{'borderRight': `12px solid ${setStatusColor(tab.status)}`}}*/>
                                                <CardContent style={{'paddingBottom': '7px'}}>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-mid ellipsis-1-v2">
                                                                {value.claimtype}
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">
                                                                {t('claim.practise-number')}
                                                                <span className="font-weight-bold black">
                                                        {value.claimnr}
                                                    </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">{t('claim.open-date')}
                                                                <span
                                                                    className="font-weight-bold black">
                                                    <Moment locale="it" format="DD/MM/YYYY">{value.eventdate}</Moment>
                                                </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    {value.controparte && <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">
                                                                {t('claim.counterpart')}
                                                                <span className="font-weight-bold black">
                                                        {value.controparte}
                                                    </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>}
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content">
                                                        <span className="ellipsis-1-v2">
                                                         {t('claims.status')}
                                                            <span className="font-weight-bold">
                                                            <span
                                                                style={(value.settledValueCents > 0 && value.closeType === 'SINISTRO CHIUSO') ? {color: themeContext.color.green}
                                                                    : value.closeType === 'SINISTRO APERTO' ? {color: themeContext.color.mainActive}
                                                                        : (value.closeType === 'SINISTRO CHIUSO' ? {color: themeContext.color.red}
                                                                            : (value.closeType === 'SENZA SEGUITO' ? {color: themeContext.color.neutral200} : {}))}>
                                                                                    {value.closeType}
                                                            </span>
                                                            </span>
                                                        </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    {value.settledValueCents > 0 && <Row>
                                                        <Col>
                                                            <h6 className="card-text-content">
                                                        <span className="ellipsis-1-v2">
                                                        {t('claims.settled-value')}
                                                            <span className="font-weight-bold black">
                                                            {value.settledValue}
                                                        </span>
                                                        </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>}
                                                    {
                                                        value.plate !== null &&
                                                        <Row>
                                                            <div className="col-plate">
                                                                <img src={car} alt="car icon"/>
                                                                <span className="card-text-content"
                                                                      style={{'marginLeft': '7px'}}>{value.plate}</span>
                                                            </div>
                                                        </Row>
                                                    }
                                                </CardContent>
                                            </Card>
                                        ))
                                    }
                                    {!isLoadingDX && tab === 1 && notActiveClaims.length > 0 &&
                                        notActiveClaims.map((value, index) => (
                                            <Card className={classes.card}
                                                  key={index}
                                                  onClick={() => onClickClaim(value.id)}>
                                                <CardContent style={{'paddingBottom': '7px'}}>
                                                    <Row>
                                                        <Col className="col-8">
                                                            <h6 className="card-text-mid ellipsis-1-v2">
                                                                {value.claimtype}
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">
                                                                {t('claim.practise-number')}
                                                                <span className="font-weight-bold black">
                                                        {value.claimnr}
                                                    </span>

                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">{t('claim.open-date')}
                                                                <span
                                                                    className="font-weight-bold black">
                                                    <Moment locale="it" format="DD/MM/YYYY">{value.eventdate}</Moment>
                                                </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    {value.controparte && <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">
                                                                {t('claim.counterpart')}
                                                                <span className="font-weight-bold black">
                                                        {value.controparte}
                                                    </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>}
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">
                                                                {t('claims.status')}
                                                                <span className="font-weight-bold">
                                                        <span
                                                            style={(value.settledValueCents > 0 && value.closeType === 'SINISTRO CHIUSO') ? {color: themeContext.color.green}
                                                                : value.closeType === 'SINISTRO APERTO' ? {color: themeContext.color.mainActive}
                                                                    : (value.closeType === 'SINISTRO CHIUSO' ? {color: themeContext.color.red}
                                                                        : (value.closeType === 'SENZA SEGUITO' ? {color: themeContext.color.neutral200} : {}))}>
                                                               {value.closeType}
                                                        </span>
                                                        </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    {value.settledValueCents > 0 && <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">
                                                                {t('claims.settled-value')}<span
                                                                className="font-weight-bold black">
                                                            {value.settledValue}
                                                </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>}
                                                    {
                                                        value.plate !== null &&
                                                        <Row>
                                                            <div className="col-plate">
                                                                <img src={car} alt="car icon"/>
                                                                <span className="card-text-content"
                                                                      style={{'marginLeft': '7px'}}>{value.plate}</span>
                                                            </div>
                                                        </Row>
                                                    }
                                                </CardContent>
                                            </Card>
                                        ))
                                    }
                                </Col>
                            </Row>
                        </StyledPageLayout>
                }
            </StyledPageCard>
        </ExternalPageLayout>
    )
}