import React, {Fragment, useEffect, useState} from 'react'
import Dialog from "@mui/material/Dialog";
import {Backdrop, InputLabel} from "@mui/material";
import themeContext from "../ThemeContext.js";
import DialogTitle from "@mui/material/DialogTitle";
import {useTranslation} from "react-i18next";
import Iframe from "react-iframe";
import {
    generatePayment,
    getSlimPayOrNexi,
    PAYMENT_METHOD,
    paymentReceived,
    sendUpdatePass,
    setSlimpayPending
} from "../../service/PaymentsService.js";
import exitIcon from "../../../images/exit-icon.svg";
import copyIcon from "../../../images/copy-icon.svg";
import {Col, Row} from "react-bootstrap";
import {operationComplete, operationCreateOrUpdate} from "../../service/CommonService.js";
import UseCopyToClipboard from "../../middleware/UseCopyToClipboard.js";
import {makeStyles} from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import infoPaymentIcon from "../../../images/payment-info-icon.svg";
import {StyledBackdrop} from "../../styles/Styles.js";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    infoText: {
        fontSize: '12px !important',
        fontWeight: 'normal !important',
        color: `${themeContext.color.neutral400} !important`,
    },
    rootList: {
        width: '100% !important',
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "0 0 0 0 !important",
    },
    listItem: {
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '18px !important',
        paddingBottom: '18px !important',
    },
    listTextTip: {
        textTransform: 'uppercase !important',
        color: `${themeContext.color.neutral400} !important`,
        fontSize: '12px !important !important',
    },
    listTextDescr: {
        color: `${themeContext.color.neutral400} !important`,
        fontSize: '18px !important',
        fontWeight: 'bold !important'
    },
}));

const CheckoutDialog = ({
                            open,
                            handleClose,
                            title,
                            titleStyle,
                            dataType,
                            data,
                        }) => {

    const {t} = useTranslation();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = React.useState(false);
    const [url, setUrl] = React.useState('');
    const token = sessionStorage.getItem('token');
    const root = 'desktop.payments.checkout';
    const [isSentNotification, setIsSentNotification] = useState(false);
    const [tooltipList, setTooltipList] = useState([]);
    const classes = useStyles();
    const [value, copy] = UseCopyToClipboard()
    const [id, setId] = useState(null);
    const [bankInfo, setBankInfo] = useState([]);

    useEffect(() => {
        if (data && dataType) {
            setUrl('');
            if (dataType !== PAYMENT_METHOD.BANK) {
                operationCreateOrUpdate(token, 'PAYMENT', data.id);
                setIsLoading(true);
                generatePayment(token, dataType === PAYMENT_METHOD.CREDIT ? 'nexi' : 'sdd', data.id).then((response) => {
                    if (response.ok) {
                        setUrl(response.message);
                        if (dataType === PAYMENT_METHOD.DEBIT) {
                            setId(response.orderId);
                        }
                    } else {
                        handleClose(isSentNotification);
                        navigate('/error-page')
                    }
                }).then(() => {
                    setIsLoading(false);
                }).catch(error => {
                    setIsLoading(false);
                    handleClose(isSentNotification);
                    navigate('/error-page')
                });
            } else {
                const bankInfoTemp = [
                    {
                        title: t('paymentsMethod.bankTransfer-contractor-1'),
                        tooltip: t('paymentsCheckout.copied-text'),
                        reason: data.nr
                    },
                    {
                        title: t('paymentsMethod.bankTransfer-contractor'),
                        tooltip: t('paymentsCheckout.copied-text'),
                        reason: t('paymentsMethod.bankTransfer-contractor-1')
                    },
                    {
                        title: t('paymentsMethod.bankTransfer-iban'),
                        tooltip: t('paymentsCheckout.copied-text'),
                        reason: t('paymentsMethod.bankTransfer-iban-1')
                    },
                    {
                        title: t('paymentsMethod.bankTransfer-total'),
                        tooltip: t('paymentsCheckout.copied-text'),
                        reason: data.requestedPremium
                    },
                    {
                        title: t('paymentsMethod.bankTransfer-bic-swift'),
                        tooltip: t('paymentsCheckout.copied-text'),
                        reason: t('paymentsMethod.bankTransfer-bic-swift-')
                    },
                ];
                setBankInfo(bankInfoTemp)
                let newList = [];
                bankInfoTemp.map((item, key) => {
                    newList = changeTipValue(key, false, false, newList)
                })
                setTooltipList(newList);
            }

        }
    }, [data, dataType, token]);

    const changeTipValue = (key, value, timer, newList) => {
        if (newList) {
            const tempList = [...newList];
            tempList[key] = {
                value: value,
                isTimerOn: timer
            }
            return tempList
        } else {
            const tempList = [...tooltipList];
            tempList[key].value = value
            tempList[key].isTimerOn = timer
            setTooltipList(tempList)
        }
    }

    const getOrderInfo = () => {
        getSlimPayOrNexi(token, dataType, dataType === PAYMENT_METHOD.CREDIT ? data.id : id).then((result) => {
            const type = dataType === PAYMENT_METHOD.CREDIT ? 'nexi' : 'slimpay';
            if (dataType === PAYMENT_METHOD.DEBIT && result.state === 'closed.completed') {
                setSlimpayPending(token, data.personId);
                if (!isSentNotification) {
                    sendPaymentReceived(type);
                }
            }
            if (dataType === PAYMENT_METHOD.CREDIT && result.ok) {
                if (!isSentNotification) {
                    sendPaymentReceived(type);
                }
            }
        }).catch(() => {
            return handleClose(isSentNotification)
        });
    }

    const sendPaymentReceived = (type) => {
        operationComplete(token, 'PAYMENT', data.id);
        const userId = localStorage.getItem('wideAPP_userId');
        paymentReceived(token, userId, type, data.id).then((data) => {
            JSON.parse(localStorage.getItem('policiesArray')).map((policyId) => {
                sendUpdatePass(token, userId, policyId);
            })
            setIsSentNotification(true)
        }).catch(() => {
            return handleClose(isSentNotification)
        });
    }

    const getModal = () => {
        switch (dataType) {
            case PAYMENT_METHOD.CREDIT:
                return (
                    <div>
                        {
                            !isLoading &&
                            <div style={{'height': '82vh'}} className="background-loading-spinner">
                                <Iframe
                                    className="responsive-iframe"
                                    url={url}
                                    display="block"
                                    position="relative"
                                    id="iframe_id"
                                    onLoad={e => {
                                        getOrderInfo();
                                    }}
                                />
                            </div>
                        }
                    </div>
                )
            case PAYMENT_METHOD.DEBIT:
                return (<div>
                    {
                        !isLoading &&
                        <div style={{'height': '82vh'}} className="background-loading-spinner">
                            <Iframe
                                className="responsive-iframe"
                                url={url}
                                display="block"
                                position="relative"
                                id="iframe_id"
                                onLoad={e => {
                                    getOrderInfo();
                                }}
                            />
                        </div>
                    }
                </div>)
            case PAYMENT_METHOD.BANK:
                return (
                    <Fragment>
                        {
                            createBankPage()
                        }
                        <div className={'d-flex flex-row'} style={{
                            paddingLeft: 24,
                            paddingRight: 24
                        }}>
                            <Col className="col-auto" style={{paddingRight: 0, paddingLeft: 0}}>
                                <img src={infoPaymentIcon} alt="info-payment-icon"/>
                            </Col>
                            <Col
                                style={{
                                    textAlign: 'left',
                                    padding: '0px 0px 24px 3px'
                                }}>
                            <span
                                className={classes.infoText}>{t('paymentsMethod.bankTransfer-info')}</span>
                            </Col>
                        </div>
                    </Fragment>
                )
        }
    }

    const handleOpenTooltip = (key) => {
        let timerOn = tooltipList[key].isTimerOn
        changeTipValue(key, true, true, null)
        if (!timerOn) {
            setTimeout(function () {
                changeTipValue(key, false, false, null)
            }, 3000);
        }
    };

    const createBankPage = () => {
        return <div className={'d-flex flex-column'} style={{
            gap: 24,
            padding: 24
        }}>
            {
                bankInfo.length !== 0 && bankInfo.map((item, key) => {
                    return tooltipList.length !== 0 &&
                        <div key={key} className={'d-flex flex-column cursor-pointer'} onClick={() => {
                            copy(`${(item.reason)}`);
                            handleOpenTooltip(key);
                        }}>
                            <InputLabel id={`${key}`}>{(item.title)}</InputLabel>
                            <ClickAwayListener onClickAway={() => changeTipValue(key, false, false, null)}>
                                <div className={'d-flex flex-row align-items-center'} style={{
                                    gap: 8,
                                    border: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral200}`,
                                    borderRadius: 16,
                                    height: 55,
                                    paddingLeft: 16,
                                    paddingRight: 16
                                }}>
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        onClose={() => {
                                            changeTipValue(key, false, false, null)
                                        }}
                                        open={tooltipList[key].value}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={t(item.tooltip)}
                                        placement="right">
                                        <div className={'d-flex flex-row align-items-center'} style={{
                                            gap: 8,
                                        }}>
                                                <span>
                                                    {(item.reason)}
                                                </span>
                                            <img src={copyIcon} alt="copy" style={{'height': '18px'}}/>
                                        </div>
                                    </Tooltip>
                                </div>
                            </ClickAwayListener>
                        </div>
                })
            }
        </div>
    }

    return (
        <Dialog
            components={{Backdrop: StyledBackdrop}}
            open={open}
            keepMounted
            onClose={() => handleClose(isSentNotification)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth={'sm'}
            PaperProps={{
                style: {
                    color: themeContext.color.neutral600,
                    borderTopLeftRadius: 24,
                    borderTopRightRadius: 24,
                    borderRadius: 24,
                    scrollbarWidth: 'none',
                    background: themeContext.color.white,
                    minHeight: 300,
                    maxWidth: dataType !== PAYMENT_METHOD.BANK ? 1000 : 700
                }
            }}
        >
            {
                title && dataType !== PAYMENT_METHOD.BANK &&
                <DialogTitle id="alert-dialog-slide-title" style={titleStyle}>
                    <Row>
                        <Col className="float-right" style={{paddingLeft: 'calc(15px + 8%)'}}>
                            {t(`${root}.${title}`)}
                        </Col>
                        <Col className="col-1 float-right">
                            <img src={exitIcon} className="exit-button" alt="close" height="32px"
                                 onClick={() => handleClose(isSentNotification)} style={{float: 'inline-end'}}/>
                        </Col>
                    </Row>
                </DialogTitle>
            }
            {
                title && dataType === PAYMENT_METHOD.BANK &&
                <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                             style={{textAlign: 'center', paddingBottom: '0px'}}>
                    <Row>
                        <Col className="col-7 float-left">
                            <h6 className="header-title ellipsis-1-v2"
                                style={{
                                    'paddingBottom': '3px',
                                    'textAlign': 'left',
                                    'fontSize': '22px'
                                }}
                            > {t(`${root}.${title}`)}</h6>
                        </Col>
                        <Col style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close" height="32px"
                                 onClick={() => handleClose(isSentNotification)} style={{float: 'inline-end'}}/>
                        </Col>
                    </Row>
                </DialogTitle>
            }
            {
                data &&
                <Fragment>
                    {
                        getModal()
                    }
                </Fragment>
            }
        </Dialog>
    )
}
export default CheckoutDialog;
