import {apiFetch} from "./ApiService.js";
import {archivedUrl, messagesV2Url, resumeUrl, updateUrl} from "./EndpointService.js";

export const MESSAGE_TYPE = {
    NORMAL: 'NORMAL',
    NORMAL_NOT_READ: 'NORMAL_NOT_READ',
    NORMAL_NOT_IMPORTANT: 'NORMAL_NOT_IMPORTANT',
    ARCHIVE: 'ARCHIVE',
    ARCHIVE_NOT_READ: 'ARCHIVE_NOT_READ',
    CLAIMS: 'CLAIMS',
    CLAIMS_NOT_READ: 'CLAIMS_NOT_READ',
    CLAIMS_ARCHIVE: 'CLAIMS_ARCHIVE',
    CLAIMS_ARCHIVE_NOT_READ: 'CLAIMS_ARCHIVE_NOT_READ'
}

export const getMessagesV2Url = async (token_arg, page, size, type) => {
    let url = `${messagesV2Url}`;
    if (page === 0 || page) url += `?page=${page}`;
    if (size) url += `${(page === 0 || page) ? '&' : '?'}size=${size}`;
    if (type) url += `${(page === 0 || page) || size ? '&' : '?'}type=${type}`;

    try {
        return await apiFetch(url, token_arg);
    } catch (error) {
        return null;
    }
};

export const getResumeUrl = async (token_arg, id) => {
    let url = `${resumeUrl}/${id}`;
    try {
        return await apiFetch(url, token_arg, 'POST');
    } catch (error) {
        return null;
    }
};

export const getArchiveUrl = async (token_arg, id) => {
    let url = `${archivedUrl}/${id}`;
    try {
        return await apiFetch(url, token_arg, 'POST');
    } catch (error) {
        return null;
    }
};

export const getArchivedUrl = async (token_arg, id, body) => {
    let url = `${archivedUrl}/${id}`;
    try {
        return await apiFetch(url, token_arg, 'PUT', body);
    } catch (error) {
        return null;
    }
};

export const getUpdateUrl = async (token_arg, id, body) => {
    let url = `${updateUrl}/${id}`;
    try {
        return await apiFetch(url, token_arg, 'PUT', body);
    } catch (error) {
        return null;
    }
};
