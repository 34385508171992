import React from 'react'
import Dialog from "@mui/material/Dialog";
import {Button, Slide} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import themeContext from "./ThemeContext.js";
import {StyledBackdrop} from "../styles/Styles.js";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import DialogActions from "@mui/material/DialogActions";
import {useTranslation} from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MultiButtonDialog = ({
                                 open,
                                 handleClose,
                                 title,
                                 titleStyle,
                                 content,
                                 contentStyle,
                                 actions,
                             }) => {

    const {t} = useTranslation();

    function MailTo({
                        email,
                        subject,
                        body,
                        ...props
                    }) {
        return (
            <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
               style={{
                   'textDecoration': 'none',
                   'width': '100%',
                   'margin': '0 auto'
               }}>
                {props.children}
            </a>
        );
    }

    const getButton = (action, index) => {
        switch (action.type) {
            case 'mail':
                return (<div key={index}>
                    <Divider/>
                    <DialogActions>
                        <MailTo email={action.mail}
                                subject={action.subject}
                                body={action.body}>
                            <Button color="primary" style={action.style}
                                    fullWidth>
                                {t(action.text)}
                            </Button>
                        </MailTo>
                    </DialogActions>
                </div>)
            case 'button':
                return (
                    <div key={index}>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={() => action.onClick()} color="primary"
                                    fullWidth
                                    style={action.style}>
                                {t(action.text)}
                            </Button>
                        </DialogActions>
                    </div>
                )
            case 'phone':
                return (<div key={index}>
                    <Divider/>
                    <DialogActions>
                        <a href={`tel:${action.phone}`}
                           style={{
                               'textDecoration': 'none',
                               'width': '100%',
                               'margin': '0 auto'
                           }}>
                            <Button color="primary" fullWidth
                                    style={{
                                        'textTransform': 'none',
                                        'color': themeContext.color.green
                                    }}>
                                {t('claimDetail.modal-tel-button')}
                            </Button>
                        </a>
                    </DialogActions>
                </div>)
        }
    }

    return (
        <Dialog
            components={{Backdrop: StyledBackdrop}}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                style: {
                    'position': 'absolute',
                    'bottom': '0',
                    'left': 'auto',
                    'right': 'auto',
                    'width': '95%',
                    'borderRadius': '10px',
                    'color': themeContext.color.neutral600
                },
            }}>
            <DialogTitle id="alert-dialog-slide-title" style={titleStyle}>
                {t(title)}
            </DialogTitle>
            {content &&
                <DialogContent style={contentStyle}>
                    {t(content)}
                </DialogContent>
            }
            {
                actions.map((action, index) => (
                   getButton(action, index)
                ))
            }
        </Dialog>
    )
}
export default MultiButtonDialog;
